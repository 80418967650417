/* eslint-disable no-plusplus */
import {
  Dispatch,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
} from 'react';
import moment from 'moment';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { usePrevious } from 'Services/Hooks/use-previous';
import { logApiRequestError } from 'Services/Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { IErrorObject, SocialNetworks } from 'Services/Utils/types';
import { useGetListByIdWithPublicUsers } from 'pages/PublicUserListMembers/RQCustomHooks';
import { useGetListsByPartnerAndBusinessProfile } from 'pages/PublicUserLists/RQCustomHooks';

import {
  useUpdateBenchmark,
  useCreateBenchmarkApi,
  useSearchUserBenchmark,
} from '../api';
import { MAX_NUMBER_USERS } from '../data/constants';
import { Benchmark, SearchUserBenchmarkResponse } from '../types';

type TimeframeProps = {
  startDate: Date;
  endDate: Date;
  key: string;
};

export type SubmitBenchmarkHookProps = {
  listsAvailable: any[];
  toggleListButton: boolean;
  toggleAutoUpdate: boolean;
  primaryButtonLabel: string;
  timeFrame: TimeframeProps[];
  showTimeFramePicker: boolean;
  toggleNetworkButton: boolean;
  username: string | undefined;
  socialNetwork: SocialNetworks;
  benchmarkName: string | undefined;
  listSelectedId: string | undefined;
  isLoadingSearchUserBenchmark: boolean;
  selectedUsers: SearchUserBenchmarkResponse[];
  searchData: SearchUserBenchmarkResponse[] | undefined;
  setTimeFrame: Dispatch<SetStateAction<TimeframeProps[]>>;
  setShowTimeFramePicker: Dispatch<SetStateAction<boolean>>;
  handleGoBack: () => void;
  handleToggleListButton: () => void;
  handleToggleAutoUpdate: () => void;
  handleToggleNetworkButton: () => void;
  handleSubmitBenchmark: () => Promise<void>;
  handleClickOutToggleListButton: () => void;
  handleClickOutToggleNetworkButton: () => void;
  handleChangeUsername: (handler: string) => void;
  handleChangeBenchmarkName: (name: string) => void;
  handleChangeSocialNetwork: (network: SocialNetworks) => void;
  handleChangeListSelectedId: (id: string | undefined) => void;
  handleSelectUsers: (user: SearchUserBenchmarkResponse) => void;
};

export const useSubmitBenchmark = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location || {};
  const { benchmarkData }: { benchmarkData: Benchmark } = state || {};

  const primaryButtonLabel = benchmarkData
    ? t('benchmark.updateButton')
    : t('benchmark.createButton');

  const startDate = moment().subtract(1, 'month').utc().startOf('day');
  const endDate = moment().utc().endOf('day');

  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ];
  });

  const [toggleListButton, setToggleListButton] = useState(false);
  const [toggleAutoUpdate, setToggleAutoUpdate] = useState(false);
  const [toggleNetworkButton, setToggleNetworkButton] = useState(false);
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);

  const [username, setUsername] = useState<string | undefined>();
  const [benchmarkName, setBenchmarkName] = useState<string | undefined>();
  const [socialNetwork, setSocialNetwork] = useState(SocialNetworks.INSTAGRAM);

  const [listsAvailable, setListsAvailable] = useState<any[]>([]);
  const [listSelectedId, setListSelectedId] = useState<string | undefined>();
  const [selectedUsers, setSelectedUsers] = useState<
    SearchUserBenchmarkResponse[]
  >([]);

  const prevUsername = usePrevious(username);

  const { data: lists, isSuccess: isSuccessGetLists } =
    useGetListsByPartnerAndBusinessProfile({
      enabled: true,
    });

  useEffect(() => {
    const availableLists = [];

    if (lists) {
      for (let i = 0; i < lists.length; i++) {
        availableLists.push({
          label: lists[i].name,
          value: lists[i].id,
          data: lists[i],
        });
      }
      setListsAvailable(availableLists);
    }
  }, [lists, isSuccessGetLists]);

  const { data: listWithPublicUsers } = useGetListByIdWithPublicUsers({
    id: listSelectedId!,
    enabled: !!listSelectedId,
  });

  const usersFromList = listWithPublicUsers?.publicUsers.map(user => {
    return {
      publicUserId: user._id!,
      displayPicture: user.image!,
      username: user.socialNetwork?.username!,
      socialNetwork: user.socialNetwork?.network!,
    };
  });

  const handleChangeUsername = useCallback((handler: string) => {
    setUsername(handler);
  }, []);

  const handleChangeBenchmarkName = useCallback((name: string) => {
    setBenchmarkName(name);
  }, []);

  const handleChangeSocialNetwork = useCallback((network: SocialNetworks) => {
    setSocialNetwork(network);
    setToggleNetworkButton(false);
  }, []);

  const handleToggleNetworkButton = useCallback(() => {
    setTimeout(() => setToggleNetworkButton(!toggleNetworkButton), 0);
  }, [toggleNetworkButton]);

  const handleClickOutToggleNetworkButton = useCallback(() => {
    if (toggleNetworkButton) {
      setToggleNetworkButton(false);
    }
  }, [toggleNetworkButton]);

  const handleChangeListSelectedId = useCallback((id: string | undefined) => {
    setListSelectedId(id);
    setToggleListButton(false);
  }, []);

  const handleToggleListButton = useCallback(() => {
    setTimeout(() => setToggleListButton(!toggleListButton), 0);
  }, [toggleListButton]);

  const handleClickOutToggleListButton = useCallback(() => {
    if (toggleListButton) {
      setToggleListButton(false);
    }
  }, [toggleListButton]);

  const handleToggleAutoUpdate = useCallback(() => {
    setTimeout(() => setToggleAutoUpdate(!toggleAutoUpdate), 0);
  }, [toggleAutoUpdate]);

  const {
    data: searchUserData,
    reset: resetSearchUser,
    mutateAsync: searchUserBenchmark,
    isLoading: isLoadingSearchUserBenchmark,
  } = useSearchUserBenchmark();

  const searchData = searchUserData ?? usersFromList;

  const handleSearchUserBenchmark = useCallback(async () => {
    if (username && username.trim().length > 0) {
      try {
        await searchUserBenchmark({
          username,
          socialNetwork,
        });
      } catch (error) {
        toast.error(logApiRequestError(error as IErrorObject));
      }
    }
  }, [searchUserBenchmark, username, socialNetwork]);

  useEffect(() => {
    const debouncedFunction = debounce(handleSearchUserBenchmark, 500);

    if (username?.length! > 2 && prevUsername !== username) {
      debouncedFunction();
    } else {
      resetSearchUser();
    }

    return () => {
      debouncedFunction.cancel();
    };
  }, [username]);

  const handleSelectUsers = useCallback(
    (user: SearchUserBenchmarkResponse) => {
      const isAlreadySelected = selectedUsers.some(
        item => item.publicUserId === user.publicUserId,
      );

      const hasDifferentSocialNetwork = selectedUsers.some(
        item => item.socialNetwork !== user.socialNetwork,
      );

      if (isAlreadySelected) {
        setSelectedUsers(
          selectedUsers.filter(item => item.publicUserId !== user.publicUserId),
        );
      } else if (
        selectedUsers.length < MAX_NUMBER_USERS &&
        (!hasDifferentSocialNetwork || selectedUsers.length === 0)
      ) {
        setSelectedUsers([...selectedUsers, user]);
      }
    },
    [selectedUsers],
  );

  const { mutateAsync: updateBenchmark, isSuccess: isSuccessUpdateBenchmark } =
    useUpdateBenchmark();

  const { mutateAsync: createBenchmark, isSuccess: isSuccessCreateBenchmark } =
    useCreateBenchmarkApi();

  const handleSubmitBenchmark = useCallback(async () => {
    const publicUserIds = selectedUsers.map(user => user.publicUserId);

    try {
      if (benchmarkData) {
        await updateBenchmark({
          id: benchmarkData.id,
          name:
            benchmarkName === undefined || benchmarkName.trim() === ''
              ? `Benchmark ${moment().format('YYYY-MM-DD HH:mm:ss')}`
              : benchmarkName!,
          list: publicUserIds,
          startAt: timeFrame[0].startDate,
          endAt: toggleAutoUpdate ? null : timeFrame[0].endDate,
        });
      } else {
        await createBenchmark({
          name:
            benchmarkName === undefined || benchmarkName.trim() === ''
              ? `Benchmark ${moment().format('YYYY-MM-DD HH:mm:ss')}`
              : benchmarkName!,
          list: publicUserIds,
          startAt: timeFrame[0].startDate,
          endAt: toggleAutoUpdate ? null : timeFrame[0].endDate,
          usersInfo: selectedUsers,
        });
      }
    } catch (error) {
      toast.error(logApiRequestError(error as IErrorObject));
    }
  }, [
    timeFrame,
    benchmarkData,
    benchmarkName,
    selectedUsers,
    updateBenchmark,
    createBenchmark,
    toggleAutoUpdate,
  ]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isSuccessCreateBenchmark) {
      toast.success(t('benchmark.toast.create'));

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }

    if (isSuccessUpdateBenchmark) {
      toast.success(t('benchmark.toast.update'));

      setTimeout(() => {
        navigate('/benchmarks-list');
      }, 1000);
    }
  }, [isSuccessCreateBenchmark, isSuccessUpdateBenchmark, navigate]);

  useEffect(() => {
    if (benchmarkData) {
      setBenchmarkName(benchmarkData.name);
      setToggleAutoUpdate(!benchmarkData.endAt);
      setSelectedUsers(
        benchmarkData.usersBasicInfo.map(user => ({
          publicUserId: user.id,
          displayPicture: user.image!,
          socialNetwork: user.network!,
          username: user.username ?? '',
        })),
      );
      setTimeFrame([
        {
          startDate: moment(benchmarkData.startAt ?? startDate).toDate(),
          endDate: moment(benchmarkData.endAt ?? endDate).toDate(),
          key: 'selection',
        },
      ]);
    }
  }, [benchmarkData]);

  return {
    username,
    timeFrame,
    searchData,
    setTimeFrame,
    handleGoBack,
    selectedUsers,
    socialNetwork,
    benchmarkName,
    listsAvailable,
    listSelectedId,
    toggleListButton,
    toggleAutoUpdate,
    handleSelectUsers,
    primaryButtonLabel,
    showTimeFramePicker,
    toggleNetworkButton,
    handleChangeUsername,
    handleSubmitBenchmark,
    setShowTimeFramePicker,
    handleToggleListButton,
    handleToggleAutoUpdate,
    handleChangeSocialNetwork,
    handleChangeBenchmarkName,
    handleToggleNetworkButton,
    handleChangeListSelectedId,
    isLoadingSearchUserBenchmark,
    handleClickOutToggleListButton,
    handleClickOutToggleNetworkButton,
  };
};
