import { z } from 'zod';
import { t } from 'i18next';
import { GENDERS } from 'Services/Utils/types';

export const adminAgentFormSchema = z.object({
  name: z.string().nonempty({ message: t('generic.cantBeEmpty') }),
  email: z
    .string()
    .email()
    .nonempty({ message: t('generic.cantBeEmpty') }),
  password: z
    .string()
    .refine(value => /^(?=.*[a-z])(?=.*[A-Z])/.test(value), {
      message: t('signup.passwordLetterCaseWarning'),
    })
    .refine(value => /^(?=.*[0-9])/.test(value), {
      message: t('signup.passwordNumbersWarning'),
    })
    .refine(value => /^(?=.*[!@_#$%^&*])/.test(value), {
      message: t('signup.passwordSpecialCharacterWarning'),
    })
    .refine(value => value.length >= 8, {
      message: t('signup.passwordLengthWarning'),
    }),
  displayPicture: z.string().nullable(),
  topicsOfInterest: z.array(z.string()).optional(),
  country: z
    .object({
      value: z.string(),
      data: z.object({
        country: z.string(),
        countryId: z.string(),
      }),
    })
    .optional(),
  gender: z.nativeEnum(GENDERS).nullable(),
  dateOfBirth: z.number().nullable(),
  roleIds: z.array(z.string()).optional(),
  newPassword: z
    .string()
    .transform(value => (value.trim() === '' ? undefined : value))
    .optional()
    .refine(
      value => value === undefined || /^(?=.*[a-z])(?=.*[A-Z])/.test(value),
      {
        message: t('signup.passwordLetterCaseWarning'),
      },
    )
    .refine(value => value === undefined || /^(?=.*[0-9])/.test(value), {
      message: t('signup.passwordNumbersWarning'),
    })
    .refine(value => value === undefined || /^(?=.*[!@_#$%^&*])/.test(value), {
      message: t('signup.passwordSpecialCharacterWarning'),
    })
    .refine(value => value === undefined || value.length >= 8, {
      message: t('signup.passwordLengthWarning'),
    }),
});

export type AdminAgentFormData = z.infer<typeof adminAgentFormSchema>;
