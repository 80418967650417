/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  SharingAccount,
  SharingAccountConnectionStatus,
} from 'Services/SkorrApi/new/Shares/types';
import { DEFAULT_USER_AVATAR, PartnerTypes } from 'Services/Utils/types';
import VerifiedIconV1 from 'assets/iconComponents/VerifiedIconV1';
import InfoV3Icon from 'assets/iconComponents/InfoIconV3';
import { getThemeStyles } from 'css/ThemeGlobal';

import * as S from './Styles';
import {
  useGetSharedSocialDataAccess,
  useRevokeSocialDataAccess,
} from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import {
  getSocialNetworkIcon,
  isAuthenticated,
} from '../../Services/Utils/Utils';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { getShortenedMonthResourceKey } from '../../Services/Utils/dateUtils';
import Button from '../../components/shared/Button/Button';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import NoPermissionToAccessPageWarning from '../../components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { ManagerSocialDataAccessProps } from './types';

const CopyToClipboard = require('react-copy-to-clipboard');

const ManagerSocialDataAccess = (props: ManagerSocialDataAccessProps) => {
  const navigate = useNavigate();
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [
    isGetSharedSocialDataAccessEnabled,
    setIsGetSharedSocialDataAccessEnabled,
  ] = useState(true);
  const [showCopiedStatus, setShowCopiedStatus] = useState(false);
  const [showRevokeAccessModal, setShowRevokeAccessModal] = useState(false);
  const [socialUserIdToDisconnect, setSocialUserIdToDisconnect] = useState('');
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const {
    data: sharedSocialDataAccess,
    isFetching: isFetchingGetSharedSocialDataAccess,
    isSuccess: isSuccessGetSharedSocialDataAccess,
    isError: isErrorGetSharedSocialDataAccess,
    refetch: refetchGetSharedSocialDataAccess,
  } = useGetSharedSocialDataAccess(isGetSharedSocialDataAccessEnabled);
  const {
    isLoading: isLoadingRevokeSocialDataAccess,
    isSuccess: isSuccessRevokeSocialDataAccess,
    isError: isErrorRevokeSocialDataAccess,
    mutate: revokeSocialDataAccess,
  } = useRevokeSocialDataAccess();
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const themeGlobal = useTheme();
  const shareLink = useMemo(() => {
    if (process.env.REACT_APP_ENV == 'PRD') {
      return `https://m.skorr.social/p-invite/${
        sharedSocialDataAccess?.partnerInfo.partnerId! ??
        isAuthenticated()?.partnerId
      }`;
    }
    return `https://m.staging.skorr.social/p-invite/${
      sharedSocialDataAccess?.partnerInfo.partnerId! ??
      isAuthenticated()?.partnerId
    }`;
  }, [sharedSocialDataAccess?.partnerInfo.partnerId]);

  useEffect(() => {
    if (isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin) {
      setIsGetSharedSocialDataAccessEnabled(true);
      setIsOperatorOrPartnerAdminEnabled(false);
    }
  }, [isSuccessIsOperatorOrPartnerAdmin, isOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isSuccessGetSharedSocialDataAccess) {
      setIsGetSharedSocialDataAccessEnabled(false);
    }
  }, [isSuccessGetSharedSocialDataAccess]);

  useEffect(() => {
    if (isSuccessRevokeSocialDataAccess) {
      setSuccessfulActionNotificationMessage(
        props.t('managerSocialDataAccess.accessRevoked'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetSharedSocialDataAccess();
    }
  }, [isSuccessRevokeSocialDataAccess]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isErrorGetSharedSocialDataAccess) {
      setIsGetSharedSocialDataAccessEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorGetSharedSocialDataAccess]);

  useEffect(() => {
    if (isErrorRevokeSocialDataAccess) {
      setHasError(true);
      setMsgError(props.t('managerSocialDataAccess.couldNotRevokeAccess'));
    }
  }, [isErrorRevokeSocialDataAccess]);

  useLayoutEffect(() => {
    if (
      sharedSocialDataAccess == null &&
      isAuthenticated()?.partnerType == PartnerTypes.influencer
    ) {
      navigate('/discover');
    }
  }, [sharedSocialDataAccess]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }

    if (isErrorGetSharedSocialDataAccess) {
      setIsGetSharedSocialDataAccessEnabled(true);
    }
  };

  const getLastActionDateLabel = (sharedSocialAccount: SharingAccount) => {
    let action;
    let date;

    if (sharedSocialAccount.expired) {
      action = props.t('managerSocialDataAccess.expired');
      date = new Date(sharedSocialAccount.expiredAt!);
    } else {
      date = new Date(sharedSocialAccount.connection.changedAt);
      action =
        sharedSocialAccount.connection.status ==
        SharingAccountConnectionStatus.CONNECTED
          ? props.t('managerSocialDataAccess.connected')
          : props.t('managerSocialDataAccess.disconnected');
    }

    const lastActionDateLabel = `${action} ${date.getDate()} ${props.t(
      getShortenedMonthResourceKey(date),
    )} ${date.getFullYear()}`;

    return `${lastActionDateLabel}`;
  };

  const getRevokeAccessModalTitle = () => {
    if (!socialUserIdToDisconnect) {
      return (
        <S.RevokeAccessModalTitleContainer>
          {props.t('managerSocialDataAccess.revokeFullAccessModalTitle')}
          {` ${sharedSocialDataAccess?.partnerInfo.name}?`}
        </S.RevokeAccessModalTitleContainer>
      );
    }

    return (
      <S.RevokeAccessModalTitleContainer>
        {props.t('managerSocialDataAccess.revokeSingleAccessModalTitle')}
        {` ${sharedSocialDataAccess?.partnerInfo.name}?`}
      </S.RevokeAccessModalTitleContainer>
    );
  };

  const getRevokeAccessModalContent = () => {
    const accountToDisconnect = sharedSocialDataAccess?.sharingAccounts.find(
      account => account.socialUserId == socialUserIdToDisconnect,
    );

    const getSubtitle = () => {
      if (!socialUserIdToDisconnect) {
        return (
          <S.RevokeAccessModalSubtitleContainer>
            {props.t(
              'managerSocialDataAccess.revokeFullAccessModalDescriptionPart1',
            )}
            <br />
            {props.t(
              'managerSocialDataAccess.revokeFullAccessModalDescriptionPart2',
            )}
            {` ${sharedSocialDataAccess?.partnerInfo.name} `}
            {props.t(
              'managerSocialDataAccess.revokeFullAccessModalDescriptionPart3',
            )}
          </S.RevokeAccessModalSubtitleContainer>
        );
      }

      return (
        <S.RevokeAccessModalSubtitleContainer>
          {props.t(
            'managerSocialDataAccess.revokeSingleAccessModalDescriptionPart1',
          )}
          <br />
          {props.t(
            'managerSocialDataAccess.revokeSingleAccessModalDescriptionPart2',
          )}
          {` ${sharedSocialDataAccess?.partnerInfo.name} `}
          {props.t(
            'managerSocialDataAccess.revokeSingleAccessModalDescriptionPart3',
          )}
        </S.RevokeAccessModalSubtitleContainer>
      );
    };

    const getAccountToDisconnect = () => {
      return (
        <S.SharedAccountContainerToDisconnect>
          <S.SharedAccountLeftContainer>
            <S.SharedAccountImage
              src={accountToDisconnect!.displayPicture ?? DEFAULT_USER_AVATAR}
            />

            <S.SharedAccountNetworkIconContainer>
              {getSocialNetworkIcon({
                socialNetwork: accountToDisconnect!.network,
                width: '2rem',
                height: '2rem',
                gradientColored: true,
              })}
            </S.SharedAccountNetworkIconContainer>
          </S.SharedAccountLeftContainer>

          <S.SharedAccountCenterContainer>
            <S.SharedAccountUsernameContainer
              verified={
                !(
                  accountToDisconnect!.expired ||
                  accountToDisconnect!.connection.status ==
                    SharingAccountConnectionStatus.DISCONNECTED
                )
              }
            >
              <S.SharedAccountUsername>
                {`${accountToDisconnect!.username}`}
              </S.SharedAccountUsername>
              {!(
                accountToDisconnect!.expired ||
                accountToDisconnect!.connection.status ==
                  SharingAccountConnectionStatus.DISCONNECTED
              ) ? (
                <VerifiedIconV1
                  containerWidth="1.7rem"
                  containerHeight="1.7rem"
                  containerMarginLeft="0.3rem"
                />
              ) : (
                <S.SharedAccountExpiredContainer>
                  <InfoV3Icon containerHeight="2rem" containerWidth="2rem" />
                </S.SharedAccountExpiredContainer>
              )}
            </S.SharedAccountUsernameContainer>

            <S.SharedAccountLastActionDate>
              {getLastActionDateLabel(accountToDisconnect!)}
            </S.SharedAccountLastActionDate>
          </S.SharedAccountCenterContainer>
        </S.SharedAccountContainerToDisconnect>
      );
    };

    const getFooter = () => {
      return (
        <S.RevokeAccessModalFooter>
          <Button
            width={'auto'}
            borderRadius={'0.96rem'}
            onClick={() => {
              setShowRevokeAccessModal(false);
              setSocialUserIdToDisconnect('');
              revokeSocialDataAccess({
                partnerId: sharedSocialDataAccess?.partnerInfo.partnerId!,
                socialUserIds: socialUserIdToDisconnect
                  ? [accountToDisconnect!.socialUserId]
                  : [],
              });
              setLoadingActionNotificationMessage(
                props.t('managerSocialDataAccess.revokingAccess'),
              );
            }}
          >
            {props.t('generic.confirm')}
          </Button>

          <Button
            width={'auto'}
            borderRadius={'0.96rem'}
            themeStyles={getThemeStyles(['button', 'secondary-1'], themeGlobal)}
            onClick={() => {
              setShowRevokeAccessModal(false);
              setSocialUserIdToDisconnect('');
            }}
          >
            {props.t('generic.cancel')}
          </Button>
        </S.RevokeAccessModalFooter>
      );
    };

    return (
      <S.RevokeAccessModalContainer
        fullAccessRevocationMode={!socialUserIdToDisconnect}
      >
        {getSubtitle()}
        {socialUserIdToDisconnect && getAccountToDisconnect()}
        {getFooter()}
      </S.RevokeAccessModalContainer>
    );
  };

  const getFirstRowImage = () => {
    if (isAuthenticated()?.partnerType == PartnerTypes.influencer) {
      return sharedSocialDataAccess?.partnerInfo.img;
    }
    return (
      sharedSocialDataAccess?.partnerInfo.img ?? isAuthenticated()?.partnerImg
    );
  };

  const getFirstRowName = () => {
    if (isAuthenticated()?.partnerType == PartnerTypes.influencer) {
      return sharedSocialDataAccess?.partnerInfo.name;
    }
    return (
      sharedSocialDataAccess?.partnerInfo.name ?? isAuthenticated()?.partnerName
    );
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingIsOperatorOrPartnerAdmin ||
          isFetchingGetSharedSocialDataAccess
        }
        message={props.t('generic.loadingData')}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('managerSocialDataAccess.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin ? (
            <S.CardInnerContainer>
              <S.PartnerContainer>
                <S.PartnerImageContainer>
                  <S.PartnerImage src={getFirstRowImage()} />
                </S.PartnerImageContainer>

                <S.PartnerNameContainer>
                  <S.PartnerNameLabel>
                    {props.t('generic.name')}
                  </S.PartnerNameLabel>
                  <S.PartnerName>{getFirstRowName()}</S.PartnerName>
                </S.PartnerNameContainer>

                {isAuthenticated()?.partnerType == PartnerTypes.influencer &&
                  sharedSocialDataAccess?.sharingAccounts.filter(
                    account =>
                      account.connection.status ==
                      SharingAccountConnectionStatus.CONNECTED,
                  ).length && (
                    <S.PartnerDisconnectContainer>
                      <Button
                        borderRadius={'0.6rem'}
                        borderWidth={'0rem'}
                        height={'4rem'}
                        fontSize={'1.6rem'}
                        textAlign={'center'}
                        fontWeight={'500'}
                        onClick={() => {
                          setShowRevokeAccessModal(true);
                        }}
                      >
                        {props.t('managerSocialDataAccess.disconnect')}
                      </Button>
                    </S.PartnerDisconnectContainer>
                  )}
              </S.PartnerContainer>

              {isAuthenticated()?.partnerType !== PartnerTypes.influencer && (
                <S.ShareLinkOuterContainer>
                  <S.ShareLinkInnerContainer>
                    <S.ShareLinkContainer>{shareLink}</S.ShareLinkContainer>

                    <S.CopyShareLinkButtonContainer
                      onClick={() => {
                        setShowCopiedStatus(true);
                        setTimeout(() => setShowCopiedStatus(false), 600);
                      }}
                    >
                      <CopyToClipboard text={shareLink}>
                        <Button
                          borderRadius={'0.6rem'}
                          borderWidth={'0rem'}
                          height={'4rem'}
                          fontSize={'1.6rem'}
                          textAlign={'center'}
                          fontWeight={'500'}
                        >
                          {!showCopiedStatus
                            ? props.t('generic.copy')
                            : props.t('generic.copied')}
                        </Button>
                      </CopyToClipboard>
                    </S.CopyShareLinkButtonContainer>
                  </S.ShareLinkInnerContainer>
                </S.ShareLinkOuterContainer>
              )}

              <S.SharedAccountsContainer
                influencerPage={
                  isAuthenticated()?.partnerType == PartnerTypes.influencer
                }
              >
                {sharedSocialDataAccess?.sharingAccounts
                  .sort((accountA, accountB) => {
                    if (
                      accountA.connection.status ==
                      SharingAccountConnectionStatus.DISCONNECTED
                    ) {
                      return 1;
                    }
                    if (accountA.expired) {
                      if (
                        accountB.connection.status !==
                        SharingAccountConnectionStatus.DISCONNECTED
                      ) {
                        return 1;
                      }
                      return -1;
                    }
                    return -1;
                  })
                  .map(account => (
                    <S.SharedAccountOuterContainer
                      count={sharedSocialDataAccess?.sharingAccounts.length}
                    >
                      <S.SharedAccountContainer>
                        <S.SharedAccountLeftContainer>
                          <S.SharedAccountImage
                            src={account.displayPicture ?? DEFAULT_USER_AVATAR}
                          />

                          <S.SharedAccountNetworkIconContainer>
                            {getSocialNetworkIcon({
                              socialNetwork: account.network,
                              width: '2rem',
                              height: '2rem',
                              gradientColored: true,
                            })}
                          </S.SharedAccountNetworkIconContainer>
                        </S.SharedAccountLeftContainer>

                        <S.SharedAccountCenterContainer>
                          <S.SharedAccountUsernameContainer
                            verified={
                              !(
                                account.expired ||
                                account.connection.status ==
                                  SharingAccountConnectionStatus.DISCONNECTED
                              )
                            }
                          >
                            <S.SharedAccountUsername>
                              {`${account.username}`}
                            </S.SharedAccountUsername>
                            {!(
                              account.expired ||
                              account.connection.status ==
                                SharingAccountConnectionStatus.DISCONNECTED
                            ) ? (
                              <VerifiedIconV1
                                containerWidth="1.7rem"
                                containerHeight="1.7rem"
                                containerMarginLeft="0.3rem"
                              />
                            ) : (
                              <S.SharedAccountExpiredContainer>
                                <InfoV3Icon
                                  containerHeight="2rem"
                                  containerWidth="2rem"
                                />
                              </S.SharedAccountExpiredContainer>
                            )}
                          </S.SharedAccountUsernameContainer>

                          <S.SharedAccountLastActionDate>
                            {getLastActionDateLabel(account)}
                          </S.SharedAccountLastActionDate>
                        </S.SharedAccountCenterContainer>

                        <S.SharedAccountRightContainer
                          disabled={
                            account.connection.status ===
                            SharingAccountConnectionStatus.DISCONNECTED
                          }
                        >
                          <Button
                            borderRadius={'0.6rem'}
                            borderWidth={'0rem'}
                            height={'4rem'}
                            fontSize={'1.6rem'}
                            textAlign={'center'}
                            fontWeight={'500'}
                            onClick={() => {
                              setSocialUserIdToDisconnect(account.socialUserId);
                              setShowRevokeAccessModal(true);
                            }}
                            disabled={
                              account.connection.status ===
                              SharingAccountConnectionStatus.DISCONNECTED
                            }
                          >
                            {props.t('managerSocialDataAccess.disconnect')}
                          </Button>
                        </S.SharedAccountRightContainer>
                      </S.SharedAccountContainer>
                    </S.SharedAccountOuterContainer>
                  ))}
              </S.SharedAccountsContainer>

              {isSuccessGetSharedSocialDataAccess &&
                !sharedSocialDataAccess?.sharingAccounts.length && (
                  <S.EmptyInfoContainer>
                    <S.EmptyInfoInnerContainer>
                      <S.SharedAccountsNotFoundFirstMessage>
                        {props.t(
                          'managerSocialDataAccess.noVerifiedAccountsFoundMessagePart1',
                        )}
                      </S.SharedAccountsNotFoundFirstMessage>
                      <S.SharedAccountsNotFoundFirstMessage>
                        {props.t(
                          'managerSocialDataAccess.noVerifiedAccountsFoundMessagePart2',
                        )}
                      </S.SharedAccountsNotFoundFirstMessage>
                      <S.SharedAccountsNotFoundFirstMessage>
                        {props.t(
                          'managerSocialDataAccess.noVerifiedAccountsFoundMessagePart3',
                        )}
                      </S.SharedAccountsNotFoundFirstMessage>

                      <S.SharedAccountsNotFoundSecondMessage>
                        {props.t(
                          'managerSocialDataAccess.noVerifiedAccountsFoundYet',
                        )}
                      </S.SharedAccountsNotFoundSecondMessage>
                    </S.EmptyInfoInnerContainer>
                  </S.EmptyInfoContainer>
                )}
            </S.CardInnerContainer>
          ) : isSuccessIsOperatorOrPartnerAdmin && !isOperatorOrPartnerAdmin ? (
            <NoPermissionToAccessPageWarning containerHeight="auto" />
          ) : null}
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />

      <GenericModal
        show={showRevokeAccessModal}
        title={getRevokeAccessModalTitle()}
        body={getRevokeAccessModalContent()}
        isCancelable={true}
        cancelAction={() => {
          setShowRevokeAccessModal(false);
          setSocialUserIdToDisconnect('');
        }}
        hideFooter={true}
        modalWidth={'80rem'}
        centerTitle={true}
      />

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={isLoadingRevokeSocialDataAccess}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.Container>
  );
};

export default withTranslation()(ManagerSocialDataAccess);
