/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { IErrorObject } from '../../Services/Utils/types';
import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';

const { user } = SkorrApi;

export const useGetAgentsByPartnerId = (enabled: boolean = false) => {
  return useQuery(
    ['get-agents-by-partner-id', isAuthenticated()?.partnerId],
    async () => {
      try {
        const data = await user.getAgentsByPartnerId();

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useActivateAgentById = () => {
  return useMutation(
    async (id: string) => {
      try {
        await user.activateAgentById(id);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useDeactivateAgentById = () => {
  return useMutation(
    async (id: string) => {
      try {
        await user.deactivateAgentById(id);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useDeleteAgentById = () => {
  return useMutation(
    async (agentId: string) => {
      try {
        await user.deleteAgentById(agentId);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
