/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
import { AxiosInstance } from 'axios';
import queryString from 'query-string';
import { SocialNetworks } from 'Services/Utils/types';

import config from '../../../config';
import {
  GetPublicPostsByPublicUserIdFilteredByKeywordsResponse,
  GetPublicPostsByPublicUserIdResponse,
  GetPublicPostsEvolutionGroupedByKpiInput,
  GetPublicPostsEvolutionGroupedByKpiOutput,
  GetPublicUserHashtagsSortedByMetricsParams,
  GetPublicUserHashtagsSortedByMetricsResponse,
  IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
  IGetPublicPostsByPublicUserIdParams,
  PublicPostTypes,
  SortOrder,
} from './types';

const createPostInstanceApi = (instance: AxiosInstance) => {
  const getPublicUserHashtagsSortedByMetrics = async (
    data: GetPublicUserHashtagsSortedByMetricsParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: [data.publicUserId],
        startDate:
          data.startDate ?? new Date().getTime() - 30 * 24 * 3600 * 1000,
        endDate: data.endDate ?? new Date().getTime(),
        sortBy: 'likes',
        limit: data.limit ?? 5,
        keywords: data.keywords ?? [],
        countryCode: data.countryCode ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
        engRateValueLow: data.engRateValueLow,
        engRateValueHigh: data.engRateValueHigh,
        likesValueLow: data.likesValueLow,
        likesValueHigh: data.likesValueHigh,
        sharesValueLow: data.sharesValueLow,
        sharesValueHigh: data.sharesValueHigh,
        commentsValueLow: data.commentsValueLow,
        commentsValueHigh: data.commentsValueHigh,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_USER_HASHTAGS_SORTED_BY_METRICS}?${query}`;

    const {
      data: hashtags,
    }: { data: GetPublicUserHashtagsSortedByMetricsResponse } =
      await instance.get(url);
    return hashtags[Object.keys(hashtags)[0]].map(item => item.hashtag);
  };

  const getPublicPostsByPublicUserId = async (
    data: IGetPublicPostsByPublicUserIdParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserIds: data.publicUserIds,
        startDate: data.startDate,
        endDate: data.endDate,
        types: data.types ?? [
          PublicPostTypes.IMAGE,
          PublicPostTypes.PHOTO,
          PublicPostTypes.TEXT,
          PublicPostTypes.VIDEO,
          PublicPostTypes.STORY,
          PublicPostTypes.CAROUSEL,
        ],
        page: data.page ?? 1,
        limit: data.page ?? 9999999,
        keywords: data.keywords ?? [],
        countryCode: data.countryCode ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
        engRateValueLow: data.engRateValueLow,
        engRateValueHigh: data.engRateValueHigh,
        likesValueLow: data.likesValueLow,
        likesValueHigh: data.likesValueHigh,
        sharesValueLow: data.sharesValueLow,
        sharesValueHigh: data.sharesValueHigh,
        commentsValueLow: data.commentsValueLow,
        commentsValueHigh: data.commentsValueHigh,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID}?${query}`;

    const {
      data: publicPosts,
    }: { data: GetPublicPostsByPublicUserIdResponse } = await instance.get(url);

    return publicPosts;
  };

  const getPublicPostsByPublicUserIdFilteredByKeywords = async (
    data: IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
  ) => {
    const query = queryString.stringify(
      {
        publicUserId: data.publicUserId,
        startDate: data.startDate,
        endDate: data.endDate,
        types: data.types ?? [
          PublicPostTypes.IMAGE,
          PublicPostTypes.PHOTO,
          PublicPostTypes.TEXT,
          PublicPostTypes.VIDEO,
          PublicPostTypes.STORY,
          PublicPostTypes.CAROUSEL,
        ],
        page: data.page ?? 1,
        limit: data.page ?? 9999999,
        keywords: data.keywords ?? [],
        iPostIds: data.includePostIds ?? [],
        ePostIds: data.excludePostIds ?? [],
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID_FILTERED_BY_KEYWORDS}?${query}`;

    const {
      data: publicPosts,
    }: { data: GetPublicPostsByPublicUserIdFilteredByKeywordsResponse } =
      await instance.get(url);

    return publicPosts;
  };

  const getPublicPostsByFilter = async (
    data: GetPublicPostsByFilterRequest,
  ) => {
    const url = config.ENDPOINTS.POST.GET_PUBLIC_POSTS_BY_FILTER;

    const {
      data: publicPostsByFilter,
    }: { data: GetPublicPostsByFilterResponse } = await instance.post(
      url,
      data,
    );

    return publicPostsByFilter;
  };

  const getPublicPostsKpisByFilter = async (
    data: GetPublicPostsKpisByFilterRequest,
  ) => {
    const url = config.ENDPOINTS.POST.GET_PUBLIC_POSTS_KPIS_BY_FILTER;

    const {
      data: publicPostsKpisByFilter,
    }: { data: GetPublicPostsKpisByFilterResponse } = await instance.post(
      url,
      data,
    );

    return publicPostsKpisByFilter;
  };

  const getHashtagsByFilter = async (data: GetHashtagsByFilterRequest) => {
    const url = config.ENDPOINTS.POST.GET_HASHTAGS_BY_FILTER;

    const { data: hashtagsByFilter }: { data: GetHashtagsByFilterResponse[] } =
      await instance.post(url, data);

    return hashtagsByFilter;
  };

  const getEvolutionByFilter = async (data: GetEvolutionByFilterRequest) => {
    const url = config.ENDPOINTS.POST.GET_EVOLUTION_BY_FILTER;

    const {
      data: evolutionByFilter,
    }: { data: GetEvolutionByFilterResponse[] } = await instance.post(
      url,
      data,
    );

    return evolutionByFilter;
  };

  const getPublicPostsEvolutionGroupedByKpi = async (
    data: GetPublicPostsEvolutionGroupedByKpiInput,
  ) => {
    const url = config.ENDPOINTS.POST.GET_EVOLUTION_GROUPED_BY_KPI;

    const {
      data: evolutionGroupedByKpi,
    }: { data: GetPublicPostsEvolutionGroupedByKpiOutput } =
      await instance.post(url, data);

    const kpisByDateMap = {};
    const network =
      evolutionGroupedByKpi.network === SocialNetworks.INSTAGRAM
        ? SocialNetworks.FACEBOOKPAGEINSTAGRAM
        : evolutionGroupedByKpi.network;

    if (evolutionGroupedByKpi?.kpisEvolution) {
      Object.keys(evolutionGroupedByKpi.kpisEvolution).forEach(kpi => {
        const daysPerKpi = (evolutionGroupedByKpi.kpisEvolution as any)[
          kpi as keyof typeof evolutionGroupedByKpi.kpisEvolution
        ];

        let kpiKey = kpi;

        switch (kpi) {
          case 'reactions': {
            kpiKey = 'engagement';
            break;
          }
          case 'engRate': {
            kpiKey = 'engagement_rate';
            break;
          }
        }

        daysPerKpi?.forEach((day: any) => {
          const date = new Date(day.date).getTime();

          (kpisByDateMap as any)[date] = {
            ...(kpisByDateMap as any)[date],
            [`${network}.${kpiKey}`]: day.v,
          };
        });
      });
    }

    const kpisAvg = {
      likes: evolutionGroupedByKpi?.kpisAverage.likes,
      comments: evolutionGroupedByKpi?.kpisAverage.comments,
      shares: evolutionGroupedByKpi?.kpisAverage.shares,
      reactions: evolutionGroupedByKpi?.kpisAverage.reactions,
      engRate: evolutionGroupedByKpi?.kpisAverage.engRate,
      views: evolutionGroupedByKpi?.kpisAverage.views,
      posts: evolutionGroupedByKpi?.kpisAverage.posts,
      n_days: evolutionGroupedByKpi?.numberOfDays,
    };

    const kpisTotal = {
      likes: evolutionGroupedByKpi?.kpisTotals.likes,
      comments: evolutionGroupedByKpi?.kpisTotals.comments,
      shares: evolutionGroupedByKpi?.kpisTotals.shares,
      reactions: evolutionGroupedByKpi?.kpisTotals.reactions,
      views: evolutionGroupedByKpi?.kpisTotals.views,
      posts: evolutionGroupedByKpi?.kpisTotals.posts,
      n_days: evolutionGroupedByKpi?.numberOfDays,
    };

    const formattedEvolutionGroupedByKpi = {
      dataKPIs: Object.keys(kpisByDateMap).map(date => ({
        date: Number(date),
        ...(kpisByDateMap as any)[date],
        networks: [network],
      })),
      avg: kpisAvg,
      total: kpisTotal,
      date: [
        { startDate: data.filters.startDate, endDate: data.filters.endDate },
      ],
      networkSelectedId: network,
      networks: [network],
    };

    return formattedEvolutionGroupedByKpi;
  };

  return {
    getPublicUserHashtagsSortedByMetrics,
    getPublicPostsByPublicUserId,
    getPublicPostsByPublicUserIdFilteredByKeywords,
    getPublicPostsByFilter,
    getPublicPostsKpisByFilter,
    getHashtagsByFilter,
    getEvolutionByFilter,
    getPublicPostsEvolutionGroupedByKpi,
  };
};

export default {
  createPostInstanceApi,
};

export type GetPublicPostsByFilterRequest = {
  filters: {
    endDate: number;
    startDate: number;
    keywords: string[];
    countryCode: string[];
    network?: SocialNetworks[];
  };
  page: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type PublicPostByFilter = {
  id: string;
  publicUserId: string;
  type: PublicPostTypes;
  date: Date;
  image: string;
  content: string;
  url: string;
  username: string;
  socialNetwork: SocialNetworks;
  kpis: {
    likes: number;
    shares: number;
    comments: number;
    reactions: number;
    engRate: number;
    views: number;
  };
  user?: {
    displayPicture?: string;
    countryCode?: string | null;
    biography?: string | null;
  };
};

export type GetPublicPostsByFilterResponse = {
  posts: PublicPostByFilter[];
  total: number;
};

export type GetPublicPostsKpisByFilterRequest = {
  endDate: number;
  startDate: number;
  keywords: string[];
  countryCode: string[];
  network?: SocialNetworks[];
};

export type GetPublicPostsKpisByFilterResponse = {
  numPosts: number;
  numUsers: number;
  audience: number;
  likes: number;
  shares: number;
  comments: number;
  reactions: number;
  engRate: number;
  views: number;
};

export type GetHashtagsByFilterRequest = {
  filters: {
    endDate: number;
    startDate: number;
    keywords: string[];
    countryCode: string[];
    network?: SocialNetworks[];
  };
  limit?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type KpisByFilter = {
  avgAudience: number;
  likes: number;
  avgLikes: number;
  shares: number;
  avgShares: number;
  engRate: number;
  avgEngRate: number;
  comments: number;
  avgComments: number;
  reactions: number;
  avgReactions: number;
};

export type GetHashtagsByFilterResponse = {
  hashtag: string;
  numPosts: number;
  numUsers: number;
  kpis: KpisByFilter;
};

export type GetEvolutionByFilterRequest = {
  filters: {
    endDate: number;
    startDate: number;
    keywords: string[];
    countryCode: string[];
    network?: SocialNetworks[];
  };
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type GetEvolutionByFilterResponse = {
  day: string;
  numPosts: number;
  numUsers: number;
  kpis: KpisByFilter;
};
