import {
  getSocialNetworkIcon,
  convertNumberToHumanStringfiedFormat,
} from 'Services/Utils/Utils';
import {
  benchmarkColors,
  MAX_NUMBER_USERS,
} from 'features/Benchmark/data/constants';
import { t } from 'i18next';
import { BenchmarkOutput } from 'features/Benchmark/types';

import * as S from './Styles';

type Props = {
  benchmarkConfig: BenchmarkOutput;
  handleGoToConfig: (data: BenchmarkOutput) => void;
};

const BenchmarkOverviewCompetitors = ({
  benchmarkConfig,
  handleGoToConfig,
}: Props) => {
  return (
    <S.MainContainer>
      {benchmarkConfig.kpisOverview.map((user, index) => {
        const username = benchmarkConfig.benchmarkInfo.usersBasicInfo.find(
          item => item.id === user.publicUserId,
        )?.username!;

        return (
          <S.UserCardContainer key={user.publicUserId}>
            <S.TopSectionContainer>
              <S.UserAvatarInfoContainer>
                <S.UserAvatarInfoImg src={user.displayPicture} />
                {getSocialNetworkIcon({
                  width: '1.5rem',
                  height: '1.5rem',
                  gradientColored: true,
                  socialNetwork: user.socialNetwork,
                })}
              </S.UserAvatarInfoContainer>

              <S.UserInfoContainer>
                <S.UserInfoName>{username}</S.UserInfoName>
                <S.UserInfoNetwork>
                  {t(`socialNetworks.${user.socialNetwork}`)}
                </S.UserInfoNetwork>
              </S.UserInfoContainer>
            </S.TopSectionContainer>

            <S.UserMetricsContainer>
              <S.UserMetricContainer>
                <S.UserMetricLabel>
                  {t('benchmark.analytics.followers')}
                </S.UserMetricLabel>
                <S.UserMetricValue>
                  {convertNumberToHumanStringfiedFormat(
                    user.kpis.total.followers,
                  )}
                </S.UserMetricValue>
                <S.UserMetricColor color={benchmarkColors[index]} />
              </S.UserMetricContainer>

              {user.kpis.growth_perc.followers !== 0 && (
                <S.UserEvolutionContainer
                  positive={user.kpis.growth_perc.followers > 0}
                >
                  {user.kpis.growth_perc.followers > 0 ? (
                    <svg viewBox="0 0 32 32">
                      <path d="M29.231 8.821c-0.052-0.127-0.136-0.233-0.223-0.336-0.025-0.029-0.035-0.067-0.063-0.095-0.017-0.017-0.040-0.023-0.057-0.039-0.112-0.101-0.235-0.191-0.376-0.249-0.164-0.067-0.337-0.103-0.512-0.103 0 0 0 0 0 0h-6.667c-0.737 0-1.333 0.597-1.333 1.333s0.596 1.333 1.333 1.333h3.449l-7.336 7.337-4.613-4.613c-0.521-0.521-1.364-0.521-1.885 0l-8.333 8.333c-0.521 0.521-0.521 1.364 0 1.885 0.26 0.26 0.601 0.391 0.943 0.391s0.683-0.131 0.943-0.391l7.391-7.391 4.613 4.613c0.5 0.5 1.385 0.5 1.885 0l8.277-8.279v3.447c0 0.737 0.596 1.333 1.333 1.333s1.333-0.596 1.333-1.333v-6.66c0.001-0.177-0.035-0.353-0.103-0.519z" />
                    </svg>
                  ) : (
                    <svg viewBox="0 0 32 32">
                      <path d="M29.231 23.179c-0.052 0.127-0.136 0.233-0.223 0.336-0.025 0.029-0.035 0.067-0.063 0.095-0.017 0.017-0.040 0.023-0.057 0.039-0.112 0.101-0.235 0.191-0.376 0.249-0.164 0.067-0.337 0.103-0.512 0.103 0 0 0 0 0 0h-6.667c-0.737 0-1.333-0.597-1.333-1.333s0.596-1.333 1.333-1.333h3.449l-7.336-7.337-4.613 4.613c-0.521 0.521-1.364 0.521-1.885 0l-8.333-8.333c-0.521-0.521-0.521-1.364 0-1.885 0.26-0.26 0.601-0.391 0.943-0.391s0.683 0.131 0.943 0.391l7.391 7.391 4.613-4.613c0.5-0.5 1.385-0.5 1.885 0l8.277 8.279v-3.447c0-0.737 0.596-1.333 1.333-1.333s1.333 0.596 1.333 1.333v6.66c0.001 0.177-0.035 0.353-0.103 0.519z" />
                    </svg>
                  )}
                  {` ${
                    user.kpis.growth_perc.followers > 0 ? '+' : ''
                  }${convertNumberToHumanStringfiedFormat(
                    (user.kpis.growth_perc.followers / 100) *
                      user.kpis.total.followers,
                  )} `}
                </S.UserEvolutionContainer>
              )}
            </S.UserMetricsContainer>
          </S.UserCardContainer>
        );
      })}

      {benchmarkConfig.kpisOverview.length < MAX_NUMBER_USERS && (
        <S.AddMoreCard onClick={() => handleGoToConfig(benchmarkConfig)}>
          <S.AddMoreLabel>{t('benchmark.analytics.addMore')}</S.AddMoreLabel>
          <svg viewBox="0 0 32 32">
            <path d="M26.075 16c0 0.556-0.451 1.008-1.008 1.008h-8.060v8.059c0 0.557-0.451 1.008-1.008 1.008s-1.008-0.451-1.008-1.008v-8.060h-8.057c-0.557 0-1.008-0.451-1.008-1.008s0.451-1.008 1.008-1.008h8.060v-8.057c0-0.557 0.451-1.008 1.008-1.008s1.008 0.451 1.008 1.008v8.060h8.057c0.557-0.001 1.008 0.451 1.008 1.007z" />
          </svg>
        </S.AddMoreCard>
      )}
    </S.MainContainer>
  );
};

export default BenchmarkOverviewCompetitors;
