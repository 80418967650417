/* eslint-disable no-use-before-define */

export enum SharingAccountConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export type SharingAccount = {
  username: string;
  displayPicture: string;
  network: string;
  expired: boolean;
  expiredAt: Date | null;
  socialUserId: string;
  connection: {
    status: SharingAccountConnectionStatus;
    changedAt: Date;
  };
};

export type GetSharedSocialDataAccessOutput = {
  partnerInfo: {
    img: string;
    name: string;
    partnerId: string;
  };
  sharingAccounts: SharingAccount[];
};

export type RevokeSocialDataAccessInput = {
  partnerId: string;
  socialUserIds?: string[];
};
