import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListeningSearch = styled.div`
  height: 8rem;
  display: flex;
  margin: 2rem 0;
  padding: 0 3.2rem;
  position: relative;
  align-items: center;
  border-radius: 5rem;
  background-color: white;
  border: solid 1px #e0e5ea;

  &:hover {
    border-color: #8492a6;
  }

  &:has(input:placeholder-shown) .search-icon,
  &:has(input:not(:placeholder-shown)) .close-icon {
    opacity: 1;
    rotate: 0deg;
    visibility: visible;
    pointer-events: all;
  }

  &:has(input:placeholder-shown) button {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  input {
    border: none;
    height: 4rem;
    outline: none;
    line-height: 18;
    color: #292929;
    font-weight: 400;
    font-size: 1.8rem;
    background: transparent;
    width: calc(100% - 3rem);
    transition: width 0.3s, color 0.3s;

    &::placeholder {
      color: #8492a6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #292929;
      -webkit-box-shadow: 0 0 0px 40rem white inset;
    }
  }

  svg {
    top: 50%;
    opacity: 0;
    rotate: 90deg;
    width: 2.4rem;
    height: 2.4rem;
    right: 3.85rem;
    translate: 0 -50%;
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    transition-duration: 0.15s;
    transition-property: opacity, rotate;
  }

  button {
    all: unset;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.25rem;
    cursor: pointer;

    &:focus-visible {
      box-shadow: 0 0 0 0.25rem #8492a6;
    }
  }
`;

export const ErrorText = styled.span`
  color: #b00020;
  font-size: 1rem;
  margin-top: -1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;
