import { t } from 'i18next';
import { SubmitBenchmarkHookProps } from 'features/Benchmark/hooks/use-submit-benchmark';

import * as S from './Styles';
import SubmitBenchmarkSelectedUsersCard from '../SubmitBenchmarkSelectedUsersCard';
import SubmitBenchmarkUsersFound from '../SubmitBenchmarkUsersFound';

type Props = {
  customHook: SubmitBenchmarkHookProps;
};

const SubmitBenchmarkUsersList = ({ customHook }: Props) => {
  const {
    searchData,
    selectedUsers,
    handleSelectUsers,
    isLoadingSearchUserBenchmark,
  } = customHook;

  return (
    <S.Container>
      {selectedUsers.length > 0 && (
        <>
          <S.SelectedUsersText>
            {selectedUsers.length + t('benchmark.form.profiles')}
            <p>{t('benchmark.form.selected')}</p>
          </S.SelectedUsersText>

          <SubmitBenchmarkSelectedUsersCard
            data={selectedUsers}
            handleUsers={handleSelectUsers}
          />
        </>
      )}

      {isLoadingSearchUserBenchmark ? (
        <S.CustomLabel hasUsers={selectedUsers.length > 0}>
          {t('benchmark.form.loadingUsers')}
        </S.CustomLabel>
      ) : (
        <>
          {searchData !== undefined && (
            <>
              <S.CustomLabel hasUsers={selectedUsers.length > 0}>
                {searchData.length > 0
                  ? t('benchmark.form.searchResults')
                  : t('benchmark.form.noResults')}
              </S.CustomLabel>

              <SubmitBenchmarkUsersFound
                data={searchData}
                selectedUsers={selectedUsers}
                handleUsers={handleSelectUsers}
              />
            </>
          )}
        </>
      )}
    </S.Container>
  );
};

export default SubmitBenchmarkUsersList;
