import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const CalendarButton = styled.div<{ active: boolean }>`
  height: 4rem;
  display: flex;
  color: #676767;
  font-weight: 400;
  line-height: 1.8;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #eff1f6;
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    fill: #676767;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
    transition: transform 1s;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : undefined)};
  }
`;

export const CalendarPickerContainer = styled.div`
  right: 0;
  overflow: hidden;
  position: absolute;
  border-radius: 0.6rem;
  box-shadow: 0 0 0 0.1rem #eee;
`;
