import { t } from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import { withTranslation } from 'react-i18next';
import Button from 'components/shared/Button/Button';
import { useAdminPartnersList } from 'features/Admin';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import Pagination from 'components/shared/Pagination/Pagination';
import { partnerTypeAvailableValues } from 'features/Discover/data/constants';

import * as S from './Styles';

const AdminPartnersList = () => {
  const {
    type,
    name,
    page,
    setType,
    setName,
    inputRef,
    totalPages,
    adminPartners,
    handleChangePage,
    handleGoToAgentsList,
    handleGoToEditPartner,
    isLoadingAdminPartners,
    handleGoToCreatePartner,
    handleValidateAdminPartner,
  } = useAdminPartnersList();

  return (
    <S.Container>
      <S.TopBarContainer>
        <S.MainTitle>{t('skorrAdmin.partner.title')}</S.MainTitle>

        <S.FormContainer>
          <S.InputContainer>
            <svg viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.2369 17.2128L14.8131 13.789C15.878 12.4962 16.5178 10.84 16.5178 9.03428C16.5178 4.90128 13.1674 1.55078 9.03434 1.55078C4.90128 1.55078 1.5509 4.90128 1.5509 9.03434C1.5509 13.1674 4.90134 16.5178 9.0344 16.5178C10.8401 16.5178 12.4963 15.878 13.7891 14.8131L17.2129 18.2369C17.4957 18.5197 17.9542 18.5197 18.237 18.2369C18.5198 17.9541 18.5198 17.4955 18.2369 17.2128ZM2.99928 9.03434C2.99928 5.70128 5.70128 2.99928 9.03434 2.99928C12.3674 2.99928 15.0694 5.70128 15.0694 9.03434C15.0694 12.3674 12.3674 15.0694 9.03434 15.0694C5.70128 15.0694 2.99928 12.3674 2.99928 9.03434Z"
                fill="#8492A6"
              />
            </svg>

            <input
              ref={inputRef}
              type="text"
              name="name"
              value={name}
              placeholder={t('skorrAdmin.partner.namePlaceholder')}
              onChange={e => setName(e.target.value)}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  inputRef.current?.blur();
                  handleChangePage(0);
                }
              }}
            />
          </S.InputContainer>

          <ComboBox
            width="22rem"
            type="single"
            value={type}
            valueKey="partnerType"
            placeholder={t('skorrAdmin.partner.typePlaceholder')}
            isClearable={true}
            isFilterable={false}
            valuesAvailable={partnerTypeAvailableValues}
            onChange={(obj: any) => {
              if (type !== obj.partnerType) {
                setType(obj.partnerType);
                handleChangePage(0);
              }
            }}
            optionContainerBorderRadius={'0.6rem'}
            innerOptionsContainerPaddingTop={'1.2rem'}
            innerOptionsContainerPaddingBottom={'1.2rem'}
            innerOptionsContainerPaddingLeft={'1.2rem'}
            innerOptionsContainerPaddingRight={'1.2rem'}
            optionContainerPaddingTop={'1rem'}
            optionContainerPaddingBottom={'1rem'}
            optionContainerPaddingLeft={'1rem'}
            optionContainerPaddingRight={'1rem'}
            changeBackgroundColorOnHover={false}
          />

          <Button
            marginLeft="auto"
            borderRadius="3rem"
            onClick={handleGoToCreatePartner}
          >
            {t('skorrAdmin.partner.createPartner')}
          </Button>
        </S.FormContainer>
      </S.TopBarContainer>

      <S.TableContainer>
        <Loader message={t('generic.loading')} show={isLoadingAdminPartners}>
          <table>
            <thead>
              <tr>
                <th>{t('skorrAdmin.partner.logo')}</th>
                <th>{t('skorrAdmin.partner.name')}</th>
                <th>{t('skorrAdmin.partner.type')}</th>
                <th>{t('skorrAdmin.partner.accountValidated')}</th>
                <th>{t('skorrAdmin.partner.createdAt')}</th>
                <th>{t('skorrAdmin.partner.actions')}</th>
              </tr>
            </thead>

            <tbody>
              {adminPartners?.partners.map(item => (
                <tr key={item.id}>
                  <td>
                    <S.LogoContainer src={item.displayPicture} />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>
                    {item.active ? t('generic.true') : t('generic.false')}
                  </td>
                  <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                  <td>
                    <S.ActionsContainer>
                      <a
                        id={`edit-partner-${item.id}`}
                        onClick={() => handleGoToEditPartner(item.id)}
                      >
                        <svg viewBox="0 0 32 32">
                          <path d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z" />
                        </svg>
                      </a>

                      <S.ActionTooltip
                        placement="top"
                        target={`edit-partner-${item.id}`}
                      >
                        {t('skorrAdmin.partner.editPartner')}
                      </S.ActionTooltip>

                      <a
                        id={`validate-partner-${item.id}`}
                        onClick={() =>
                          handleValidateAdminPartner(item.id, !item.active)
                        }
                      >
                        <svg viewBox="0 0 32 32">
                          <path d="M10.827 26.778c-0.331 0-0.662-0.126-0.914-0.379l-8.622-8.622c-0.505-0.505-0.505-1.324 0-1.829s1.324-0.505 1.829 0l7.708 7.708 18.054-18.054c0.505-0.505 1.324-0.505 1.829 0s0.505 1.324 0 1.829l-18.969 18.969c-0.253 0.253-0.584 0.379-0.914 0.379z" />
                        </svg>
                      </a>

                      <S.ActionTooltip
                        placement="top"
                        target={`validate-partner-${item.id}`}
                      >
                        {t('skorrAdmin.partner.validatePartner')}
                      </S.ActionTooltip>

                      <a
                        id={`agents-list-${item.id}`}
                        onClick={() => handleGoToAgentsList(item.id)}
                      >
                        <svg viewBox="0 0 32 32">
                          <path d="M11.886 6.858c-1.757 0-3.2 1.443-3.2 3.2s1.443 3.2 3.2 3.2c1.757 0 3.2-1.443 3.2-3.2s-1.443-3.2-3.2-3.2zM20.114 6.858c-1.757 0-3.2 1.443-3.2 3.2s1.443 3.2 3.2 3.2 3.2-1.443 3.2-3.2-1.442-3.2-3.2-3.2zM11.886 8.686c0.768 0 1.371 0.603 1.371 1.371s-0.605 1.371-1.371 1.371c-0.768 0-1.371-0.603-1.371-1.371s0.603-1.371 1.371-1.371zM20.114 8.686c0.768 0 1.371 0.603 1.371 1.371s-0.603 1.371-1.371 1.371c-0.768 0-1.371-0.603-1.371-1.371s0.605-1.371 1.371-1.371zM7.771 12.342c-2.011 0-3.658 1.646-3.658 3.658 0 1.018 0.435 1.936 1.114 2.6-1.216 0.826-2.027 2.229-2.027 3.8h1.829c0-1.525 1.218-2.742 2.742-2.742s2.742 1.218 2.742 2.742h1.829c0-1.571-0.811-2.974-2.029-3.8 0.68-0.664 1.115-1.582 1.115-2.6 0-2.011-1.646-3.658-3.658-3.658zM12.342 22.4c-0.571 0.765-0.914 1.725-0.914 2.742h1.829c0-1.525 1.218-2.742 2.742-2.742s2.742 1.218 2.742 2.742h1.829c0-1.018-0.342-1.979-0.914-2.742-0.31-0.414-0.686-0.768-1.114-1.058 0.678-0.664 1.114-1.582 1.114-2.6 0-2.011-1.646-3.658-3.658-3.658s-3.658 1.646-3.658 3.658c0 1.018 0.435 1.936 1.114 2.6-0.427 0.29-0.803 0.643-1.114 1.058zM19.658 22.4h1.829c0-1.525 1.218-2.742 2.742-2.742s2.742 1.218 2.742 2.742h1.829c0-1.571-0.811-2.974-2.029-3.8 0.678-0.664 1.114-1.582 1.114-2.6 0-2.011-1.646-3.658-3.658-3.658s-3.658 1.646-3.658 3.658c0 1.018 0.435 1.936 1.114 2.6-1.216 0.826-2.026 2.229-2.026 3.8zM7.771 14.171c1.022 0 1.829 0.808 1.829 1.829s-0.806 1.829-1.829 1.829-1.829-0.808-1.829-1.829c0-1.021 0.808-1.829 1.829-1.829zM24.229 14.171c1.021 0 1.829 0.806 1.829 1.829 0 1.021-0.806 1.829-1.829 1.829s-1.829-0.808-1.829-1.829c0-1.021 0.806-1.829 1.829-1.829zM16 16.914c1.021 0 1.829 0.806 1.829 1.829s-0.808 1.829-1.829 1.829-1.829-0.806-1.829-1.829 0.808-1.829 1.829-1.829z" />
                        </svg>
                      </a>

                      <S.ActionTooltip
                        placement="top"
                        target={`agents-list-${item.id}`}
                      >
                        {t('skorrAdmin.partner.agentsList')}
                      </S.ActionTooltip>
                    </S.ActionsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Loader>
      </S.TableContainer>

      <Pagination
        totalPages={totalPages}
        currentPage={page}
        onPageChange={handleChangePage}
      />
    </S.Container>
  );
};

export default withTranslation()(AdminPartnersList);
