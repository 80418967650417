/* eslint-disable func-names */
import {
  DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM,
  LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY,
} from 'data/constants';
import { t } from 'i18next';
import moment from 'moment';
import auth from 'api/auth-helper';
import { select } from 'd3-selection';
import queryString from 'query-string';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SortOrder, SortByFields } from 'Services/SkorrApi/new/User/types';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';

import {
  useGetHashtagsByFilter,
  useGetEvolutionByFilter,
  useGetPublicUsersByFilter,
  useGetPublicPostsByFilter,
  useGetPublicPostsKpisByFilter,
} from '../api';
import {
  ParamsFilters,
  SearchesLocalStorage,
  ListeningPostsSortByField,
  ListeningUsersSortByField,
} from '../types';

const wordCloudOptions = {
  colors: [
    '#52ACFE',
    '#2C9AFE',
    '#108AF9',
    '#067EEB',
    '#0271D5',
    '#0264BD',
    '#0559A4',
    '#024F94',
  ],
  padding: 2,
  rotations: 1,
  scale: 'sqrt',
  fontSizes: [10, 50],
  enableTooltip: true,
  deterministic: false,
  spiral: 'archimedean',
  fontFamily: 'Poppins',
  rotationAngles: [0, 90],
  transitionDuration: 1000,
};

export const useListeningTerm = () => {
  const navigate = useNavigate();

  const { term } = useParams();
  const [queryParameters] = useSearchParams();

  const filters = useCallback((): ParamsFilters => {
    return {
      keywords: queryParameters.getAll('keywords[]')!,
      countryCode: queryParameters.getAll('countryCode[]')!,
      startDate: Number(queryParameters.get('startDate')),
      endDate: Number(queryParameters.get('endDate')),
      network: queryParameters.getAll('network[]')
        ? (queryParameters.getAll('network[]') as any)
        : undefined,
    };
  }, [queryParameters]);

  const isFirstRender = useRef(true);
  const filtersContainerRef = useRef<HTMLDivElement>(null);

  const [postsPage, setPostsPage] = useState(0);
  const [usersPage, setUsersPage] = useState(0);
  const [numOfDrills, setNumOfDrills] = useState(0);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [tabSelected, setTabSelected] = useState<'posts' | 'creators'>('posts');

  const [showSaveList, setShowSaveList] = useState(false);
  const [canSelectUsers, setCanSelectUsers] = useState(false);
  const [toggleSortByButton, setToggleSortByButton] = useState(false);
  const [selectAllUsersToggle, setSelectAllUsersToggle] = useState(false);

  const [sortByPostsField, setSortByPostsField] = useState(
    ListeningPostsSortByField.date,
  );
  const [sortByUsersField, setSortByUsersField] = useState(
    ListeningUsersSortByField.audience,
  );

  const sortByField =
    tabSelected === 'posts' ? sortByPostsField : sortByUsersField;

  const sortByAvailableValues = useCallback(() => {
    if (tabSelected === 'posts') {
      return ListeningPostsSortByField;
    }
    return ListeningUsersSortByField;
  }, [tabSelected]);

  const handleToggleCanSelectUsers = useCallback(() => {
    setTimeout(() => setCanSelectUsers(!canSelectUsers), 0);
  }, [canSelectUsers]);

  const handleToggleSortByButton = useCallback(() => {
    setTimeout(() => setToggleSortByButton(!toggleSortByButton), 0);
  }, [toggleSortByButton]);

  const handleClickOutToggleSortByButton = useCallback(() => {
    if (toggleSortByButton) {
      setToggleSortByButton(false);
    }
  }, [toggleSortByButton]);

  const handleOpenSaveList = useCallback(() => {
    setShowSaveList(true);
  }, []);

  const handleCloseSaveList = useCallback(() => {
    setShowSaveList(false);
  }, []);

  const saveListButtonDisabled = useCallback(() => {
    if (!selectAllUsersToggle && (selectedUserIds ?? []).length === 0) {
      return true;
    }
    return false;
  }, [selectAllUsersToggle, selectedUserIds]);

  const handleUserCardClicked = useCallback(
    (userId: string) => {
      if (selectedUserIds) {
        if (selectedUserIds.some(id => id === userId)) {
          setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
        } else {
          setSelectedUserIds([...selectedUserIds, userId]);
        }
      }
    },
    [selectedUserIds],
  );

  const isUserSelected = useCallback(
    (userId: string) => {
      if ((selectedUserIds ?? []).includes(userId)) {
        return true;
      }
      return false;
    },
    [selectedUserIds],
  );

  const handleGoBack = useCallback(() => {
    setNumOfDrills(prevState => prevState - 2);

    if (numOfDrills > 1) {
      navigate(-1);
    } else {
      navigate('/listening', {
        state: {
          filters: filters(),
        },
      });
    }
  }, [numOfDrills, filters]);

  const handleGoToUserMediaKit = useCallback(
    (userId: string) => {
      const query = queryString.stringify(
        {
          ...filters(),
        },
        { arrayFormat: 'bracket' },
      );

      navigate(`/user-media-kit/${userId}?${query}`);
    },
    [filters],
  );

  const diffDays = moment(filters().endDate).diff(
    moment(filters().startDate),
    'days',
  );

  const timeFrame = `${moment(filters().startDate).format('MMM D')} - ${moment(
    filters().endDate,
  ).format('ll')} (${diffDays + t('listeningTerm.days')})`;

  const {
    data: hashtagsByFilter,
    mutateAsync: getHashtagsByFilter,
    isLoading: isLoadingHashtagsByFilter,
  } = useGetHashtagsByFilter();

  const relatedTopics = useMemo(
    () =>
      hashtagsByFilter?.map(tag => {
        return {
          text: tag.hashtag,
          value: tag.numPosts,
        };
      }),
    [hashtagsByFilter],
  );

  const handleGetHashtagsByFilter = useCallback(async () => {
    await getHashtagsByFilter({
      filters: filters(),
    });
  }, [getHashtagsByFilter, filters]);

  const {
    data: evolutionByFilter,
    mutateAsync: getEvolutionByFilter,
    isLoading: isLoadingEvolutionByFilter,
  } = useGetEvolutionByFilter();

  const chartData = useCallback(() => {
    const categoriesData: string[] = [];
    const engagementData: number[] = [];

    (evolutionByFilter || []).forEach(item => {
      categoriesData.push(item.day);
      engagementData.push(item.kpis.reactions);
    });

    return {
      categories: categoriesData,
      lineData: [{ data: engagementData, name: term }],
    };
  }, [evolutionByFilter, term]);

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
      connectNulls: true,
    },
    xaxis: {
      tickAmount: 6,
      type: 'datetime',
      categories: chartData().categories,
      labels: {
        offsetY: -0,
        rotate: -45,
        trim: false,
        rotateAlways: false,
        hideOverlappingLabels: true,
      },
    },
    markers: {
      size: 0,
      opacity: 0.5,
      strokeWidth: 1,
      strokeColor: '#fff',
      hover: {
        size: 5,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: 7,
        formatter(tickAmount: number) {
          return convertNumberToHumanStringfiedFormat(tickAmount.toFixed(2), 2);
        },
      },
    },
  };

  const handleGetEvolutionByFilter = useCallback(async () => {
    await getEvolutionByFilter({
      filters: filters(),
    });
  }, [getEvolutionByFilter, filters]);

  const {
    data: publicPostsByFilter,
    mutateAsync: getPublicPostsByFilter,
    isLoading: isLoadingPublicPostsByFilter,
  } = useGetPublicPostsByFilter();

  const totalPostsPages = Math.ceil(
    (publicPostsByFilter?.total ?? DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM) /
      DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM,
  );

  const handleGetPublicPostsByFilter = useCallback(
    async (pageNumber?: number, sortBy?: string) => {
      await getPublicPostsByFilter({
        filters: filters(),
        limit: DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM,
        page: pageNumber ?? postsPage,
        sortBy: sortBy ?? sortByPostsField,
        sortOrder: SortOrder.DESC,
      });
    },
    [getPublicPostsByFilter, sortByPostsField, postsPage, filters],
  );

  const handleChangePostsPage = useCallback(
    (pageNumber: number, sortBy?: string) => {
      if (pageNumber >= 0 && pageNumber <= totalPostsPages) {
        setPostsPage(pageNumber);

        setTimeout(() => {
          handleGetPublicPostsByFilter(pageNumber, sortBy);
        }, 100);
      }
    },
    [totalPostsPages, handleGetPublicPostsByFilter],
  );

  const {
    data: publicPostsKpisByFilter,
    mutateAsync: getPublicPostsKpisByFilter,
    isLoading: isLoadingPublicPostsKpisByFilter,
  } = useGetPublicPostsKpisByFilter();

  const handleGetPublicPostsKpisByFilter = useCallback(async () => {
    await getPublicPostsKpisByFilter(filters());
  }, [getPublicPostsKpisByFilter, filters]);

  const {
    data: top5Users,
    mutateAsync: getTop5Users,
    isLoading: isLoadingTop5Users,
  } = useGetPublicUsersByFilter();

  const handleGetTop5Users = useCallback(async () => {
    await getTop5Users({
      filters: filters(),
      page: 0,
      limit: 5,
      sort: SortByFields.engagement_rate,
      sortOrder: SortOrder.DESC,
    });
  }, [getTop5Users, filters]);

  const {
    data: publicUsersByFilter,
    mutateAsync: getPublicUsersByFilter,
    isLoading: isLoadingPublicUsersByFilter,
  } = useGetPublicUsersByFilter();

  const totalUsersPages = Math.ceil(
    (publicUsersByFilter?.total ?? DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM) /
      DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM,
  );

  const handleGetPublicUsersByFilter = useCallback(
    async (pageNumber?: number, sortBy?: string) => {
      await getPublicUsersByFilter({
        filters: filters(),
        limit: DEFAULT_ITEMS_PER_PAGE_LISTENING_TERM,
        page: pageNumber ?? usersPage,
        sort:
          (sortBy as SortByFields) ??
          (sortByUsersField as unknown as SortByFields),
        sortOrder: SortOrder.DESC,
      });
    },
    [getPublicUsersByFilter, sortByUsersField, usersPage, filters],
  );

  const handleChangeUsersPage = useCallback(
    (pageNumber: number, sortBy?: string) => {
      if (pageNumber >= 0 && pageNumber <= totalUsersPages) {
        setUsersPage(pageNumber);

        setTimeout(() => {
          handleGetPublicUsersByFilter(pageNumber, sortBy);
        }, 100);
      }
    },
    [totalUsersPages, handleGetPublicUsersByFilter],
  );

  const handleChangeSortBy = useCallback(
    (sortKey: string) => {
      if (tabSelected === 'posts') {
        setSortByPostsField(
          ListeningPostsSortByField[
            sortKey as keyof typeof ListeningPostsSortByField
          ],
        );
        handleChangePostsPage(0, sortKey);
      } else {
        setSortByUsersField(
          ListeningUsersSortByField[
            sortKey as keyof typeof ListeningUsersSortByField
          ],
        );
        handleChangeUsersPage(0, sortKey);
      }
      setToggleSortByButton(false);
    },
    [tabSelected, handleChangePostsPage, handleChangeUsersPage],
  );

  const handleSelectAllUsers = useCallback(() => {
    const allUserIds = publicUsersByFilter?.publicUsers?.map(user => user._id!);

    setSelectAllUsersToggle(prevState => {
      setSelectedUserIds(!prevState ? allUserIds ?? [] : []);
      return !prevState;
    });
  }, [publicUsersByFilter]);

  const isLoadingData =
    isLoadingTop5Users ||
    isLoadingHashtagsByFilter ||
    isLoadingEvolutionByFilter ||
    isLoadingPublicPostsKpisByFilter;

  const noDataToShow =
    top5Users?.total === 0 &&
    hashtagsByFilter?.length === 0 &&
    evolutionByFilter?.length === 0 &&
    publicPostsByFilter?.total === 0 &&
    Object.values(publicPostsKpisByFilter || {}).length === 0;

  const getCallback = (callback: string) => {
    return function (word: any, event: any) {
      const isActive = callback !== 'onWordMouseOut';
      const element = event.target;
      const text = select(element);
      text
        .on('click', () => {
          if (isActive && word.text.trim().length > 2) {
            const query = queryString.stringify(
              {
                ...filters(),
                keywords: [word.text],
              },
              { arrayFormat: 'bracket' },
            );

            navigate(`/listening/${word.text.trim()}?${query}`, {
              state: {
                filters: { ...filters(), keywords: [word.text] },
              },
            });
          }
        })
        .attr('text-decoration', isActive ? 'underline' : 'none');
    };
  };

  const callbacks = useMemo(
    () => ({
      getWordTooltip: (word: any) =>
        `The "#${word.text}" appears in ${word.value} posts.`,
      onWordClick: getCallback('onWordClick'),
      onWordMouseOut: getCallback('onWordMouseOut'),
      onWordMouseOver: getCallback('onWordMouseOver'),
    }),
    [],
  );

  useEffect(() => {
    handleGetHashtagsByFilter();
    handleGetEvolutionByFilter();
    handleGetPublicPostsByFilter();
    handleGetPublicPostsKpisByFilter();
    handleGetTop5Users();
    handleGetPublicUsersByFilter();
    setNumOfDrills(prevState => prevState + 1);
  }, [filters]);

  useEffect(() => {
    if (publicPostsKpisByFilter && term) {
      const newSearch = {
        term,
        numUsers: publicPostsKpisByFilter.numUsers,
        numPosts: publicPostsKpisByFilter.numPosts,
        avgEng: publicPostsKpisByFilter.reactions,
        filters: filters(),
      };

      const existingSearches: SearchesLocalStorage[] = JSON.parse(
        localStorage.getItem(
          `${LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY}_${
            auth.isAuthenticated().agentId
          }`,
        ) || '[]',
      );

      const index = existingSearches.findIndex(
        item => item.term === newSearch.term,
      );

      if (index !== -1) {
        existingSearches[index] = { ...existingSearches[index], ...newSearch };
      } else {
        existingSearches.push(newSearch);
      }

      localStorage.setItem(
        `${LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY}_${
          auth.isAuthenticated().agentId
        }`,
        JSON.stringify(existingSearches),
      );
    }
  }, [publicPostsKpisByFilter, term]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    filtersContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [postsPage, usersPage]);

  return {
    term,
    postsPage,
    usersPage,
    timeFrame,
    chartData,
    top5Users,
    callbacks,
    tabSelected,
    sortByField,
    showSaveList,
    chartOptions,
    handleGoBack,
    noDataToShow,
    relatedTopics,
    isLoadingData,
    setTabSelected,
    canSelectUsers,
    isUserSelected,
    selectedUserIds,
    totalPostsPages,
    totalUsersPages,
    wordCloudOptions,
    hashtagsByFilter,
    evolutionByFilter,
    toggleSortByButton,
    handleChangeSortBy,
    handleOpenSaveList,
    filtersContainerRef,
    handleCloseSaveList,
    publicPostsByFilter,
    publicUsersByFilter,
    selectAllUsersToggle,
    handleSelectAllUsers,
    sortByAvailableValues,
    handleChangePostsPage,
    handleChangeUsersPage,
    handleUserCardClicked,
    saveListButtonDisabled,
    handleGoToUserMediaKit,
    publicPostsKpisByFilter,
    handleToggleSortByButton,
    handleToggleCanSelectUsers,
    isLoadingPublicPostsByFilter,
    isLoadingPublicUsersByFilter,
    handleClickOutToggleSortByButton,
  };
};
