/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { useMutation, useQuery } from 'react-query';

import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';
import {
  DEFAULT_USER_AVATAR,
  IErrorObject,
  User,
} from '../../Services/Utils/types';
import {
  IUseCreateAgentInput,
  IUseUpdateAgentAndUserParams,
  IUseUpdateAgentUserParams,
  IUseUpdateInfluencerProfileParams,
} from './types';
import SkorrApi from '../../Services/SkorrApi/new';

const { user } = SkorrApi;
const { files } = SkorrApi;

export const useUpdateInfluencerProfile = () => {
  return useMutation(
    async (params: IUseUpdateInfluencerProfileParams) => {
      try {
        const { data: imageUrl }: { data: string } =
          params.influencerPictureData
            ? await files.uploadImage(params.influencerPictureData!)
            : { data: '' };
        if (imageUrl) {
          params.influencerProfileUpdateData.displayPicture = imageUrl;
        } else {
          params.influencerProfileUpdateData.displayPicture =
            params.influencerInitialPicture || DEFAULT_USER_AVATAR;
        }
        const { data: modifiedCount } = await user.updateAccount(
          params.influencerProfileUpdateData,
        );
        return modifiedCount;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdateAgentAndUser = () => {
  return useMutation(
    async (params: IUseUpdateAgentAndUserParams) => {
      try {
        const { data: imageUrl }: { data: string } = params.pictureData
          ? await files.uploadImage(params.pictureData!)
          : { data: '' };
        if (imageUrl) {
          params.updateData.displayPicture = imageUrl;
        } else {
          params.updateData.displayPicture =
            params.initialPicture || DEFAULT_USER_AVATAR;
        }
        const result = await user.updateAgentAndUser(params.updateData);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useCreateAgent = () => {
  return useMutation(
    async (params: IUseCreateAgentInput) => {
      try {
        const { data: imageUrl }: { data: string } = params.pictureData
          ? await files.uploadImage(params.pictureData!)
          : { data: '' };

        if (imageUrl) {
          params.creationData.displayPicture = imageUrl;
        } else {
          params.creationData.displayPicture =
            params.initialPicture || DEFAULT_USER_AVATAR;
        }
        const result = await user.createAgent(params.creationData);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetAgentUser = (enabled: boolean, agentId: string) => {
  return useQuery(
    ['get-agent-user', isAuthenticated()?.partnerId, agentId],
    async () => {
      try {
        const data: User = await user.getAgentUser(agentId);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useUpdateAgentUser = () => {
  return useMutation(
    async (params: IUseUpdateAgentUserParams) => {
      try {
        const { data: imageUrl }: { data: string } = params.pictureData
          ? await files.uploadImage(params.pictureData!)
          : { data: '' };
        if (imageUrl) {
          params.updateData.displayPicture = imageUrl;
        } else {
          params.updateData.displayPicture =
            params.initialPicture || DEFAULT_USER_AVATAR;
        }
        const result = await user.updateAgentUser(params.updateData);
        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
