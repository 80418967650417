import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const TopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 3rem;
  border-spacing: 0;
  background: #ffffff;
  border-radius: 1.2rem;
  border: solid 1px #e0e5ea;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media (max-width: 850px) {
    width: 100%;
    overflow-x: scroll;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    @media (max-width: 850px) {
      width: unset;
      table-layout: fixed;
    }
  }

  tbody {
    tr {
      &:hover {
        background: #f7f8fa;
      }
    }
  }

  tr {
    height: 7.6rem;
  }

  th {
    padding: 2rem;
    color: #676767;
    font-weight: 400;
    font-size: 1.2rem;

    @media (max-width: 850px) {
      &:not(:first-child):not(:nth-child(2)) {
        min-width: 8rem;
      }
    }

    &:first-child {
      width: 8rem;
      color: #8492a6;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;

      @media (max-width: 850px) {
        left: 0;
        width: 2rem;
        position: sticky;
      }
    }

    &:nth-child(2) {
      width: 30rem;
      max-width: 20vw;
      white-space: nowrap;

      @media (max-width: 850px) {
        left: 5.6rem;
        position: sticky;
        overflow: hidden;
        min-width: 12rem;
        padding-right: 4rem;
        padding-left: 1.2rem;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }

  td {
    padding: 2rem;
    border-top: solid 1px #e0e5ea;

    @media (max-width: 850px) {
      &:not(:first-child):not(:nth-child(2)) {
        min-width: 8rem;
      }
    }

    &:first-child {
      width: 8rem;
      color: #8492a6;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;

      @media (max-width: 850px) {
        left: 0;
        width: 2rem;
        position: sticky;
      }
    }

    &:nth-child(2) {
      width: 30rem;
      max-width: 20vw;
      font-weight: 600;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 850px) {
        left: 5.6rem;
        position: sticky;
        overflow: hidden;
        min-width: 12rem;
        padding-right: 4rem;
        padding-left: 1.2rem;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const LogoContainer = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: fill;
  border-radius: 100%;
`;

export const ActionsContainer = styled.div`
  gap: 1rem;
  display: flex;

  a {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #696969;

      svg {
        fill: white;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const ActionTooltip = styled(UncontrolledTooltip)<{
  autohide?: boolean;
}>`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: ${props => (props.autohide === false ? 'all' : 'none')};

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0rem;
  }

  .arrow {
    display: none !important;
  }
`;
