import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  margin-bottom: auto;
  border-radius: 2rem;
  border: solid 1px #eee;
  flex-direction: column;
  background-color: white;
`;

export const ItemContainer = styled.div`
  display: flex;
  height: 8.2rem;
  padding: 2rem 3rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f9fbff;
  }

  &:first-of-type {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  &:last-of-type {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
`;

export const ItemNameDateContainer = styled.div`
  gap: 0.4rem;
  width: 23rem;
  display: flex;
  flex-direction: column;
`;

export const ItemName = styled.h1`
  color: #292929;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ItemDate = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const ItemAvatars = styled.div`
  width: 30rem;
  display: flex;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ItemAvatarContainer = styled.div`
  display: flex;
  border-radius: 50%;
  margin-left: -1.2rem;
  align-items: flex-end;

  &:first-child {
    margin-left: 0;
  }

  div {
    padding: 0.2rem;
    margin-left: -1.5rem;
    border-radius: 0.6rem;
    margin-bottom: -0.2rem;
    background-color: white;
  }
`;

export const ItemAvatarImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
  border: 0.3rem solid white;
`;

export const ItemTimeFrame = styled.div<{ show: boolean }>`
  height: 3rem;
  width: 16rem;
  display: flex;
  border-radius: 1.5rem;
  padding: 0.8rem 1.2rem;
  justify-content: center;
  background-color: #eff1f6;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;

export const ItemTimeFrameText = styled.span`
  color: #8492a6;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export const ItemCreatedAtContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const ItemCreatedAtLabel = styled.div`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const ItemCreatedAtDate = styled.div`
  color: #292929;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
`;

export const ItemActionsContainer = styled.div`
  gap: 5rem;
  display: flex;
  align-items: center;
`;

export const ItemIconButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eee;

    svg {
      fill: #292929;
    }
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: #bfbfbf;
  }
`;

export const ItemDivider = styled.div`
  height: 0.1rem;
  background-color: #eee;
`;
