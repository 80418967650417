import { t } from 'i18next';
import { DateRangePicker } from 'react-date-range';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type Props = {
  customHook: any;
};

export function ListeningFilterCalendar({ customHook }: Props) {
  const {
    timeFrame,
    setTimeFrame,
    validTimeframe,
    showTimeFramePicker,
    setShowTimeFramePicker,
  } = customHook;

  return (
    <>
      <ClickOutHandler
        onClickOut={() => {
          setShowTimeFramePicker(false);

          const activeInputContainer = document.querySelector(
            '.rdrDateRangeWrapper .rdrDateDisplayItemActive',
          );

          activeInputContainer?.classList.remove('rdrDateDisplayItemActive');
        }}
      >
        <S.InputContainer>
          <S.InputTitle>{t('discover.timeframe')}</S.InputTitle>

          <S.TimeFrameInnerContainer>
            <S.TimeFrameCalendarContainer
              onClick={() => setShowTimeFramePicker(!showTimeFramePicker)}
            >
              {showTimeFramePicker ? t('discover.close') : t('discover.open')}
              {t('discover.calendar')}
            </S.TimeFrameCalendarContainer>

            <S.TimeFramePickerContainer visible={showTimeFramePicker}>
              <DateRangePicker
                ranges={timeFrame}
                editableDateInputs={true}
                retainEndDateOnFirstSelection={true}
                onChange={(item: any) => {
                  setTimeFrame([item.selection]);
                }}
              />
            </S.TimeFramePickerContainer>
          </S.TimeFrameInnerContainer>
        </S.InputContainer>
      </ClickOutHandler>

      {!validTimeframe && (
        <S.ErrorText>* {t('listening.timeframeLimit')}</S.ErrorText>
      )}
    </>
  );
}
