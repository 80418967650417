/* eslint-disable no-dupe-keys */
const resources = {
  en: {
    translation: {
      languages: {
        'pt-PT': 'Portuguese - Portugal',
        'pt-BR': 'Portuguese - Brazil',
        'en-US': 'English',
      },
      generic: {
        submit: 'Submit',
        search: 'Search ',
        searchFor: 'Search for ',
        writeToSearch: 'Write to Search',
        searchHandler: 'Search Handler',
        to: 'To',
        lowercase_to: ' to ',
        clear: 'Clear',
        reset: 'Reset',
        default: 'Default',
        basic: 'Basic',
        full: 'Full',
        apply: 'Apply',
        type: 'Type',
        cancel: 'Cancel',
        close: 'Close',
        back: 'Back',
        dismiss: 'Dismiss',
        uhoh: 'Uh oh!',
        loading: 'Loading...',
        warning: 'Warning!',
        mandatoryField: 'Mandatory Field',
        accept: 'Accept',
        continue: 'Continue',
        letStart: "Let's Start",
        appleStore: 'App Store',
        googleStore: 'Google Play',
        articles: 'Articles',
        keywords: 'Keywords',
        audience: 'Audience',
        influencer: 'Influencer',
        engRate: 'Eng. Rate',
        posts: 'Posts',
        stories: 'Stories',
        videos: 'Videos',
        image: 'Image',
        video: 'Video',
        likes: 'Likes',
        shares: 'Shares',
        comments: 'Comments',
        entity: 'Entity',
        profile: 'Profile',
        country: 'Country',
        city: 'City',
        age: 'Age',
        date: 'Date',
        network: 'Social Network',
        handler: 'Handler',
        biography: 'Biography',
        skorr: 'Skorr',
        user: 'User',
        skorr_user: 'Skorr User',
        categories: 'Categories',
        category: 'Category',
        calendar: 'Calendar',
        cost: 'Cost',
        reach: 'Reach',
        replies: 'Replies',
        impressions: 'Impressions',
        skorrUser: 'Skorr Influencer',
        excludeTalent: 'Exclude Agency Talent',
        includeTalent: 'Include Agency Talent',
        reactions: 'Reactions',
        avgKpis: '(*) AVG for the last 30 Days',
        invalidValue: 'Invalid Value',
        noPermissionToAccessPageWarning:
          "You don't have permission to access this page",
        emptyHeader: 'No Results found',
        articlesLegend: "Don't have any Articles with this filters",
        postsLegend: "Don't have any Posts with this filters",
        newInfluencerLegend:
          'If is a new influencer you can submit in the Request influencer',
        linkArticle: 'Link to Article',
        avgKpisMetrics: '(*) AVG Metrics',
        hashTags: 'HashTags',
        smartlinks: 'SmartLinks',
        terms: 'Terms',
        noBiography: 'This influencer does not have biography',
        inviteInfluencers: 'Invite Influencers',
        sendEmail: 'Send Email',
        requestProfile: 'Request Profile',
        influencerWillAppear:
          'Your Profile will appear in list in a few moments',
        provideAllFields: 'Please provide all fields',
        showCharts: 'Show Charts',
        skorrUserAnalytics: 'Skorr Influencer Analytics',
        exportPage: 'Export Page',
        exportingPDF: 'Exporting PDF',
        noHashTags: 'No HashTags',
        requestInfo: 'Requesting Profile for ',
        contactCompany: 'Contact Company',
        agenciedBy: 'By ',
        avgPostFrom: 'Average per Post from ',
        month: 'Month',
        year: 'Year',
        years: ' years',
        show: 'Show ',
        match: ' Match',
        totalPosts: 'Total Posts',
        totalStories: 'Total Stories',
        reachPerPost: 'Reach per Post',
        impressionsPerPost: 'Impressions per Post',
        engagementPerPost: 'Engagement per Post',
        reachPerStory: 'Reach per Story',
        reachPerVideo: 'Reach per Video',
        impressionsPerStory: 'Impressions per Story',
        impressionsPerVideo: 'Impressions per Video',
        avgLikes: 'Avg Likes',
        loadingData: 'Loading Data',
        loadingUsers: 'Loading Users',
        loadingUserKpis: 'Loading User Kpis',
        loadingUserPosts: 'Loading User Posts',
        loadingUserSocialActivity: 'Loading User Social Activity',
        loadingUserEmotion: 'Loading User Emotion Data',
        loadingUserPrivacyData: 'Loading User Privacy Data',
        loadingUserReactionsVelocity: 'Loading User Reactions Velocity',
        loadingListUsersData: 'Loading List Users Data',
        loadingInvitations: 'Loading Invitations',
        updatingFilterDefinition: 'Updating filter definition',
        creatingDefaultInvitations: 'Creating Default Invitations',
        viewPost: 'View Post',
        copiedtoclipboard: 'Copied to Clipboard',
        copyText: 'Copy Text',
        texts: 'Text',
        previous: 'Previous',
        next: 'Next',
        finish: 'Finish',
        average: 'Average',
        evolution: 'Evolution',
        noDataFound: 'No data found',
        noInfoToRender: 'No information to be rendered',
        audience_demographic: 'Audience Demographic',
        last30days: ' Last 30 days',
        last: ' (Last ',
        days: ' days)',
        sum: 'Sum',
        add: 'Add',
        confirm: 'Confirm',
        personalValues: 'Personal Values',
        numberProfiles: 'Number of Profiles',
        'save&publish': 'Save and Publish',
        save: 'Save',
        update: 'Update',
        create: 'Create',
        updated: 'updated',
        created: 'created',
        postsSortBy: 'Posts Sort By',
        scrollTop: 'Scroll to Top',
        loadMore: 'Load more',
        postCount: 'Post Count',
        storyCount: 'Story Count',
        videoCount: 'Video Count',
        postsPerPage: 'Posts Per Page',
        storiesPerPage: 'Stories Per Page',
        orderPostsByReactions: 'Order Posts by Reactions',
        orderPostsByDate: 'Order Posts by Date',
        orderStoriesByDate: 'Order Stories by Date',
        orderStoriesByImpressions: 'Order by Stories Impressions',
        demographics: 'Demographics',
        NPS_analytics: 'NPS Sentiment Analysis',
        showUserGenderAge: 'Show User Gender and Age',
        showUserLocation: 'Show User Location',
        showUserBiography: 'Show User Biography',
        showUserHashtags: 'Show User Hashtags',
        showAudienceMatch: 'Show Audience Match',
        emotionalIntel: 'Emotional Intelligence',
        big5: 'Big 5',
        needs_values: 'Needs and Values',
        hashtagsWithMoreEng: 'HashTags With More Engagement',
        hashtagsWordCloud: 'Hashtags in Word Cloud',
        hashtagsTableView: 'Hashtags in Table View',
        orderMediaKitText:
          'Define the order by which sections will be displayed in the media kit',
        last90days: ' (Last 90 days)',
        velocityActivityTitle: 'Best Time to Post - Velocity',
        velocityActivityDescription:
          'Heat map analysis of best time to post by the average velocity each post got its reactions and analysis of on average how many hours after the content the reactions took place. By day of the week and time of the day',
        layout: 'Layout',
        privateProfile: 'This profile is private',
        privateProfileMsg1:
          'To see more Information about this Profile, you need to submit a request to ',
        privateProfileMsg2: ' which this Influencer is connected to',
        best: 'Best',
        userAnalytics: 'User Analytics',
        brandProfileAnalytics: 'Brand Profile Analytics',
        draft: 'Draft',
        running: 'Running',
        completed: 'Completed',
        canceled: 'Canceled',
        goingLive: 'Going to Live',
        waitingInfluencers: 'Waiting influencers',
        stopped: 'Stopped',
        public: 'Public',
        article: 'Article',
        hiddenArticles: 'hidden articles',
        showArticles: 'Show Articles',
        recent: 'Recent',
        showPosts: 'Show Posts',
        'post(s)': ' post(s)',
        'article(s)': ' article(s)',
        loadMorePosts: 'Load More Posts',
        loadMoreArticles: 'Load More Articles',
        goBack: 'Go back',
        resultsFor: 'Results for',
        combined: 'Combined ',
        separated: 'Separated ',
        editable: 'Editable',
        manageInfluencers: 'Manage Influencers',
        manageStatus: 'Manage Status',
        fillMandatoryFields: 'Please fill mandatory fields',
        details: 'Details',
        content: 'Content',
        timeline: 'Timeline',
        dateMustBeLesser: 'Date must be lesser than ',
        dateMustBeGreater: 'Date must be greater than ',
        '&lesserThan': ' and lesser than ',
        invalidField: 'Invalid Field',
        value: 'Value',
        product: 'Product',
        offer: 'Offer',
        topicsInterest: 'Topics',
        gender: 'Gender',
        name: 'Name',
        skorrValue: 'Skorr Value',
        influencerState: 'Influencer State',
        influencersExecuted: 'Influencers Executed',
        influencersAccepted: 'Influencers Accepted',
        typeWritingPolarity: ' Type Writing - Polarity',
        number_posts: '#Posts',
        number_stories: '#Stories',
        number_videos: '#Videos',
        number_hashtags: '#HashTags',
        short_impressions: 'Impr.',
        short_engagement: 'Engs',
        location: 'Location',
        engagement: 'Engagement',
        protectedInfo: 'Protected Info',
        SUM: 'SUM',
        saved: 'Saved',
        totalCost: 'Total Cost(€)',
        choosePic: 'Choose your Picture',
        skorrLink: 'Skorr Link',
        by: 'By ',
        edit: 'Edit',
        share: 'Share',
        noDataAvailable: 'No Data Available',
        cost1kimpressions: 'Cost Per 1k Impressions',
        cost1kaudience: 'Cost Per 1k Audience',
        costPerReactions: 'Cost per Reactions',
        typeWritting: 'Type Writting',
        influencerTypeWrittingPolarity: 'Influencer Type Writing - Polarity',
        influencerOffensiveLanguage: 'Influencer - Offensive Language',
        audienceTypeWrittingPolarity: 'Audience Type Writing - Polarity',
        audienceOffensiveLanguage: 'Audience - Offensive Language',
        hashtags_keywords_smartlinks: 'HashTags - Keywords - SmartLinks',
        influencers: 'Influencers',
        positive: 'Positive',
        neutral: 'Neutral',
        negative: 'Negative',
        false: 'False',
        true: 'True',
        editUserDetails: 'Edit Influencer Details',
        signInFacebookAndInstagramBusiness:
          'Sign in Facebook and Instagram Business',
        signInTwitter: 'Sign in with Twitter',
        viewPublications: 'View publications',
        add_edit_post_manually: 'Add/Edit post manually',
        wasSucessfully: ' was successfully ',
        added: 'added',
        edited: 'edited',
        ok: 'OK',
        plsWait: 'Please wait...',
        exportingToCSV: 'Exporting to CSV',
        exportToCSV: 'Export to CSV',
        taps_forward: 'Taps Forward',
        taps_back: 'Taps Backward',
        exits: 'Exits',
        clearImage: 'Clear Image',
        clearThumb: 'Clear Thumbnail',
        numberComments: 'Number of comments',
        views: 'Views',
        remove: 'Remove',
        saveInList: 'Save in a List',
        saveList: 'Save List',
        getBack: 'Get back',
        navigateToList: 'Navigate to list',
        newList: 'New List',
        myLists: 'My lists',
        icon: 'Icon',
        success: 'Success',
        title: 'Title',
        requiredField: 'This field is required.',
        tryAgain: 'Try again',
        email: 'Email',
        password: 'Password',
        website: 'Website',
        needHelp: 'Need help?',
        connectFacebookOrInstagram: 'Connect Facebook or Instagram Account',
        connectTwitterAccount: 'Connect Twitter Account',
        connectASocialNetwork: 'Connect a Social Network',
        profileFound: 'Profile Found',
        profilesFound: 'Profiles Found',
        searchAccount: 'Search Account...',
        description: 'Description',
        activeSocialNetworkAccounts: 'Active Social Network Accounts',
        connectedAccounts: 'Connected Accounts',
        loading1: 'Loading',
        addToCart: 'Add to Cart',
        members: 'Members',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        copySmartLink: 'Copy smart link',
        cpr: 'CPR',
        avgCPR: 'AVG CPR',
        cpmi: 'CPMI',
        avgCPMI: 'AVG CPMI',
        cpma: 'CPMA',
        avgCPMA: 'AVG CPMA',
        real: 'Real',
        engagements: 'Engagements',
        positiveSentiment: 'Positive Sentiment',
        neutralSentiment: 'Neutral Sentiment',
        negativeSentiment: 'Negative Sentiment',
        influencersInSkorr: 'Influencers in Skorr',
        totalInvested: 'Total Invested',
        totalCommitted: 'Total Committed',
        acceptanceRate: 'Acceptance Rate',
        executionRate: 'Execution Rate',
        uniqueInfluencersExecuted: 'Unique Influencers Executed',
        averageCampaignPrice: 'Average Campaign Price',
        budgetAccepted: 'Budget Accepted',
        budgetExecuted: 'Budget Executed',
        postsExecuted: 'Posts Executed',
        avgReactions: 'AVG Reactions',
        avgCampaignPrice: 'AVG Campaign Price',
        avgCampaignPricePerUser: 'AVG Campaign price per user',
        invites: 'Invites',
        skorrRanges: 'Skorr Ranges',
        engaged: 'Engaged',
        sort: 'Sort',
        ascending: 'Ascending',
        descending: 'Descending',
        images: 'Images',
        label: 'Label',
        custom: 'Custom',
        load: 'Load',
        emotionalIntelligence: 'Emotional Intelligence - Big 5',
        emotionalIntelligenceText:
          'Benchmark of brand and its social media audience personality. ML and AI powered linguistic analytics to infer brand, audience and individual personality characteristics (like big five, needs, and values) from digital communications in the social media. Big 5 represents the personlality traits scientificaly most accepted and with broader use.',
        allAccountTypes: 'All account types',
        connectThisAccount: 'Connect this account',
        accountsFound: 'Accounts Found',
        couldNotUpdateToken: 'Could not update token',
        tokenExpired: 'Token expired.',
        clickHereToRenew1: 'Click',
        clickHereToRenew2: 'here',
        clickHereToRenew3: 'to renew',
        connectingToTwitter: 'Connecting to twitter',
        selectLoginSocialNetwork: 'Select/Login Social Network',
        delete: 'Delete',
        activatingBrandProfile: 'Activating Brand Profile',
        brandProfileActivated: 'Brand Profile Activated',
        couldNotActivateBrandProfile: 'Could Not Activate Brand Profile',
        deactivatingBrandProfile: 'Deactivating Brand Profile',
        couldNotDeactivateBrandProfile: 'Could not Deactivate Brand Profile',
        optional: '(optional)',
        couldNotGetTopicsOfInterest: 'Could not get topics of interest',
        couldNotGetCountries: 'Could not get countries',
        allInfluencers: 'All Influencers',
        wallet: 'Wallet',
        talent: 'Talent',
        userFee: 'Data Opt-In Fee',
        orders: 'Orders',
        invalidEmail: 'Invalid Email',
        cantBeEmpty: 'Cannot be empty',
        pleaseWait: 'Please Wait',
        couldNotExportPageToPDF: 'Could not export page to PDF',
        couldNotGetInfluencerPrivacyData:
          'Could not get influencer privacy data',
        couldNotGetInfluencerKpisData: 'Could not get influencer Kpis data',
        inactive: 'Inactive',
        language: 'Language',
        logo: 'Logo',
        promoters: 'Promoters',
        passives: 'Passives',
        detractors: 'Detractors',
        npstitle: 'Audience (about ',
        usersReactedToThePosts: 'Users reacted to the posts',
        user_empty_profanity_title: ' Offensive Language',
        user_empty_profanity_legend:
          'Unfortunately the user does not produce enough data to calculate the offensive language level present in his social media',
        profanity: 'Offensive',
        clean: 'Clean',
        timezone: 'TimeZone',
        oops: 'Oops!',
        setValue: 'Set Value',
        defineCost: 'Define Cost',
        delete: 'Delete',
        brandProfile: 'Brand Profile',
        agencyProfile: 'Agency Profile',
        couldNotGetData: 'Could not get data',
        couldNotUpdatePartnerProfile: 'Could not update partner profile',
        partnerProfileUpdated: 'Partner profile updated',
        updatingPartnerProfile: 'Updating Partner Profile',
        yes: 'Yes',
        no: 'No',
        couldNotUpdateAgent: 'Could not update agent',
        agentUpdated: 'Agent updated',
        agentDeleted: 'Agent deleted',
        couldNotDeleteAgent: 'Could not delete agent',
        agentBeingUpdated: 'Agent being updated',
        agentBeingDeleted: 'Agent being deleted',
        partnerProfile: 'Partner Profile',
        usersWithoutAudienceDemographic:
          'Influencers without Audience Demographic',
        usersWithoutAudienceDemographicLegend:
          'No Audience Demographic found in the selected timeframe',
        enterCurrentPassword: 'Enter current password',
        enterNewPassword: 'Enter new password',
        enterPassword: 'Enter password',
        invalidPassword: 'Invalid Password',
        agentBeingCreated: 'Agent being created',
        agentCreated: 'Agent created',
        couldNotCreateAgent: 'Could not create agent',
        couldNotUpdatePassword: 'Could not update password',
        deletingPartner: 'Deleting partner',
        updateDataAccess: 'Update DataAccess',
        createModel: 'Create Model',
        modelType: 'Model Type',
        modelEdit: 'Model Edit',
        editModelConfig: 'Edit Model Configuration',
        configStatus: 'Configuration Status',
        modelTooltip: 'Public model, and for that can be used by other players',
        scheduler: 'Scheduler',
        enableScheduler: 'Enable Scheduler',
        enableSchedulerTooltip:
          'If the model version is created using a cron Job',
        publisher: 'Publisher',
        articlePublisher: 'Article Publisher',
        editPublisher: 'Edit Publisher',
        editInformation: 'Edit Information',
        changePageMoreUsers: 'Change page for more Influencers',
        changePageMorePublishers: 'Change page for more Publishers',
        userInfoEdited: 'Influencer info Edited',
        publisherInfoEdited: 'Publisher info Edited',
        createAgent: 'Create Agent',
        noAgentsToBeDisplayedTitle: 'No agents to be displayed',
        noAgentsToBeDisplayedSubtitle:
          'There are currently no agents to be displayed',
        configurations: 'Configurations',
        version: 'Version',
        deploymentStatus: 'Deployment Status',
        dictionary: 'Dictionary',
        dictionaries: 'Dictionaries',
        selectDictionary: 'Select Dictionary',
        semantic: 'Semantic',
        configSemantic: 'Configure Semantic',
        'enrichDictionary?': 'Enrich Dictionary?',
        'enrichDictionary?tooltip': 'Enrich Dictionary With Semantic?',
        streams: 'Streams',
        semanticConfig: 'Semantic Configuration',
        attachFiles: 'Attach Files',
        addSemanticRecord: 'Add Semantic Record',
        addChildTo: 'Add child to ',
        key: 'Key',
        definition: 'Definition',
        deleteRecord: 'Delete record ',
        deleteAImodelModalbody:
          'You are about to delete the key and his childs. Do you confirm?',
        uploadAImodelModalTitle: 'You are about to load Semantic Tree By File',
        pleaseSelectSemanticTreeFile: 'Please select the Semantic Tree file',
        invalidJSONtext: 'Invalid Json Text',
        uploadAll: 'Upload All',
        all: 'All',
        chat: 'Chat',
        reminders: 'Reminders',
        size: 'Size',
        progress: 'Progress',
        modelVersions: 'Model Versions',
        configVersion: 'Configuration Version',
        accuracy: 'Accuracy',
        versionStatus: 'Version Status',
        deployStatus: 'Deploy Status',
        promote: 'Promote',
        promoteVersionToFinal: 'Promote Version to Final',
        promoteVersionToFinalBody:
          'You are about to change the version to Final, any other Final version will be changed to Staging',
        compareVersions: 'Compare Versions',
        filtersApplied: 'Filters Applied',
        filtersMissing: 'Filters Missing',
        insertNumberOfPosts: 'Insert Number of Posts',
        insertNumberOfStories: 'Insert Number of Stories',
        noInfluencersToBeDisplayedTitle: 'No influencers to be displayed',
        noInfluencersToBeDisplayedSubtitle:
          'There are currently no influencers to be displayed',
        dateOfBirth: 'Date of Birth',
        socialNetwork: 'Social Network',
        username: 'Username',
        followers: 'Followers',
        others: 'Others',
        gettingData: 'Getting data',
        mediaKits: 'Media Kits',
        portfolios: 'Portfolios',
        last: 'Last',
        days: 'days',
        new: 'New',
        copied: 'Copied',
        errorGettingData: 'Error getting data',
        settings: 'Settings',
        view: 'View',
        reconnect: 'Reconnect',
        second: 'second',
        seconds: 'seconds',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'hour',
        hours: 'hours',
        day: 'day',
        days: 'days',
        week: 'week',
        weeks: 'weeks',
        month: 'month',
        months: 'months',
        year: 'year',
        years: 'years',
        ago: 'ago',
        created: 'created',
        ok: 'Ok',
        copy: 'Copy',
        portfolio: 'Portfolio',
        emailFormatIsNotValid: 'Email format is not valid',
        emailIsRequired: 'Email is required',
        passwordIsRequired: 'Password is required',
        nameIsRequired: 'Name is required',
        countryIsRequired: 'Country is required',
        topicsOfInterestIsRequired: 'Topics of interest are required',
        skip: 'Skip',
        nameIsRequired: 'Name is required',
        actions: 'Actions',
        active: 'Active',
        activate: 'Activate',
        lastUpdatedAt: 'Last updated at',
        topicsOfInterest: 'Topics of Interest',
        members: 'Members',
        lists: 'Lists',
        searchByUsernamePlaceholder: 'Search by username...',
        loadingLocationsData: 'Loading locations data',
        insertName: 'Insert Name',
        websiteFormatIsNotValid: 'Website format is not valid',
        selectPicture: 'Select picture',
        couldNotLoadData: 'Could not load data',
        savingChanges: 'Saving Changes',
        verified: 'Verified',
        expired: 'Expired',
        added: 'Added',
        gotIt: 'Got it',
        clearFilters: 'Clear filters',
      },
      placeholder: {
        selectCountry: 'Select Country',
        selectCity: 'Select City',
        selectGender: 'Select Gender',
        insertDescription: 'Insert Description',
        insertName: 'Insert Name',
        insertEmail: 'Insert Email',
        insertPassword: 'Insert Password',
        insertNewPassword: 'Insert New Password',
      },
      warning: {
        invalidDateOfBirth: 'Invalid Date of Birth',
      },
      partner: {
        partnerDetails: 'Partner Details',
        influencerProfile: 'Influencer Profile',
        uploadLogo: 'Upload Logo',
        billDifFromHQ: 'Billing different from Head Office Details?',
        companyRepPerson1: 'Company Representative Person 1',
        companyRepPerson2: 'Company Representative Person 2',
        companyBusinessDescription: 'Company Business Description',
        companyMainBrand: 'Company Main Brand',
        contactEmail: 'Contact Email',
        headOfficeDetails: 'Head Office Details',
        companyName: 'Company Name',
        webSite: 'Web Site',
        address: 'Address',
        postalCode: 'Postal Code',
        vatId: 'Vat-Id',
        vatExemption: 'Vat-Exemption',
        billingAddress: 'Billing Address',
        applications: 'Applications',
        checkAPIusage: 'Check API Usage',
        createApplication: 'Create Application',
        readAPIdoc: 'Read API Documentation',
        applicationName: 'Application Name',
        createdDate: 'Created Date',
        updatedDate: 'Updated Date',
        dataAccessLevel: 'Data Access Level',
        editApplicationDetails: 'Edit Application Details',
        saveApplication: 'Save Application',
        saveApplicationKey: 'Save Application Key',
        createApplicationKey: 'Create Application Key',
        editApplicationKey: 'Edit Application Key',
        expireDate: 'Expire Date',
        createAppKey: 'Create App Key',
        API: 'API',
        APIkey: 'API KEY',
        APIusage: 'API Usage',
        APIdetail: 'API - Detail',
        application: 'Application',
        APIusageSummary: 'API Usage Summary',
        requests: 'Requests',
        returnedRecords: 'Returned Records',
        APIusageSummaryByApplication: 'API Usage Summary by Application',
        createIntegration: 'Create Integration',
        integration: 'Integration ',
        googleAnalytics: 'Google Analytics',
        transactionDate: 'Transaction Date',
        direction: 'Direction',
        transactionId: 'Transaction Id',
        effectiveDate: 'Effective Date',
        source: 'Source',
        createBrandProfile: 'Create Brand Profile',
        createNewProfile: 'Create New Profile',
        brandProfileName: 'Brand Profile Name',
        agentName: 'Agent Name',
        agentEmail: 'Agent Email',
        editAgent: 'Edit Agent',
        dpo: 'Dpo',
        'isDPO?': 'is DPO?',
        'isExternal?': 'is External?',
        externalCompany: 'External Company',
        mobilePhone: 'Mobile Phone',
        dataProtectionOfficer: 'Data Protection Officer',
        searchAgent: 'Search Agent',
        createTemplate: 'Create Template',
        htmlCodeBody: 'HTML Code - Body',
        editTemplateDetails: 'Edit Template Details',
        editTemplate: 'Edit Template',
        updateTemplate: 'Update Template',
        saveTemplate: 'Save Template',
        skorrTrackers: 'Skorr Trackers',
        createNew: 'Create New',
        segment: 'Segment',
        createSkorrTracker: 'Create Skorr Tracker',
        editSkorrTracker: 'Edit Skorr Tracker',
        url: 'URL',
        domain: 'Domain',
        viewsSkorrTracker: 'Views (total/unique)',
        impressionsSkorrTracker: 'Impressions (total/unique)',
        createPartner: 'Create Partner',
        accountValidated: 'Account Validated',
        activationCode: 'Activation Code',
        activeByMobile: 'Active by Mobile',
        commercialName: 'Commercial Name',
        companyEmail: 'Company Email',
        billingDetails: 'Billing Details',
        editPartner: 'Edit Partner',
        features: 'Features',
        agentsList: 'Agents List',
        updateApplication: 'Update Application',
        newTemplate: 'New Template',
        userInviteSummary: 'User Invitation Summary by Invitation List',
        measure: 'Measure',
        invitationsDetail: 'Invitations - Detail',
        userFollowerSummary: 'User Follower Summary',
        userFollowerSNEvolution: 'User Follower by Social Network - Evolution',
        followers: 'Followers',
        activeFollowers: 'Active Followers',
        candidates: 'Candidates',
        'skorr-tracker-segment': {
          ad: 'Advertising',
          if: 'Influence',
          cm: 'Community',
          ab: 'Ambassador',
        },
        GOOGLE_ANALYTICS: {
          message: 'To set up Google Analytics, you simply have to configure:',
          viewId: 'ViewId',
          viewId_tooltip: 'View Identifier',
          activated: 'Activated?',
          activated_tooltip: 'If the ViewId was activated',
        },
        entityType: {
          BUSINESS_PROFILE: 'Brand Profile',
          PARTNER: 'Partner',
          USER: 'User',
          SKORR: 'Skorr',
          PAYWARE: 'Payware',
        },
        tooltip: {
          dataAccessLevel:
            'Defines the data that public API requests made with this application will have access to',
          roles:
            'Defines the endpoints whose code, public API requests made with this application, will have permission to run',
          integrationName: 'Integration Name',
          integrationType: 'Integration Type',
          integrationActive: 'Active?',
          transactionDirection: 'Transaction Direction In/Out',
          transactionStatus: 'Transaction Status',
          transactionDate: 'Transaction created Date',
          transactionId: 'Transaction Identifier',
          transactionCreatedAt: 'Transaction Created At',
          transactionEffectiveDate: 'Transaction Effective Date',
          transactionAmount: 'Transaction Amount',
          transactionSource: 'Transaction Source',
          transactionTarget: 'Transaction Target',
          brandProfileAnalytics: 'Open Business Analytics Dashboard',
          brandProfileEdit: 'Edit Details',
          dataProtectionOfficer:
            'Person who can accept the Data Protection Officer - Contract',
          'isExternal?': 'If the Person does not belong the company',
          agentRoles: 'Defines the permission level of the agent',
          agentBrandProfile:
            'Defines the brand profile the agent is associated with',
          createAgent: {
            partnerAdmin: 'Full access and features',
            agentAdmin:
              'Full access and features except manage funds transfer between partner and brand wallets or recharge the wallet',
            agentOperator:
              'Access both partner and all brands area. Can view and edit all features, except editing partner data or create new agents',
            agentViewer:
              'Access both partner and all brands defined. Can view all features, but cannot create campaigns, lists or define other agents or change any data',
            campaignViewer:
              'Can only access campaigns and his own profile. Ideal for external accesses. You shall define a brand profile, otherwise will access campaigns of all brands created under your partner profile',
          },
        },
      },
      wallet: {
        TRANSFER: 'Transfer',
        WITHDRAW: 'Withdraw',
        TOPUP: 'TopUp',
        income: 'Income',
        outcome: 'Outcome',
        balance: 'Balance',
        transfer: {
          PAYWARE: 'Payware',
          PARTNER: 'Partner',
          BUSINESS_PROFILE: 'Brand Profile',
          title: 'Transfer money',
          legend: 'Send money between accounts',
          valuePlaceHolder: 'Value to Transfer',
          value: 'Value(pts)',
          action: 'Transfer',
          to: {
            label: 'To',
          },
          from: {
            label: 'From',
          },
          balance: 'Wallet Balance(pts)',
        },
        withdraw: {
          title: 'Withdraw money',
          title: 'Send money to your bank account',
          action: 'Withdraw',
          withdraw: 'Withdraw',
          bankName: 'Bank Name',
          bankAccountNumber: 'Bank Account Number',
          value: 'Amount(pts)',
          valuePlaceHolder: 'Amount',
          description: 'Description',
          orderError: 'Order was not generated',
        },
        topup: {
          errortrylater: 'Payment error please try again later',
          amount: 'Amount(€)',
          amountPlacehoder: 'Amount',
          processing: 'Processing...',
          topup: 'TopUp',
          sucess: 'Your TopUp was executed with succeeded',
          paymentIntentresp: 'View PaymentIntent response',
          paymentFaild: 'Payment failed',
          title: 'TopUp - Money Transfer',
          amountPts: 'Amount in Pts',
          aftertax: 'After Tax & Fee (€)',
          info: 'In order, of applying the correct tax of your country, please contact SKORR to help you.',
        },
        order: {
          agent: 'Agent',
          orderType: 'Order Type',
          effectiveDate: 'EffectiveDate',
          transaction: {
            direction: {
              INBOUND: 'IN',
              OUTBOUND: 'OUT',
            },
          },
          statustitle: 'Status',
          status: {
            PENDING: 'Pending',
            OPEN: 'Open',
            REFUND: 'Refund',
            CANCEL: 'Cancel',
            REJECTED: 'Rejected',
            COMPLETE: 'Complete',
          },
          empty: {
            title: 'No orders',
            legend: 'No orders found',
          },
          ordertype: {
            CAMPAIGN_INFLUENCE: 'Campaign Influence',
            MARKET_STUDY: 'Market Study',
            BUY_USER_DATA: 'User Data',
            NONE: 'None',
            TRANSFER: 'Transfer',
            WITHDRAW: 'Withdraw',
            TOPUP: 'TopUp',
          },
          popup: {
            orderDate: 'Order submitted Date',
            agent: 'Agent who submitted the order',
            amount: 'Total order amount(pts)',
            orderType: 'Order defined Type',
            status: 'Order Status',
            effectiveDate: 'Date where the order was confirmed',
          },
        },
      },
      cockpit: {
        manageFilters: 'Manage filters',
        skorrListInfluencers: 'Skorr List Influencers',
        publicListInfluencers: 'Public List Influencers',
        brandWithoutLists: 'No lists were created in the context of this brand',
        brandAnalytics: 'Brand Analytics',
        someTokensExpired: 'Some token(s) have expired!',
        alertEvents: 'Alert events: ',
        alertEventsTooltip:
          'Alert events triggered in the last 24 hours / Total alert events triggered',
        seeAllAlerts: 'See all alerts',
        avgAudienceFrom: 'Avg. Audience from ',
        avgLikesFrom: 'Avg. Likes from ',
        avgEngRateFrom: 'Avg. Engagement Rate from ',
        avgCommentsFrom: 'Avg. Comments from ',
        avgSharesFrom: 'Avg. Shares from ',
        changeFilterConfig: 'Change filter configuration',
        createFilterForList: 'Create filter for list',
        variationFrom: ' variation from ',
        addListing: 'Add Listing',
        positiveResult:
          'The average number of the specified criteria per post currently observed is greater than previously',
        negativeResult:
          'The average number of the specified criteria per post currently observed is smaller than previously',
        neutralResult:
          'The average number of the specified criteria per post currently observed is equal to previously',
        overview: 'Overview',
        campaignsAnalytics: 'Campaigns Analytics',
        overviewTooltip:
          'Broad social media metrics and key Skorr performance analytics',
        campaignsAnalyticsTooltip:
          'Key performance indicators of all campaigns executed, by social media network',
        globalKpis: 'Global KPI’s',
        brandSocialNetworks: 'Brand Social Networks',
        sinceStart: 'Since Start',
        skorr_influencers: 'Skorr - Influencers',
        skorr_investment: 'Skorr - Investment',
        skorr_campaignBudget: 'Skorr - Campaigns Budget',
        skorr_campaignPerformance: 'Skorr - Campaigns Performance',
        editProfile: 'Edit Profile',
        exitProfile: 'Exit Profile',
        campaignExecutionFunnel: 'Campaign Execution Funnel',
        campaignExecutionFunnelTooltip:
          'Funnel analysis with potential and real audience and CPMI (cost per 1 thousand impressions)',
        campaignPowerInfluencers: 'Campaign Power Influencers',
        campaignPowerInfluencersTooltip:
          "Influencers that participated in Brand's campaigns with best results",
        campaignList: 'Campaign List',
        socialNetworkTooltip: 'Social Network of this campaign',
        period: 'Period',
        periodTooltip: 'Campaign start/end date',
        invitedTooltip: 'Number of influencers that were invited',
        executedTooltip: 'Number of influencers that executed the campaign',
        costTooltip: 'Total cost of campaign, including agency or other fees',
        userSkorrTooltip:
          'Skorr indicates influencer social influence level, in a scale from 10 to 100, for a social media',
        audienceTooltip: 'Number of connections in this social media',
        invitedCampaignTooltip:
          'Number of campaign invitations from the brand this influencer has received for this social media',
        acceptedCampaignTooltip:
          'Number of campaign invitations from the brand this influencer has accepted for this social media',
        executedCampaignTooltip:
          'Number of campaign from the brand this influencer has executed in this social media',
        postCampaignTooltip:
          'Number of campaign posts /videos /stories this influencer has executed in this social media',
        reachCampaignTooltip:
          'Total number of people that saw this influencer campaign posts /videos /stories',
        avgImpressionsCampaignTooltip:
          'Average times each campaign post was shown in social media users feeds',
        totalImpressionsCampaignTooltip:
          'Total times campaign posts were shown in social media users feeds',
        cpmImpressionsCampaignTooltip:
          'Cost per Mille, refers to cost per 1000 impressions',
        avgAudienceCampaignTooltip:
          'Average number of audience per campaign post',
        totalAudienceCampaignTooltip: 'Total number of fans in a social media',
        cpmAudienceCampaignTooltip:
          'Estimated metric: cost per 1000 audience in a social media',
        avgReactionsCampaignTooltip:
          'Average number of reactions per campaign post',
        totalReactionsCampaignTooltip:
          'Total number of campaign reactions generated by this influencer',
        cprCampaignTooltip: 'Influencer cost / Total number of reactions',
        realRateCampaignTooltip:
          'Total price paid to this influencer for running campaigns in this social media',
        keyPerformanceIndicators: 'Key Performance Indicators',
        keyPerformanceIndicatorsTooltip:
          'Most important performance indicators agreggated for all campaigns run by social media',
        activatingBrandProfile: 'Activating Brand Profile...',
        cantActivateBrandProfile: 'Cannot Activate Brand Profile',
        desactivatingBrandProfile: 'Desactivating Brand Profile...',
        cantDesactivateBrandProfile: 'Cannot Desactivate Brand Profile',
        customizeCockpit: 'Customize Cockpit Activity Listing',
        filterName: 'Filter Name',
        filterNameTooltip:
          'Name to be assigned to the filter that will be attached to the selected list',
        filterTargetTooltip: 'List the filter will be attached to',
        filterSortTooltip:
          'Sort order by which the list members will be displayed',
        filterKpiMetric: 'KPI Metric',
        filterKpiMetricTooltip:
          'KPI metric by which the list members will be ranked',
        filterPanelOrder: 'Panel Order',
        filterPanelOrderTooltip:
          'Order by which the list to which this filter is attached will be displayed in the cockpit',
        filterVisible: 'Visible',
        filterVisibleTooltip:
          'Defines if this list will be visible in the cockpit',
        filterTimeRange: 'Time Range',
        filterTimeRangeTooltip:
          'Select a predefined time range or define a custom one',
        filterStartAt: 'Start At',
        filterStartAtTooltip: 'Date at which the time interval begins',
        filterEndAt: 'End At',
        filterEndAtTooltip: 'Date at which the time interval ends',
        filterSlidingTimeFrame: 'Sliding Time Frame',
        filterSlidingTimeFrameTooltip:
          'Defines if the selected time range will be used starting from the present day and going backwards or as it was originally defined',
        filterUpdated: 'Filter Updated',
        filterNotUpdated: 'Filter Not Updated',
        publicListFilters: 'Public list filters',
        createListFilter: 'Create list filter',
        sortBy: 'Sorty By',
        sortOrder: 'Sort Order',
        noFiltersFound: 'No filters found',
        cockpitOverviewTooltip: {
          audience:
            "Today's total of followers/fans in the Brand's social media",
          posts:
            "Total number os posts, tweets, videos, stories published in the Brand's social media in the timeline defined",
          engagements:
            "Total of reactions, likes, shares and comments identified in the Brand's social media in the timeline defined",
          positiveSentiment:
            'Total positive inbound messages / total inbound messages',
          neutralSentiment:
            'Total neutral inbound messages / total inbound messages',
          negativeSentiment:
            'Total negative inbound messages / total inbound messages',
          influencersInSkorr:
            'Users that accepted the opt-inTotal users that have given/sold the data optin, with their name and social media activity being currently visible',
          moneySpent:
            'Average amount of money paid on users data optin (active for 90 days)',
          totalInvested:
            'Total money invested on data opt-in and campaigns since the start',
          totalCommitted:
            'The total investment allocated to ongoing campaigns, whether they have already been executed or not',
          acceptanceRate:
            'Percentage of the budget set for the campaigns that got committed via the invited influencers acceptance',
          executionRate:
            'Percentage of the budget set for the campaigns that has been executed',
          campaigns: 'Total Number of Executed Campaigns',
          uniqueInfluencersExecuted:
            'Number of unique influencers that executed at least one post within the timeline selected',
          postsPerformance: 'Total posts executed during the timeline selected',
          reactions:
            'Total number of reations resulting from active campaigns in the timeline selected',
          cpr: 'Average cost per reaction in the timeline selected',
          averageCampaignPrice:
            'Average price of the campaigns that were closed within the timeline selected',
          postsExecuted:
            "Total number os posts, tweets, videos, stories published in the Brand's social media",
          avgCPMI:
            "Average cost per 1000 impressions in the Brand's social media",
          avgCPMA: "Average cost per 1000 audience in the Brand's social media",
          avgReactions:
            "Total of reactions, likes, shares and comments identified in the Brand's social media",
          avgCPR: "Average cost per reaction in the Brand's social media",
          avgCampaignPrice:
            "Average price of the campaigns in the Brand's social media",
          avgCampaignPricePerUser:
            "Average price of the campaigns in the Brand's social media per user",
          viewInfluencersFilters: 'Click to view influencers filters',
          filterListName: 'Name of the list the filter is associated with',
          filterSortBy: 'Kpi metric by which the list if sorted',
          filterSortOrder: 'Order by which the list is sorted',
          filterVisible: 'Defines if the list will be visible in the cockpit',
          filterPanelOrder:
            'Order by which the list will be displayed in the cockpit',
          editFilterDefinition: 'Edit filter definition',
        },
        accountFound: 'Account Found',
        accountsFound: 'Accounts Found',
        connectThisAccount: 'Connect this account',
        tokenExpired: 'Token expired.',
        clickHereToRenew1: 'Click',
        clickHereToRenew2: 'here',
        clickHereToRenew3: 'to renew',
        allAccountTypes: 'All account types',
      },
      userProfile: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
        email: 'Email',
        addAccount: 'Add Account',
        tokenExpired: 'Token expired',
        editInfluencer: 'Edit Influencer',
        influencerName: 'Influencer Name',
        influencerPicture: 'Influencer Picture',
        accountHasBeenAdded: 'This account has been added',
        cantLoadSN: 'Cannot Load Social Networks',
        influencerUpdated: 'Influencer Updated',
        influencerCreated: 'Influencer Created',
        cantUpdateInfluencer: 'Cannot Update Influencer',
        searchTopicsText:
          'Click on the search bar to start searching a new category',
        accountNetworkAlreadySelected:
          'An account of this network is already selected',
        snAlertText:
          'In order to add multiple-social accounts, please, first, in your web-browser log-out in each Social Network.',
        emptyProfileTitle:
          'Cannot found any Profile that match with your search.',
        emptyProfileLegend1:
          'You must enable the Profile, under your Facebook Business Settings.',
        emptyProfileLegend2:
          "You don't have Profile associated with your Facebook.",
        waitingSocialNetworkAccounts:
          'Waiting for Social Network Accounts data',
        snAccountTokens: 'Social Networks Account Tokens',
      },
      workspace: {
        myTalents: 'My Talents',
        selectNetworkDisplay: 'Select a social network to display',
        removeInfluencerFromAgency:
          'Confirm influencer removal from the agency',
        removeInfluencerFromAgencyDetails:
          'This action may take a while to complete and for that reason you can wait a few moments and refresh the page if you want to see the updated list of your talents.',
        registerInfluencer: 'Register Influencer',
        renewToken: 'Renew Token',
        renewTokens: 'Renew Tokens',
        renewAllTokens: 'Renew All Tokens',
        renewingTokens: 'Renewing tokens',
        youAreRenewing: 'You are renewing:',
        tokenNotBelongAnyAccount:
          'Token does not belong to Any Account registered in SKORR',
        tokenHasBeenUpdated: 'This account token has been updated.',
        removeUserFromAgency: 'Remove Influencer from Agency',
        userHasExpiredTokens: 'Influencer has Expired Tokens',
        removeUserFromAgencySuccess:
          'Influencer was successfully removed from Agency',
        removeUserFromAgencyError:
          'It was not possible to remove influencer from Agency',
        viewMyTalentInvite: 'View My Talent Invitations',
        noInvitationsDisplayed: 'No Invitations to be displayed',
        noInvitationsDisplayedLegend:
          'No Invitations were made by other agencies to your influencers',
        partner: 'Partner',
        agencyTalents: 'Agency Talents',
        workingLists: 'Working Lists',
        createNewList: 'Create New List',
        editList: 'Edit List',
        removeList: 'Remove List',
        removeListSubtitle: 'You are about to remove the list',
        removeListBody:
          'You are about to remove, after that you cannot use these Lists Anymore. Do you confirm?',
        createNewListLegend: 'Please write the name of your new list below',
        editListLegend: 'Please edit the name of your list below',
        invitationTypes: 'Invitations Types',
        myAgencyTalents: 'My Agency Talents',
        publicInviteDescription:
          'Invite influencers from the discovery database, your brand clients or social media followers. Select one of the pre-defined templates and the language. You can create your own invitation inserting a html or to just customize the notification and the message influencers will receive once Skorr app is installed. The invitation with the link can be sent via email, whatsapp, sms or social media. ',
        agencyInviteDescription:
          'Invite your agency talents to onboard in Skorr. Choose the pre-defined template and the language. You can create your own invitation inserting a html or just customize the notification and message your talents will receive once Skorr app is installed. The invitation with the link can be sent via email, whatsapp, sms or social media. Be sure talents link their professional social, confirming their IG professional is linked to a FB Pages. Your talents data will be protected, with media kit only visible under request to the agency. All campaign requests will be handled via agency.',
        seeBrowserPreview: 'See browser preview',
        editInvitation: 'Edit Invitation',
        createTalentInvitation: 'Create Talent Invitation',
        createPublicInvitation: 'Create Public Invitation',
        userTable: {
          USER_INFO: 'Influencer',
          USER_COUNTRY_CODE: 'Country',
          SOCIAL_NETWORK: 'Social Network',
          POST_CNT: '#Posts',
          STORY_CNT: '#Stories',
          AUDIENCE: 'Audience',
          REACH: 'Reach',
          USER_CAMPAIGN_RATE: 'Campaign Influencer Rate',
          USER_SKORR_CAMPAIGN_RATE: 'Campaign Skorr Rate',
          USER_COST_CAMPAIGN: 'Cost Per Campaign',
          LIKES: 'Likes',
          COMMENTS: 'Comments',
          SHARES: 'Shares',
          CPI: 'CPI',
          IMPRESSIONS: 'Impressions',
          USER_HANDLER: 'Handler',
          ACTIONS: 'Actions',
          ENGAGEMENT_RATE: 'Eng. Rate',
          tooltip: {
            USER_INFO: 'SKORR Influencer Identification',
            USER_HANDLER: 'Social Network handler',
            USER_COUNTRY_CODE: 'User geographic location',
            SOCIAL_NETWORK: 'Social Network',
            POST_CNT: 'Number of posts/videos to be published for the campaign',
            STORY_CNT: 'Number of stories to be published for the campaign',
            AUDIENCE: 'Number of connections in talent network',
            LIKES: 'Number of likes',
            COMMENTS: 'Number of Comments',
            SHARES: 'Number of Shares',
            REACH:
              "Total number of people who saw the influencer's content in the last 90 days",
            USER_CAMPAIGN_RATE:
              'Amount defined by Influencer to make a publication / Amount defined by user to perform campaign',
            USER_SKORR_CAMPAIGN_RATE:
              'Amount calculated by SKORR for one publication / Amount calculated by SKORR for the influencer to perform the campaign',
            USER_COST_CAMPAIGN: 'Cost Per Campaign',
            CPI: 'Cost per Impression',
            IMPRESSIONS:
              "Number of times talent content was shown in users' feeds in the last 90 days",
            ENGAGEMENT_RATE: 'Engagement Rate amount Influencer Audience',
          },
        },
        tooltips: {
          influencerAccepted: 'Influencer accepted the campaign invitation',
          influencerDeclined: 'Influencer declined the campaign invitation',
          influencerWaiting:
            'Waiting for the agency to react to its influencer invitation to the campaign',
          influencerExpired: "It's past the campaign's expiration date",
          createInvitation:
            'Click on the cards above to toggle between Public and Talent Invitation creation',
          invitationActivePeriod: 'Invitation Active Period',
        },
      },
      campaigns: {
        campaign: 'Campaign',
        editCampaign: 'Edit Campaign',
        shareCampaignLink: 'Share Campaign Summary Link',
        searchCampaign: 'Search Campaign...',
        create_campaign: 'Create Campaign',
        editableCampaigns:
          'Click to alternate between Influence Campaigns and Campaigns whose reports are editable',
        createCampaign: 'Create New Campaign',
        createEditableCampaign: 'Create Editable Report',
        campaignEmptyStateHeader: 'Welcome to Campaigns!',
        campaignEmptyStateP1:
          "You don't have campaigns yet, try creating a new one by pressing the ",
        campaignEmptyStateP2: ' button at the top of the page',
        editReportEmptyStateHeader: 'Welcome to Editable Report!',
        editReportEmptyStateP1:
          "You don't have editable reports yet, try creating a new one by pressing the ",
        emptyInfoHeader1: 'Empty filter result',
        emptyInfoLegend1:
          'No Influencers that satisfy the filter conditions you provided were found',
        emptyInfoHeader2: 'No Influencers',
        emptyInfoLegend2:
          'There are currently no Influencers that satisfy the required conditions to be displayed in this tab',
        emptyInfoLegend3: 'Cannot find any Influencers',
        partnerProfile: 'Partner Profile',
        campaignName: 'Campaign Name',
        duration: 'Duration',
        budget: 'Budget',
        statusChanged: 'Status Changed',
        campaignUpdated: 'Campaign Updated',
        invitationUpdated: 'Invitation Updated',
        campaignNotUpdated: 'Campaign was not updated',
        invitationNotUpdated: 'Invitation was not updated',
        campaignSaved: 'Campaign Saved',
        invitationSaved: 'Invitation Saved',
        campaignCreated: 'Campaign Created',
        invitationCreated: 'Invitation Created',
        campaignNotCreated: 'Campaign was not created',
        invitationNotCreated: 'Invitation was not created',
        campaignBriefing: 'Campaign Briefing',
        campaignBriefingLegend:
          'Here you can see more information about the campaign your influencer was invited to as well as accept or decline the invitation',
        noEditableReport: 'No Editable Report Campaigns',
        noEditableReportFound:
          'No Campaigns whose Reports are editable could be found',
        influencerOnboarding: 'Influencer Onboarding',
        budgetPayment: 'Budget & Payment',
        internalName: 'Internal Campaign Name',
        invitationName: 'Invitation Name',
        invitation: 'Invitation ',
        selectSocialNetwork: 'Please select one Social Network',
        sendProductAdv: 'Send product for advertise',
        finalUserApproval: 'Final Influencer Approval',
        userContentApproval: 'Influencer Content Approval',
        chatUser: 'Chat with Influencer',
        editRegulation: 'Edit Regulation/Briefing',
        uploadRegulation: 'Upload Regulation/Briefing',
        uploadImage: 'Upload Image(s)',
        regulation_briefing: 'Regulation/Briefing',
        survey: 'Survey',
        required: 'Required',
        optional: 'Optional',
        improvise: 'Improvise',
        currentStatus: 'Current Status:',
        numberPosts: 'Number of Posts',
        enterNumberPosts: 'Enter number of posts',
        numberStories: 'Number of Stories',
        enterNumberStories: 'Enter number of stories',
        addHashtag: 'Add Hashtag',
        addKeyword: 'Add Keyword',
        addText: 'Add Text',
        addSmartlink: 'Add Smartlink',
        infoToShare: 'Information to Share',
        listName: 'List Name',
        listCreated: 'List Created',
        listUpdated: 'List Updated',
        pleaseFillListName: 'Please fill List Name',
        pleaseSelectList: 'Please select a List',
        pleaseSelectListOption: 'Please select List Option',
        skorrSendEmailInvite: 'I want SKORR to send Email invitation (free)',
        skorrSendPMInvite:
          'I want SKORR to send private message invitation (free)',
        brandingPrivacy: 'Branding Privacy in Skorr Email / Private Message',
        allowBrandLogo: 'Allow Using Brand Logo',
        allowBrandName: 'Allow Using Brand Name',
        allowAgencyLogo: 'Allow Using Agency Logo',
        allowAgencyName: 'Allow Using Agency Name',
        allowCampaignName: 'Allow Using Campaign Name',
        allowCampaignDescription: 'Allow Using Campaign Description',
        allocatedBudget: 'Allocated Budget (pts)',
        maxBudget: 'Maximum Budget (pts)',
        minBudget: 'Minimum Budget (pts)',
        paymentSkorr: 'Payment using Skorr',
        paymentSkorrDetails:
          'With this option active, the payment is done by SKORR, using the In-APP SKORR payment feature. The payment will be transfered to the Influencer Wallet.',
        paymentOutSkorr: 'Payment outside Skorr',
        paymentOutSkorrDetails:
          'With this option active, you will reward directly the influencers (with money or perks), not using the in-App skorr payment feature. Specific fees may apply.',
        paymentTerms: 'Payment Terms',
        marketplacePayrate: 'Market Place Payrate',
        skorrPayrate: 'Skorr Payrate',
        fixedPayrate: 'Fixed Payrate',
        usersNotSkorrAccount: 'Influencers without Skorr account',
        usersEmailDetected: ' Influencer(s) with Email Detected',
        usersEmailDetectedP1:
          'Public Influencers are not registered in SKORR platform yet. Those must be onboarded first. For that purpose, the Brand/Agency must define if will be contacting those influencers or if prefers SKORR proving that service (free). SKORR will contact via email (if available) and/or private message.',
        usersEmailDetectedP2:
          "Please select the options so that SKORR acknowledges your preference, and is clarified about what can be shared with the influencers as well. Once influencers are onboarded, will appear in the 'Invited' tab in the campaign management.",
        skorrTalentDetected: ' Talent Influencer(s) Detected',
        skorrTalent: ' Talent Influencer(s)',
        skorrTalentP1:
          'SKORR Influencers are the ones registered in SKORR platform. Can be independent or bonded to an Agency. Being an Independent influencer, will automatically receive your Campaign Invitation directly in-APP, to be Accepted/Rejected by himself.',
        skorrTalentP2:
          ' Being an Agency talent, you must first negotiate and get the acceptance of his Agency, which will be notified once you send your campaign invitation.',
        usersNotSkorr: 'Influencers without Skorr',
        selectedTargetList: 'Selected Target List',
        linkedToPublicList: 'Linked to Public List',
        totalUsers: 'Total Influencers',
        totalAudience: 'Total Audience',
        noListsToDisplay: 'No lists can be displayed',
        noListsToDisplayParagraph:
          "You either didn't select a social network in the details tab or no lists were created in your brand profile",
        noListSelected: 'No list was selected',
        noListSelectedParagraph:
          'In order for content to be displayed in this page a list of influencers must be selected in the target tab',
        perInfluencer: 'Per Influencer',
        describeValue: 'Here you can describe the value',
        describeProduct: 'Here you can describe the product',
        describeOffer: 'Here you can describe the offer',
        onlyForAdvertise: 'Only for advertise',
        invited: 'Invited',
        accepted: 'Accepted',
        executed: 'Executed',
        waiting: 'Waiting',
        awaitingApproval: 'Awaiting Approval',
        awaitingContent: 'Awaiting Content',
        forApproval: 'For Approval',
        posting: 'Posting',
        selectUsers: 'Select Influencers',
        contentSubmittedReview: 'Content Submitted for Review',
        moveUserToPosting: 'Move Influencer to Posting',
        viewAnalytics: 'View Analytics',
        viewMore: 'View More',
        message: 'Message',
        approve: 'Approve',
        reject: 'Reject',
        userHasSelected: 'The influencer has selected ',
        hasntSubmittedForReview:
          " in the application but hasn't submitted it for review yet.",
        userHasSubmitted: 'The influencer has submitted ',
        forReview: ' for review.',
        campaignSummary: 'Campaign Summary',
        ownerInfo: 'Owner Info',
        owner: 'Owner',
        statusTitle: 'Status',
        report: 'Report',
        performance: 'Performance',
        funnel: 'Funnel',
        defineLayoutOrder:
          'Define the order by which sections will be displayed in the campaign kit',
        viewUsersPub: "View user's publications",
        viewUsersPosts: "View user's posts",
        viewUsersStories: "View user's stories",
        removePost: 'Remove Post',
        addPost: 'Add Post',
        addStory: 'Add Story',
        editPost: 'Edit Post',
        editStory: 'Edit Story',
        addPostLegend: 'Here you can specify the post content',
        editPostLegend: 'Here you can edit the post content',
        addStoryLegend: 'Here you can specify the story content',
        editStoryLegend: 'Here you can edit the story content',
        addPostCampaign: 'Add post to campaign',
        addPostCampaignLegend: 'A post will be added to the campaign',
        addPostConfirmation:
          'You are about to add a post to the campaign. Do you confirm?',
        required_fields_image:
          'Either the Content or Image field must be filled as well as the post creation date',
        required_fields_video:
          'Either the Content or Video field must be filled as well as the post creation date',
        choosepicture: 'Choose your picture',
        choosevideo: 'Choose your Thumbnail',
        story_edited: 'Story was successfully edited',
        story_created:
          'Story was successfully created and added to the campaign',
        post_edited: 'Post was successfully edited',
        post_created: 'Post was successfully created and added to the campaign',
        publicationType: 'Publication Type',
        selectPublicationType: 'Select publication type',
        postContent: 'Post content',
        postReactions: 'Post reactions',
        required_fields_must_be_filled: 'All required fields must be filled',
        operation_successful: 'Operation finished with success',
        operation_failure: 'Operation could not be completed',
        campaignSkorrUserAnalytic: ' - Campaign Skorr Influencer Analytic',
        campaignSkorrUsersAnalytics: ' - Campaign Skorr Influencers Analytics',
        manageCustomKpis: 'Manage your custom KPIs',
        postCreationDate: 'Post creation date',
        savePublicInfluencerList: 'Save in a public influencer list',
        savePublicInfluencerListLegend:
          'You can add public influencers to an existing list or create a new one',
        selectSaveListType: 'Select where to add the selected influencers',
        saveToExistingList: 'Add to existing influencer list',
        saveToExistingPublicList: 'Add to existing public influencer list',
        listCreated: 'Your list has been successfully created!',
        listEdited: 'The list has been successfully edited!',
        checkYourLists: 'Check on your lists',
        listNotCreated:
          'It was not possible to create the list, try again. Please contact our',
        listNotEdited:
          'It was not possible to update the list, try again. Please contact our',
        supportTeam: 'support team',
        difficultiesPersist: ' if your difficulties persist',
        campaignRate: 'Campaign Rate',
        inviteRejectedByUser: 'Invitation Rejected By Influencer',
        inviteWaitingByUser: 'Invitation Waiting By Influencer',
        userRejectedByAgent: 'Influencer Rejected By Agent',
        editNameImageCampaign: 'Edit Name or Image for the Campaign',
        shareCampaignLinkLegend:
          'Here you can copy the campaign summary link so you can share it with other people you want to have access to this page',
        createAgent: 'Create Agent',
        manageYourCustom: 'Here you are able to manage your custom ',
        selectCampaignFromList: 'Select Campaign from List',
        selectCampaignFromListLegend: 'Here you are able to add a campaign.',
        selectFromExistingCampaigns: 'Select from existing Campaigns',
        myCampaigns: 'My Campaigns',
        loadCampaigns: 'Load Campaigns',
        editCampaignSummaryHeader: 'Edit Campaign Summary Header',
        editCampaignSummaryHeaderLegend:
          'Here you can edit the Campaign Summary Header section fields',
        campaignImage: 'Campaign Image',
        startDate: 'Start Date',
        endDate: 'End Date',
        editCampaignSummaryPerformance: 'Edit Campaign Summary Performance',
        editCampaignSummaryPerformanceLegend:
          'Here you can edit the Campaign Summary Performance section fields',
        addInfluencer: 'Add Influencer',
        noInfluencersCouldBeFound: 'No influencers could be found',
        editInfluencerPerformance: 'Edit Influencer Performance',
        addInfluencerPerformance: 'Add Influencer Performance',
        editInfluencerPerformanceLegend:
          'Here you can edit influencer performance metric values',
        addInfluencerPerformanceLegend:
          'Here you can add an influencer performance data',
        publicationDate: 'Publication Date',
        publicationImage: 'Publication Image',
        inviteAgreeConfig: 'Invitation/Agreement Configuration',
        desktopBrowserTemplate: 'Desktop Browser Template',
        'createNewTemplate?': 'Create a new Template?',
        mobileInviteImage: 'Mobile Invitation Image',
        mobileNotificationTitle: 'Mobile Notification Title',
        mobileNotificationDescription: 'Mobile Notification Description',
        mobileInviteTitle: 'Mobile Invitation Title',
        mobileInviteDescription: 'Mobile Invitation Description',
        inviteTitle: 'Invite Title',
        inviteDescription: 'Invite Description',
        mandatoryFieldsMobileInvite: 'Fields used for Mobile Invitation',
        desktopTemplatePreview: 'Desktop Template Preview',
        preview: 'Preview',
        defineCostUser: 'Define a cost to pay to the influencer in campaigns',
        manualPost: {
          storyTapsForward:
            "Total number of taps to see this story's next photo or video",
          storyTapsBackward:
            "Total number of taps to see this story's previous photo or video",
          storyExits: 'Number of times someone exited the story',
          storyReplies: 'Total number of replies to the story',
          postEngagement:
            'Total number of likes and comments on the media object',
          postSaved:
            'Total number of unique accounts that have saved the media object',
          impressions:
            'Total number of times this profile has been seen within the specified period',
          reach:
            'Total number of unique accounts that have seen this profile within the specified period.',
          postVideoViews:
            "The number of times your videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video. This includes live views.",
          postVideoViewsPaid:
            "The number of times your promoted videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
          postVideoViewsOrganic:
            "The number of times your videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
          postVideoViewsUnique:
            "The number of people who viewed your videos for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
          postVideoViewsTime:
            'The total time, in milliseconds, your videos played, including videos played for less than 3 seconds and replays. Returns 0 for reshared videos.',
          postImpressions:
            "The number of times your Page's post entered a person's screen. Posts include statuses, photos, links, videos and more.",
          postImpressionsUnique:
            "The number of people who had your Page's post enter their screen. Posts include statuses, photos, links, videos and more.",
          postImpressionsPaid:
            "The number of times your Page's post entered a person's screen through paid distribution such as an ad.",
          postImpressionsPaidUnique:
            "The number of people who had your Page's post enter their screen through paid distribution such as an ad.",
          postImpressionsOrganic:
            "The number of times your Page's posts entered a person's screen through unpaid distribution.",
          postImpressionsOrganicUnique:
            "The number of people who had your Page's post enter their screen through unpaid distribution.",
          postImpressionsViral:
            "The number of times your Page's post entered a person's screen with social information attached. Social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
          postImpressionsViralUnique:
            "The number of people who had your Page's post enter their screen with social information attached. As a form of organic distribution, social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
          postClicks:
            'The number of times people clicked on anywhere in your posts without generating a story.',
          postClicksUnique:
            'The number of people who clicked anywhere in your post without generating a story.',
          postEngagedFan:
            'People who have liked your Page and engaged with your post.',
          postEngagedUsers:
            'The number of people who clicked anywhere in your posts.',
          postNegativeFeedback:
            'The number of times people took a negative action in your post (e.g. hid it).',
          postNegativeFeedbackUnique:
            'The number of people who took a negative action in your post (e.g., hid it).',
          postImpressionsFan:
            'The number of impressions for your Page post by people who have liked your Page.',
          postImpressionsFanUnique:
            'The number of people who have liked your Page who saw your Page post.',
          postImpressionsFanPaid:
            'The number of impressions for your Page post by people who like your Page in an Ad or Sponsored Story.',
          postImpressionsFanPaidUnique:
            'The number of people who have liked your Page and saw your Page post in an Ad or Sponsored Story.',
          postImpressionsNonviral:
            "The number of times your Page's post entered a person's screen. This does not include content created about your Page with social information attached. Social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
          postImpressionsNonviralUnique:
            "The number of people who had your Page's post enter their screen. This does not include content created about your Page with social information attached. As a form of organic distribution, social information displays when a person's friend interacted with you Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
        },
        share: {
          bodyTitle:
            'You can share this link with other agents controlled by their roles, such as:',
          key1: 'Campaign Viewer:',
          value1: 'Can view list of existing campaigns and their summaries',
          key2: 'Agent Viewer:',
          value2:
            "Can view campaigns and their summaries as well as the cockpit and other agencies' influencers",
          key3: 'Agent Operator:',
          value3:
            'Can view campaigns and influencers as well as create campaigns and agents',
          key4: 'Agent Admin:',
          value4:
            'Has the same permissions as agent operator but can also approve campaigns',
          key5: 'Partner Admin:',
          value5:
            'Has the same permissions as agent admin but can also purchase agents and execute payment operations',
        },
        empty: {
          smartlink: {
            title: 'SmartLinks',
            legend: 'Not enough data to show',
          },
          conversions: {
            title: 'Conversions',
            legend: 'Not enough data to show',
          },
          invited: {
            title: 'Users Invited',
            legend: 'Not enough data to show',
          },
          accepted: {
            title: 'Users Accepted',
            legend: 'Not enough data to show',
          },
          executed: {
            title: 'Users Executed',
            legend: 'Not enough data to show',
          },
          campaignperformance: {
            title: 'Kpi',
            legend: 'Not enough data to show',
          },
        },
        briefing: {
          campaignObjective: 'The objective of this campaign is to publish ',
          'posts&': ' posts and ',
          'post&': ' post and ',
          campaignBegin: 'Campaign Beginning',
          campaignEnd: 'Campaign Ending',
          description: 'Description',
          userApproval: 'User Approval',
          userContentApproval: 'User Content Approval',
          allowMessages: 'Allow Messages',
          objective: 'Objective',
          contentReq: 'Content Requirements',
          visualRefPhotos: 'Visual Reference Photos',
          publicationsGuidelines:
            'The publications also have to be made in accordance with the following guidelines:',
          optional: '(optional)',
          influencerTextImprovise:
            "PS: The influencer can improvise, which means the text present in the publications doesn't have to exactly match the text presented above.",
        },
        alert: {
          informationToShare: 'Please select at least one Information To Share',
          smartLinks: 'Please add at least one Smart Link',
          hashTags: 'Please add at least one Hash Tag',
          keywords: 'Please add at least one Keyword',
          texts: 'Please add at least one Text',
          images: 'Please select a Image Share',
          posts: 'Please specify the number of Posts',
          stories: 'Please specify the number of Stories',
          postsAndStories: 'Please specify the number of Posts and/or Stories',
          missingTargetQualified:
            'No Qualified Users. Please select at least one Qualified User',
          selectPaymentOption: 'Please Select at leat one Payment Term',
        },
        tooltips: {
          STUDY: {
            create_campaign: 'Open Panel for a New Study',
            content: 'Study Questions',
            campaignName: 'Market Study Name',
            description: 'Market Study Description',
            timeline: 'Market Study Timeline',
          },
          INFLUENCER: {
            create_campaign: 'Open Panel for a New Campaign',
            content:
              'Define campaign content, including mandatory, optional or inspirational elements',
            internalName:
              'Campaign Name that will be visible in Social Hub but will not be displayed in the influencer invitation',
            campaignName:
              'Campaign designation. Will appear in the invitation Influencer will receive',
            description:
              'Campaign description. Will appear in the invitation Influencer will receive',
            timeline:
              'Defines period during which invitation must be accepted and content executed',
          },
          AMBASSADOR: {
            content: 'Content',
            campaignName:
              'Invitation designation. Will appear in the invitation Influencers will receive',
            description:
              'Invitation description. Will appear in the invitation Influencers will receive',
            timeline:
              'Defines period during which invitation must be accepted and content executed',
          },
          DISCOVER: {
            campaignName:
              'Invitation designation. Will appear in the invitation Influencers will receive',
            description:
              'Invitation description. Will appear in the invitation Influencers will receive',
            timeline:
              'Defines period during which invitation must be accepted and content executed',
          },
          usersTable: {
            user: 'SKORR Influencer Identification',
            skorr: 'Application SKORR value',
            audience: 'Number of connections in talent network',
            nposts: 'Count of Posts',
            impressions_post:
              "How many times on average a post in influencer Pages was shown in users' feeds",
            reach_post:
              'Total number of people that saw posts in influencer Pages',
            eng_post: 'Total of interactions identified in the post',
            engRate:
              'Level of interaction with followers that is generated from content created by a user',
            nstories: 'Number of Stories',
            impressions_stories:
              "How many times on average a story in influencer Pages was shown in users' feeds",
            reach_stories:
              'Total number of people that saw stories in influencer Pages',
            eng_stories: 'Total of interactions identified in the story',
            nvideos: 'Count of Videos',
            impressions_videos:
              "How many times on average a video in influencer Pages was shown in users' feeds",
            reach_videos:
              'Total number of people that saw videos in influencer Pages',
            eng_videos: 'Total of interactions identified in the publication',
            cost: 'Campaign User Cost',
            location: 'User geographic location',
            age: 'User age',
            nhashTags: 'Number of HashTags',
            engagement: 'User network response to social media activity',
            socialNetworks: 'Connected Social Media Networks',
          },
          details: 'First step of campaign set-up',
          detailsInvitation: 'First step of invitation set-up',
          target: 'Target Audience Definitions',
          budgetPayment: 'Finanancial Terms Definitions',
          network:
            'Only one by campaign. If you want to run same campaign in different social networks, use duplicate feature in the campaigns list',
          finalUserApproval:
            'Final approval option after invited influencers accept invitation',
          userContentApproval:
            'Content published by the influencers will be subject to agent review',
          chatUser: 'Activate chat feature with selected influencers',
          survey:
            'Here you can place the link to a survey to be answered by the influencers',
          required: 'At least one information to share',
          improvise: 'User is Allowed to Improvise',
          restrictions:
            'The information can only be shared in these Social Networks',
          hashtags:
            'Define mandatory and optional hashtags to be used by influencer. At least one is mandatory to identify the campaign content',
          smartlinks: 'SmartLink to be shared in Social Networks',
          keywords:
            'Define mandatory and optional keywords to be used by influencer. At least one is mandatory to identify the campaign content',
          texts:
            'Define mandatory and optional text to be used by influencer. At least one is mandatory to identify the campaign content',
          totalUsers: 'Number of Skorr(Public) influencers in the list',
          totalAudience:
            'Total number of connections from all Skorr(Public) influencers networks (agreggated data)',
          marketplacePayrate: 'Pay what each influencer asked in Skorr app',
          skorrPayrate:
            'Pay what optimized value proposed by Skorr to each influencer',
          fixedPayrate: 'Pay same value to all influencers',
          allocatedBudget: 'Define a value for the campaign budget',
          maxBudget: 'Define a maximum value per influencer for the budget',
          minBudget: 'Define a minimum value per influencer for the budget',
          userNeutralInvitation:
            "Influencer didn't accept nor decline the invitation",
          userAcceptedInvitation: 'Influencer accepted the invitation',
          userRejectedInvitation: 'Influencer rejected the invitation',
          agentNeutralInvitation:
            'Agent did not review the influencer acceptance to the invitation',
          agentAcceptedInvitation:
            'Agent accepted the influencer acceptance to the invitation',
          agentRejectedInvitation:
            'Agent rejected the influencer acceptance to the invitation',
          reach:
            'Number of Reach of influencers that executed in campaign versus potential reach of all invited influencers',
          influencers:
            'Number of influencers that executed in campaign versus the invited influencers',
          audience:
            'Number of audience of influencers that executed in campaign versus potential audience of all invited influencers',
          performance: 'Overhaul Campaign Performance on a given timeline',
          funnel: 'General Metric for the Users on the Campaign',
          belongs_to_other_agency:
            'You can not add/edit posts manually because the influencer belongs to another agency',
          add_edit_post_manually: 'Add or edit post to campaign manually',
          image_video_toggle_button:
            'Allows the agent to choose between selecting an image or a video for the post being created. Note: selecting video will result in the display of additional video specific kpi input fields.',
          saveListType: 'Save to a New List or Append to an existing List',
          selectExistingList: 'Select an existing List to Append Users',
          writeNewListName: 'Please write the name of your new list',
          images:
            'Define mandatory, optional and inspirational images to be used by influencer.',
          selectExistingCampaign: 'Select an existing Campaign',
        },
        status: {
          COMPLETED: 'Completed',
          CANCELED: 'Canceled',
          TO_APPROVE: 'To Approve',
          DRAFT: 'Draft',
          APPROVED: 'Approved',
          LIVE: 'Live',
          SUSPEND: 'Suspended',
          REPLACED: 'Replaced',
          REJECTED: 'Rejected',
          CHECKOUT: 'Checkout',
          READY: 'Ready',
        },
        status_flow: {
          CANCELED: 'Cancel',
          TO_APPROVE: 'To Approve',
          DRAFT: 'Draft',
          APPROVED: 'Approve',
          LIVE: 'Live',
          SUSPEND: 'Suspend',
          REPLACED: 'Replace',
          REJECTED: 'Reject',
          CHECKOUT: 'Checkout',
          READY: 'Ready',
          COMPLETED: 'Completed',
        },
        status_live: {
          title: 'Launch To Live',
          legend: 'You are about to change to Live',
          descriptionItem: 'Live:',
          description: 'Please review all forms before launching the campaign',
          item1: 'Is visible, to selected Targets',
          item2: 'If is already Live, it will be replaced',
        },
        status_to_approve: {
          title: 'Start the Approval Process',
          legend: 'You are about to submit to start the Approval Process',
          description: 'The Approver will review the process.',
          placeholder: 'Message to the Approval',
        },
        status_reject: {
          title: 'Reject the Process',
          legend: 'You are about to Reject the Process',
          description:
            'The current process will be rejected, and return to the Owner',
          placeholder: 'The reason of your rejection',
        },
        status_completed: {
          title: 'Complete the Campaign',
          legend: 'You are about to Close the Campaign',
          description:
            'The current process will be completed, and all the budget allocated will be refund back to the Owner',
        },
        status_canceled: {
          title: 'Cancel the Campaign',
          legend: 'You are about to Cancel the Campaign',
          description:
            'The current process will be canceled, and all the budget allocated will be refund back to the Owner',
          placeholder: 'The reason of your canceling the campaign',
        },
      },
      toolTips: {
        keywordsHelper1:
          'To find all stores containing "java" or "shop" but not "coffee":',
        subKeywordsHelper1: 'java shop -coffee',
        keywordsHelper2:
          'To find all stores containing "java" or "shop" but must contains "coffee":',
        subKeywordsHelper2: 'java shop +coffee',
        keywordsHelper3: 'To find all documents containing "coffee shop":',
        subKeywordsHelper3: '"coffee shop"',
        keywordsHelper4:
          'To find all stores containing any terms from the list "coffee", "shop", and "java"',
        subKeywordsHelper4: 'java coffee shop',
        benchmark: {
          audience: 'Number of connections in entity network',
          audienceGrowth: 'Audience Growth for a specific period of time',
          avgGrowth: 'Total of new Followers divided by total days',
          likes: 'Total entity Likes in the last 30 days',
          comments: 'Total entity Comments in the last 30 days',
          engRate:
            'Average number of engagements / average number of people that saw entity social activity in the last 30 days',
          posts: 'Total entity Posts in the last 30 days',
        },
        NPS_analytics: 'How the social media followers feel about the brand',
        alertName:
          'The alert name is used for organization to identify it in the future',
        alertTarget:
          'If the target is Social Media or Public Entities from Social Networkds',
        selectedFilters:
          'List of filters already added to this alert. Remove a filter by clicking the X on the right side of the filter to be removed',
        saveAlert:
          'Defines the state of the alert during its creation. You can change it later',
      },
      socialNetworks: {
        FACEBOOK: 'Facebook',
        FACEBOOKPAGE: 'Facebook Page',
        INSTAGRAM: 'Instagram',
        FACEBOOKPAGEINSTAGRAM: 'Instagram Business',
        TWITTER: 'Twitter',
        YOUTUBE: 'Youtube',
        TIKTOK: 'TikTok',
        allNetworks: 'All Networks',
        networkselect: 'Select social network',
      },
      signupTypes: {
        title: 'Welcome to Skorr',
        subtitle: 'Select your account type',
        influencer: {
          title: 'Influencer',
          subtitle:
            'You are a celebrity or a newcomer in the influence world. It’s free.',
          descriptionParagraph1:
            'You sell products, services or your image. You are independent or an agency talent.',
          descriptionParagraph2:
            'Join Skorr platform and have full analytics of your social presence. Match your personality with anyone, including brands.',
          descriptionParagraph3:
            'Use same login in the Skorr App and be invited to campaigns.',
        },
        agency: {
          title: 'Agency',
          subtitle: 'You are an agency running influence campaigns.',
          descriptionParagraph1:
            'You develop social listening or consumer intelligence for your clients.',
          descriptionParagraph2:
            'Join Skorr platform and get real-time analytics in a GDPR compliant way. Onboard your talents and manage multi-influencer Campaigns with easy invitations, smart contracts, live chat, in-APP briefings and payments.',
        },
        brand: {
          title: 'Brand',
          subtitle: 'You are a multinational or a small business.',
          descriptionParagraph1:
            'You want to know more about your brand social presence and of your competitors. You want to run yourself influence campaigns or to get real-time reports of your agency.',
          descriptionParagraph2:
            'Get full analytics in a GDPR compliant way. Match your brand personality with the influencers.',
          descriptionParagraph3: 'From always free to customized plans.',
        },
        generic: {
          startNow: 'Start Now',
          requestDemo: 'Request a Demo',
        },
      },
      signupBrandDetails: {
        title1: 'About your Company',
        title2: "Skorr's Plans",
        title3: 'Phone Validation',
        title4: 'Ready to Start!',
        title5: 'All configurations are set and ready',
        sendSMS: 'Send SMS',
        validate: 'Validate',
        skorrPlan: {
          title: {
            '1st': 'Free Trial of',
            '2nd': 'Pro Plan',
            '3rd': 'during',
            '4th': '15 days',
            '5th': '| No credit card needed*',
          },
          details: {
            title: 'Pro',
            '1st': 'Users',
            '2nd': 'Monthly Influencers Discovery',
            '3rd': 'Monthly Profiles Analysis',
            '4th': 'Lists',
            '5th': 'Everything in Basic plus...',
            '6th': 'Ambassador Program',
            '7th': 'Market Research*',
            '8th': 'Export Reports to PDF*',
            '9th': 'Team Approval & Collaboration',
            '10th': 'Support by Whatsapp',
            footer: '* Feature to be launched soon',
          },
          footer:
            '* After 15 Days, you will be automatically downgraded to ESSENTIAL plan, which has less features but is always free. You can subscribe any paid plan anytime',
        },
      },
      signupInfluencerDetails: {
        title1: 'Your Profile',
        title2: 'Select your Agency',
        title3: 'Congratulations',
        'title4.1': 'You are set!',
        'title4.2':
          'You can use same log in the Skorr App to get campaign invitations.',
        alertTitle:
          'In case you work with an agency, all inquires about you, negotiations and payments will be directly handled by your agency.',
        signinFacebook: 'Sign in Facebook and Instagram Business',
        signinTwitter: 'Sign in with Twitter',
        activeNetworks: 'Active Social Networks Accounts',
        connectNetwork: 'Connect a Social Network',
        profilesFound: ' Profile(s) Found',
        searchAccount: 'Search Account...',
        accountInUse: 'This account is already associated with another user',
        noAgency: "I'm independent",
        workWithAgency: "I'm looking forward to work with an agency",
        agencyTalent: "I'm an agency talent",
        appStores: {
          title: 'Install App',
          apple: 'Available on the',
          google: 'Get it on',
        },
      },
      signin: {
        titlePart1: 'Hello!',
        titlePart2: 'Welcome to Skorr.',
        forgotPassword: 'Forgot password?',
        generic: {
          signin: 'Sign in',
          dontHaveAnAccount: "Don't have an account? ",
          alreadyHaveAnAccount: 'Already have an account? ',
          signup: 'Sign up',
          termsAndConditions: 'Terms and Conditions',
          cannotSignin: 'Cannot signin!',
          send: 'Send',
          continueWithFacebook: 'Continue with Facebook',
          keepMeSignedIn: 'Keep me signed in',
          signup: ' Sign up',
          about: 'About',
          agencies: 'Agencies',
          blog: 'Blog',
          terms: 'Terms',
          help: 'Help',
          copyright: '© 2022 Skorr, Inc.',
          havingProblemsWithYourEmail: 'Having problems with your email? ',
          contactUs: 'Contact us',
          typeVerificationCode: 'Type verification code',
          recoverPassword: 'Recover Password',
          captcha: 'Captcha',
          validate: 'Validate',
          invalidCaptcha: 'Invalid Captcha',
          continue: 'Continue',
          didntReceiveAnEmail: "Didn't receive an email? ",
          resendEmail: 'Resend',
          resendIn: 'Resend in',
          sendEmail: 'Send email',
          verificationCodeCouldNotBeSentToTheProvidedEmail:
            'Verification code could not be sent to the provided email.',
          passwordTooltip:
            "Password can't be shorter than 8 characters and must have at least one capital and lower case letters, one number and one special character.",
          verificationCodeDoesNotMatchVerificationCodeSentToEmail:
            'Either the verification code you provided does not match our records or the time limit to submit this code has been exceeded.',
          providedCodeDoesNotMatchOurRecords:
            'The provided code does not match our records.',
          providedCodeHasExpired: 'The provided code has expired.',
          couldNotVerifyForgotPasswordCode:
            'The provided code could not be verified.',
          couldNotUpdatePasswordSuccessfully:
            'It was not possible to update your password succesfully.',
          couldNotSignIn: 'Could not sign in.',
          verificationCodeDoesNotMatch:
            'The provided verification code does not match our records.',
          verificationCodeWasSentToEmail:
            'A verification code was sent to your email. Please insert it in the input under 3 hours.',
          enterNewPassword: 'Enter new password',
          sendingVerificationCodeToEmail:
            'Sending verification code to email...',
          verificationCodeWasSentToEmail:
            'Verification code will be sent to your email',
          validatingVerificationCode: 'Validating verification code...',
          updatingPassword: 'Updating password...',
          passwordUpdatedSuccessfully:
            'Your password has been updated successfully.',
          signingIn: 'Signing in...',
          insertVerificationCode:
            'Type the verification code we sent to your email',
          accountActivationCodeTitle: 'Verify email',
          invalidVerificationCodeFormat:
            'The verification code you entered is invalid',
          incorrectVerificationCode:
            'The verification code you entered appears to be invalid. Please try entering it again or request a new verification code using the "Resend" button below',
          sendingAccountActivationCodeToEmail:
            'Sending account activation code to email...',
          accountActivationCodeWasSentToEmail:
            'Account activation code sent to your email.',
          accountActivationCodeCouldNotBeSent:
            'Account activation code could not be sent.',
          couldNotActivateAccount: 'Could not activate account',
          activatingYourAccount: 'Activating your account',
          accountActivatedSuccessfully:
            'Your account has been activated successfully',
          couldNotGetEssentialData: 'Could not get essential data',
          incorrectEmailPasswordCombination:
            'Incorret email/password combination',
          agentDeactivatedError: 'Your account has been deactivated.',
          couldNotCertifyActivationCodeEmailSent:
            'Could not certify that the email with the account activation code has been sent.',
          accountActivationCodeEmailTimeoutError:
            'Please wait 30 seconds before requesting to send another email with the account activation code.',
          accountActivationCodeEmailCapError:
            "The maximum number of emails with the account activation code has been reached. Please contact our platform's administration for assistance with this issue.",
          verificationCodeEmailTimeoutError:
            'Please wait 30 seconds before requesting another email with the password recovery code.',
          verificationCodeEmailCapError:
            "The maximum number of emails with the password's recovery code has been reached. Please contact our platform's administration for assistance with this issue.",
          userDoesNotExistError: 'User with the provided email does not exist',
          sendingForgotPasswordCodeToEmail:
            "Sending the password's recovery code to email...",
          forgotPasswordCodeCouldNotBeSentToTheProvidedEmail:
            "Password's recovery code could not be sent to the provided email.",
          deleteAccount: 'Delete account',
          googleRecaptchaTokenValid: 'Google ReCaptcha token valid',
          googleRecaptchaTokenInvalid: 'Google ReCaptcha token invalid',
          websiteIsRequired: 'Website is required',
          websiteFormatIsNotValid: 'Website format is not valid',
        },
        recaptchaFooter: {
          part1: 'This site is protected by reCAPTCHA and the Google ',
          part2: 'Privacy Policy ',
          part3: 'and ',
          part4: 'Terms of Service ',
          part5: 'apply.',
        },
      },
      accountDeletionAuthenticationless: {
        title: 'Account deletion request',
        description:
          'By submitting an account email, you will receive a deletion confirmation link via email, open the link in it to complete the account deletion.',
        sendConfirmationEmail: 'Send confirmation email',
        sendingAccountDeletionCodeEmail: 'Sending account deletion code email',
        emailSentSuccessfullyHeaderMessage: 'Email was successfully sent',
        emailSentSuccessfullyDescriptionMessage:
          'Check your inbox to complete account deletion',
      },
      brandSelectionAfterLogin: {
        title: 'Choose a Brand to work with',
        topHelperMessageParagraph1:
          'On this page you can select the brand profile you will be working on as well as create a new one.',
        topHelperMessageParagraph2:
          'You will only be allowed to continue is a brand profile is selected.',
        addBrandProfileCardText: 'Add a Brand',
      },
      signup: {
        alreadyHaveAnAccount: 'Already have an account? ',
        signin: 'Sign in',
        createAnAccount: 'Create an account',
        signupWithEmail: 'Sign up with email',
        businessAccount: 'Business account',
        managerAccount: 'Manager account',
        couldNotCreateAccount: 'Could not create account.',
        signingUp: 'Signing up...',
        letsStart: 'Lets Start',
        managerAccountTooltip: {
          brand: {
            title: "Turn on if you're a Brand",
            rest: " and want to know more about your brand and competitor's social presence.",
          },
          influencer: {
            title: "Turn off if you're an influencer",
            rest: ' and you will be able to use this account on Skorr mobile app too.',
          },
          agency: {
            title: "If you're an Agency",
            rest: ' and want to try out a full features account made for agencies.',
          },
        },
        downloadSkorrApp: 'Download Skorr App',
        congratulations: 'Congratulations!',
        yourAccountWasCreated: 'Your account was created',
        businessAccountFinalMessage1: 'You are set!',
        businessAccountFinalMessage2: 'You can now manage your campaigns.',
        influencerFinalMessage1: 'You are set!',
        influencerFinalMessage2:
          'You can use the same login in the Skorr mobile app to receive campaign invitations.',
        signin: 'Sign in',
        signinFromWeb: 'Sign in from Web',
        activationCodeEmailSent:
          'An email with the account activation code was sent.',
        continue: 'Continue',
        continueFromWeb: 'Continue from Web',
        passwordLetterCaseWarning:
          'Password must contain uppercase and lowercase letters',
        passwordNumbersWarning: 'Password must contain numbers',
        passwordSpecialCharacterWarning:
          'Password must contain at least one special character',
        passwordLengthWarning: 'Password must contain at least 8 characters',
        confirmPassword: 'Confirm password',
        passwordsNeedToMatch: 'Passwords need to match',
        confirmPasswordIsRequired: 'Confirm password is required',
        termsAndConditionsAgreementPart1: 'By registering,',
        termsAndConditionsAgreementPart2: "you're",
        termsAndConditionsAgreementPart3: 'agreeing',
        termsAndConditionsAgreementPart4: 'to',
        termsAndConditionsAgreementPart5: 'the',
        termsAndConditionsAgreementPart6: 'Terms',
        termsAndConditionsAgreementPart7: '&',
        termsAndConditionsAgreementPart8: 'Conditions',
      },
      deleteAccount: {
        deleteAccount: 'Delete account',
        couldNotSendAccountDeletionEmail:
          'Could not send account deletion email',
        sendingAccountDeletionEmail: 'Sending account deletion email',
        accountDeletionEmailSent: 'Account deletion email sent',
      },
      forgotPassword: {
        title: 'Forgot Password',
      },
      invalidPassword: {
        title: 'Password must contain the following:',
        a: 'A',
        letter: 'letter',
        lowercase: 'lowercase',
        uppercase: 'capital (uppercase)',
        number: 'number',
        specialChr: 'special character',
        minimum: 'Minimum',
        minChr: '8 characters',
      },
      accountSelectionAfterLogin: {
        title: 'Choose an account to associate with',
        topHelperMessageParagraph1:
          'On this page you can select an account to associate with as well as create a new one.',
        topHelperMessageParagraph2:
          'After selecting an account, whose type is not individual, you will need to select a business.',
        noAccountsFound:
          'There are no accounts associated with the logged in user',
        addAccountCardText: 'Add an Account',
      },
      businessSelectionAfterLogin: {
        title: 'Choose a business',
        topHelperMessageParagraph1:
          'On this page you can select a business to define the context of the data to which you will have access.',
        topHelperMessageParagraph2:
          'You can also click the button presented below to create a new business.',
        noBusinessesFound:
          'There are no businesses associated with the selected account',
        addBusinessCardText: 'Add a Business',
      },
      alerts: {
        myAlerts: 'My Alerts',
        addAlert: 'Add Alert',
        searchAlert: 'Seach an Alert...',
        alertName: 'Alert Name',
        target: 'Target',
        alertStatus: 'Alert Status',
        eventsTrigger: 'Events Triggered',
        actions: 'Actions',
        welcomeAlerts: 'Welcome to alerts!',
        alertDescribe1:
          "You don't have alerts yet, try creating a new one by pressing the ",
        alertDescribe2: ' button at the top of the page',
        createAlert: 'Create Alert',
        enterAlertName: 'Enter a alert name...',
        selectTargetToStart: 'Select a target to start',
        selectField: 'Select a field',
        selectOperation: 'Select an operation',
        selectValue: 'Select a value',
        selectedFilters: 'Selected filters',
        saveAlert: 'Save Alert',
        errorAlertContent: 'Mentions must have more than 3 characters',
        errorAlertEmpty: 'Value cannot be empty',
        errorAlertCountry: 'You must select at least one country',
        errorAlertHandler: 'Choose at least one handler',
        searchHandler: 'Search By Handler',
        welcomeAlertPreview: 'Welcome to the alert preview!',
        seeAlertDetails:
          'To see the details of an alert, you need to first create it on the alert',
        creationPage: ' creation page ',
        itWillAppear: 'and it will appear in the',
        list: ' list ',
        afterThat: 'after that.',
        noEntriesAlert:
          'There are no entries for this alert yet, try to come back later.',
      },
      mediaKit: {
        socialMediaKpis: 'Social Media KPIs',
        hashtagsMoreEngagement: 'Hashtags with more engagement',
        topicsMoreEngagement: 'Topics with more engagement',
        typeWriting: 'Type writing',
        languageDetection: 'Language Detection',
        bestTimePostReactions: 'Best Time to Post - Reactions',
        bestTimePostVelocity: 'Best Time to Post - Velocity',
        bestTimePostReactionsDescription:
          'Analysis of number of reactions by day of the week and time of the day',
        userTypeWrittingandEmotion: '/Audience type writing',
        userTones: 'Brand Tones',
        analytical: 'Analytical',
        analytical_description:
          'A reasoning and analytical attitude about things',
        confident: 'Confident',
        confident_description: 'A degree of certainty',
        tentative: 'Tentative',
        tentative_description: 'A degree of inhibition',
        offensiveLanguage: 'Language Detection',
        offensiveLanguage_legend:
          'The uses of some offensive language in social media',
        socialPropensities: 'Social Propensities',
        opennessExperience: 'Openness To Experience',
        opennessExperienceLegend:
          'Describes a dimension of cognitive style that distinguishes imaginative, creative people from down-to-earth, conventional people',
        conscientiousness: 'Conscientiousness',
        conscientiousnessLegend:
          'Concerns the way in which we control, regulate, and direct our impulses',
        extraversion: 'Extraversion',
        extraversionLegend:
          'Is marked by pronounced engagement with the external world',
        agreeableness: 'Agreeableness',
        agreeablenessLegend:
          'Refers to a behavioral tendency to be kind, sympathetic, cooperative, warm, and considerate',
        neuroticism: 'Neuroticism',
        neuroticismLegend:
          'Refers to the tendency to experience negative feelings',
        needsAndValues: 'Emotional Intelligence - Needs and Values',
        needsAndValuesLegend:
          'ML and AI powered linguistic analytics to infer brand, audience and individual personality characteristics from digital communications in the social media. Table with 13 needs and values whereas data is more common to obtain.',
        socialPropensities: 'Social Propensities',
        opennessExperience: 'Openness To Experience',
        opennessExperienceLegend:
          'Describes a dimension of cognitive style that distinguishes imaginative, creative people from down-to-earth, conventional people',
        conscientiousness: 'Conscientiousness',
        conscientiousnessLegend:
          'Concerns the way in which we control, regulate, and direct our impulses',
        extraversion: 'Extraversion',
        extraversionLegend:
          'Is marked by pronounced engagement with the external world',
        agreeableness: 'Agreeableness',
        agreeablenessLegend:
          'Refers to a behavioral tendency to be kind, sympathetic, cooperative, warm, and considerate',
        neuroticism: 'Neuroticism',
        neuroticismLegend:
          'Refers to the tendency to experience negative feelings',
        needsAndValues: 'Emotional Intelligence - Needs and Values',
        needsAndValuesLegend:
          'ML and AI powered linguistic analytics to infer brand, audience and individual personality characteristics from digital communications in the social media. Table with 13 needs and values whereas data is more common to obtain.',
        hashtagsMoreEngTooltip:
          "HashTags present in brand's social media content with more engagement",
        membersWoutKpis: "Members without KPI's",
        membersWoutKpisLegend: "No KPI's found in the selected timeframe",
        noUsersFound: 'No Users Found',
        noUsersFoundLegend: 'No users found in the selected timeframe',
        userHasNoPosts: 'This user has no posts',
        userHasNoPostsLegend: 'No user posts found in the selected timeframe',
        profilesAnalytics: 'Profiles Analytics',
        talentsFromOthersAgencies1: 'The List has Talents from other Agencies',
        talentsFromOthersAgencies2:
          ", for that reason some KPI's will not be displayed",
        user_profanity_description:
          "Refers to the percentage of the user's posts containing offensive language",
        user_cleanlanguage_description:
          "Refers to the percentage of the user's posts free of offensive language",
        audience_empty_profanity_title: 'Audience Offensive Language',
        audience_empty_profanity_legend:
          "Unfortunately the brand's audience does not produce enough data to calculate their offensive language level on social media",
        audience_profanity_description:
          "Refers to the percentage of the brand's audience's posts containing offensive language",
        audience_cleanlanguage_description:
          "Refers to the percentage of the brand's audience's posts free of offensive language",
        user_empty_tones_self_title: 'User Tones',
        user_empty_tones_self_legend:
          'Unfortunately the user does produce enough data to calculate the sentence tone',
        user_empty_tones_audience_title: 'Audience Tones',
        user_empty_tones_audience_legend:
          'Unfortunately the audience does not produce enough data to calculate the sentence tone',
        user_analytical: 'Analytical',
        user_analytical_description:
          'A reasoning and analytical attitude about things',
        user_confident: 'Confident',
        user_confident_description: 'A degree of certainty',
        user_tentative: 'Tentative',
        user_tentative_description: 'A degree of inhibition',
        emotionsTitle: 'Emotions',
        audienceTitle: 'Audience',
        user_empty_emotions_self_title: 'User Emotions',
        user_empty_emotions_self_legend:
          'Unfortunately the user does not enough data to calculate the sentence emotions',
        user_empty_emotions_audience_title: 'Audience Emotions',
        user_empty_emotions_audience_legend:
          'Unfortunately the audience does not produce enough data to calculate the sentence emotions',
        user_anxiety: 'Anxiety',
        user_anxiety_description:
          'Brand high in anxiety often feels like something dangerous is about to happen. Brand low in Anxiety is generally calm and fearless',
        user_joy: 'Joy',
        user_joy_description:
          'Brand is often optimistic, and determined to find the fun in every situation',
        user_sadness: 'Sadness',
        user_sadness_description:
          'Brand often has the idea that actions made are bad and may be harmful',
        user_fear: 'Fear',
        user_fear_description:
          'Emotion triggered by a perceived threat. With higher values brand can experience negative impacts in all areas of activity',
        user_anger: 'Anger',
        user_anger_description:
          'Brand that scores high feels enraged when things do not go its way.',
        user_audienceTypeWritingAndEmotion_title: '/Audience emotions',
        user_audience_tones: 'Tones',
        topics_with_more_engagement_tooltip:
          "Hashtags present in brand's social media content with more engagement",
        user_audience_emotions_title: 'Emotions',
        loading_media_kit_data: 'Loading media kit data',
        userPresentationalCard: {
          noBiography: 'This influencer does not have biography',
          totalPosts: 'Total Posts',
          totalVideos: 'Total Videos',
          avgEngRate: 'Avg Eng. Rate',
          avgLikes: 'Avg Likes',
          avgComments: 'Avg Comments',
          avgViews: 'Avg Views',
          day: 'day',
          days: 'days',
          customized: 'Customized',
        },
        evolutionPerformance: {
          engagementRate: {
            title: 'Eng. Rate(%)',
          },
          label: {
            avg: 'AVG',
            total: 'Total',
            evolutionInThisPeriod: 'Evolution in this Period',
            vsPrevPeriod: 'VS prev. Period',
          },
          tooltip: {
            avgAudience: 'Last Followers value within selected timeframe',
            evolutionInThisPeriodAudience:
              'Evolution within selected timeframe',
            avgEngagementRate: 'Avg. Engagement Rate per Post',
            'youtube.avgEngagementRate': 'Avg. Engagement Rate per Video',
            vsKpi: 'Percentual points difference within selected timeframe',
            avgLikes: 'Avg. Likes per Post',
            'youtube.avgLikes': 'Avg. Likes per Video',
            avgEngRate: 'Avg. Eng.Rate per Post',
            totalLikes: 'Sum of Likes within selected timeframe',
            avgComments: 'Avg. Comments per Post',
            'youtube.avgComments': 'Avg. Comments per Video',
            totalComments: 'Sum of Comments within selected timeframe',
            avgEngagement: 'Avg. Engagement per Post',
            'youtube.avgEngagement': 'Avg. Engagement per Video',
            totalEngagement: 'Sum of Engagement within selected timeframe',
            avgPostImpressions: 'Avg. Impressions per Post',
            'youtube.avgPostImpressions': 'Avg. Impressions per Video',
            totalPostImpressions: 'Sum of Impressions per Post',
            'youtube.totalPostImpressions': 'Sum of Impressions per Video',
            avgPostReach: 'Avg. Reach per Post',
            'youtube.avgPostReach': 'Avg. Reach per Video',
            totalPostReach: 'Sum of Reach per Post',
            'youtube.totalPostReach': 'Sum of Reach per Video',
            avgPostEngagementRate: 'Avg. Post Engagement Rate per Post',
            avgPostCnt: 'Avg. Post Count per Day',
            'youtube.avgPostCnt': 'Avg. Video Count per Day',
            totalPostCnt: 'Sum of Post Count within selected timeframe',
            'youtube.totalPostCnt':
              'Sum of Video Count within selected timeframe',
            avgStoryImpressions: 'Avg. Story Impressions per Post',
            totalStoryImpressions: 'Sum of Story Impressions per Post',
            avgStoryReach: 'Avg. Story Reach per Post',
            totalStoryReach: 'Sum of Story Reach per Post',
            avgStoryCnt: 'Avg. Story Count per Post',
            totalStoryCnt: 'Sum of Story Count per Post',
            avgShares: 'Avg. Shares per Post',
            totalShares: 'Sum of Shares per Post',
            totalPEngagement: 'Sum of Post Engagement per Post',
          },
        },
        audienceDemographic: {
          'gender.male': 'Male',
          'gender.female': 'Female',
          'gender.other': 'Other',
          title: 'Followers Demographic',
          titleSmall: '( Last 30 days )',
          'country.title': 'Country',
          'city.title': 'City',
          'gender.title': 'Gender',
          'age.title': 'Age',
          'type.title': 'Type',
          'ethnicity.title': 'Ethnicity',
          'reachability.title': 'Reachability',
          'type.real': 'Real',
          'type.massFollowers': 'Mass followers',
          'type.suspicious': 'Suspicious',
          'type.influencers': 'Influencers',
          'ethnicity.white': 'White / Caucasian',
          'ethnicity.hispanic': 'Hispanic',
          'ethnicity.africanAmerican': 'African American',
          'ethnicity.asian': 'Asian',
          'reachability.-500': 'Below 500',
          'reachability.500-1000': 'From 500 to 1000',
          'reachability.1000-1500': 'From 1000 to 1500',
          'reachability.1500-': 'Above 1500',
        },
        'brandAffinity.title': 'Brand Affinity',
        'topicsOfInterest.title': 'Topics of Interest',
        'noData.noDataFound': 'No data found',
        'noData.noInfoToRender': 'No information to be rendered',
        'posts.title': 'Posts',
        'stories.title': 'Stories',
        'post.description.viewPost': 'View Post',
        'post.description.copiedToClipboard': 'Copied',
        'post.description.copyText': 'Copy Text',
        'post.reactions.views': 'Views',
        'post.reactions.likes': 'Likes',
        'post.reactions.comments': 'Comments',
        'post.reactions.shares': 'Shares',
        'post.reactions.impressions': 'Impressions',
        'post.reactions.interactions': 'Interactions',
        'post.reactions.reach': 'Reach',
        'post.reactions.engagement': 'Engagement',
        'post.reactions.saved': 'Saved',
        'post.reactions.tapsForward': 'Taps Forward',
        'post.reactions.tapsBack': 'Taps Backward',
        'post.reactions.exits': 'Exits',
        'error.error-getting-data': "Couldn't get media kit data",
        'powered-by-label': ' powered by ',
      },
      benchmark: {
        createNewButton: '+\xa0\xa0Create New Benchmark',
        choose5Users: 'Choose up to 5 competitors',
        createButton: 'Create benchmark',
        updateButton: 'Update benchmark',
        empty: {
          title: 'No benchmarks found yet',
          description:
            'Create your first benchmark and start comparing\nentities performance',
          title2: 'No benchmark found with searched name',
          description2: 'Refine your searched name',
        },
        list: {
          title: 'Benchmarks',
          inputPlaceholder: 'Search benchmark...',
          createdAtLabel: 'Created',
          dropdownMenu: {
            settings: 'Change settings',
            delete: 'Delete benchmark',
          },
        },
        form: {
          namePlaceholder: 'Type a name for this benchmark...',
          handlerPlaceholder: 'Search by @handler...',
          listPlaceholder: 'Select list',
          profiles: ' profiles ',
          selected: 'selected',
          show: 'Show ',
          hide: 'Hide ',
          competitors: ' competitors',
          remove: 'Remove',
          loadingUsers: 'Loading users...',
          searchResults: 'Search results',
          noResults: 'No results found',
          addToBenchmark: 'Add to benchmark',
          autoUpdate: 'Auto-update',
        },
        analytics: {
          settings: 'Change settings',
          delete: 'Delete',
          followers: 'Followers',
          addMore: 'Add more',
          days: ' days',
          followersGrowth: 'Followers growth',
          engRate: 'Engagement rate',
          posts: 'Posts',
          postsFeed: 'Posts feed',
        },
        modal: {
          delete: {
            title: 'Confirm delete benchmark',
            body: 'Are you sure you want to delete this benchmark?',
            primaryButton: 'Delete',
          },
        },
        toast: {
          create: 'Benchmark created successfully',
          update: 'Benchmark updated successfully',
          delete: 'Benchmark deleted successfully',
        },
      },
      inputTitle: {
        yourEmail: 'Your Email',
        yourPassword: 'Your Password',
        yourName: 'Your Name',
        captcha: 'Captcha',
        age: 'Age',
        gender: 'Gender',
        city: 'City',
        country: 'Country',
        topicsInterest: 'Topics of Interest ',
        yourBrandName: 'Your Brand Name',
        yourBrandWebsite: 'Your Brand Website',
        yourBrandEmail: 'General Contact Email',
        selectAgency: 'Select your Agency',
        agencyName: 'Agency Name',
        phoneNumber: 'Phone Number',
        validationCode: 'Validation Code',
      },
      inputControl: {
        mandatoryField: 'Mandatory Field',
        optionalField: 'Optional Field',
        pleaseEnterEmail: 'Please enter your email',
        pleaseEnterName: 'Please enter your name',
        pleaseEnterPassword: 'Please enter your password',
        pleaseEnterAge: 'Please enter your age',
        pleaseEnterCaptcha: 'Please enter captcha',
        pleaseEnterCompanyName: 'Please enter your company name',
        pleaseEnterWebsite: 'Please enter your website',
        pleaseEnterAgencyName: 'Please enter agency name',
        pleaseEnterPhone: 'Please enter your phone number',
        pleaseEnterCode: 'Please enter validation code',
        checkingEmailAvailability: 'Checking for email availability',
      },
      comboBox: {
        noOptionsAvailable: 'No options available',
        selectOption: 'Select an option',
        selectOneorMoreOpts: 'Select one or more options',
        typeInOption: 'Type in an option',
        typeInOneorMoreOpts: 'Type in one or more options',
        search: 'Search',
        type: 'Type',
      },
      filePicker: {
        nothingToUpload: 'Nothing to Upload',
        maxImgSize: 'Maximum Image Size is',
        mb: 'MB',
        clickToUpload: 'Click to Upload one or more files',
      },
      errorBoundary: {
        title: 'Something went wrong',
        button: 'Try again',
        errorFound: 'Oops an error was found!',
        oups: 'Oups!',
      },
      'menu-navbar': {
        cockpit: 'Dashboard',
        socialNetworks: 'Social Networks',
        myAnalytic: 'My Analytics',
        workspace: 'Workspace',
        subWorkspace: {
          myTalents: 'My Talents',
          myTalentsInvite: 'My Talents Invitations',
          agencyTalents: 'Agencies Talents',
          skorrInfluencers: 'Skorr Content Creators',
          lists: 'Lists',
          invitations: 'Invitations',
        },
        discover: 'Discover',
        benchmark: 'Benchmark',
        listening: 'Listening',
        campaigns: 'Campaigns',
        mentions: 'Social Listening',
        alerts: 'Alerts',
        help: 'Help',
        subHelp: {
          knowledgeBase: 'Knowledge Base',
          contact: 'Contact',
        },
        socialAccountsSelectionTitle: 'Select media kit',
        noSocialAccountsPrimaryMessage:
          'No social accounts are associated with your account.',
        noSocialAccountsSecondaryMessagePart1: 'Connect your social accounts',
        noSocialAccountsSecondaryMessagePart2: 'to view',
        noSocialAccountsSecondaryMessagePart3: 'content',
        noSocialAccountsSecondaryMessagePart4: 'on',
        noSocialAccountsSecondaryMessagePart5: 'this',
        noSocialAccountsSecondaryMessagePart6: 'page.',
        'media-kits-entry': 'Media Kits',
        'media-kit-configurations-entry': 'Media Kit Configurations',
        myAnalyticTooltip: 'My Analytic',
        socialNetworksTooltip: 'My Social Networks',
        cockpitTooltip:
          'Key social media, Skorr, Brand, Influencer and Campaign analytics',
        workspaceTooltip:
          'Work with agency or independent influencers already in Skorr database',
        discoverTooltip:
          'Discover and invite influencers from a public database',
        benchmarkTooltip: 'Manage Benchmark List',
        alertsTooltip: 'Manage Alerts List',
        campaignsTooltip: 'Create or manage campaigns',
        mentionsTooltip:
          'Public - social media and articles - or private listening of what is being talked about',
        businessPlan: 'Business Plan',
        openCart: 'Open Cart',
        optinMessages: 'Opt-in Messages',
        notifications: 'Notifications',
        seeAllNotifications: 'See All Notifications',
        notificationEmptyState: {
          title: 'Your notifications will show up here',
          body: 'XXX',
        },
        messages: 'Messages',
        businessOptions: 'Business and Account management',
        userProfile: 'User Profile',
        balance: 'Balance',
        pts: ' pts',
        balanceToolTip: 'Balance is ',
        legalInformation: 'Legal Information',
        privacyPolicy: 'Privacy Policy',
        'help&support': 'Help and Support',
        myAnalytics: 'My Analytics',
        subBusinessProfileOptions: {
          businessOptions: 'Business Options',
          viewAnalytics: 'View Analytics',
          viewAnalyticsTooltip: 'Open Analytics Dashboard',
          exitProfile: 'Exit Profile',
          exitProfileTooltip: 'Close Brand Profile',
          partner: 'Partner',
          influencer: 'Influencer',
          partnerProfile: 'Partner Profile',
          partnerProfileTooltip: 'Edit Partner Corporate and Billing Details',
          brandProfiles: 'Brand Profiles',
          myBrandProfiles: 'My Brand Profiles',
          userOptions: 'User Options',
          brandProfilesTooltip: 'Open Social Media Connectivity Details',
          orders: 'Orders',
          ordersTooltip: 'Explore Purchase Order Details',
          applications: 'Applications',
          applicationsTooltip:
            'Create and manage keys that allow you to use our public API',
          integrations: 'Integrations',
          integrationsTooltip: 'Manage External Analytics Integrations',
          transactions: 'Transactions',
          transactionsTooltip: 'View Financial Transaction History',
          agents: 'Agents',
          agentsTooltip: 'Manage Social Hub Portal Users',
          templates: 'Templates',
          templatesTooltip: 'Setup Communication Metadata Templates',
          skorrTracker: 'Skorr Tracker',
          tracker: 'Tracker',
          settings: 'Settings',
          skorrTrackerTooltip: 'Setup social media keyword tracking',
          aiModels: 'AI Models',
          discoverSupervision: 'Discover Supervision',
          socialNetworksTooltip: 'Open Social Media Connectivity Details',
          editProfile: 'Edit Profile',
          editCompany: 'Edit Company',
          changeSocialAccounts: 'Change Social Accounts',
          businessProfiles: 'Business Profiles',
          agents: 'Agents',
          managerDataAccess: 'Manager data access',
        },
        subPartnerOptions: {
          myProfile: 'My Profile',
          logout: 'Logout',
          partners: 'Partners',
          roles: 'Roles',
          dataAccess: 'Data Access',
          monitor: 'Monitor',
          skorrAdmin: 'Skorr Admin',
          myAnalytics: 'My Analytics',
        },
      },
      accountCreation: {
        accountCreation: 'Account Creation',
        accountName: 'Account Name',
        accountWebsite: 'Account Website',
        updateButton: 'Update Button',
      },
      mentions: {
        welcome: 'Welcome to the Mentions Searcher!',
        insert:
          'Do your search inserting the mention(s) word(s), country and date at the section above to start receiving your results.',
        tPositive: 'Total Positive',
        avgPositive: 'Positive',
        tNeutral: 'Total Neutral',
        avgNeutral: 'Neutral',
        tNegative: 'Total Negative',
        avgNegative: 'Negative',
        tClean: 'Total Clean',
        avgClean: 'Clean',
        tProfanity: 'Total Offensive Language',
        avgProfanity: 'Offensive Language',
        total: 'Total',
      },
      errors: {
        emailInUseRequestError: 'Could not check email availability.',
        emailInUse: 'This email is already registered.',
        invalidField: 'Invalid field.',
      },
      additionalDataStep: {
        influencerTitle: 'Tell us about you',
        businessAccountTitle: 'Before you continue...',
        subtitle:
          'This information will enhance your in-app experience, and your data will always be protected',
        companyDetails: 'Company details',
        createBusinessProfileTitle: 'Create a Business Profile',
        createBusinessProfileSubtitle:
          'Create a business profile and optionally add its respective social media accounts',
        selectPicture: 'Select a picture',
        socialNetworkAccountButtonsOr: 'OR',
        couldNotUpdatePartner: 'Could not update partner',
        updatingPartner: 'Updating partner',
        selectFiveOrMoreOptions: 'Select five or more options',
        connectYourSocialNetworkProfile: 'Connect your social network profile',
        socialNetworkAccountComponentSubtitle:
          'Connect your social network accounts to start getting insights with Skorr that helps your social media performance.',
      },
      businessProfile: {
        brandProfile: 'Profile',
        legalDetails: 'Legal Details',
        businessDescription: 'Brand Description',
        insertBusinessDescription: 'Insert Brand Description',
        insertBusinessProfileName: 'Insert Brand Name',
        chooseBrandProfilePicture: 'Choose Brand Picture',
        brandProfileDetails: 'Profile Details',
        creatingBrandProfile: 'Creating Profile',
        savingChanges: 'Saving Changes',
        deletingBrandProfile: 'Deleting Profile',
        activatingBrandProfile: 'Activating Profile',
        brandProfileCreated: 'Profile Created',
        brandProfileUpdated: 'Profile Updated',
        brandProfileDeleted: 'Profile Deleted',
        brandProfileActivated: 'Profile Activated',
        couldNotCreateBrandProfile: 'Could not create Profile',
        couldNotUpdateBrandProfile: 'Could not update Profile',
        couldNotDeleteBrandProfile: 'Could not delete Profile',
        couldNotActivateBrandProfile: 'Could not activate Profile',
        facebookExpiredTokens:
          'There are facebook/facebookPage/facebookPageInstagram accounts whose token is expired',
        twitterExpiredTokens:
          'There are twitter accounts whose token is expired',
        'save-button-disabled-tooltip-influencer':
          'Name, description and country fields must be filled and a minimum number of 5 topics of interest must be selected',
        'save-button-disabled-tooltip-not-influencer':
          'Name and Description fields must be filled',
        'loading-business-profile-data': 'Loading Profile data',
        'error-get-business-profile-data': 'Could not load Profile data',
        filterEmptyResult: {
          title:
            'No social network accounts matching the provided filter could be found',
          subtitle:
            'Below are listed the possible reasons why no matches were found:',
          'reason-1':
            'The account profile is not enabled in your Facebook Business Settings(in case of a facebook account)',
        },
        activeAccountSameNetworkExists:
          'An account of this social network is already active',
        socialNetworkAccountAlreadyExists:
          'This account is associated with another user',
        editBrandProfile: 'Edit Business Profile',
        createBrandProfile: 'Create Business Profile',
        businessProfileNameIsRequired: 'Business profile name is required',
      },
      profileEdit: {
        'error-get-user-data': 'Could not load Profile data',
        'loading-influencer-profile-data': 'Loading Profile data',
        editProfile: 'Edit Profile',
        createProfile: 'Create Profile',
        profileDetails: 'Profile Details',
        insertName: 'Insert Name',
        insertAge: 'Insert Age',
        insertYearPlaceholder: 'YYYY',
        insertMonthPlaceholder: 'MM',
        insertDayPlaceholder: 'DD',
        couldNotUpdateProfile: 'Could not update profile',
        couldNotCreateProfile: 'Could not create profile',
        couldNotDeleteAccount: 'Could not delete account',
        savingChanges: 'Saving Changes',
        creatingProfile: 'Creating Profile',
        deletingAccount: 'Deleting Account',
        profileUpdated: 'Profile Updated',
        profileCreated: 'Profile Created',
        accountDeleted: 'Account Deleted',
        'save-button-disabled-tooltip-influencer': 'Name field must be filled',
        'loading-locations-data': 'Loading locations data',
        selectNoMoreThanFiveTopics: 'Select a maximum of five topics',
        newPassword: 'New password',
        newPasswordIsRequired: 'New Password is required',
        confirmNewPassword: 'Confirm new password',
        confirmNewPasswordIsRequired: 'Confirm New Password is required',
      },
      companyEdit: {
        editCompany: 'Edit Company',
        companyDetails: 'Company Details',
        contactDetails: 'Contact Details',
        billingDetails: 'Billing Details',
        couldNotLoadCompanyData: 'Could not load company data',
        couldNotUpdateCompany: 'Could not update company',
        couldNotDeleteAccount: 'Could not delete account',
        loadingCompanyData: 'Loading company data',
        updatingCompany: 'Updating company',
        deletingAccount: 'Deleting Account',
        companyUpdated: 'Company Updated',
        accountDeleted: 'Account Deleted',
        vatId: 'Vat Id',
        insertVatId: 'Insert Vat Id',
        address: 'Address',
        insertAddress: 'Insert Address',
        postalCode: 'Postal Code',
        insertPostalCode: 'Insert Postal Code',
        website: 'Website',
        insertWebsite: 'Insert Website',
        vatExemption: 'Vat Exemption',
        needsDpoReview: 'Needs DPO review',
        billingToContactAddressToggleLabel:
          'Use My Contact Address for Billing',
        deleteAccount: 'Delete Account',
        deleteAccountButtonLabel: 'Delete this account and all saved data',
      },
      businessPlan: {
        skorrPlans: 'SKORR Plans',
        selectSubscriptionPlan: 'Select Your Subscription Plan',
        nowSkorrPlan: 'At moment your SKORR Plan is ',
        seeMySubsEvents: 'See my subscriptions events',
        mostPopular: 'Most Popular',
        myPlan: 'My Plan',
        dueOn: 'Due on',
        essentials: 'Essentials',
        socialDigitalMediaQueries: 'Social and Digital Media Listening queries',
        campaignsPaidSkorr: 'Campaigns paid via Skorr with Skorr pre-approval',
        smartContracts: 'Smart Contracts',
        addNewSocialByHandler: 'Add new social media sources by handler',
        subscribe: 'Subscribe',
        excludeVAT: 'Exc. VAT',
        everythingEssentialsPlus: 'Everything in Essentials plus...',
        everythingProPlus: 'Everything in PRO plus...',
        alert: 'Alert',
        clientSocialFollowerInvite: 'Clients / Social followers invitations',
        influencerDirectReward: 'Influencer direct rewarding',
        exportReportListPDF: 'Export Reports and Lists to PDF',
        addNewSocialByCSV: 'Add new social and digital media sources by CSV',
        unlimited: 'Unlimited',
        addNewEntityByTopics:
          'Add new entities of FB / Twitter by specific topics',
        teamApprovalCollab: 'Team approval and collaboration',
        openAPI: 'Open API',
        coreFeatures: 'Core Features',
        corporate: 'Corporate',
        cockpitWithBrandCampaign:
          'Cockpit with Brand & Campaign Analytics and Listings',
        workspaceWithInfluencersLists:
          'Workspace with influencers and list management',
        discoverMonthlyResults: 'Discovery - Monthly Results',
        influencerCampaigns: 'Influencer Campaigns',
        withSkorrAuth: 'With Skorr authorization',
        smlMonthlySearches:
          'Social Media Listening - IG, FB, Twitter - Monthly searches',
        smlMonthlyResults:
          'Social Media Listening - IG, FB, Twitter - Monthly Results',
        smlAddNewSourceByHandler:
          'Social Media Listening - Add new sources by handler',
        smlAddNewSourceByCSV: 'Social Media Listening - Add new sources by CSV',
        smlAddNewEntitiesByTopics:
          'Social Media Listening - Add new entities by specific topics on FB, Twitter',
        dmlMonthlySearches: 'Digital Media Listening - Monthly Searches',
        dmlMonthlyResults: 'Digital Media Listening - Monthly Results',
        dmlAddNewSourceByCSV:
          'Digital Media Listening - Add new sources by CSV',
        support: 'Support',
        emailSupport: 'Email Support',
        whatsappSupport: 'WhatsApp Support',
        phoneSupport: 'Phone Support',
        dedicatedSatisfactionManager: 'Dedicated Satisfaction Manager',
        maxInfluencersList: 'Max Influencers per List',
        reportsExportPDF: 'Reports export to PDF',
        reportingAnalysis: 'Reporting & Analysis',
        realTimeCampaignReports: 'Real Time Campaign Reports',
        customizable: 'Customizable',
        realTimeUsersReports: 'Real Time Users Reports',
        campaignsManagement: 'Campaigns Management',
        userCampaignFeeImplementedByAgency:
          'Influencer Campaigns Fee - Implemented by Agency',
        free: 'Free',
        executingSubscription: 'Executing Subscription...',
        accessCampaignWorflowByAgency:
          'Access to campaign workflow executed by agency',
        influencerCampaignFeeRewardByBrand:
          'Influencer Campaigns Fee - Rewarded by Brand',
        influencerCampaignFeeRewardBySkorr:
          'Influencer Campaigns Fee - Rewarded via Skorr',
        manageInfluencersSkorr:
          'Management of new influencers onboarding for campaigns in Skorr',
        smartContracts: '"Smart" Contracts',
        contentRevisionApproval: 'Content Revision and Approval',
        talkToUs: 'Talk to Us',
        proprietaryFakeDetection: 'Proprietary Fake Detection',
        onDemand: 'On Demand',
        reportsExportCSV: 'Reports export to CSV',
        customSmartContracts: 'Customizable "Smart" Contracts',
        influencersPerksManage: 'Influencers Perks Management',
        partnerSubsEvents: 'Partner Subscriptions Events',
        showMore: 'Show more',
        showLess: 'Show less',
        rateLimitsByMonth: 'Rate limits By Month',
        problemCreditCard:
          'Problem with your Credit Card. Your Subscription is ',
        payWithCreditCard: 'Pay with Credit Card',
        skorrPlanLimit: 'Skorr Plan Limit',
        upgradeNow: 'Upgrade Now',
        campaign: {
          CAMPAIGN_REPORT_VIEW: 'Campaign Report View',
          CAMPAIGN_CREATE: 'Campaign Create',
          CAMPAIGN_CREATE_VIEW: 'Campaign Create View',
        },
        alert: {
          ALERT_CREATE: 'Alert(s)',
          ALERT_UPDATE: 'Alert Update',
          ALERT_REPORT_VIEW: 'Alert Report View',
        },
        benchmark: {
          BENCHMARK_SEARCH: 'Benchmark',
          BENCHMARK_SEARCH_NUMBER: 'Benchmark Search Number',
          BENCHMARK_VIEW: 'Benchmark View',
          BENCHMARK_CREATE: 'Benchmark Create',
        },
        analytics: {
          LIST_PUBLIC_ANALYTICS: 'Public List Analytics',
          LIST_SKORR_ANALYTICS: 'Skorr List Analytics',
        },
        listening: {
          SOCIAL_MEDIA_LISTENING: 'Social and Digital Media Listening queries',
        },
        export: {
          EXPORT_PDF: 'Export to PDF',
        },
        common: {
          label: 'Common',
          COMMON_SUPPORT_EMAIL: 'Support by Email',
          COMMON_SUPPORT_PHONE: 'Support by Phone',
          COMMON_SUPPORT_WHATSAPP: 'Support by WhatsApp',
          COMMON_SUPPORT_CONCIERGE: 'Support Concierge',
          COMMON_AGENTS: 'Users',
        },
        search: {
          label: 'Search',
          SEARCH_QUERIES: '# Monthly Queries',
          SEARCH_INFLUENCERDISCOVERY: 'Influencer Discovery',
        },
        cockpit: {
          label: 'Cockpit',
          COCKPIT_BASICS: 'Basics',
          COCKPIT_LISTS: 'Lists',
          COCKPIT_CAMPAIGNS: 'Campaigns',
        },
        lists: {
          label: 'Lists',
          LIST_LISTS: '# Lists',
          LIST_MAX_USERS: '# Max Influencers per List',
          LIST_MANAGEMENT: 'List Management',
        },
        users: {
          label: 'Users',
          USER_MAX_USERS: '# Max Qualified Users',
          USER_USERS_PROFILES: 'Users Profiles',
        },
        'usage-limits': {
          ALERT_CREATE: '# Alerts',
          PUBLIC_MEDIA_KIT: '# Public Media Kit',
          SKORR_MEDIA_KIT: '# Skorr Media Kit',
          PUBLIC_POST_DETAIL_VIEW: '# Public Post Detail View',
          LIST_PUBLIC_ANALYTICS_NUMBER: '# List Public Analytic',
          LIST_SKORR_ANALYTICS_NUMBER: '# List Skorr Analytic',
          ALERT_UPDATE: '# Alert Update',
          ALERT_REPORT_VIEW: '# Alert Report View',
          COCKPIT_VIEW: '# Cockpit View',
          MENTIONS_SEARCH_PROFILES: '# Mentions Search Profiles',
          MENTIONS_SEARCH_ARTICLES: '# Mentions Search Articles',
          MENTIONS_SEARCH_POSTS: '# Mentions Search Posts',
          DISCOVER_NEW_PROFILE: '# Discover New Profile',
          BENCHMARK_CREATE: '# Benchmark Create',
          BENCHMARK_VIEW: '# Benchmark View',
          BENCHMARK_SEARCH_NUMBER: '# Benchmark Search',
          CAMPAIGN_CREATE_VIEW: '# Campaign Create View',
          CAMPAIGN_CREATE: '# Campaign Create',
          CAMPAIGN_REPORT_VIEW: '# Campaign Report View',
          EXPORT_PDF: 'Export to PDF',
          SOCIAL_MEDIA_LISTENING: 'Social Listening Monthly Searchs',
          LIST_SKORR_ANALYTICS: 'List Analytics',
          LIST_PUBLIC_ANALYTICS: 'List Analytics',
          DISCOVER_SEARCH: 'Monthly Discovery Results',
          BENCHMARK_SEARCH: 'Benchmark',
          discountpayper: 'pay per',
          SEARCH_QUERIES: 'Search',
          LIST_LISTS: '# Lists',
          CAMPAIGN_INFLUENCER: '# Max Campaigns',
          CAMPAIGN_INSTAGRAM: 'Instagram',
          CAMPAIGN_TWITTER: 'Twitter',
          FACEBOOKPAGEINSTAGRAM: 'Instagram Business',
          CAMPAIGN_YOUTUBE: 'Google',
          CAMPAIGN_FACEBOOK: 'Facebook',
          CAMPAIGN_FACEBOOK_PAGE: 'Facebook Page',
          CAMPAIGN_DISCOVER: 'Make Discover Campaigns',
          STUDY_MAX_QUESTIONS: '# Max Questions',
          STUDY_STUDIES: '# Max Studies',
          STUDY_PHOTHOS: 'Photos',
          STUDY_VIDEOS: 'Videos',
          upgrade: 'Upgrade',
          yes: 'Yes',
          no: 'No',
          TIER1: 'Essentials',
          TIER2: 'Basic',
          TIER3: 'Pro',
          TIER4: 'Unlimited',
          TIER6: 'Campaigns',
          TIER101: 'Essentials',
          TIER102: 'Basic',
          TIER103: 'Pro',
          TIER104: 'Unlimited',
          TIER201: 'Essentials',
          TIER202: 'Basic',
          TIER203: 'Pro',
          COMMON_SUPPORT_EMAIL: 'Support by Email',
          COMMON_SUPPORT_PHONE: 'Support by Phone',
          COMMON_SUPPORT_WHATSAPP: 'Support by WhatsApp',
          COMMON_SUPPORT_CONCIERGE: 'Support Concierge',
          SEARCH_QUERIES: '# Monthly Queries',
          SEARCH_INFLUENCERDISCOVERY: 'Influencer Discovery',
          SEARCH_INFLUENCERDISCOVERY: 'Influencer Discovery',
          LIST_LISTS: '# Lists',
          LIST_MAX_USERS: '# Max Influencers per List',
          LIST_MANAGEMENT: 'List Management',
          USER_MAX_USERS: '# Max Qualified Users',
          USER_USERS_PROFILES: 'Users Profiles',
          COMMON_AGENTS: 'Users',
          validated: 'Validated',
          notvalidated: 'Not Validated',
          PARTNER_VALIDATION: 'Account',
          active: 'Active',
          notActive: 'Not Active',
          purchase: 'Purchase',
          skorrBP: 'SKORR Business Plan: ',
          price: 'Price',
          tax: 'Tax',
          total: 'Total',
          month: 'Month',
          year: 'Year',
        },
      },
      messages: {
        optinMessagesInbox: 'Opt-in Messages Inbox',
        broadcast: 'Broadcast',
        broadcasted: 'Broadcasted',
        inboxIsEmpty: 'Your Inbox is empty',
        new: 'New',
        noMessages: 'No messages',
        seenByUser: 'Seen by the user in ',
        state: 'State',
        item: 'Item',
        expiresAt: 'Expires At',
        tooltip: {
          broadcast: 'Select audience list and send message',
        },
      },
      roles: {
        roles: 'Roles',
        superUser: 'Super User',
        partnerAdmin: 'Partner Admin',
        agentAdmin: 'Agent Admin',
        agentOperator: 'Agent Operator',
        agentViewer: 'Agent Viewer',
        campaignViewer: 'Campaign Viewer',
      },
      socialAccountManagement: {
        title: 'Manage your social accounts',
        'loading-social-accounts-data': 'Loading social accounts data',
        'error-loading-social-accounts-data':
          'Error loading social accounts data',
        'error-getting-social-account-data':
          'Error getting social account data',
        'could-not-find-account-information':
          'Could not find account information',
        'could-not-load-synced-in-instagram-social-accounts':
          'Could not load official instagram accounts data',
        'could-not-load-synced-in-tiktok-social-accounts':
          'Could not load official tiktok accounts data',
        'error-updating-user-public-profiles':
          'Error updating user social accounts',
        addedSocialAccounts: {
          title: 'Added social accounts',
          noAddedSocialAccountsMessage:
            'No social accounts have been added yet',
          noAddedSocialAccountsMatchTheSpecifiedFilters:
            'No added social accounts match the specified filters',
        },
        addSocialAccounts: {
          title: 'Add social accounts',
          subtitle:
            'Keeping track of the growth and changes in the number of followers:',
          usernameInputTitle: 'Enter @ account name',
          usernameInputPlaceholder: '@username',
          usernameInputHelperMessageInstagram:
            'You can find the Instagram handler at the top of profile or from profile URL (instagram.com/<strong>username</strong>/).',
          usernameInputHelperMessageYoutube:
            'You can find the Youtube handler at the top of profile or from profile URL (youtube.com/@<strong>username</strong>/).',
          'getting-social-account-data': 'Getting social account data',
          'updating-social-accounts': 'Updating social accounts',
          'social-accounts-updated-successfully':
            'Social accounts updated successfully',
          'connect-instagram-account-button': 'Connect Instagram account',
          'connect-youtube-account-button': 'Connect Youtube account',
          'connect-tiktok-account-button': 'Connect Tiktok account',
        },
        syncWithOfficialData: {
          title: 'Sync with official data',
          subtitlePart1: 'Synchronization with official insights provides:',
          subtitlePart2BulletPoints: {
            first: 'Socials and media kits ownership verification;',
            second: 'Real-time data updates;',
            third: 'Precise metrics instead of estimated;',
            fourth: 'Deeper post insights that require login synchronization;',
          },
          loginWithInstagram: 'Login with Instagram',
          loginWithTiktok: 'Login with TikTok',
        },
        officialSocialAccounts: {
          officialAccountsFoundDescriptionPart1: 'We found ',
          officialAccountsFoundDescriptionPart2: ' that you can add and link:',
          instagramAccount: 'instagram account ',
          instagramAccounts: 'instagram accounts ',
          tiktokAccount: 'tiktok account ',
          tiktokAccounts: 'tiktok accounts ',
          addAccount: 'Add account',
          maximumAccountCountReached: 'Max. 3 reached',
          noInstagramAccountsWereFound:
            'It was not possible to find instagram accounts to add and link',
          noTiktokAccountsWereFound:
            'It was not possible to find tiktok accounts to add and link',
        },
        instagramAccountSyncFailedTitle: 'Instagram account sync failed',
        instagramAccountSyncFailedDescriptionFirstParagraphPart1:
          'Ensure that your Instagram ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart2: 'account ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart3: 'is ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart4: 'set ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart5: 'to ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart6: 'Creator ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart7: 'or ',
        instagramAccountSyncFailedDescriptionFirstParagraphPart8: 'Business',
        instagramAccountSyncFailedDescriptionFirstParagraphPart9: '.',
        instagramAccountSyncFailedDescriptionSecondParagraphPart1:
          'Ensure that you have ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart2:
          'one Facebook Page ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart3:
          'associated ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart4: 'with ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart5: 'your ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart6: 'Instagram ',
        instagramAccountSyncFailedDescriptionSecondParagraphPart7: 'account.',
        tiktokAccountSyncFailedTitle: 'TikTok account sync failed',
        tiktokAccountSyncFailedDescriptionPart1: 'Ensure that ',
        tiktokAccountSyncFailedDescriptionPart2: 'all required permissions ',
        tiktokAccountSyncFailedDescriptionPart3: 'are allowed ',
        tiktokAccountSyncFailedDescriptionPart4: 'when ',
        tiktokAccountSyncFailedDescriptionPart5: 'you ',
        tiktokAccountSyncFailedDescriptionPart6: 'sync ',
        tiktokAccountSyncFailedDescriptionPart7: 'your ',
        tiktokAccountSyncFailedDescriptionPart8: 'TikTok ',
        tiktokAccountSyncFailedDescriptionPart9: 'account.',
      },
      externalMediaKitConfigurationsList: {
        gettingConfigurations: 'Getting configurations',
        couldNotGetConfigurations: 'Could not get configurations',
        configurationNameLabel: 'Name',
        configurationDurationLabel: 'Duration',
        configurationCreatedAtLabel: 'Created at',
        configurationViewsLabel: 'Views',
        configurationOpenMediaKitLabel: 'Open',
        configurationPublicUserImagesLabel: 'Creators',
        configurationActionsLabel: 'Actions',
        mediaKitsTitle: 'Your media kit configurations',
        portfoliosTitle: 'Your portfolio configurations',
        configurationFilterAll: 'All',
        configurationFilterLive: 'Live',
        configurationFilterExpired: 'Expired',
        slidingWindow: 'Sliding Window',
        editConfiguration: 'Edit configuration',
        configurationEditionDisabledMessage:
          'Settings are unavailable because this profile is not connected',
        copyConfigurationUrl: 'Copy url',
        viewConfiguration: 'View configuration',
        createMediaKit: {
          selectType: 'Select media kit type',
          mediaKitTitle: 'Media kit',
          mediaKitSubtitle: 'Single social account',
          mediaKitDescription:
            'Recommended for single account performance report',
          portfolioTitle: 'Portfolio',
          portfolioSubtitle: 'Multiple social accounts',
          portfolioDescription: 'Recommended for multi media kit presentation',
        },
        title: 'My Analytics',
        emptyInfoTitle: 'No configurations available',
        emptyInfoSubtitle: 'There are no configurations to display',
      },
      createMediaKitConfiguration: {
        title: 'Create your Media Kit',
        subtitle: 'Share your performance with friends or brands',
        create: 'Create Media Kit',
        socialAccountFilterTitle: 'Social accounts',
        timeRangeTitle: 'Time',
        expirationDateTitle: 'Expiration date',
        neverExpires: 'Never expires',
        automaticUpdate: 'Automatic update',
        automaticUpdateDescription:
          'Turn to dynamic report to collect new content posted',
        filterContent: 'Filter content',
        filterContentDescription:
          'Select content by #hashtag or any keyword, by specific posts...',
        return: 'Return',
        filterByKeywords: 'Filter by keywords',
        selectPosts: 'Select posts',
        add: 'Add',
        postFilter: {
          all: 'All',
          photos: 'Photos',
          videos: 'Videos',
        },
        saveOptions: 'Save options',
        loadingPosts: 'Loading posts',
        name: 'Name',
        mediaKit: 'MediaKit',
        nameInputPlaceholder: 'Type a name for this report...',
        mediaKitEditionMessage: 'You can edit these settings later',
        creatingMediaKit: 'Creating media kit',
        mediaKitCreated: 'Media kit created',
        postOptionsSaved: 'Post options saved',
        couldNotCreateMediaKit: 'Could not create media kit',
      },
      editMediaKitConfiguration: {
        title: 'Edit your Media Kit',
        updatingMediaKit: 'Updating media kit',
        couldNotUpdateMediaKit: 'Could not update media kit',
        update: 'Update Media Kit',
        mediaKitUpdated: 'Media kit updated',
        selectMediaKits: 'Select media kits',
      },
      createPortfolioConfiguration: {
        titleFirstPart: 'Create your ',
        titleSecondPart: 'Portfolio',
        subtitle: 'Create your theme portfolio with multiple social accounts',
        create: 'Create Portfolio',
        addAMediaKit: 'Add a Media Kit',
        emptyMediaKitsTitle: 'No media kits found yet',
        emptyMediaKitsSubtitle:
          'Create your first media kit, explore themes and show your creator performance',
        customizeView: 'Customize view',
        removeMediaKit: 'Remove media kit',
        customizeViewTitle: 'Customize view',
        customizedNameInputPlaceholder: 'Type a name for this report',
        uploadCustomImage: 'Upload custom image',
        removeCustomImage: 'Remove custom image',
        customizeViewGeneralSettings: 'General settings',
        showPicture: 'Show picture',
        showIcon: 'Show icon',
        showTitle: 'Show title',
        showUsername: 'Show @username',
        customizeButtonTitle: 'Customize',
        customizeButtonLabel:
          'Adjust the different settings and preferences for your portfolio',
        expirationDateTitle: 'Expiration date',
        neverExpires: 'Never expires',
        portfolioNameTitle: 'Name',
        portfolioNameInputPlaceholder: 'Type a name for this report',
        customizePortfolioTitle: 'Customize portfolio',
        return: 'Return',
        saveSettings: 'Save settings',
        uploadImages: 'Upload images',
        layout: 'Layout',
        horizontalLayout: 'Horizontal layout',
        verticalLayout: 'Vertical layout',
        selectLayout: 'Select layout',
        horizontalLayoutTitle: 'Horizontal layout',
        horizontalLayoutDescription: 'Standard layout',
        verticalLayoutTitle: 'Vertical layout',
        verticalLayoutDescription: 'Larger background image',
        themeColor: 'Theme color',
        buttonsUIColor: 'Buttons & UI color',
        uploadImagesModalTitle: 'Upload images',
        uploadCustomProfilePicture: 'Upload custom profile picture',
        uploadBackgroundCoverImage: 'Upload background cover image',
        removeCustomProfilePicture: 'Remove custom profile picture',
        removeBackgroundCoverImage: 'Remove background cover image',
        name: 'Name',
        portfolioAuthorNameInputPlaceholder: 'Type your name...',
        tags: 'Tags',
        selectNoMoreThanFiveTags: 'Select a maximum of five tags',
        country: 'Country',
        countryInputPlaceholder: 'Select a country',
        section: 'Section',
        portfolioSectionTitleInputPlaceholder:
          'Type custom title, leave empty for default',
        portfolioSectionDescriptionInputPlaceholder:
          'Type your presentation text',
        partnersSection: 'Partners section',
        portfolioPartnersSectionTitleInputPlaceholder:
          'Type custom title, leave empty for default',
        addPartners: 'Add partners',
        selectPartners: 'Select partners',
        partnersSearchInputPlaceholder: 'Search partners...',
        partnersSelectedCountPart1: 'Added',
        partnersSelectedCountPart2: 'of 12 partners',
        addCustomPartner: 'Add',
        createPartnerManually: 'Create partner manually',
        uploadBrandImage: 'Upload brand image',
        removeBrandImage: 'Remove brand image',
        customPartnerBrandNameInputPlaceholder: 'Brand name',
        website: 'Website',
        customPartnerBrandWebsiteInputPlaceholder:
          'Website url ex: www.brand.com',
        brandNameAlreadyInUse: 'Brand name is already being used',
        invalidWebsiteUrlFormat: 'Invalid website url format',
        partnerCreatedSuccessfully: 'Partner created sucessfully',
        portfolioSettingsSavedSuccessfully: 'Portfolio settings saved',
        creatingPartner: 'Creating partner',
        couldNotUploadBrandImage: 'Could not upload brand image',
        partnerDeletion: 'Brand deletion',
        partnerDeletionPermissionCheckMessage:
          'We are currently checking if your picture is being used in any other portfolios. Please wait a moment.',
        partnerDeletionConfirmationPrimaryMessage:
          'Are you sure you want to delete your user brand?',
        partnerDeletionConfirmationSecondaryMessage:
          'This brand will only be deleted after portfolio creation.',
        errorGetPortfoliosByBrandPicture:
          'There was an error verifying if the brand picture is being used by other portfolios. Please retry the operation',
        partnerDeletionDeniedMessage:
          'Deletion denied due to currently being used on:',
        portfolioCreatedSuccessfully: 'Portfolio created',
        portfolioUpdatedSuccessfully: 'Portfolio updated',
        creatingPortfolio: 'Creating portfolio',
        updatingPortfolio: 'Updating portfolio',
        couldNotCreatePortfolio: 'Could not create portfolio',
        couldNotUpdatePortfolio: 'Could not update portfolio',
        customizeView: 'Customize View',
        selectedPartners: 'Selected Partners',
      },
      updatePortfolioConfiguration: {
        titleFirstPart: 'Update your ',
        subtitle: 'Update your theme portfolio with multiple social accounts',
        partnerDeletionConfirmationSecondaryMessage:
          'This brand will only be deleted after the portfolio is updated.',
      },
      businessProfiles: {
        title: 'Business Profiles',
        emptyInfoTitle: 'No business profiles available',
        emptyInfoSubtitle: 'There are no business profiles to display',
        activateBusinessProfile: 'Activate',
      },
      lists: {
        deletingList: 'Deleting list',
        viewMembers: 'View members',
        listDeleted: 'List deleted',
        couldNotDeleteList: 'Could not delete list',
        createList: 'Create List',
        editList: 'Edit List',
        listCreated: 'List Created',
        listUpdated: 'List Updated',
        couldNotCreateList: 'Could not create list',
        couldNotUpdateList: 'Could not update list',
        creatingList: 'Creating list',
        savingChanges: 'Saving changes',
        deletingList: 'Deleting list',
        listNameIsRequired: 'List name is required',
        insertListName: 'Insert list name',
        insertListDescription: 'Insert list description',
        memberRemovedFromList: 'Member removed from list',
        couldNotDeleteMemberFromList: 'Could not delete member from list',
        noListsAvailableInfoTitle: 'No lists available',
        noListsAvailableInfoSubtitle: 'There are no lists to display',
        noListMembersAvailableInfoTitle: 'No list members available',
        noListMembersAvailableInfoSubtitle:
          'There are no list members to display',
        listMembers: 'List members',
        listName: 'List name',
        influencerDoesNotHaveBiography:
          'This influencer does not have biography',
        deletingListMember: 'Deleting list member',
        deletingListMembers: 'Deleting list members',
        memberDeletedFromList: 'Member deleted from list',
        membersDeletedFromList: 'Members deleted from list',
      },
      kpis: {
        audience: 'audience',
        engRate: 'eng. Rate',
        likes: 'likes',
        comments: 'comments',
        shares: 'shares',
        posts: 'posts',
      },
      discover: {
        title: 'Discover',
        showing: 'Showing',
        results: ' results',
        entities: ' entities',
        selected: ' selected',
        orderBy: 'Order by ',
        sortByFields: {
          audience: 'Audience',
          likes: 'Likes',
          comments: 'Comments',
          posts: 'Posts',
          engagement_rate: 'Eng. Rate',
          shares: 'Shares',
          reactions: 'Reactions',
        },
        filter: 'Filter',
        keywords: 'Keywords',
        keywordsPlaceholder: 'Search by keywords...',
        handler: 'Handler',
        handlerPlaceholder: 'Search by @handler...',
        timeframe: 'Timeframe',
        open: 'Open',
        close: 'Close',
        calendar: ' calendar',
        country: 'Country',
        countryPlaceholder: 'Select country',
        socialNetwork: 'Social Network',
        socialNetworkPlaceholder: 'Select social network',
        category: 'Category',
        categoryPlaceholder: 'Select category',
        topcisOfInterest: 'Topics of interest',
        topcisOfInterestPlaceholder: 'Select topics of interest',
        gender: 'Gender',
        genderPlaceholder: 'Select gender',
        to: 'TO',
        clear: 'Clear',
        apply: 'Apply',
        noBiography: 'This influencer does not have biography',
        categories: {
          BRAND: 'Brand',
          BLOG: 'Blog',
          INFLUENCER: 'Influencer',
          SOCIALMEDIA: 'Media',
        },
        emptyState: {
          title: 'No results found',
          description: 'Try adjusting your filters or search query',
          or: 'OR',
          button: 'Request social account',
        },
      },
      listening: {
        title: 'What are people talking about?',
        searchPlaceholder: 'Search anything...',
        sortBy: 'Sort by ',
        sortByField: {
          creators: 'Creators',
          posts: 'Posts',
          avgEng: 'Avg. Eng.',
          audience: 'Audience',
          likes: 'Likes',
          comments: 'Comments',
          shares: 'Shares',
          engagement_rate: 'Eng. Rate',
          reactions: 'Reactions',
          date: 'Date',
        },
        filter: 'Filter',
        shortTerm: ' must have at least 3 characters',
        cannotBeEmpty: ' cannot be empty',
        timeframeLimit: 'The timeframe cannot exceed 90 days.',
        previousSearches: 'Your previous searches',
        topic: 'Topic',
        loadMore: 'Load more results',
      },
      listeningTerm: {
        days: ' days',
        creators: 'Creators',
        posts: 'Posts',
        avgAudience: 'Avg. Audience',
        avgEngagements: 'Avg. Engagements',
        engRate: 'Eng. Rate',
        engagements: 'Engagements',
        topCreators: 'Top Creators',
        creator: 'Creator',
        followers: ' followers',
        relatedHashtags: 'Related Hashtags',
        noHashtagsFound: 'No related hashtags found',
        titleEmptyState: 'No results found for search ',
        descriptionEmptyState: 'Try adjusting your filters or search query',
      },
      requestSocialAccounts: {
        title: 'Request social accounts',
        subtitle: 'Choose one network to request a profile',
        button: {
          instagram: 'Request Instagram account',
          youtube: 'Request YouTube account',
          tiktok: 'Request TikTok account',
          request: 'Request',
        },
      },
      agents: {
        title: 'Agents',
        agentDeleted: 'Agent Deleted',
        agentActivated: 'Agent Activated',
        agentDeactivated: 'Agent Deactivated',
        couldNotDeleteAgent: 'Could not delete agent',
        couldNotActivateAgent: 'Could not activate agent',
        couldNotDeactivateAgent: 'Could not deactivate agent',
        deletingAgent: 'Deleting Agent',
        activatingAgent: 'Activating Agent',
        deactivatingAgent: 'Deactivating Agent',
        activateAgent: 'Activate',
        deactivateAgent: 'Deactivate',
        emptyInfoTitle: 'No agents available',
        emptyInfoSubtitle: 'There are no agents to display',
      },
      managerSocialDataAccess: {
        title: 'Data Access',
        accessRevoked: 'Access revoked',
        couldNotRevokeAccess: 'Could not revoke access',
        connected: 'Connected',
        disconnected: 'Disconnected',
        expired: 'Expired',
        disconnect: 'Disconnect',
        copied: 'Copied',
        revokingAccess: 'Revoking access',
        noVerifiedAccountsFoundMessagePart1:
          'Request manager access by sharing the invitation link above.',
        noVerifiedAccountsFoundMessagePart2:
          'Accepted accounts will be displayed below.',
        noVerifiedAccountsFoundMessagePart3:
          'Be able to view official and verified data from others, future features such as chat and campaign invitations.',
        noVerifiedAccountsFoundYet: 'No verified accounts found yet',
        revokeSingleAccessModalTitle:
          'Are you sure you want to disconnect this account from',
        revokeSingleAccessModalDescriptionPart1:
          'Removing this access will require an invite link to rejoin.',
        revokeSingleAccessModalDescriptionPart2: 'By clicking "confirm",',
        revokeSingleAccessModalDescriptionPart3:
          "will no longer have access to this account's official insight updates.",
        revokeFullAccessModalTitle: 'Are you sure you want to disconnect from',
        revokeFullAccessModalDescriptionPart1:
          'Removing this access will require an invite link to rejoin.',
        revokeFullAccessModalDescriptionPart2: 'By clicking "confirm",',
        revokeFullAccessModalDescriptionPart3:
          'will no longer have access to your official insight updates.',
      },
      partnerTypes: {
        brand: 'Brand',
        agency: 'Agency',
        influencer: 'Influencer',
      },
      skorrAdmin: {
        partner: {
          title: 'Partners',
          createPartner: 'Create Partner',
          editPartner: 'Edit Partner',
          updatePartner: 'Update Partner',
          validatePartner: 'Validate Partner',
          createPartnerToast: 'Partner created successfully',
          updatePartnerToast: 'Partner updated successfully',
          agentsList: 'Agents List',
          namePlaceholder: 'Search for name...',
          typePlaceholder: 'Select Partner Type',
          logo: 'Logo',
          name: 'Name',
          type: 'Type',
          accountValidated: 'Account Validated',
          createdAt: 'Created At',
          actions: 'Actions',
          partnerDetails: 'Partner Details',
          commercialName: 'Commercial Name',
          companyEmail: 'Company Email',
          dataAccessLevel: 'Data Access Level',
          choosePicture: 'Choose your picture',
          billingDiff: 'Billing different from Head Office Details?',
          legalDetails: 'Legal Details',
          person1: 'Company Representative Person 1',
          person2: 'Company Representative Person 2',
          businessDescription: 'Company Business Description',
          mainBrand: 'Company Main Brand',
          headOfficeDetails: 'Head Office Details',
          companyName: 'Company Name',
          address1: 'Address1',
          address2: 'Address2',
          postalCode: 'Postal Code',
          country: 'Country',
          selectCountry: 'Select Country',
          city: 'City',
          selectCity: 'Select City',
          vatId: 'Vat-Id',
          webSite: 'Web Site',
          billingDetails: 'Billing Details',
          vatExemption: 'Vat-Exemption',
        },
        agent: {
          title: 'Agents',
          createAgent: 'Create Agent',
          editAgent: 'Edit Agent',
          updateAgent: 'Update Agent',
          validateAgent: 'Validate Agent',
          createAgentToast: 'Agent created successfully',
          updateAgentToast: 'Agent updated successfully',
          logo: 'Logo',
          name: 'Name',
          email: 'Email',
          active: 'Active',
          dpo: 'Dpo',
          createdAt: 'Created At',
          actions: 'Actions',
          password: 'Password',
          newPassword: 'New Password',
          choosePicture: 'Choose your picture',
          topicsOfInterest: 'Topics of Interest',
          selectTopcisOfInterest: 'Select Topics of Interest',
          country: 'Country',
          selectCountry: 'Select Country',
          gender: 'Gender',
          selectGender: 'Select Gender',
          dateOfBirth: 'Date of birth',
          roles: 'Roles',
          selectRole: 'Select Role',
        },
      },
      accountActivation: {
        couldNotActivateAccount: 'Could not activate account',
        providedCodeDoesNotMatchOurRecords:
          'The provided code does not match our records.',
        providedCodeHasExpired: 'The provided code has expired.',
      },
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        shortened: {
          january: 'Jan',
          february: 'Feb',
          march: 'Mar',
          april: 'Apr',
          may: 'May',
          june: 'Jun',
          july: 'Jul',
          august: 'Aug',
          september: 'Sep',
          october: 'Oct',
          november: 'Nov',
          december: 'Dec',
        },
        shortenedLowerCased: {
          january: 'jan',
          february: 'feb',
          march: 'mar',
          april: 'apr',
          may: 'may',
          june: 'jun',
          july: 'jul',
          august: 'aug',
          september: 'sep',
          october: 'oct',
          november: 'nov',
          december: 'dec',
        },
      },
      topicsOfInterest: {
        key_11000000: 'Sports & Fitness',
        key_17000000: 'Tech & Gadgets',
        key_03000000: 'Travel',
        key_01000000: 'Gaming',
        key_10000000: 'Health & Wellness',
        key_08000000: 'Nutri & Cooking',
        key_16000000: 'Lifestyle',
        key_02000000: 'Beauty & Cosmetics',
        key_14000000: 'Fashion',
        key_04000000: 'Communication & Social Networks',
        key_06000000: 'Eco & Sustainability',
        key_18000000: 'Maternity',
        key_07000000: 'Animals & Pets',
        key_05000000: 'Entrepreneurship & Business',
        key_09000000: 'Art & Culture',
        key_12000000: 'TV & Movies',
        key_13000000: 'Music - Singer',
        key_13000001: 'Music - DJ',
        key_15000000: 'Society',
        key_19000000: 'Humor',
        key_20000000: 'Dance',
        key_21000000: 'Decor & Design',
        key_22000000: 'Paternity',
        key_23000000: 'Family',
        key_24000000: 'Blockchain / Metaverse',
        key_13000002: 'Crypto / NFT',
        key_13000003: 'Graphic Design',
        key_13000004: 'Diets',
        key_13000005: 'DIY & Crafts',
        key_13000006: 'Education',
        key_13000007: 'Gossip',
        key_13000008: 'Investment & Trading',
        key_13000009: 'Nature & Outdoors',
        key_13000010: 'NFT Artists',
        key_13000011: 'Personal Finance',
        key_13000012: 'Photography & Video',
        key_13000013: 'Politics',
        key_31000000: 'Digital',
        key_31000001: 'Press',
        key_31000002: 'Radio',
        key_31000003: 'TV - Generic',
        key_31000004: 'TV - Movies',
        key_31000005: 'TV - Sports',
        key_41000000: 'Aesthetics - Products',
        key_41000001: 'Agencies - Advertising',
        key_41000002: 'Agencies - Digital Performance & Social Media',
        key_41000003: 'Agencies - Media',
        key_41000004: 'Automotive - Manufactors',
        key_41000005: 'Financial Services - Banking',
        key_41000006: 'Fashion & Apparel',
        key_41000007: 'Telecom',
        key_41000008: 'E-commerce - Others',
        key_41000009: 'Education - Others',
        key_41000010: 'Utilities - Energy',
        key_41000011: 'Entertainment & Leisure',
        key_41000012: 'Food - Others',
        key_41000013: 'Healthcare - Others',
        key_41000014: 'Industry',
        key_41000015: 'Information Technology',
        key_41000016: 'Prof. Services - Others',
        key_41000017: 'Real Estate - Agencies',
        key_41000018: 'Restaurants',
        key_41000019: 'Retail',
        key_41000020: 'Sports - Clubs',
        key_41000021: 'Supers & Hypers',
        key_41000022: 'Travel & Tourism - Hotels',
        key_41000023: 'Beauty & Cosmetics',
        key_41000024: 'Agencies - Influencers',
        key_41000025: 'Agencies - PR / Comunications',
        key_41000026: 'Consumer Electronics',
        key_41000027: 'Beverages - Beer',
        key_41000028: 'Beverages - Non-alcoholic',
        key_41000029: 'Beverages - Water',
        key_41000030: 'Beverages - Wine',
        key_41000031: 'Food - Dairy',
        key_41000032: 'Healthcare - Hospitals',
        key_41000033: 'Home Furniture & Textiles',
        key_41000034: 'Financial Services - Insurance',
        key_41000035: 'Culture - Festivals & Concerts',
        key_41000036: 'Culture - Publisher & Literature',
        key_41000037: 'Stationery & Office Suppliers',
        key_41000038: 'Aesthetics - Clinics',
        key_41000039: 'Agencies - Events',
        key_41000040: 'Agricultural - Services & Products',
        key_41000041: 'AgriTech',
        key_41000042: 'Automotive - Dealers',
        key_41000043: 'Automotive - Rental',
        key_41000044: 'Blockchain - Cryptos',
        key_41000045: 'Blockchain - NFTs / Metaverse',
        key_41000046: 'Blockchain - Crypto Trading Platforms',
        key_41000047: 'Consumer Packaged Goods',
        key_41000048: 'Culture - Performing Arts (Theatre, Dance,...)',
        key_41000049: 'Culture - Visual Art & Exhibitions',
        key_41000050: 'E-commerce - Beauty & Personal Care',
        key_41000051: 'E-commerce - Electronics',
        key_41000052: 'E-commerce - Entertainment',
        key_41000053: 'E-commerce - Fashion & Apparel',
        key_41000054: 'E-commerce - Household Goods',
        key_41000055: 'EdTech',
        key_41000056: 'Education - Colleges',
        key_41000057: 'Education - IB',
        key_41000058: 'Education - Universities',
        key_41000059: 'Financial Sevices - Payment Solutions',
        key_41000060: 'Financial Sevices - Private Equity & Funds',
        key_41000061: 'FinTech',
        key_41000062: 'Fishery',
        key_41000063: 'Footwear',
        key_41000064: 'Forestry',
        key_41000065: 'Foundations & NGOs',
        key_41000066: 'Gaming - PC / Mobile / Console',
        key_41000067: 'Gaming - Others',
        key_41000068: 'Gaming - Metaverse / NFTs',
        key_41000069: 'Gambling - Casinos',
        key_41000070: 'Gambling - Online',
        key_41000071: 'Healthcare - Dental Clinics',
        key_41000072: 'Healthcare - Labs',
        key_41000073: 'Healthcare - Nutrition',
        key_41000074: 'Healthcare - Pharma',
        key_41000075: 'Healthcare - Plastic Surgery Clinics',
        key_41000076: 'HealthTech',
        key_41000077: 'Livestock',
        key_41000078: 'Luxury',
        key_41000079: 'MarTech',
        key_41000080: 'Mobility - Aviation',
        key_41000081: 'Mobility - Private Transportation',
        key_41000082: 'Mobility - Public Transportation',
        key_41000083: 'Mobility - Ride Sharing',
        key_41000084: 'Pets - Products',
        key_41000085: 'Pets - Veterinary',
        key_41000086: 'Prof Services - Architecture & Projects',
        key_41000087: 'Prof. Services - Accounting',
        key_41000088: 'Prof. Services - Audit & Consultancy',
        key_41000089: 'Prof. Services - Law',
        key_41000090: 'Prof. Services - Management & Finance',
        key_41000091: 'Public Adm - Institutes',
        key_41000092: 'Public Adm - Municipalities',
        key_41000093: 'Public Adm - Others',
        key_41000094: 'Public Adm - Political Parties',
        key_41000095: 'Real Estate - Promotores',
        key_41000096: 'RealTech',
        key_41000097: 'Sports - Equipment',
        key_41000098: 'Travel & Tourism - Agencies',
        key_41000099: 'Travel & Tourism - Operators',
        key_41000100: 'Travel & Tourism - Venues',
        key_41000101: 'Unions',
        key_41000102: 'Utilities - Waste Management',
        key_41000103: 'Utilities - Water',
      },
      countryCode: {
        OTHER: 'Others',
        Others: 'Others',
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia (Plurinational State of)',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        CV: 'Cabo Verde',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo (Democratic Republic of the)',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: "Côte d'Ivoire",
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran (Islamic Republic of)',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: "Korea (Democratic People's Republic of)",
        KR: 'Korea (Republic of)',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: "Lao People's Democratic Republic",
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'North Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia (Federated States of)',
        MD: 'Moldova (Republic of)',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestine, State of',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Eswatini',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China[a]',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States of America',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela (Bolivarian Republic of)',
        VN: 'Viet Nam',
        VG: 'Virgin Islands (British)',
        VI: 'Virgin Islands (U.S.)',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
      },
    },
  },
};

export default resources;
