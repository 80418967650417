import { GENDERS } from 'Services/Utils/types';
import { SortOrder } from 'Services/SkorrApi/new/User/types';

export enum AdminPartnersSortBy {
  name = 'name',
  type = 'type',
  createdAt = 'createdAt',
}

export type AdminPartnersListFilters = {
  name: string;
  page: number;
  sortBy: AdminPartnersSortBy;
  sortOrder: SortOrder;
  type?: string;
};

export type GetAdminPartnersRequest = {
  name?: string;
  type?: string;
  page?: number;
  limit?: number;
  sortBy?: AdminPartnersSortBy;
  sortOrder?: SortOrder;
};

export enum Version {
  ZERO = 0.0,
  ONE = 1.0,
}

export type BusinessPlan = {
  tier: string;
  trialDays: number;
  active: boolean;
  months: number;
  createdAt: Date;
  updatedAt: Date;
};

export type PaywareDetails = {
  payware: string;
  customerId: string;
  paymentId: string | null;
  active: boolean;
};

export type Partner = {
  id: string;
  name: string;
  dataAccessLevel: string;
  displayPicture?: string;
  img?: string;
  contactDetail?: {
    companyName?: string;
    vat_id?: string;
    address1?: string;
    address2?: string;
    postalCode?: string;
    country?: string;
    countryId?: string;
    city?: string;
    cityId?: string;
    website?: string;
    manager1?: string;
    manager2?: string;
    description?: string;
    projectName?: string;
    brand?: string;
    email?: string;
  };
  billingDetail?: {
    companyName?: string;
    vat_id?: string;
    vatExemption?: boolean;
    address1?: string;
    address2?: string;
    postalCode?: string;
    city?: string;
    cityId?: string;
    country?: string;
    countryId?: string;
  };
  billingToContactAddress?: boolean;
  needsDpoReview?: boolean;
  businessPlanTier?: string;
  businessPlans?: BusinessPlan[];
  businessPlanStartAt?: Date;
  businessPlanEndAt?: Date;
  businessPlanExpired?: boolean;
  businessPlanTrialDays?: number;
  businessPlanMonths?: number;
  webSite?: string;
  paywareDetails?: Array<PaywareDetails>;
  active?: boolean;
  activeCode?: string;
  activeCreatedAt?: Date;
  activeUpsertedAt?: Date;
  activeAttempts?: number;
  activeByMobile?: string;
  type?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isAdditionalDataStepComplete?: boolean;
  version?: Version;
  deleted?: boolean | null;
  deletedAt?: Date | null;
};

export type GetAdminPartnersResponse = {
  partners: Partner[];
  total: number;
};

export type CreateAdminPartnerRequest = {
  name: string;
  email?: string;
  picture?: string;
  billingToContactAddress?: boolean;
  type: string;
  contactManager1?: string;
  contactManager2?: string;
  contactDescription?: string;
  contactBrand?: string;
  companyName?: string;
  contactAddress1?: string;
  contactAddress2?: string;
  contactPostalCode?: string;
  contactCountry?: string;
  contactCountryId?: string;
  contactCity?: string;
  contactCityId?: string;
  contactVat_id?: string;
  contactWebSite?: string;
  billingCompanyName?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingPostalCode?: string;
  billingCountry?: string;
  billingCountryId?: string;
  billingCity?: string;
  billingCityId?: string;
  billingVatExemption?: boolean;
  billingVat_id?: string;
  fileFormData?: FormData;
};

export type UpdateAdminPartnerRequest = {
  id: string;
  name: string;
  email?: string;
  picture?: string;
  billingToContactAddress?: boolean;
  type: string;
  contactManager1?: string;
  contactManager2?: string;
  contactDescription?: string;
  contactBrand?: string;
  companyName?: string;
  contactAddress1?: string;
  contactAddress2?: string;
  contactPostalCode?: string;
  contactCountry?: string;
  contactCountryId?: string;
  contactCity?: string;
  contactCityId?: string;
  contactVat_id?: string;
  contactWebSite?: string;
  billingCompanyName?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingPostalCode?: string;
  billingCountry?: string;
  billingCountryId?: string;
  billingCity?: string;
  billingCityId?: string;
  billingVatExemption?: boolean;
  billingVat_id?: string;
  fileFormData?: FormData;
};

export type ValidateAdminPartnerRequest = {
  id: string;
  active: boolean;
};

export type CreateAdminPartnerAgentRequest = {
  partnerId: string;
  name: string;
  email: string;
  password: string;
  displayPicture: string | null;
  topicsOfInterest?: string[];
  countryCode: string | null;
  countryId: string | null;
  gender: GENDERS | null;
  dateOfBirth: number | null;
  roleIds?: string[];
  fileFormData?: FormData;
};

export type UpdateAdminPartnerAgentRequest = {
  agentId: string;
  partnerId: string;
  name: string;
  displayPicture: string | null;
  topicsOfInterest?: string[];
  countryCode: string | null;
  countryId: string | null;
  gender: GENDERS | null;
  dateOfBirth: number | null;
  roleIds?: string[];
  newPassword?: string;
  fileFormData?: FormData;
};

export type ValidateAdminPartnerAgentRequest = {
  active: boolean;
  agentId: string;
  partnerId: string;
};
