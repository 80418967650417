/* eslint-disable no-empty-function */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Button from 'components/shared/Button/Button';

import * as S from './Styles';
import { IPublicUserMediaKitProps, PostFilters } from './types';
import {
  useGetKpisOverviewDaily,
  useGetPublicPostsEvolutionGroupedByKpi,
  useGetPublicUserAudienceDistributionData,
  useGetPublicUserHashtagsLatest,
  useGetPublicUserKpisOverview,
} from './RQCustomHooks';
import UserPresentationalCard from '../../components/shared/MediaKit/UserPresentationalCard';
import {
  useGetPublicPostsByPublicUserId,
  useGetPublicUserById,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { getDifferenceInDays } from '../../Services/Utils/Utils';
import { SocialNetworks, ViewTypes } from '../../Services/Utils/types';
import EvolutionPerformance from '../../components/shared/MediaKit/EvolutionPerformance';
import AudienceDistribution from '../../components/shared/MediaKit/AudienceDistribution';
import Posts from '../../components/shared/MediaKit/Posts';
import GenericModal from '../../components/shared/GenericModal/GenericModal';

const START_DATE_TIMESTAMP = new Date(
  new Date().getTime() - 30 * 24 * 3600 * 1000,
).setHours(0, 0, 0, 0);
const END_DATE_TIMESTAMP = new Date().setHours(23, 59, 59, 0);

const enum MediaKitSections {
  'USER_PRESENTATIONAL_CARD' = 'USER_PRESENTATIONAL_CARD',
  'EVOLUTION_PERFORMANCE' = 'EVOLUTION_PERFORMANCE',
  'AUDIENCE_DEMOGRAPHICS' = 'AUDIENCE_DEMOGRAPHICS',
  'POSTS' = 'POSTS',
}

export const orderKPIs = (dataKPIs: any) => {
  let indOfGreatest;
  let temp;

  for (let i = 0; i < dataKPIs.length - 1; i++) {
    indOfGreatest = 0;
    for (var j = 0; j < dataKPIs.length - i; j++) {
      if (dataKPIs[j].date > dataKPIs[indOfGreatest].date) {
        indOfGreatest = j;
      } else if (dataKPIs[j].date < dataKPIs[indOfGreatest].date) {
        indOfGreatest = indOfGreatest;
      }
    }
    temp = dataKPIs[j - 1];
    dataKPIs[j - 1] = dataKPIs[indOfGreatest];
    dataKPIs[indOfGreatest] = temp;
  }
  return dataKPIs;
};

export const filterKPIs = (data: any, viewType?: string) => {
  const filteredKPIsInitial = [];
  const filteredKPIsFinal = [];
  const olderKPIsInitial = [];
  const olderKPIsFinal = [];
  let hasNetwork;

  if (data.dataKPIs.length > 0) {
    // if the agent selected a time interval
    if (
      data.date[0].startDate !== undefined &&
      data.date[0].endDate !== undefined
    ) {
      data.setMostRecentDayWithData(undefined);

      const timeRangeLengthInDays = getDifferenceInDays(
        data.date[0].endDate,
        data.date[0].startDate,
      );

      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        hasNetwork = false;
        // if the day is included in the selected time interval
        if (
          data.dataKPIs[i].date >= data.date[0].startDate &&
          data.dataKPIs[i].date <= data.date[0].endDate
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            filteredKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                filteredKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        }
        // if day is not included in selected time interval
        else {
          // if day is prior to the first day of selected time interval and its distance to the first day of selected time interval is not greater than the number of days of the selected time interval
          if (
            data.dataKPIs[i].date <= data.date[0].startDate &&
            getDifferenceInDays(
              Date.parse(data.date[0].startDate),
              Date.parse(data.dataKPIs[i].date),
            ) <= timeRangeLengthInDays
          ) {
            // if in public user analytics page
            if (viewType === ViewTypes.PUBLIC) {
              olderKPIsInitial.push(data.dataKPIs[i]);
            } else {
              // search selected social network in day
              for (const kpiKey in data.dataKPIs[i]) {
                if (
                  kpiKey.substring(0, data.networkSelectedId.length) ===
                    data.networkSelectedId &&
                  kpiKey[data.networkSelectedId.length] === '.'
                ) {
                  hasNetwork = true;
                  olderKPIsInitial.push(data.dataKPIs[i]);
                }

                if (hasNetwork) {
                  break;
                }
              }
            }
          }
        }
      }
    }
    // if the agent didn't select a time interval
    else if (
      data.date[0].startDate === undefined &&
      data.date[0].endDate === undefined
    ) {
      const todayTimestamp: any = {};
      // search for the most recent day with available kpis for the selected social network
      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        if (data.dataKPIs[i].networks.indexOf(data.networkSelectedId) < 0) {
          continue;
        }
        todayTimestamp[data.networkSelectedId] = data.dataKPIs[i].date;
        data.setMostRecentDayWithData(data.dataKPIs[i].date);
        break;
      }

      for (let i = data.dataKPIs.length - 1; i >= 0; i--) {
        hasNetwork = false;
        // the day is inside the interval
        if (
          data.dataKPIs[i].date <= todayTimestamp[data.networkSelectedId] &&
          getDifferenceInDays(
            todayTimestamp[data.networkSelectedId],
            data.dataKPIs[i].date,
          ) <= 30
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            filteredKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                filteredKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        } else if (
          data.dataKPIs[i].date <= todayTimestamp[data.networkSelectedId] &&
          getDifferenceInDays(
            todayTimestamp[data.networkSelectedId],
            data.dataKPIs[i].date <= 60,
          )
        ) {
          // if in public user analytics page
          if (viewType === ViewTypes.PUBLIC) {
            olderKPIsInitial.push(data.dataKPIs[i]);
          } else {
            // search selected social network in day
            for (const kpiKey in data.dataKPIs[i]) {
              if (
                kpiKey.substring(0, data.networkSelectedId.length) ===
                  data.networkSelectedId &&
                kpiKey[data.networkSelectedId.length] === '.'
              ) {
                hasNetwork = true;
                olderKPIsInitial.push(data.dataKPIs[i]);
              }

              if (hasNetwork) {
                break;
              }
            }
          }
        }
      }
    }
  }
  for (let i = filteredKPIsInitial.length - 1; i >= 0; i--) {
    filteredKPIsFinal.push(filteredKPIsInitial[i]);
  }
  for (let i = olderKPIsInitial.length - 1; i >= 0; i--) {
    olderKPIsFinal.push(olderKPIsInitial[i]);
  }
  data.setFilteredKPIs(filteredKPIsFinal);
  data.setOlderKpis(olderKPIsFinal);
};

const PublicUserMediaKit: React.FC<IPublicUserMediaKitProps> = (
  props: IPublicUserMediaKitProps,
) => {
  const searchRouteParams = useParams();
  const [searchQueryParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const extractFiltersFromUrl = useCallback((): PostFilters | undefined => {
    const filters = {
      ...(searchQueryParams.getAll('keywords[]') && {
        keywords: searchQueryParams.getAll('keywords[]'),
      }),
      ...(searchQueryParams.getAll('countryCode[]') && {
        countryCode: searchQueryParams.getAll('countryCode[]'),
      }),
      ...(searchQueryParams.get('audienceValueLow') && {
        audienceValueLow: Number(searchQueryParams.get('audienceValueLow')),
      }),
      ...(searchQueryParams.get('audienceValueHigh') && {
        audienceValueHigh: Number(searchQueryParams.get('audienceValueHigh')),
      }),
      ...(searchQueryParams.get('engRateValueLow') && {
        engRateValueLow: Number(searchQueryParams.get('engRateValueLow')),
      }),
      ...(searchQueryParams.get('engRateValueHigh') && {
        engRateValueHigh: Number(searchQueryParams.get('engRateValueHigh')),
      }),
      ...(searchQueryParams.get('postsValueLow') && {
        postsValueLow: Number(searchQueryParams.get('postsValueLow')),
      }),
      ...(searchQueryParams.get('postsValueHigh') && {
        postsValueHigh: Number(searchQueryParams.get('postsValueHigh')),
      }),
      ...(searchQueryParams.get('likesValueLow') && {
        likesValueLow: Number(searchQueryParams.get('likesValueLow')),
      }),
      ...(searchQueryParams.get('likesValueHigh') && {
        likesValueHigh: Number(searchQueryParams.get('likesValueHigh')),
      }),
      ...(searchQueryParams.get('sharesValueLow') && {
        sharesValueLow: Number(searchQueryParams.get('sharesValueLow')),
      }),
      ...(searchQueryParams.get('sharesValueHigh') && {
        sharesValueHigh: Number(searchQueryParams.get('sharesValueHigh')),
      }),
      ...(searchQueryParams.get('commentsValueLow') && {
        commentsValueLow: Number(searchQueryParams.get('commentsValueLow')),
      }),
      ...(searchQueryParams.get('commentsValueHigh') && {
        commentsValueHigh: Number(searchQueryParams.get('commentsValueHigh')),
      }),
      ...(searchQueryParams.get('startDate') && {
        startDate: Number(searchQueryParams.get('startDate')),
      }),
      ...(searchQueryParams.get('endDate') && {
        endDate: Number(searchQueryParams.get('endDate')),
      }),
    };

    if (Object.keys(filters).length) {
      return filters;
    }
  }, []);
  const [mostRecentDayWithData, setMostRecentDayWithData] = useState<number>();
  const [olderKpis, setOlderKpis] = useState([]);
  const [filteredKPIs, setFilteredKPIs] = useState([]);
  const [
    mostRecentDayWithDataFromPostFilters,
    setMostRecentDayWithDataFromPostFilters,
  ] = useState<number>();
  const [filteredKPIsFromPostFilters, setFilteredKPIsFromPostFilters] =
    useState([]);
  const [postFilters, setPostFilters] = useState<PostFilters>();
  const [isGetPublicUserByIdEnabled, setIsGetPublicUserByIdEnabled] =
    useState(false);
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(false);
  const [
    isGetPublicUserHashtagsLatestEnabled,
    setIsGetPublicUserHashtagsLatestEnabled,
  ] = useState(false);
  const [isGetPublicUserKpisOverviewEnabled, setIsGetKpisOverviewEnabled] =
    useState(false);
  const [
    isGetPublicUserKpisOverviewDailyEnabled,
    setIsGetKpisOverviewDailyEnabled,
  ] = useState(false);
  const [
    isGetPublicUserAudienceDistributionEnabled,
    setIsGetPublicUserAudienceDistributionEnabled,
  ] = useState(false);
  const [
    isGetPublicPostsByPublicUserIdEnabled,
    setIsGetPublicPostsByPublicUserIdEnabled,
  ] = useState(false);
  const [
    isGetPublicPostsEvolutionGroupedByKpiEnabled,
    setIsGetPublicPostsEvolutionGroupedByKpiEnabled,
  ] = useState(false);
  const [
    isProcessingPublicUserKpisDailyData,
    setIsProcessingPublicUserKpisDailyData,
  ] = useState(false);
  const [
    isProcessingPublicUserKpisDailyDataFromPostFilters,
    setIsProcessingPublicUserKpisDailyDataFromPostFilters,
  ] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: publicUsers,
    isFetching: isFetchingGetPublicUser,
    isSuccess: isSuccessGetPublicUser,
    isError: isErrorGetPublicUser,
  } = useGetPublicUserById(isGetPublicUserByIdEnabled, [
    searchRouteParams.publicUserId!,
  ]);
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: publicUserHashtagsLatest,
    isFetching: isFetchingGetPublicUserHashtagsLatest,
    isSuccess: isSuccessGetPublicUserHashtagsLatest,
    isError: isErrorGetPublicUserHashtagsLatest,
  } = useGetPublicUserHashtagsLatest(isGetPublicUserHashtagsLatestEnabled, {
    publicUserId: searchRouteParams.publicUserId!,
    startDate: postFilters?.startDate ?? START_DATE_TIMESTAMP,
    endDate: postFilters?.endDate ?? END_DATE_TIMESTAMP,
    keywords: postFilters?.keywords ?? [],
    countryCode: postFilters?.countryCode ?? [],
    engRateValueLow: postFilters?.engRateValueLow,
    engRateValueHigh: postFilters?.engRateValueHigh,
    likesValueLow: postFilters?.likesValueLow,
    likesValueHigh: postFilters?.likesValueHigh,
    sharesValueLow: postFilters?.sharesValueLow,
    sharesValueHigh: postFilters?.sharesValueHigh,
    commentsValueLow: postFilters?.commentsValueLow,
    commentsValueHigh: postFilters?.commentsValueHigh,
  });
  const {
    data: publicUserKpisOverview,
    isFetching: isFetchingGetPublicUserKpisOverview,
    isSuccess: isSuccessGetPublicUserKpisOverview,
    isError: isErrorGetPublicUserKpisOverview,
  } = useGetPublicUserKpisOverview(isGetPublicUserKpisOverviewEnabled, {
    publicUserIds: [searchRouteParams.publicUserId!],
    startDate: postFilters?.startDate ?? START_DATE_TIMESTAMP,
    endDate: postFilters?.endDate ?? END_DATE_TIMESTAMP,
  });
  const {
    data: publicUserKpisOverviewDaily,
    isFetching: isFetchingGetPublicUserKpisOverviewDaily,
    isSuccess: isSuccessGetPublicUserKpisOverviewDaily,
    isError: isErrorGetPublicUserKpisOverviewDaily,
  } = useGetKpisOverviewDaily(isGetPublicUserKpisOverviewDailyEnabled, {
    publicUserIds: [searchRouteParams.publicUserId!],
    startDate: postFilters?.startDate ?? START_DATE_TIMESTAMP,
    endDate: postFilters?.endDate ?? END_DATE_TIMESTAMP,
    socialNetwork: publicUsers?.[0]?.socialNetwork.network,
  });
  const {
    data: publicUserAudienceDistributionData,
    isFetching: isFetchingGetPublicUserAudienceDistributionData,
    isSuccess: isSuccessGetPublicUserAudienceDistributionData,
    isError: isErrorGetPublicUserAudienceDistributionData,
  } = useGetPublicUserAudienceDistributionData(
    isGetPublicUserAudienceDistributionEnabled,
    {
      publicUserIds: [searchRouteParams.publicUserId!],
      startDate: START_DATE_TIMESTAMP,
      endDate: END_DATE_TIMESTAMP,
    },
  );
  const {
    data: publicPosts,
    isFetching: isFetchingGetPublicPostsByPublicUserId,
    isSuccess: isSuccessGetPublicPostsByPublicUserId,
    isError: isErrorGetPublicPostsByPublicUserId,
  } = useGetPublicPostsByPublicUserId(isGetPublicPostsByPublicUserIdEnabled, {
    publicUserIds: [searchRouteParams.publicUserId!],
    startDate: postFilters?.startDate ?? START_DATE_TIMESTAMP,
    endDate: postFilters?.endDate ?? END_DATE_TIMESTAMP,
    keywords: postFilters?.keywords ?? [],
    countryCode: postFilters?.countryCode ?? [],
    engRateValueLow: postFilters?.engRateValueLow,
    engRateValueHigh: postFilters?.engRateValueHigh,
    likesValueLow: postFilters?.likesValueLow,
    likesValueHigh: postFilters?.likesValueHigh,
    sharesValueLow: postFilters?.sharesValueLow,
    sharesValueHigh: postFilters?.sharesValueHigh,
    commentsValueLow: postFilters?.commentsValueLow,
    commentsValueHigh: postFilters?.commentsValueHigh,
  });
  const {
    data: publicPostsEvolutionGroupedByKpi,
    isFetching: isFetchingGetPublicPostsEvolutionGroupedByKpi,
    isSuccess: isSuccessGetPublicPostsEvolutionGroupedByKpi,
    isError: isErrorGetPublicPostsEvolutionGroupedByKpi,
  } = useGetPublicPostsEvolutionGroupedByKpi(
    isGetPublicPostsEvolutionGroupedByKpiEnabled,
    postFilters ? { filters: postFilters } : undefined,
  );

  useEffect(() => {
    const postFiltersLocal = extractFiltersFromUrl();

    if (searchRouteParams.publicUserId) {
      if (postFiltersLocal?.keywords?.length) {
        if (!postFiltersLocal.startDate) {
          postFiltersLocal.startDate = START_DATE_TIMESTAMP;
          postFiltersLocal.endDate = END_DATE_TIMESTAMP;
        }
        postFiltersLocal.publicUserIds = [searchRouteParams.publicUserId];

        setPostFilters(postFiltersLocal);
        setIsGetPublicPostsEvolutionGroupedByKpiEnabled(true);
      }

      setIsGetPublicUserHashtagsLatestEnabled(true);
      setIsGetKpisOverviewEnabled(true);
      setIsGetPublicUserByIdEnabled(true);
      setIsGetPublicUserAudienceDistributionEnabled(true);
      setIsGetPublicPostsByPublicUserIdEnabled(true);
      setIsGetSelfUserEnabled(true);
    }
  }, [searchRouteParams.publicUserId]);

  useEffect(() => {
    if (isSuccessGetPublicUser && searchRouteParams.publicUserId) {
      setIsGetKpisOverviewDailyEnabled(true);
    }
  }, [isSuccessGetPublicUser, searchRouteParams.publicUserId]);

  useEffect(() => {
    if (isSuccessGetPublicUserKpisOverviewDaily) {
      setIsProcessingPublicUserKpisDailyData(true);

      let orderedKPIs = null;

      orderedKPIs = orderKPIs(publicUserKpisOverviewDaily.dataKPIs);

      for (let i = orderedKPIs.length - 1; i >= 0; i--) {
        if (
          orderedKPIs[i].networks.indexOf(
            publicUsers?.[0]?.socialNetwork.network === SocialNetworks.INSTAGRAM
              ? SocialNetworks.FACEBOOKPAGEINSTAGRAM
              : publicUsers?.[0]?.socialNetwork.network,
          ) < 0
        ) {
          continue;
        }
        setMostRecentDayWithData(orderedKPIs[i].date);
        break;
      }

      filterKPIs(
        {
          dataKPIs: publicUserKpisOverviewDaily.dataKPIs,
          date: [
            {
              startDate: publicUserKpisOverviewDaily.date[0].startDate,
              endDate: publicUserKpisOverviewDaily.date[0].endDate,
            },
          ],
          networkSelectedId: publicUsers?.[0]?.socialNetwork.network,
          setMostRecentDayWithData,
          setFilteredKPIs,
          setOlderKpis,
        },
        ViewTypes.PUBLIC,
      );

      setIsProcessingPublicUserKpisDailyData(false);
    }
  }, [isSuccessGetPublicUserKpisOverviewDaily, searchRouteParams.publicUserId]);

  useEffect(() => {
    if (isSuccessGetPublicPostsEvolutionGroupedByKpi) {
      setIsProcessingPublicUserKpisDailyDataFromPostFilters(true);

      let orderedKPIs = null;

      orderedKPIs = orderKPIs(publicPostsEvolutionGroupedByKpi.dataKPIs);

      for (let i = orderedKPIs.length - 1; i >= 0; i--) {
        if (
          orderedKPIs[i].networks.indexOf(
            publicUsers?.[0]?.socialNetwork.network === SocialNetworks.INSTAGRAM
              ? SocialNetworks.FACEBOOKPAGEINSTAGRAM
              : publicUsers?.[0]?.socialNetwork.network,
          ) < 0
        ) {
          continue;
        }
        setMostRecentDayWithDataFromPostFilters(orderedKPIs[i].date);
        break;
      }

      filterKPIs(
        {
          dataKPIs: publicPostsEvolutionGroupedByKpi.dataKPIs,
          date: [
            {
              startDate: publicPostsEvolutionGroupedByKpi.date[0].startDate,
              endDate: publicPostsEvolutionGroupedByKpi.date[0].endDate,
            },
          ],
          networkSelectedId: publicUsers?.[0]?.socialNetwork.network,
          setMostRecentDayWithData: setMostRecentDayWithDataFromPostFilters,
          setFilteredKPIs: setFilteredKPIsFromPostFilters,
          setOlderKpis: () => {},
        },
        ViewTypes.PUBLIC,
      );

      setIsProcessingPublicUserKpisDailyDataFromPostFilters(false);
    }
  }, [
    isSuccessGetPublicPostsEvolutionGroupedByKpi,
    searchRouteParams.publicUserId,
  ]);

  useEffect(() => {
    if (
      isErrorGetPublicUser ||
      isErrorGetSelfUser ||
      isErrorGetPublicUserHashtagsLatest ||
      isErrorGetPublicUserKpisOverview ||
      isErrorGetPublicUserKpisOverviewDaily ||
      isErrorGetPublicUserAudienceDistributionData ||
      isErrorGetPublicPostsByPublicUserId ||
      isErrorGetPublicPostsEvolutionGroupedByKpi
    ) {
      setHasError(true);
      setMsgError(props.t('mediaKit.error.error-getting-data'));
    }
  }, [
    isErrorGetPublicUser,
    isErrorGetSelfUser,
    isErrorGetPublicUserHashtagsLatest,
    isErrorGetPublicUserKpisOverview,
    isErrorGetPublicUserKpisOverviewDaily,
    isErrorGetPublicUserAudienceDistributionData,
    isErrorGetPublicPostsByPublicUserId,
    isErrorGetPublicPostsEvolutionGroupedByKpi,
  ]);

  const getLoaderMessage = () => {
    if (
      isFetchingGetPublicUser ||
      isFetchingGetSelfUser ||
      isFetchingGetPublicUserHashtagsLatest ||
      isFetchingGetPublicUserKpisOverview ||
      isFetchingGetPublicUserKpisOverviewDaily ||
      isProcessingPublicUserKpisDailyData ||
      isProcessingPublicUserKpisDailyDataFromPostFilters ||
      isFetchingGetPublicUserAudienceDistributionData ||
      isFetchingGetPublicPostsByPublicUserId ||
      isFetchingGetPublicPostsEvolutionGroupedByKpi
    ) {
      return props.t('mediaKit.loading_media_kit_data');
    }
    return '';
  };

  const canRenderSection = (section: MediaKitSections) => {
    switch (section) {
      case MediaKitSections.USER_PRESENTATIONAL_CARD: {
        if (
          isSuccessGetPublicUser &&
          isSuccessGetSelfUser &&
          isSuccessGetPublicUserHashtagsLatest &&
          isSuccessGetPublicUserKpisOverview &&
          publicUsers?.[0] &&
          ((postFilters &&
            isSuccessGetPublicPostsEvolutionGroupedByKpi &&
            publicPostsEvolutionGroupedByKpi) ||
            !postFilters)
        ) {
          return true;
        }
        return false;
      }
      case MediaKitSections.EVOLUTION_PERFORMANCE: {
        if (
          isSuccessGetPublicUserKpisOverviewDaily &&
          !isProcessingPublicUserKpisDailyData &&
          publicUsers?.[0] &&
          ((postFilters &&
            isSuccessGetPublicPostsEvolutionGroupedByKpi &&
            !isProcessingPublicUserKpisDailyDataFromPostFilters &&
            publicPostsEvolutionGroupedByKpi) ||
            !postFilters)
        ) {
          return true;
        }
        return false;
      }
      case MediaKitSections.AUDIENCE_DEMOGRAPHICS: {
        if (
          isSuccessGetPublicUserAudienceDistributionData &&
          publicUserAudienceDistributionData.publicUserAudienceGeo
        ) {
          return true;
        }
        return false;
      }
      case MediaKitSections.POSTS: {
        if (
          isSuccessGetPublicPostsByPublicUserId &&
          publicPosts &&
          publicUsers?.[0]
        ) {
          return true;
        }
        return false;
      }
    }
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetPublicUser ||
          isFetchingGetSelfUser ||
          isFetchingGetPublicUserHashtagsLatest ||
          isFetchingGetPublicUserKpisOverview ||
          isFetchingGetPublicUserKpisOverviewDaily ||
          isProcessingPublicUserKpisDailyData ||
          isProcessingPublicUserKpisDailyDataFromPostFilters ||
          isFetchingGetPublicUserAudienceDistributionData ||
          isFetchingGetPublicPostsByPublicUserId ||
          isFetchingGetPublicPostsEvolutionGroupedByKpi
        }
        message={getLoaderMessage()}
      />

      {canRenderSection(MediaKitSections.USER_PRESENTATIONAL_CARD) && (
        <UserPresentationalCard
          publicUser={publicUsers![0]}
          user={selfUser!}
          publicUserHashtagsLatest={publicUserHashtagsLatest ?? []}
          kpis={{
            followers: publicUserKpisOverview?.total.followers ?? 0,
            postCount:
              (postFilters
                ? publicPostsEvolutionGroupedByKpi?.total.posts
                : publicUserKpisOverview?.total.posts) ?? 0,
            likesAvg:
              (postFilters
                ? publicPostsEvolutionGroupedByKpi?.avg.likes
                : publicUserKpisOverview?.avg.likes) ?? 0,
            commentsAvg:
              (postFilters
                ? publicPostsEvolutionGroupedByKpi?.avg.comments
                : publicUserKpisOverview?.avg.comments) ?? 0,
            engRateAvg:
              (postFilters
                ? publicPostsEvolutionGroupedByKpi?.avg.engRate
                : publicUserKpisOverview?.avg.engRate) ?? 0,
            views:
              (postFilters
                ? publicPostsEvolutionGroupedByKpi?.avg.views
                : publicUserKpisOverview?.avg.views) ?? 0,
          }}
          startDate={
            postFilters?.startDate
              ? new Date(postFilters.startDate)
              : new Date(START_DATE_TIMESTAMP)
          }
          endDate={
            postFilters?.endDate
              ? new Date(postFilters.endDate)
              : new Date(END_DATE_TIMESTAMP)
          }
          keywordFilters={[]}
          hasPostPicking={false}
        />
      )}

      {postFilters && (
        <S.ClearFiltersButtonContainer>
          <Button
            borderRadius={'0.6rem'}
            borderWidth={'0rem'}
            height={'4rem'}
            fontSize={'1.6rem'}
            textAlign={'center'}
            fontWeight={'500'}
            paddingRight={'1rem'}
            onClick={() => {
              setPostFilters(undefined);
              setIsGetPublicPostsEvolutionGroupedByKpiEnabled(false);
              navigate(location.pathname);
            }}
          >
            {props.t('generic.clearFilters')}
          </Button>
        </S.ClearFiltersButtonContainer>
      )}

      {canRenderSection(MediaKitSections.EVOLUTION_PERFORMANCE) && (
        <EvolutionPerformance
          data={{
            filteredKPIs,
            olderKpis,
            filteredKPIsFromPosts: filteredKPIsFromPostFilters,
            networkSelectedId:
              publicUsers![0].socialNetwork.network === SocialNetworks.YOUTUBE
                ? SocialNetworks.YOUTUBE
                : SocialNetworks.FACEBOOKPAGEINSTAGRAM,
            date: postFilters
              ? publicPostsEvolutionGroupedByKpi?.date
              : publicUserKpisOverviewDaily?.date,
            kpisOverview: publicUserKpisOverview,
            kpisFilteredFromPostsOverview: {
              avg: publicPostsEvolutionGroupedByKpi?.avg,
              total: publicPostsEvolutionGroupedByKpi?.total,
            },
            isDataFilteredFromPosts: Boolean(postFilters),
          }}
        />
      )}

      {canRenderSection(MediaKitSections.AUDIENCE_DEMOGRAPHICS) && (
        <AudienceDistribution
          age={publicUserAudienceDistributionData.publicUserAudienceAgeGroups}
          city={
            publicUserAudienceDistributionData.publicUserAudienceGeo!.cities
          }
          country={
            publicUserAudienceDistributionData.publicUserAudienceGeo!.countries
          }
          gender={publicUserAudienceDistributionData.publicUserAudienceGenders}
          type={publicUserAudienceDistributionData.publicUserAudienceTypes}
          reachability={
            publicUserAudienceDistributionData.publicUserAudienceReachability
          }
        />
      )}

      {canRenderSection(MediaKitSections.POSTS) && (
        <Posts posts={publicPosts!} user={publicUsers![0]} />
      )}

      {(canRenderSection(MediaKitSections.USER_PRESENTATIONAL_CARD) ||
        canRenderSection(MediaKitSections.EVOLUTION_PERFORMANCE) ||
        canRenderSection(MediaKitSections.AUDIENCE_DEMOGRAPHICS) ||
        canRenderSection(MediaKitSections.POSTS)) && (
        <S.PoweredByOuterContainer>
          <S.PoweredByContainer>
            <S.PoweredByInnerContainer>
              <S.PoweredByLabel
                className="madeby-button"
                onClick={() => window.open('https://skorr.social/', '_blank')}
              >
                {props.t(`mediaKit.powered-by-label`)}
                <S.PoweredByImage
                  src={require('../../assets/mk-copyright-logo.png')}
                />
              </S.PoweredByLabel>
            </S.PoweredByInnerContainer>
          </S.PoweredByContainer>
        </S.PoweredByOuterContainer>
      )}

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => setHasError(false)}
      />
    </S.Container>
  );
};

export default withTranslation()(PublicUserMediaKit);
