import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 1rem;
  margin-top: 3rem;
  border-radius: 2rem;
  background-color: white;
  border: 0.1rem solid #eee;
`;

export const CardInnerContainer = styled.div`
  width: 100%;
  min-height: 40rem;
`;

export const CardTitle = styled.h2`
  color: #8492a6;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-top: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 1rem;
`;
