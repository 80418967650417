/* eslint-disable no-use-before-define */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
import FacebookIcon from '../../assets/iconComponents/FacebookIcon';
import FacebookPageIcon from '../../assets/iconComponents/FacebookPage';
import InstagramIcon from '../../assets/iconComponents/InstagramIcon';
import TiktokIcon from '../../assets/iconComponents/TiktokIcon';
import TwitterIcon from '../../assets/iconComponents/TwitterIcon';
import YoutubeIcon from '../../assets/iconComponents/YoutubeIcon';
import * as S from './Styles';
import { IErrorObject, IjwtData, SocialNetworks } from './types';

export const jwtParser = (data: IjwtData) => {
  const jwt = JSON.parse(localStorage.getItem('jwt') || '{}');
  jwt.partnerId = data.partnerId;
  jwt.partnerType = data.partnerType;
  jwt.partnerName = data.partnerName;
  jwt.partnerImg = data.partnerImg;
  jwt.businessProfileId = data.businessProfileId;
  jwt.businessProfile = data.businessProfile;
  jwt.businessProfileUserId = data.businessProfileUserId;
  jwt.businessProfileUserImage = data.businessProfileUserImage;
  jwt.businessProfileUserIdAnalyticsReady =
    data.businessProfileUserIdAnalyticsReady == 'true';
  jwt.user = data.user;
  jwt.userId = data.userId;
  jwt.agentEmail = data.agentEmail;
  jwt.agentId = data.agentId;
  jwt.img = data.img
    ? data.img
    : 'https://b-skorr-pp.s3.eu-west-3.amazonaws.com/images/default/default_avatar-2.png';
  jwt.pages = data.pages;
  jwt.dpo = data.dpo == 'true';
  jwt.needsDpoReview = data.needsDpoReview == 'true';
  jwt.language = data.language;
  jwt.timezone = data.timezone;
  jwt.viewer = data.viewer;
  jwt.attributes = data.attributes;
  jwt.token = data.token;
  localStorage.setItem('jwt', JSON.stringify(jwt));
};

export const isAuthenticated = (): IjwtData | undefined => {
  if (typeof window === 'undefined') return undefined;
  if (localStorage.getItem('jwt')) {
    try {
      const jwt = JSON.parse(localStorage.getItem('jwt') || '{}');
      if (jwt.token && jwt.partnerId) return jwt as IjwtData;
      return undefined;
    } catch (err) {
      localStorage.removeItem('jwt');
      return undefined;
    }
  }
  return undefined;
};

export const setJwt = (jwt: IjwtData) => {
  localStorage.setItem('jwt', JSON.stringify(jwt));
};

export const authenticate = (data: any, cb: () => any) => {
  if (typeof window !== 'undefined') {
    jwtParser(data);
  }
  cb();
};

export const logApiRequestError = (error: IErrorObject): string => {
  const errorObject = error;
  let errorMessage;
  if (typeof errorObject?.response?.data !== 'string') {
    if (errorObject.response?.data?.message)
      errorMessage = errorObject?.response?.data?.message;
    else if (errorObject?.message) errorMessage = errorObject.message;
    else if (errorObject?.response?.data)
      errorMessage = errorObject.response.data;
    else errorMessage = error;
  } else if (errorObject?.message) errorMessage = errorObject.message;
  else if (errorObject?.response?.data)
    errorMessage = errorObject.response.data;
  else errorMessage = error;
  console.log(errorMessage);
  return errorMessage as string;
};

export const logApiRequestErrorWithModal = (
  error: IErrorObject,
  setHasError: (hasError: boolean) => void,
  setMsgError: (errorMessage: string) => void,
) => {
  const errorMessage = logApiRequestError(error);
  setHasError(true);
  setMsgError(errorMessage);
};

export const isNavbarDisplayed = (pathname: string): boolean => {
  if (
    pathname.includes('signup') ||
    pathname == '/' ||
    pathname == '/account-activation' ||
    pathname == '/additional-data-step' ||
    pathname == '/delete-account' ||
    pathname.includes('twitter-popup-component')
  )
    return false;
  return true;
};

export const getSocialNetworkIcon = (args: {
  socialNetwork:
    | SocialNetworks.FACEBOOK
    | SocialNetworks.FACEBOOKPAGE
    | SocialNetworks.FACEBOOKPAGEINSTAGRAM
    | SocialNetworks.TWITTER
    | SocialNetworks.INSTAGRAM
    | SocialNetworks.YOUTUBE
    | SocialNetworks.TIKTOK
    | string;
  width?: string;
  height?: string;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  gradientColored?: boolean;
}) => {
  switch (args.socialNetwork) {
    case SocialNetworks.FACEBOOK.toLowerCase():
    case SocialNetworks.FACEBOOK:
      return (
        <FacebookIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
        />
      );

    case SocialNetworks.FACEBOOKPAGE.toLowerCase():
    case SocialNetworks.FACEBOOKPAGE:
      return (
        <FacebookPageIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
        />
      );

    case SocialNetworks.TWITTER.toLowerCase():
    case SocialNetworks.TWITTER:
      return (
        <TwitterIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
        />
      );

    case SocialNetworks.FACEBOOKPAGEINSTAGRAM.toLowerCase():
    case SocialNetworks.INSTAGRAM.toLowerCase():
    case SocialNetworks.FACEBOOKPAGEINSTAGRAM:
    case SocialNetworks.INSTAGRAM:
      return (
        <InstagramIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
          gradientColored={args.gradientColored}
        />
      );

    case SocialNetworks.YOUTUBE.toLowerCase():
    case SocialNetworks.YOUTUBE:
      return (
        <YoutubeIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
        />
      );

    case SocialNetworks.TIKTOK.toLowerCase():
    case SocialNetworks.TIKTOK:
      return (
        <TiktokIcon
          svgWidth={args.width}
          svgHeight={args.height}
          containerMarginTop={args.marginTop}
          containerMarginBottom={args.marginBottom}
          containerMarginLeft={args.marginLeft}
          containerMarginRight={args.marginRight}
          containerPaddingTop={args.paddingTop}
          containerPaddingBottom={args.paddingBottom}
          containerPaddingLeft={args.paddingLeft}
          containerPaddingRight={args.paddingRight}
        />
      );
  }
};

export const getSocialNetworkName = (socialNetwork: string) => {
  switch (socialNetwork) {
    case SocialNetworks.FACEBOOK:
      return 'Facebook';
    case SocialNetworks.FACEBOOKPAGE:
      return 'Facebook Page';
    case SocialNetworks.FACEBOOKPAGEINSTAGRAM:
      return 'Instagram Business';
    case SocialNetworks.TWITTER:
      return 'Twitter';
    case SocialNetworks.INSTAGRAM:
      return 'Instagram';
  }
};

export function convertNumberToHumanStringfiedFormat(
  value: number | string,
  fixed?: number,
): string {
  const scales = ['', 'K', 'M', 'B', 'T'];
  const baseScale = 1000;

  const signal = (value as number) < 0 ? '-' : '';
  const absoluteNumber = Math.abs(value as number);

  if (absoluteNumber < baseScale) {
    return signal + absoluteNumber.toString();
  }

  const logarithm = Math.floor(
    Math.log10(absoluteNumber) / Math.log10(baseScale),
  );
  const scale = scales[logarithm];
  let formattedNumber = (absoluteNumber / baseScale ** logarithm).toFixed(
    fixed ?? 1,
  );

  if (formattedNumber.includes('.')) {
    const decimalPart = formattedNumber.split('.')[1];
    let i = 0;
    for (; i < decimalPart.length; i += 1) {
      if (!(decimalPart[i] === '0')) {
        break;
      }
    }

    if (i === decimalPart.length) {
      formattedNumber = `${formattedNumber.split('.')[0]}.0`;
    }
  }

  return signal + formattedNumber + scale;
}

export function formatNumberByGroupingDigits(value: number | string): string {
  const [integerPart, decimalPart] = String(value).split('.');

  let numberOfSpacesToBeInserted = integerPart.length / 3;
  if (integerPart.length % 3 === 0) {
    numberOfSpacesToBeInserted--;
  }

  if (integerPart.length > 3) {
    const spacedOutIntegerPart = ' '
      .repeat(integerPart.length + Math.floor(numberOfSpacesToBeInserted))
      .split('');

    let spacesInserted = 0;

    for (let i = integerPart.length - 1; i >= 0; i--) {
      spacedOutIntegerPart[
        spacedOutIntegerPart.length -
          1 -
          (integerPart.length - 1 - i) -
          spacesInserted
      ] = integerPart[i];

      if ((integerPart.length - 1 - i + 1) % 3 === 0) {
        spacedOutIntegerPart[i - spacesInserted - 1] = ' ';
        spacesInserted++;
      }
    }

    return decimalPart
      ? `${spacedOutIntegerPart.join('')}.${decimalPart}`
      : spacedOutIntegerPart.join('');
  }

  return String(value);
}

export const round = (value: number, places: number) => {
  const multiplier = 10 ** places;
  return Math.round(value * multiplier) / multiplier;
};

export const hashCode = (s: string) =>
  s.split('').reduce((a, b) => ((a << 10) - a + b.charCodeAt(0)) | 0, 0);

export const renderTooltip = (
  icon: any,
  key: string,
  text: any,
  placement?: string,
) => {
  const r = hashCode(key);
  return (
    <S.PopOverInfo>
      <span id={`labelHelper${r}`}>{icon}</span>
      <S.CustomTooltip
        placement={placement || 'top'}
        target={`labelHelper${r}`}
      >
        {text}
      </S.CustomTooltip>
    </S.PopOverInfo>
  );
};

const convertTimestampToDays = (timestamp: any) => {
  return timestamp / 1000 / 60 / 60 / 24;
};

export const getDifferenceInDays = (
  recentTimestamp: any,
  oldTimestamp: any,
) => {
  const recentInDays = convertTimestampToDays(recentTimestamp);
  const oldInDays = convertTimestampToDays(oldTimestamp);
  return Math.round(recentInDays - oldInDays);
};

export const getCountryCodeFromCountryName = (countryName: string) => {
  return (countryNamesToCodes as any)[
    countryName.toLowerCase().replace(' ', '')
  ];
};

export const isUrlValid = (url: string): boolean => {
  const trimmedUrl = url.trim();

  if (trimmedUrl.indexOf(' ') !== -1) {
    return false;
  }

  if (trimmedUrl.match(validUrlRegex) == null) {
    return false;
  }

  return true;
};

export const capitalizeText = (text: string | undefined) => {
  if (!text || text.length < 1) return '';

  const firstCharacter = text[0].toUpperCase();
  return `${firstCharacter}${text.slice(1, text.length)}`;
};

export const validUrlRegex =
  /^(https?:\/\/)?(www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/i;

const countryNamesToCodes = {
  others: 'others',
  afghanistan: 'af',
  ålandislands: 'ax',
  albania: 'al',
  algeria: 'dz',
  americansamoa: 'as',
  andorra: 'ad',
  angola: 'ao',
  anguilla: 'ai',
  antarctica: 'aq',
  'antiguaand barbuda': 'ag',
  argentina: 'ar',
  armenia: 'am',
  aruba: 'aw',
  australia: 'au',
  austria: 'at',
  azerbaijan: 'az',
  bahamas: 'bs',
  bahrain: 'bh',
  bangladesh: 'bd',
  barbados: 'bb',
  belarus: 'by',
  belgium: 'be',
  belize: 'bz',
  benin: 'bj',
  bermuda: 'bm',
  bhutan: 'bt',
  'bolivia(plurinational state of)': 'bo',
  'bonaire,sint eustatius and saba': 'bq',
  'bosniaand herzegovina': 'ba',
  botswana: 'bw',
  bouvetisland: 'bv',
  brazil: 'br',
  'britishindian ocean territory': 'io',
  bruneidarussalam: 'bn',
  bulgaria: 'bg',
  burkinafaso: 'bf',
  burundi: 'bi',
  caboverde: 'cv',
  cambodia: 'kh',
  cameroon: 'cm',
  canada: 'ca',
  caymanislands: 'ky',
  'centralafrican republic': 'cf',
  chad: 'td',
  chile: 'cl',
  china: 'cn',
  christmasisland: 'cx',
  'cocos(keeling) islands': 'cc',
  colombia: 'co',
  comoros: 'km',
  congo: 'cg',
  'congo(democratic republic of the)': 'cd',
  cookislands: 'ck',
  costarica: 'cr',
  "côted'ivoire": 'ci',
  croatia: 'hr',
  cuba: 'cu',
  curaçao: 'cw',
  cyprus: 'cy',
  czechia: 'cz',
  denmark: 'dk',
  djibouti: 'dj',
  dominica: 'dm',
  dominicanrepublic: 'do',
  ecuador: 'ec',
  egypt: 'eg',
  elsalvador: 'sv',
  equatorialguinea: 'gq',
  eritrea: 'er',
  estonia: 'ee',
  ethiopia: 'et',
  'falklandislands (malvinas)': 'fk',
  faroeislands: 'fo',
  fiji: 'fj',
  finland: 'fi',
  france: 'fr',
  frenchguiana: 'gf',
  frenchpolynesia: 'pf',
  'frenchsouthern territories': 'tf',
  gabon: 'ga',
  gambia: 'gm',
  georgia: 'ge',
  germany: 'de',
  ghana: 'gh',
  gibraltar: 'gi',
  greece: 'gr',
  greenland: 'gl',
  grenada: 'gd',
  guadeloupe: 'gp',
  guam: 'gu',
  guatemala: 'gt',
  guernsey: 'gg',
  guinea: 'gn',
  'guinea-bissau': 'gw',
  guyana: 'gy',
  haiti: 'ht',
  'heardisland and mcdonald islands': 'hm',
  holysee: 'va',
  honduras: 'hn',
  hongkong: 'hk',
  hungary: 'hu',
  iceland: 'is',
  india: 'in',
  indonesia: 'id',
  'iran(islamic republic of)': 'ir',
  iraq: 'iq',
  ireland: 'ie',
  'isleof man': 'im',
  israel: 'il',
  italy: 'it',
  jamaica: 'jm',
  japan: 'jp',
  jersey: 'je',
  jordan: 'jo',
  kazakhstan: 'kz',
  kenya: 'ke',
  kiribati: 'ki',
  "korea(democratic people's republic of)": 'kp',
  'korea(republic of)': 'kr',
  kuwait: 'kw',
  kyrgyzstan: 'kg',
  "laopeople's democratic republic": 'la',
  latvia: 'lv',
  lebanon: 'lb',
  lesotho: 'ls',
  liberia: 'lr',
  libya: 'ly',
  liechtenstein: 'li',
  lithuania: 'lt',
  luxembourg: 'lu',
  macao: 'mo',
  northmacedonia: 'mk',
  madagascar: 'mg',
  malawi: 'mw',
  malaysia: 'my',
  maldives: 'mv',
  mali: 'ml',
  malta: 'mt',
  marshallislands: 'mh',
  martinique: 'mq',
  mauritania: 'mr',
  mauritius: 'mu',
  mayotte: 'yt',
  mexico: 'mx',
  'micronesia(federated states of)': 'fm',
  'moldova(republic of)': 'md',
  monaco: 'mc',
  mongolia: 'mn',
  montenegro: 'me',
  montserrat: 'ms',
  morocco: 'ma',
  mozambique: 'mz',
  myanmar: 'mm',
  namibia: 'na',
  nauru: 'nr',
  nepal: 'np',
  netherlands: 'nl',
  newcaledonia: 'nc',
  newzealand: 'nz',
  nicaragua: 'ni',
  niger: 'ne',
  nigeria: 'ng',
  niue: 'nu',
  norfolkisland: 'nf',
  'northernmariana islands': 'mp',
  norway: 'no',
  oman: 'om',
  pakistan: 'pk',
  palau: 'pw',
  'palestine,state of': 'ps',
  panama: 'pa',
  'papuanew guinea': 'pg',
  paraguay: 'py',
  peru: 'pe',
  philippines: 'ph',
  pitcairn: 'pn',
  poland: 'pl',
  portugal: 'pt',
  puertorico: 'pr',
  qatar: 'qa',
  réunion: 're',
  romania: 'ro',
  russianfederation: 'ru',
  rwanda: 'rw',
  saintbarthélemy: 'bl',
  'sainthelena, ascension and tristan da cunha': 'sh',
  'saintkitts and nevis': 'kn',
  saintlucia: 'lc',
  'saintmartin (french part)': 'mf',
  'saintpierre and miquelon': 'pm',
  'saintvincent and the grenadines': 'vc',
  samoa: 'ws',
  sanmarino: 'sm',
  'saotome and principe': 'st',
  saudiarabia: 'sa',
  senegal: 'sn',
  serbia: 'rs',
  seychelles: 'sc',
  sierraleone: 'sl',
  singapore: 'sg',
  'sintmaarten (dutch part)': 'sx',
  slovakia: 'sk',
  slovenia: 'si',
  solomonislands: 'sb',
  somalia: 'so',
  southafrica: 'za',
  'southgeorgia and the south sandwich islands': 'gs',
  southsudan: 'ss',
  spain: 'es',
  srilanka: 'lk',
  sudan: 'sd',
  suriname: 'sr',
  'svalbardand jan mayen': 'sj',
  eswatini: 'sz',
  sweden: 'se',
  switzerland: 'ch',
  'syrianarab republic': 'sy',
  'taiwan,province of china[a]': 'tw',
  tajikistan: 'tj',
  'tanzania,united republic of': 'tz',
  thailand: 'th',
  'timor-leste': 'tl',
  togo: 'tg',
  tokelau: 'tk',
  tonga: 'to',
  'trinidadand tobago': 'tt',
  tunisia: 'tn',
  turkey: 'tr',
  turkmenistan: 'tm',
  'turksand caicos islands': 'tc',
  tuvalu: 'tv',
  uganda: 'ug',
  ukraine: 'ua',
  'unitedarab emirates': 'ae',
  unitedkingdom: 'gb',
  'unitedstates of america': 'us',
  'unitedstates minor outlying islands': 'um',
  uruguay: 'uy',
  uzbekistan: 'uz',
  vanuatu: 'vu',
  'venezuela(bolivarian republic of)': 've',
  vietnam: 'vn',
  'virginislands (british)': 'vg',
  'virginislands (u.s.)': 'vi',
  'wallisand futuna': 'wf',
  westernsahara: 'eh',
  yemen: 'ye',
  zambia: 'zm',
  zimbabwe: 'zw',
};
