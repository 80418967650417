/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ICrossIconV3Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const CrossIconV3 = (props: ICrossIconV3Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.color1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.color1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M25.428 6.572c-5.207-5.207-13.649-5.207-18.856 0s-5.207 13.649 0 18.856c5.207 5.207 13.649 5.207 18.856 0s5.207-13.649 0-18.856zM24.013 24.013c-4.425 4.425-11.601 4.425-16.028 0-4.425-4.427-4.425-11.601 0-16.028s11.601-4.427 16.028 0c4.427 4.427 4.427 11.603 0 16.028zM22.040 11.373l-4.625 4.627 4.627 4.627c0.391 0.391 0.391 1.024 0 1.415s-1.024 0.391-1.415 0l-4.627-4.627-4.627 4.627c-0.391 0.391-1.024 0.391-1.415 0s-0.391-1.024 0-1.415l4.627-4.627-4.625-4.627c-0.391-0.391-0.391-1.024 0-1.415s1.024-0.391 1.415 0l4.627 4.627 4.627-4.627c0.391-0.391 1.024-0.391 1.415 0 0.388 0.392 0.388 1.024-0.003 1.415z"
        ></path>
      </SVG>
    </Container>
  );
};

export default CrossIconV3;
