import {
  useSubmitBenchmark,
  SubmitBenchmarkForm,
  SubmitBenchmarkUsersList,
} from 'features/Benchmark';
import { t } from 'i18next';

import * as S from './Styles';

const SubmitBenchmark = () => {
  const submitBenchmarkHook = useSubmitBenchmark();

  return (
    <S.MainContainer>
      <S.TopContainer>
        <S.GoBackButton onClick={submitBenchmarkHook.handleGoBack}>
          <svg viewBox="0 0 32 32">
            <path d="M29.333 16c0 0.736-0.596 1.333-1.333 1.333h-20.781l8.391 8.391c0.521 0.521 0.521 1.364 0 1.885-0.26 0.26-0.601 0.391-0.943 0.391s-0.683-0.131-0.943-0.391l-10.667-10.667c-0.123-0.123-0.22-0.271-0.288-0.433-0.135-0.327-0.135-0.693 0-1.019 0.068-0.163 0.165-0.311 0.288-0.433l10.667-10.667c0.521-0.521 1.364-0.521 1.885 0s0.521 1.364 0 1.885l-8.391 8.391h20.781c0.737 0 1.333 0.597 1.333 1.333z"></path>
          </svg>
        </S.GoBackButton>

        <S.MainTitle>{t('benchmark.choose5Users')}</S.MainTitle>
      </S.TopContainer>

      <SubmitBenchmarkForm customHook={submitBenchmarkHook} />

      <SubmitBenchmarkUsersList customHook={submitBenchmarkHook} />
    </S.MainContainer>
  );
};

export default SubmitBenchmark;
