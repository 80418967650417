import { t } from 'i18next';

import * as S from './Styles';

type Props = {
  title: string;
  placeholder: string;
  validSearchWord: boolean;
  value: string | undefined;
  setValue: (value: string) => void;
};

export function ListeningFilterTextInput({
  title,
  value,
  setValue,
  placeholder,
  validSearchWord,
}: Props) {
  return (
    <S.InputContainer>
      <S.InputTitle>{t(`discover.${title}`)}</S.InputTitle>

      <input
        type="text"
        value={value}
        name={`filters.${title}`}
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
      />

      {value && value.trim() === '' ? (
        <S.ErrorText>
          * {t(`discover.${title}`) + t('listening.cannotBeEmpty')}
        </S.ErrorText>
      ) : (
        !validSearchWord && (
          <S.ErrorText>
            * {t(`discover.${title}`) + t('listening.shortTerm')}
          </S.ErrorText>
        )
      )}
    </S.InputContainer>
  );
}
