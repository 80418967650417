import { t } from 'i18next';
import Loader from 'react-loader-advanced';
import { FormProvider } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useAdminPartner } from 'features/Admin';
import Button from 'components/shared/Button/Button';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import { IFile } from 'components/shared/FilePickerV2/types';
import ToggleButton from 'components/shared/ToggleButton/ToggleButton';
import FilePickerV2 from 'components/shared/FilePickerV2/FilePickerV2';
import { partnerTypeAvailableValues } from 'features/Discover/data/constants';

import * as S from './Styles';

const AdminPartner = () => {
  const {
    watch,
    errors,
    trigger,
    register,
    setValue,
    setFiles,
    handleGoBack,
    pageTypeLabel,
    adminPartnerForm,
    handleSubmitForm,
    citiesAvailableValues,
    countriesAvailableValues,
    isLoadingGetAdminPartner,
  } = useAdminPartner();

  const type = watch('type');
  const picture = watch('picture');
  const contactCity = watch('contactCity');
  const billingCity = watch('billingCity');
  const contactCountry = watch('contactCountry');
  const billingCountry = watch('billingCountry');
  const billingVatExemption = watch('billingVatExemption');
  const billingToContactAddress = watch('billingToContactAddress');

  return (
    <FormProvider {...adminPartnerForm}>
      <S.Container>
        <S.HeaderSection>
          <S.BackButton onClick={handleGoBack}>
            <svg viewBox="0 0 20 20">
              <path
                d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
                fill="#292929"
              />
            </svg>
          </S.BackButton>

          <S.MainTitle>{pageTypeLabel}</S.MainTitle>
        </S.HeaderSection>

        <S.Card>
          <Loader
            show={isLoadingGetAdminPartner}
            message={t('generic.loading')}
            style={{ margin: '-3rem', padding: '3rem' }}
          >
            <>
              <S.FormSectionBlock>
                <S.FormSectionTitle>
                  {t('skorrAdmin.partner.partnerDetails')}
                </S.FormSectionTitle>

                <S.Row>
                  <S.FormGroup>
                    <S.FormGroupLabel>
                      {t('skorrAdmin.partner.commercialName')}
                    </S.FormGroupLabel>

                    <S.Input
                      type="text"
                      {...register('name')}
                      placeholder={t('skorrAdmin.partner.commercialName')}
                    />
                    {errors.name && (
                      <S.ErrorText>*{errors.name.message}</S.ErrorText>
                    )}
                  </S.FormGroup>

                  <S.FormGroup>
                    <S.FormGroupLabel>
                      {t('skorrAdmin.partner.companyEmail')}
                    </S.FormGroupLabel>

                    <S.Input
                      type="text"
                      {...register('email')}
                      placeholder={t('skorrAdmin.partner.companyEmail')}
                    />
                    {errors.email && (
                      <S.ErrorText>*{errors.email.message}</S.ErrorText>
                    )}
                  </S.FormGroup>

                  <S.FormGroup>
                    <S.FormGroupLabel>
                      {t('skorrAdmin.partner.type')}
                    </S.FormGroupLabel>

                    <ComboBox
                      type="single"
                      width="25rem"
                      height="4.2rem"
                      value={type}
                      valueKey="type"
                      placeholder={t('skorrAdmin.partner.typePlaceholder')}
                      isClearable={true}
                      isFilterable={false}
                      valuesAvailable={partnerTypeAvailableValues}
                      onChange={(obj: any) => {
                        setValue('type', obj.type);
                        trigger('type');
                      }}
                      optionContainerBorderRadius={'0.6rem'}
                      innerOptionsContainerPaddingTop={'1.2rem'}
                      innerOptionsContainerPaddingBottom={'1.2rem'}
                      innerOptionsContainerPaddingLeft={'1.2rem'}
                      innerOptionsContainerPaddingRight={'1.2rem'}
                      optionContainerPaddingTop={'1rem'}
                      optionContainerPaddingBottom={'1rem'}
                      optionContainerPaddingLeft={'1rem'}
                      optionContainerPaddingRight={'1rem'}
                      changeBackgroundColorOnHover={false}
                    />
                    {errors.type && (
                      <S.ErrorText>*{errors.type.message}</S.ErrorText>
                    )}
                  </S.FormGroup>

                  <S.FormGroup>
                    <S.FormGroupLabel>
                      {t('skorrAdmin.partner.choosePicture')}
                    </S.FormGroupLabel>

                    <S.FilePickerContainer>
                      <FilePickerV2
                        single={true}
                        type={'images'}
                        initialImages={[picture!]}
                        showSelectedFiles={false}
                        buttonBorderRadius={'5rem'}
                        text={t('generic.selectPicture')}
                        handleChange={(files: IFile[]) => {
                          if (files.length) {
                            setFiles(files);
                          } else {
                            setFiles([]);
                          }
                        }}
                      />
                    </S.FilePickerContainer>
                  </S.FormGroup>

                  {type !== 'INFLUENCER' && (
                    <S.FormGroup>
                      <S.FormGroupLabel>
                        {t('skorrAdmin.partner.billingDiff')}
                      </S.FormGroupLabel>

                      <ToggleButton
                        width="3rem"
                        height="auto"
                        checked={billingToContactAddress!}
                        setChecked={() =>
                          setValue(
                            'billingToContactAddress',
                            !billingToContactAddress,
                          )
                        }
                      />
                    </S.FormGroup>
                  )}
                </S.Row>
              </S.FormSectionBlock>

              {type !== 'INFLUENCER' && (
                <>
                  <S.FormSectionBlock>
                    <S.FormSectionTitle>
                      {t('skorrAdmin.partner.legalDetails')}
                    </S.FormSectionTitle>

                    <S.Row>
                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.person1')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactManager1')}
                          placeholder={t('skorrAdmin.partner.person1')}
                        />
                        {errors.contactManager1 && (
                          <S.ErrorText>
                            *{errors.contactManager1.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.person2')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactManager2')}
                          placeholder={t('skorrAdmin.partner.person2')}
                        />
                        {errors.contactManager2 && (
                          <S.ErrorText>
                            *{errors.contactManager2.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.businessDescription')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactDescription')}
                          placeholder={t(
                            'skorrAdmin.partner.businessDescription',
                          )}
                        />
                        {errors.contactDescription && (
                          <S.ErrorText>
                            *{errors.contactDescription.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.mainBrand')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactBrand')}
                          placeholder={t('skorrAdmin.partner.mainBrand')}
                        />
                        {errors.contactBrand && (
                          <S.ErrorText>
                            *{errors.contactBrand.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>
                    </S.Row>
                  </S.FormSectionBlock>

                  <S.FormSectionBlock>
                    <S.FormSectionTitle>
                      {t('skorrAdmin.partner.headOfficeDetails')}
                    </S.FormSectionTitle>

                    <S.Row>
                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.companyName')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('companyName')}
                          placeholder={t('skorrAdmin.partner.companyName')}
                        />
                        {errors.companyName && (
                          <S.ErrorText>
                            *{errors.companyName.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.address1')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactAddress1')}
                          placeholder={t('skorrAdmin.partner.address1')}
                        />
                        {errors.contactAddress1 && (
                          <S.ErrorText>
                            *{errors.contactAddress1.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.address2')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactAddress2')}
                          placeholder={t('skorrAdmin.partner.address2')}
                        />
                        {errors.contactAddress2 && (
                          <S.ErrorText>
                            *{errors.contactAddress2.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.postalCode')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactPostalCode')}
                          placeholder={t('skorrAdmin.partner.postalCode')}
                        />
                        {errors.contactPostalCode && (
                          <S.ErrorText>
                            *{errors.contactPostalCode.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.country')}
                        </S.FormGroupLabel>

                        <ComboBox
                          type="single"
                          width="25rem"
                          height="4.2rem"
                          value={contactCountry}
                          valueKey="contactCountry"
                          placeholder={t('skorrAdmin.partner.selectCountry')}
                          isClearable={true}
                          isFilterable={true}
                          valuesAvailable={countriesAvailableValues}
                          onChange={(obj: any) => {
                            if (obj.contactCountry?.value) {
                              setValue('contactCountry', obj.contactCountry);
                              trigger('contactCountry');
                            }
                          }}
                          optionContainerBorderRadius={'0.6rem'}
                          innerOptionsContainerPaddingTop={'1.2rem'}
                          innerOptionsContainerPaddingBottom={'1.2rem'}
                          innerOptionsContainerPaddingLeft={'1.2rem'}
                          innerOptionsContainerPaddingRight={'1.2rem'}
                          optionContainerPaddingTop={'1rem'}
                          optionContainerPaddingBottom={'1rem'}
                          optionContainerPaddingLeft={'1rem'}
                          optionContainerPaddingRight={'1rem'}
                          changeBackgroundColorOnHover={false}
                        />
                        {errors.contactCountry && (
                          <S.ErrorText>
                            *{errors.contactCountry.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.city')}
                        </S.FormGroupLabel>

                        <ComboBox
                          type="single"
                          width="25rem"
                          height="4.2rem"
                          value={contactCity}
                          valueKey="contactCity"
                          placeholder={t('skorrAdmin.partner.selectCity')}
                          isClearable={true}
                          isFilterable={true}
                          valuesAvailable={citiesAvailableValues(
                            contactCountry?.data?.country,
                          )}
                          onChange={(obj: any) => {
                            if (obj.contactCity?.value) {
                              setValue('contactCity', obj.contactCity);
                              trigger('contactCity');
                            }
                          }}
                          optionContainerBorderRadius={'0.6rem'}
                          innerOptionsContainerPaddingTop={'1.2rem'}
                          innerOptionsContainerPaddingBottom={'1.2rem'}
                          innerOptionsContainerPaddingLeft={'1.2rem'}
                          innerOptionsContainerPaddingRight={'1.2rem'}
                          optionContainerPaddingTop={'1rem'}
                          optionContainerPaddingBottom={'1rem'}
                          optionContainerPaddingLeft={'1rem'}
                          optionContainerPaddingRight={'1rem'}
                          changeBackgroundColorOnHover={false}
                        />
                        {errors.contactCity && (
                          <S.ErrorText>
                            *{errors.contactCity.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.vatId')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactVat_id')}
                          placeholder={t('skorrAdmin.partner.vatId')}
                        />
                        {errors.contactVat_id && (
                          <S.ErrorText>
                            *{errors.contactVat_id.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.webSite')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('contactWebSite')}
                          placeholder={t('skorrAdmin.partner.webSite')}
                        />
                        {errors.contactWebSite && (
                          <S.ErrorText>
                            *{errors.contactWebSite.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>
                    </S.Row>
                  </S.FormSectionBlock>

                  <S.FormSectionBlock>
                    <S.FormSectionTitle>
                      {t('skorrAdmin.partner.billingDetails')}
                    </S.FormSectionTitle>

                    <S.Row>
                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.companyName')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('billingCompanyName')}
                          placeholder={t('skorrAdmin.partner.companyName')}
                        />
                        {errors.billingCompanyName && (
                          <S.ErrorText>
                            *{errors.billingCompanyName.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.address1')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('billingAddress1')}
                          placeholder={t('skorrAdmin.partner.address1')}
                        />
                        {errors.billingAddress1 && (
                          <S.ErrorText>
                            *{errors.billingAddress1.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.address2')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('billingAddress2')}
                          placeholder={t('skorrAdmin.partner.address2')}
                        />
                        {errors.billingAddress2 && (
                          <S.ErrorText>
                            *{errors.billingAddress2.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.postalCode')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('billingPostalCode')}
                          placeholder={t('skorrAdmin.partner.postalCode')}
                        />
                        {errors.billingPostalCode && (
                          <S.ErrorText>
                            *{errors.billingPostalCode.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.country')}
                        </S.FormGroupLabel>

                        <ComboBox
                          type="single"
                          width="25rem"
                          height="4.2rem"
                          value={billingCountry}
                          valueKey="billingCountry"
                          placeholder={t('skorrAdmin.partner.selectCountry')}
                          isClearable={true}
                          isFilterable={true}
                          valuesAvailable={countriesAvailableValues}
                          onChange={(obj: any) => {
                            if (obj.billingCountry?.value) {
                              setValue('billingCountry', obj.billingCountry);
                              trigger('billingCountry');
                            }
                          }}
                          optionContainerBorderRadius={'0.6rem'}
                          innerOptionsContainerPaddingTop={'1.2rem'}
                          innerOptionsContainerPaddingBottom={'1.2rem'}
                          innerOptionsContainerPaddingLeft={'1.2rem'}
                          innerOptionsContainerPaddingRight={'1.2rem'}
                          optionContainerPaddingTop={'1rem'}
                          optionContainerPaddingBottom={'1rem'}
                          optionContainerPaddingLeft={'1rem'}
                          optionContainerPaddingRight={'1rem'}
                          changeBackgroundColorOnHover={false}
                        />
                        {errors.billingCountry && (
                          <S.ErrorText>
                            *{errors.billingCountry.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.city')}
                        </S.FormGroupLabel>

                        <ComboBox
                          type="single"
                          width="25rem"
                          height="4.2rem"
                          value={billingCity}
                          valueKey="billingCity"
                          placeholder={t('skorrAdmin.partner.selectCity')}
                          isClearable={true}
                          isFilterable={true}
                          valuesAvailable={citiesAvailableValues(
                            billingCountry?.data?.country,
                          )}
                          onChange={(obj: any) => {
                            if (obj.billingCity?.value) {
                              setValue('billingCity', obj.billingCity);
                              trigger('billingCity');
                            }
                          }}
                          optionContainerBorderRadius={'0.6rem'}
                          innerOptionsContainerPaddingTop={'1.2rem'}
                          innerOptionsContainerPaddingBottom={'1.2rem'}
                          innerOptionsContainerPaddingLeft={'1.2rem'}
                          innerOptionsContainerPaddingRight={'1.2rem'}
                          optionContainerPaddingTop={'1rem'}
                          optionContainerPaddingBottom={'1rem'}
                          optionContainerPaddingLeft={'1rem'}
                          optionContainerPaddingRight={'1rem'}
                          changeBackgroundColorOnHover={false}
                        />
                        {errors.billingCity && (
                          <S.ErrorText>
                            *{errors.billingCity.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.vatExemption')}
                        </S.FormGroupLabel>

                        <ToggleButton
                          width="3rem"
                          checked={billingVatExemption!}
                          setChecked={() =>
                            setValue(
                              'billingVatExemption',
                              !billingVatExemption,
                            )
                          }
                        />
                      </S.FormGroup>

                      <S.FormGroup>
                        <S.FormGroupLabel>
                          {t('skorrAdmin.partner.vatId')}
                        </S.FormGroupLabel>

                        <S.Input
                          type="text"
                          {...register('billingVat_id')}
                          placeholder={t('skorrAdmin.partner.vatId')}
                        />
                        {errors.billingVat_id && (
                          <S.ErrorText>
                            *{errors.billingVat_id.message}
                          </S.ErrorText>
                        )}
                      </S.FormGroup>
                    </S.Row>
                  </S.FormSectionBlock>
                </>
              )}

              <S.FooterContainer>
                <Button
                  marginLeft="auto"
                  borderRadius="3rem"
                  onClick={handleSubmitForm}
                >
                  {pageTypeLabel}
                </Button>
              </S.FooterContainer>
            </>
          </Loader>
        </S.Card>
      </S.Container>
    </FormProvider>
  );
};

export default withTranslation()(AdminPartner);
