import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';

type Props = {
  title: string;
  placeholder: string;
  value: string | number | readonly string[] | undefined;
};

const DiscoverToolBarFilterSideBarTextInput = ({
  title,
  value,
  placeholder,
}: Props) => {
  const {
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <S.ToolBarFilterSideBarInputContainer>
      <S.ToolBarFilterSideBarInputTitle>
        {t(`discover.${title}`)}
      </S.ToolBarFilterSideBarInputTitle>

      <input
        type="text"
        value={value}
        name={`filters.${title}`}
        placeholder={placeholder}
        onChange={e => {
          setValue(`filters.${title}`, [e.target.value]);
          trigger(`filters.${title}`);
        }}
      />

      {errors.filters?.[title as keyof unknown] && (
        <S.ToolBarFilterSideBarErrorText>
          * {errors.filters?.[title as keyof unknown]?.['message' as any]}
        </S.ToolBarFilterSideBarErrorText>
      )}
    </S.ToolBarFilterSideBarInputContainer>
  );
};

export default withTranslation()(DiscoverToolBarFilterSideBarTextInput);
