import styled from 'styled-components';

export const EmptyState = styled.div`
  height: 50rem;
  display: flex;
  margin-top: 5rem;
  align-items: center;
  border-radius: 1.2rem;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 0.1rem solid #e0e5ea;
`;

export const EmptyStateIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  justify-content: center;

  svg {
    width: 8rem;
    height: 8rem;
    fill: #8492a6;
    vertical-align: middle;
  }
`;

export const EmptyStateTitle = styled.h1`
  color: #292929;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
`;

export const EmptyStateDescription = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.1rem;
  margin-bottom: 5rem;
`;
