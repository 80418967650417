import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AdminPartnersListFilters } from '../types';
import { useGetAdminPartnerAgents, useValidateAdminPartnerAgent } from '../api';

export const useAdminAgentsList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: partnerId } = useParams();
  const { state } = location || {};
  const { filters }: { filters: AdminPartnersListFilters } = state || {};

  const { data: adminAgents, isLoading: isLoadingAdminAgents } =
    useGetAdminPartnerAgents(partnerId!);

  const { mutateAsync: validateAdminAgent } = useValidateAdminPartnerAgent();

  const handleValidateAdminAgent = useCallback(
    async (active: boolean, agentId: string) => {
      await validateAdminAgent({
        active,
        agentId,
        partnerId: partnerId!,
      });
    },
    [validateAdminAgent, partnerId],
  );

  const handleGoBack = useCallback(() => {
    navigate('/admin/partners-list', {
      state: {
        filters,
      },
    });
  }, [navigate, filters]);

  const handleGoToCreateAgent = useCallback(() => {
    navigate('/admin/create-agent', {
      state: {
        partnerId,
      },
    });
  }, [navigate]);

  const handleGoToEditAgent = useCallback(
    (agentId: string, partnerId: string) => {
      navigate(`/admin/edit-agent/${agentId}`, {
        state: {
          partnerId,
        },
      });
    },
    [navigate],
  );

  return {
    adminAgents,
    handleGoBack,
    handleGoToEditAgent,
    isLoadingAdminAgents,
    handleGoToCreateAgent,
    handleValidateAdminAgent,
  };
};
