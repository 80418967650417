import styled from 'styled-components';

export const Card = styled.div`
  overflow: hidden;
  margin-top: 3rem;
  background: white;
  border-radius: 2rem;
  border: 0.1rem solid #eee;
`;

export const MainRow = styled.div`
  display: flex;
  padding: 3rem;
  height: 10.5rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const AvatarsContainer = styled.div`
  display: flex;
`;

export const AvatarImg = styled.img`
  width: 4.4rem;
  height: 4.4rem;
  margin-left: -1rem;
  border-radius: 50%;
  border: 0.4rem solid white;

  &:first-child {
    margin-left: 0;
  }
`;

export const LabelIconContainer = styled.div<{ active: boolean }>`
  display: flex;
  color: #676767;
  font-weight: 400;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: #676767;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
    transition: transform 1s;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : undefined)};
  }
`;

export const CompetitorsContainer = styled.div<{
  expanded: boolean;
  numOfUsers: number;
}>`
  overflow: hidden;
  transition: all 1s ease;
  opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  max-height: ${({ expanded, numOfUsers }) =>
    expanded ? `${numOfUsers * 105}px` : '0'};
`;

export const CompetitorList = styled.div`
  display: flex;
  padding: 3rem;
  align-items: center;
  border-top: 0.1rem solid #eee;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #f9fbff;
  }
`;

export const CompetitorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CompetitorAvatarContainer = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    padding: 0.2rem;
    margin-left: -1.5rem;
    border-radius: 0.6rem;
    margin-bottom: -0.2rem;
    background-color: white;
  }
`;

export const CompetitorAvatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
  border: 0.3rem solid white;
`;

export const CompetitorNameNetworkContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
`;

export const CompetitorUsername = styled.div`
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
`;

export const CompetitorNetwork = styled.div`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const RemoveContainer = styled.div`
  gap: 1rem;
  display: flex;
  color: #676767;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  align-items: center;

  svg {
    fill: #676767;
    width: 2rem;
    height: 2rem;
  }
`;
