import { t } from 'i18next';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type Props = {
  customHook: any;
  sortByValues: Record<string, string>;
};

export function ListeningFilterSortByButton({
  customHook,
  sortByValues,
}: Props) {
  const {
    sortByField,
    toggleSortByButton,
    handleChangeSortBy,
    handleToggleSortByButton,
    handleClickOutToggleSortByButton,
  } = customHook;

  return (
    <S.Container>
      <S.SortButton
        active={toggleSortByButton}
        onClick={handleToggleSortByButton}
      >
        {t('listening.sortBy') + t(`listening.sortByField.${sortByField}`)}
        <svg version="1.1" viewBox="0 0 32 32">
          <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
        </svg>
      </S.SortButton>

      <ClickOutHandler onClickOut={handleClickOutToggleSortByButton}>
        <S.DropdownContainer active={toggleSortByButton}>
          {Object.values(sortByValues).map(item => {
            return (
              <S.DropdownItemContainer
                key={item}
                active={sortByField === item}
                onClick={() => handleChangeSortBy(item)}
              >
                <S.DropdownItemText>
                  {t(`listening.sortByField.${item}`)}
                </S.DropdownItemText>
              </S.DropdownItemContainer>
            );
          })}
        </S.DropdownContainer>
      </ClickOutHandler>
    </S.Container>
  );
}
