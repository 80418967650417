/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { AxiosInstance } from 'axios';

import config from '../../../config';
import * as T from './types';
import {} from './types';

const createSharesInstanceApi = (instance: AxiosInstance) => {
  const getSharedSocialDataAccess =
    async (): Promise<T.GetSharedSocialDataAccessOutput> => {
      const url = config.ENDPOINTS.SHARES.GET_SHARED_SOCIAL_DATA_ACCESS;

      const { data: result } = await instance.get(url);

      return result;
    };

  const revokeSocialDataAccess = async (
    data: T.RevokeSocialDataAccessInput,
  ): Promise<Boolean> => {
    const url = config.ENDPOINTS.SHARES.REVOKE_SOCIAL_DATA_ACCESS;

    const { data: result } = await instance.patch(url, data);

    return result;
  };

  return {
    getSharedSocialDataAccess,
    revokeSocialDataAccess,
  };
};

export default {
  createSharesInstanceApi,
};
