import * as S from './Styles';

const BenchmarksListSkeleton = () => {
  return (
    <S.SkeletonContainer>
      {Array.from({ length: 9 }).map((_, index) => (
        <S.SkeletonItem key={index}>
          <S.SkeletonBlockContainer>
            <S.SkeletonBlock width={23} />
            <S.SkeletonBlock width={12} />
          </S.SkeletonBlockContainer>

          <S.SkeletonAvatarContainer>
            {Array.from({ length: 2 }).map((_, index) => (
              <S.SkeletonAvatar key={index} />
            ))}
          </S.SkeletonAvatarContainer>

          <S.SkeletonTimeframe />

          <S.SkeletonBlockContainer custom>
            <S.SkeletonBlock width={6} />
            <S.SkeletonBlock width={10} />
          </S.SkeletonBlockContainer>

          <S.SkeletonActionsContainer>
            <S.SkeletonAvatar />
            <S.SkeletonAvatar />
          </S.SkeletonActionsContainer>
        </S.SkeletonItem>
      ))}
    </S.SkeletonContainer>
  );
};

export default BenchmarksListSkeleton;
