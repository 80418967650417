import i18n from 'i18n/i18n';
import { GENDERS } from 'Services/Utils/types';

export const gendersAvailableValues = [
  {
    value: GENDERS.MALE,
    label: i18n.t('userProfile.male'),
  },
  {
    value: GENDERS.FEMALE,
    label: i18n.t('userProfile.female'),
  },
  {
    value: GENDERS.OTHER,
    label: i18n.t('userProfile.other'),
  },
];

export const categoriesAvailableValues = [
  { value: 'BRAND', label: i18n.t('discover.categories.BRAND') },
  { value: 'BLOG', label: i18n.t('discover.categories.BLOG') },
  { value: 'INFLUENCER', label: i18n.t('discover.categories.INFLUENCER') },
  { value: 'SOCIALMEDIA', label: i18n.t('discover.categories.SOCIALMEDIA') },
];

export const partnerTypeAvailableValues = [
  { value: 'BRAND', label: i18n.t('partnerTypes.brand') },
  { value: 'AGENCY', label: i18n.t('partnerTypes.agency') },
  { value: 'INFLUENCER', label: i18n.t('partnerTypes.influencer') },
];

export const agentRoleIdsAvailableValues = [
  { value: '5bcd04f00000000000000000', label: i18n.t('roles.superUser') },
  { value: '5bcd04f00000000000000001', label: i18n.t('roles.partnerAdmin') },
  { value: '5bcd04f00000000000000002', label: i18n.t('roles.agentAdmin') },
  { value: '5bcd04f00000000000000003', label: i18n.t('roles.agentOperator') },
  { value: '5bcd04f00000000000000004', label: i18n.t('roles.agentViewer') },
  { value: '60e2e076222a2e37007edc17', label: i18n.t('roles.campaignViewer') },
];
