import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% { background-position: -20rem 0; }
  100% { background-position: 20rem 0; }
`;

export const ShimmerWrapper = styled.div`
  gap: 1.6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  padding: 16px;
  display: flex;
  height: 52.4rem;
  align-items: center;
  border-radius: 2rem;
  background: #e0e0e0;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 calc(25% - 1.2rem);
  max-width: calc(25% - 1.2rem);

  @media (max-width: 1250px) {
    flex: 0 0 calc(33% - 0.8rem);
    max-width: calc(33% - 0.8rem);
  }

  @media (max-width: 1000px) {
    flex: 0 0 calc(50% - 0.8rem);
    max-width: calc(50% - 0.8rem);
  }

  @media (max-width: 650px) {
    flex: 0 0 calc(100% - 0.8rem);
    max-width: calc(100% - 0.8rem);
  }
`;

export const ShimmerBlock = styled.div<{ height?: string }>`
  width: 100%;
  border-radius: 0.8rem;
  background-size: 200% 100%;
  height: ${({ height }) => height || '2rem'};
  animation: ${shimmer} 1.5s infinite linear;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
`;
