import styled from 'styled-components';

export const Nav = styled.nav`
  gap: 1rem;
  display: flex;
  margin: 2rem 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const IconButton = styled.button<{ disabled: boolean }>`
  all: unset;
  width: 3.6rem;
  height: 3.6rem;
  color: #292929;
  text-align: center;
  transition: all 0.3s;
  border-radius: 0.6rem;
  background-color: transparent;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: #eee;
    box-shadow: 0 0 0 0.25rem #eee;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem #292929;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const PageButton = styled.button<{ active?: boolean }>`
  all: unset;
  width: 3.6rem;
  height: 3.6rem;
  color: #292929;
  text-align: center;
  transition: all 0.3s;
  border-radius: 0.6rem;
  background: transparent;
  box-shadow: ${({ active }) => (active ? '0 0 0 0.25rem #292929' : 'none')};
  cursor: pointer;

  &:hover {
    background: #eee;
    box-shadow: 0 0 0 0.25rem ${({ active }) => (active ? '#292929' : '#eee')};
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem #292929;
  }
`;
