import { t } from 'i18next';
import moment from 'moment';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';
import { BenchmarksListHookProps } from 'features/Benchmark/hooks/use-benchmarks-list';

import * as S from './Styles';
import BenchmarkListDropdown from '../BenchmarkListDropdown';

type Props = {
  customHook: BenchmarksListHookProps;
};

const BenchmarkList = ({ customHook }: Props) => {
  const { benchmarks, handleGoToViewBenchmark } = customHook;

  return (
    <S.Card>
      {benchmarks.map((bench, index) => {
        const lastItem = benchmarks.length - 1 === index;

        return (
          <div key={index}>
            <S.ItemContainer onClick={() => handleGoToViewBenchmark(bench)}>
              <S.ItemNameDateContainer>
                <S.ItemName>{bench.benchmarkInfo.name}</S.ItemName>

                <S.ItemDate>
                  {moment(bench.benchmarkInfo.createdAt).fromNow()}
                </S.ItemDate>
              </S.ItemNameDateContainer>

              <S.ItemAvatars>
                {bench.benchmarkInfo.usersBasicInfo.map((user, index) => {
                  const lastUser =
                    bench.benchmarkInfo.usersBasicInfo.length - 1 === index;

                  return (
                    <S.ItemAvatarContainer key={user.id}>
                      <S.ItemAvatarImg src={user.image} />

                      {lastUser &&
                        getSocialNetworkIcon({
                          width: '1.5rem',
                          height: '1.5rem',
                          gradientColored: true,
                          socialNetwork: user.network ?? '',
                        })}
                    </S.ItemAvatarContainer>
                  );
                })}
              </S.ItemAvatars>

              <S.ItemTimeFrame show={!!bench.benchmarkInfo.startAt}>
                <S.ItemTimeFrameText>{`${moment(
                  bench.benchmarkInfo.startAt,
                ).format('MMM D')} - ${moment(
                  bench.benchmarkInfo.endAt ?? new Date(),
                ).format('ll')}`}</S.ItemTimeFrameText>
              </S.ItemTimeFrame>

              <S.ItemCreatedAtContainer>
                <S.ItemCreatedAtLabel>
                  {t('benchmark.list.createdAtLabel')}
                </S.ItemCreatedAtLabel>

                <S.ItemCreatedAtDate>
                  {moment(bench.benchmarkInfo.createdAt).format('ll')}
                </S.ItemCreatedAtDate>
              </S.ItemCreatedAtContainer>

              <S.ItemActionsContainer>
                <BenchmarkListDropdown
                  index={index}
                  benchmark={bench}
                  customHook={customHook}
                />

                <S.ItemIconButton>
                  <svg viewBox="0 0 32 32">
                    <path d="M12.8 23.9c-0.384 0-0.768-0.147-1.060-0.44-0.585-0.585-0.585-1.535 0-2.121l5.339-5.339-5.34-5.34c-0.585-0.585-0.585-1.535 0-2.121s1.535-0.585 2.121 0l6.4 6.4c0.281 0.281 0.44 0.663 0.44 1.060s-0.159 0.779-0.44 1.060l-6.4 6.4c-0.292 0.295-0.676 0.441-1.060 0.441z" />
                  </svg>
                </S.ItemIconButton>
              </S.ItemActionsContainer>
            </S.ItemContainer>

            {!lastItem && <S.ItemDivider />}
          </div>
        );
      })}
    </S.Card>
  );
};

export default BenchmarkList;
