import { t } from 'i18next';
import { SearchesLocalStorage } from 'features/Listening/types';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  customHook: any;
};

export function ListeningPreviousSearches({ customHook }: Props) {
  const {
    previousSearchesCount,
    storedPreviousSearches,
    handleGoToTermAnalytics,
    handleLoadMorePreviousSearches,
  } = customHook;

  return (
    <>
      {storedPreviousSearches().length > 0 && (
        <>
          <S.Title>{t('listening.previousSearches')}</S.Title>

          <S.TableContainer>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('listening.topic')}</th>
                  <th>{t('listening.sortByField.creators')}</th>
                  <th>{t('listening.sortByField.posts')}</th>
                  <th>{t('listening.sortByField.avgEng')}</th>
                </tr>
              </thead>

              <tbody>
                {storedPreviousSearches()
                  .slice(0, previousSearchesCount)
                  .map((item: SearchesLocalStorage, index: number) => (
                    <tr
                      key={index}
                      onClick={() => handleGoToTermAnalytics(item)}
                    >
                      <td>{index + 1}</td>
                      <td>{item.term}</td>
                      <td>
                        {convertNumberToHumanStringfiedFormat(
                          Math.round(item.numUsers),
                        )}
                      </td>
                      <td>
                        {convertNumberToHumanStringfiedFormat(
                          Math.round(item.numPosts),
                        )}
                      </td>
                      <td>
                        {convertNumberToHumanStringfiedFormat(
                          Math.round(item.avgEng),
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </S.TableContainer>

          {previousSearchesCount < storedPreviousSearches().length && (
            <S.LoadMoreContainer>
              <S.LoadMoreButton onClick={handleLoadMorePreviousSearches}>
                {t('listening.loadMore')}
              </S.LoadMoreButton>
            </S.LoadMoreContainer>
          )}
        </>
      )}
    </>
  );
}
