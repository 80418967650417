import SkorrApi from 'Services/SkorrApi/new';
import { IErrorObject } from 'Services/Utils/types';
import { logApiRequestError } from 'Services/Utils/Utils';
import { transformPosts } from 'Services/Utils/transform-posts';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  GetBenchmarksRequest,
  CreateBenchmarkRequest,
  UpdateBenchmarkRequest,
  SearchUserBenchmarkRequest,
} from './types';

const { user } = SkorrApi;

export const useGetBenchmarks = (input: GetBenchmarksRequest) => {
  return useQuery(
    ['get-benchmarks', { page: input.page, name: input.name }],
    async () => {
      try {
        const data = await user.getBenchmarks(input);

        const result = data.map(item => {
          const posts = transformPosts(item.postsFeed);

          return {
            benchmarkInfo: item.benchmarkInfo,
            kpisOverview: item.kpisOverview,
            postsFeed: posts,
          };
        });

        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useGetTotalBenchmarks = (name?: string) => {
  return useQuery(
    ['get-total-benchmarks', { name }],
    async () => {
      try {
        const data = await user.getTotalBenchmarks(name);

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useSearchUserBenchmark = () => {
  return useMutation(
    async (params: SearchUserBenchmarkRequest) => {
      try {
        const response = await user.searchUserBenchmark(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useCreateBenchmarkApi = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: CreateBenchmarkRequest) => {
      try {
        const response = await user.createBenchmark(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-benchmarks', { page: 0 }],
        });
      },
    },
  );
};

export const useUpdateBenchmark = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateBenchmarkRequest) => {
      try {
        const response = await user.updateBenchmark(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-benchmarks', { page: 0 }],
        });
      },
    },
  );
};

export const useDeleteBenchmark = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      try {
        const response = await user.deleteBenchmark(id);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-benchmarks', { page: 0 }],
        });
      },
    },
  );
};
