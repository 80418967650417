/* eslint-disable no-empty-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import GenericModal from 'components/shared/GenericModal/GenericModal';
import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { SocialNetworks } from 'Services/Utils/types';

import * as S from './Styles';
import { OfficialAccountSynchronizationFailedProps } from './types';

const OfficialAccountSynchronizationFailedModal = (
  props: OfficialAccountSynchronizationFailedProps,
) => {
  const getTitle = useCallback(() => {
    if (props.socialNetwork == SocialNetworks.INSTAGRAM) {
      return props.t('socialAccountManagement.instagramAccountSyncFailedTitle');
    }

    return props.t('socialAccountManagement.tiktokAccountSyncFailedTitle');
  }, [props.socialNetwork]);

  const getBody = useCallback(() => {
    if (props.socialNetwork == SocialNetworks.INSTAGRAM) {
      return (
        <S.SocialNetworkAccountSyncErrorBulletPointsContainer>
          <S.SocialNetworkAccountSyncErrorBulletPoint>
            <S.SocialNetworkAccountSyncErrorDotContainer>
              <S.SocialNetworkAccountSyncErrorDot />
            </S.SocialNetworkAccountSyncErrorDotContainer>

            <S.SocialNetworkAccountSyncErrorBulletPointContent>
              {[...new Array(9)]
                .map((_, i) => i + 1)
                .map(item => {
                  const tagName = [6, 7, 8].includes(item) ? 'b' : 'span';

                  return React.createElement(
                    tagName,
                    {},
                    props.t(
                      `socialAccountManagement.instagramAccountSyncFailedDescriptionFirstParagraphPart${item}`,
                    ),
                  );
                })}
            </S.SocialNetworkAccountSyncErrorBulletPointContent>
          </S.SocialNetworkAccountSyncErrorBulletPoint>

          <S.SocialNetworkAccountSyncErrorBulletPoint>
            <S.SocialNetworkAccountSyncErrorDotContainer>
              <S.SocialNetworkAccountSyncErrorDot />
            </S.SocialNetworkAccountSyncErrorDotContainer>

            <S.SocialNetworkAccountSyncErrorBulletPointContent>
              {[...new Array(7)]
                .map((_, i) => i + 1)
                .map(item => {
                  const tagName = item == 2 ? 'b' : 'span';

                  return React.createElement(
                    tagName,
                    {},
                    props.t(
                      `socialAccountManagement.instagramAccountSyncFailedDescriptionSecondParagraphPart${item}`,
                    ),
                  );
                })}
            </S.SocialNetworkAccountSyncErrorBulletPointContent>
          </S.SocialNetworkAccountSyncErrorBulletPoint>
        </S.SocialNetworkAccountSyncErrorBulletPointsContainer>
      );
    }

    return (
      <S.SocialNetworkAccountSyncErrorBulletPointsContainer>
        <S.SocialNetworkAccountSyncErrorBulletPoint>
          <S.SocialNetworkAccountSyncErrorBulletPointContent>
            {[...new Array(9)]
              .map((_, i) => i + 1)
              .map(item => {
                const tagName = item == 2 ? 'b' : 'span';

                return React.createElement(
                  tagName,
                  {},
                  props.t(
                    `socialAccountManagement.tiktokAccountSyncFailedDescriptionPart${item}`,
                  ),
                );
              })}
          </S.SocialNetworkAccountSyncErrorBulletPointContent>
        </S.SocialNetworkAccountSyncErrorBulletPoint>
      </S.SocialNetworkAccountSyncErrorBulletPointsContainer>
    );
  }, [props.socialNetwork]);

  return (
    <GenericModal
      show={true}
      title={getTitle()}
      body={getBody()}
      modalWidth={'80rem'}
      primaryButtonText={props.t('generic.gotIt')}
      primaryButtonAction={() => props.modalCloseActionInvoked()}
    />
  );
};

export default withTranslation()(OfficialAccountSynchronizationFailedModal);
