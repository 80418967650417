import styled, { css } from 'styled-components';

import { IBusinessAccountWebsiteInputContainerProps } from './types';
import SkorrOnboardingSecondBackgroundImage from '../../assets/skorr-onboarding-elements.png';

export const Container = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  overflow-y: overlay;
  overflow-x: hidden;
  max-height: 100vh;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background: ${props =>
      props.theme.container['scrollbar-track-background-color']};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.container['scrollbar-thumb-background-color']};
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props =>
      props.theme.container['scrollbar-thumb-hover-background-color']};
  }

  scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']}
    ${props => props.theme.container['scrollbar-track-background-color']};
  scrollbar-width: thin;

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const BackgroundImageContainer = styled.div`
  display: flex;
  position: fixed;
  flex-flow: row;
  width: 100%;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const BackgroundImageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 100vh;
  max-width: 50vw;
`;

export const BackgroundImageLeft = styled.img`
  object-fit: cover;
  height: 100%;
  object-position: right center;
`;

export const BackgroundImageRight = styled(BackgroundImageLeft)`
  transform: scaleX(-1);
`;

export const Navbar = styled.nav`
  padding: 6.2rem;
  width: 100%;
  height: 18.4rem;
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    justify-content: flex-start;
    height: 15.2rem;
    padding: 5.6rem 5.6rem;
  }
`;

export const NavbarInnerContainer = styled.div`
  z-index: 2;
`;

export const NavbarAnchorTag = styled.a`
  cursor: pointer;
`;

export const NavbarImage = styled.img`
  height: 100%;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const NavbarImageSmallWindow = styled.img`
  height: 100%;
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
`;

export const FormSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const FormOuterContainer = styled.div`
  width: 44rem;
  max-width: 44rem;
  position: relative;
  min-height: calc(100vh - 18.4rem);
  z-index: 2;

  @media (max-width: 640px) {
    width: 100%;
    max-width: unset;
  }
`;

export const FormContainer = styled.div`
  width: 44rem;
  max-width: 44rem;
  background: ${props => props.theme.container['background-color-1']};
  border-radius: 2.4rem;
  box-shadow: 0rem 0rem 2rem ${props => props.theme['box-shadow']['color-3']};
  margin-bottom: 12.4rem;

  @media (max-width: 640px) {
    box-shadow: none;
    margin-bottom: 8.4rem;
    width: 100%;
    max-width: unset;
  }
`;

export const FormContainerFirstHalf = styled.div`
  padding: 2.4rem 5.6rem 0 5.6rem;

  @media (max-width: 640px) {
    padding: 0 5.6rem 0 5.6rem;
  }
`;

export const FormContainerFirstHalfInnerContainer = styled.div``;

export const Title = styled.h1`
  margin-bottom: 3.2rem;
  margin-top: 2.4rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 2.6rem;
  font-weight: 700;

  @media (max-width: 640px) {
    margin-top: 0;
  }
`;

export const FormInputContainer = styled.div`
  margin-block: 2.4rem;
`;

export const PasswordOptionsContainer = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 2.4rem;
`;

export const ForgotPasswordLinkContainer = styled.div`
  color: ${props => props.theme.text['color-28']};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SignInMethodDivider = styled.div`
  position: relative;
  margin-block: 2.4rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-method-divider-background-color']};

  &:after {
    content: 'OR';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
      props.theme.signin[
        'signin-method-divider-pseudo-element-background-color'
      ]};
    width: 6rem;
    font-size: 1.2rem;
    color: ${props =>
      props.theme.signin['signin-method-divider-pseudo-element-color']};
    letter-spacing: 0.1rem;
  }
`;

export const SignInSocialButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 640px) {
    justify-content: center;

    & > button:first-child > div:first-child > svg {
      margin-right: 0 !important;
    }
  }
`;

export const SignInFacebookButtonLabelContainer = styled.div`
  @media (max-width: 640px) {
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
`;

export const SignInFormContentDivider = styled.div`
  margin-block: 3.2rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const SignInFormFooter = styled.div`
  text-align: center;
  font-size: 1.4rem;
  padding-bottom: 3.2rem;
  color: ${props => props.theme.signin['signin-form-footer-color']};
`;

export const SignInFormFooterLink = styled.a`
  color: ${props => props.theme.text['color-28']};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ForgotPasswordFooterResendEmailTimer = styled.span`
  color: ${props => props.theme.text['color-29']};
  text-decoration: none;
  outline: none;
  pointer: default;
  font-size: 1.4rem;
  white-space: pre;
`;

export const SignInPageFooter = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 4rem auto 1rem auto;
  width: 43rem;
  text-align: center;
  max-width: 100vw;
`;

export const SignInPageFooterLinksContainer = styled.div`
  margin: 1.2rem;
`;

export const SignInPageFooterLink = styled.span`
  color: ${props => props.theme.text['color-27']};
  font-size: 1.2rem;
  margin: 1.2rem;
  opacity: 0.8;
  outline: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SignInPageFooterLangCopyRightsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.2rem;
`;

export const CopyRightSpan = styled.span`
  color: ${props => props.theme.text['color-27']};
  font-size: 1.2rem;
  opacity: 0.8;
`;

export const SecondBackgroundImageContainer = styled.div`
  top: 5rem;
  min-height: 57rem;
  width: 134.6rem;
  max-width: 90vw;
  position: absolute;
  background: url(${SkorrOnboardingSecondBackgroundImage}) no-repeat;
  background-size: contain;
  background-position: top;
  z-index: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const LabelTextContainer = styled.div`
  font-size: 1.6rem;
  color: ${props => props.theme.text['color-27']};
`;

export const BusinessAccountWebsiteInputContainer = styled.div<IBusinessAccountWebsiteInputContainerProps>`
  ${props => {
    if (props.visible) {
      return css`
        min-height: 7.2rem;
      `;
    }

    return css`
      height: 0;
    `;
  }};
  opacity: ${props => (props.visible ? '1' : '0')};
  margin-top: ${props => (props.visible ? '3.2rem' : '0')};
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  transition: all 0.08s;
`;

export const SignUpSuccessContainer = styled.div`
  width: 64rem;
  max-width: 64rem;
  position: relative;
  min-height: calc(100vh - 18.4rem);
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 640px) {
    width: 100%;
    max-width: unset;
  }
`;

export const SignUpSuccessTextContainer = styled.div`
  font-size: 1.6rem;
  color: ${props => props.theme.text['color-26']};
`;

export const ManagerAccountTogglerTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ManagerAccountTooltipContainer = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 0.7rem);
  width: 100%;
  height: auto;
  padding: 1.2rem 1.6rem;
  border-radius: 0.6rem;
  z-index: 2 !important;
  background-color: ${props =>
    props.theme.signup['manager-account-tooltip-container-background-color']};
  border: 0.2rem solid
    ${props =>
      props.theme.signup['manager-account-tooltip-container-border-color']};
  cursor: default;

  &:hover {
    display: block;
  }
`;

export const QuestionMarkIconContainer = styled.span`
  margin-left: 0.5rem;
  position: relative;
  cursor: default;

  &:hover + ${ManagerAccountTooltipContainer} {
    display: block;
  }

  &:hover {
    &::before {
      display: block;
    }
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 1rem;
    top: 100%;
  }
`;

// &::after {
//     content: '';
//     width: 2.5rem;
//     height: 2.5rem;
//     position: absolute;
//     background-color: ${props => props.theme['signup']['manager-account-tooltip-container-background-color']};
//     border: 0.2rem solid ${props => props.theme['signup']['manager-account-tooltip-container-border-color']};
//     top: 0.1rem;
//     left: 50%;
//     transform: translateX(-50%) rotateZ(45deg) skew(20deg, 20deg);
//     z-index: -1 !important;
// };

// box-shadow: 0rem 0.8rem 1rem ${props => props.theme['signup']['manager-account-tooltip-container-box-shadow-color-1']}, 0rem 0.3rem 1.4rem ${props => props.theme['signup']['manager-account-tooltip-container-box-shadow-color-2']}, 0rem 0.5rem 0.5rem ${props => props.theme['signup']['manager-account-tooltip-container-box-shadow-color-3']};

export const ManagerAccountTooltipEntry = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${props => props.theme.signup['manager-account-tooltip-color']};
`;

export const ManagerAccountTooltipEntryBold = styled.span`
  font-weight: 700;
`;

export const ManagerAccountTooltipEntryLink = styled.span`
  cursor: pointer;
  color: ${props => props.theme.signup['manager-account-tooltip-link-color']};
`;

export const ManagerAccountTooltipDivider = styled.div`
  height: 0;
  border: 1px solid
    ${props => props.theme.signup['manager-account-tooltip-divider']};
  margin: 1.2rem 0rem;
`;

export const SignupSuccesfulHeaderContainer = styled.div`
  min-height: 6.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  border-bottom: solid 1px
    ${props =>
      props.theme.signin['signin-forgot-password-header-divider-color']};
`;

export const SignupSuccessfulTitleContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 2rem;
  color: ${props => props.theme.text['color-26']};
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignupSuccessfulContentOuterContainer = styled.div`
  padding-top: 1rem;
  padding-left: 6rem;
  padding-right: 6rem;
`;

export const SignupSuccessfullContentTextContainer = styled.p`
  font-size: 1.4rem;
  color: ${props => props.theme.text['color-27']};
  display: flex;
  flex-direction: column;
  line-height: 1.8rem;
  margin-bottom: 1.2rem;
  margin-block-start: 1.4rem;
  text-align: center;
`;

export const AppDownloadButtonsContainer = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const DividerOr = styled.div`
  position: relative;
  margin-block: 2.4rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-method-divider-background-color']};

  &:after {
    content: 'OR';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
      props.theme.signin[
        'signin-method-divider-pseudo-element-background-color'
      ]};
    width: 6rem;
    font-size: 1.2rem;
    color: ${props =>
      props.theme.signin['signin-method-divider-pseudo-element-color']};
    letter-spacing: 0.1rem;
  }
`;

export const TermsAndConditions = styled.div`
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.7rem;
  flex-wrap: wrap;
  justify-content: start;

  & > div:nth-child(1 of .termsAndConditions),
  & > div:nth-child(2 of .termsAndConditions) {
    padding-right: 0.4rem;
  }
`;

export const TermsAndConditionsPart1Word = styled.div`
  color: #676767;
  padding-right: 0.4rem;
`;

export const TermsAndConditionsPart2Word = styled.div.attrs({
  className: 'termsAndConditions',
})`
  color: #5870f6;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #5870f6;
  }
`;
