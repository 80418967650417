import { t } from 'i18next';
import { BenchmarkOutput } from 'features/Benchmark/types';
import { BenchmarksListHookProps } from 'features/Benchmark/hooks/use-benchmarks-list';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type Props = {
  index: number;
  benchmark: BenchmarkOutput;
  customHook: BenchmarksListHookProps;
};

const BenchmarkListDropdown = ({ index, benchmark, customHook }: Props) => {
  const {
    toggleDropdown,
    handleCloseDropdown,
    handleToggleDropdown,
    handleDropdownAction,
  } = customHook;

  return (
    <S.Container>
      <S.ItemIconButton
        onClick={e => {
          e.stopPropagation();
          handleToggleDropdown(index);
        }}
      >
        <svg viewBox="0 0 32 32">
          <path d="M16 18c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2zM24 18c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2zM8 18c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2z" />
        </svg>
      </S.ItemIconButton>

      <ClickOutHandler onClickOut={handleCloseDropdown}>
        <S.DropdownContainer active={toggleDropdown === index}>
          {['settings', 'delete'].map(item => {
            return (
              <S.DropdownItemContainer
                key={item}
                onClick={e => {
                  e.stopPropagation();
                  handleDropdownAction(item, benchmark);
                }}
              >
                <S.DropdownItemText>
                  {t(`benchmark.list.dropdownMenu.${item}`)}
                </S.DropdownItemText>
              </S.DropdownItemContainer>
            );
          })}
        </S.DropdownContainer>
      </ClickOutHandler>
    </S.Container>
  );
};

export default BenchmarkListDropdown;
