import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IErrorObject } from 'Services/Utils/types';
import { DEFAULT_ITEMS_PER_PAGE } from 'data/constants';
import { useCallback, useEffect, useState } from 'react';
import { logApiRequestError } from 'Services/Utils/Utils';

import {
  useGetBenchmarks,
  useDeleteBenchmark,
  useGetTotalBenchmarks,
} from '../api';
import { BenchmarkOutput } from '../types';

export type BenchmarksListHookProps = {
  page: number;
  totalPages: number;
  hasBenchmarks: boolean;
  isLoadingBenchmarks: boolean;
  benchmarks: BenchmarkOutput[];
  toggleDropdown: number | null;
  hasInitialBenchmarks: boolean;
  showConfirmDeleteModal: boolean;
  searchBenchmark: string | undefined;
  handleCloseDropdown: () => void;
  handleGoToCreateBenchmark: () => void;
  handleHideConfirmDeleteModal: () => void;
  handleToggleDropdown: (id: number) => void;
  handleDeleteBenchmark: () => Promise<void>;
  handleChangePage: (pageNumber: number) => void;
  handleChangeSearchBenchmark: (name: string) => void;
  handleGoToViewBenchmark: (data: BenchmarkOutput) => void;
  handleDropdownAction: (action: string, data: BenchmarkOutput) => void;
};

export const useBenchmarksList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [searchBenchmark, setSearchBenchmark] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState(searchBenchmark);
  const [toggleDropdown, setToggleDropdown] = useState<number | null>(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedBenchmarkId, setSelectedBenchmarkId] = useState<string | null>(
    null,
  );

  const handleToggleDropdown = useCallback(
    (id: number) => {
      setToggleDropdown(toggleDropdown === id ? null : id);
    },
    [toggleDropdown],
  );

  const handleCloseDropdown = useCallback(() => {
    setToggleDropdown(null);
  }, []);

  const handleHideConfirmDeleteModal = useCallback(() => {
    setShowConfirmDeleteModal(false);
  }, []);

  const handleChangeSearchBenchmark = useCallback((name: string) => {
    setSearchBenchmark(name);
  }, []);

  const { data, isLoading: isLoadingBenchmarks } = useGetBenchmarks({
    page,
    limit: DEFAULT_ITEMS_PER_PAGE,
    name:
      debouncedSearch.trim().length! > 2 ? debouncedSearch.trim() : undefined,
  });

  useEffect(() => {
    const benchmarkName = setTimeout(() => {
      setPage(0);
      setDebouncedSearch(searchBenchmark);
    }, 500);

    return () => {
      clearTimeout(benchmarkName);
    };
  }, [searchBenchmark]);

  const { data: totalBenchmarks } = useGetTotalBenchmarks(
    debouncedSearch.trim().length! > 2 ? debouncedSearch.trim() : undefined,
  );

  const totalPages = Math.ceil(
    (totalBenchmarks ?? DEFAULT_ITEMS_PER_PAGE) / DEFAULT_ITEMS_PER_PAGE,
  );

  const benchmarks =
    data?.filter(benchmark => !benchmark.benchmarkInfo.deleted) ?? [];

  const hasBenchmarks = (benchmarks ?? []).length > 0;

  const initialBenchmarks: BenchmarkOutput[] =
    queryClient.getQueryData(['get-benchmarks', { page: 0 }], {
      queryKey: ['get-benchmarks', { page: 0 }],
      exact: true,
    }) || [];

  const hasInitialBenchmarks = (initialBenchmarks ?? []).length > 0;

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 0 && pageNumber <= totalPages) {
        setPage(pageNumber);
      }
    },
    [totalPages],
  );

  const handleDropdownAction = useCallback(
    (action: string, data: BenchmarkOutput) => {
      const benchmarkId = data.benchmarkInfo.id;

      if (action === 'settings') {
        navigate(`/update-benchmark/${benchmarkId}`, {
          state: {
            benchmarkData: data.benchmarkInfo,
          },
        });
      }

      if (action === 'delete') {
        setShowConfirmDeleteModal(true);
        setSelectedBenchmarkId(benchmarkId);
      }

      setToggleDropdown(null);
    },
    [navigate],
  );

  const { mutateAsync: deleteBenchmark, isSuccess: isSuccessDeleteBenchmark } =
    useDeleteBenchmark();

  const handleDeleteBenchmark = useCallback(async () => {
    try {
      await deleteBenchmark(selectedBenchmarkId!);
    } catch (error) {
      toast.error(logApiRequestError(error as IErrorObject));
    }
    handleHideConfirmDeleteModal();
  }, [deleteBenchmark, selectedBenchmarkId, handleHideConfirmDeleteModal]);

  useEffect(() => {
    if (isSuccessDeleteBenchmark) {
      toast.success(t('benchmark.toast.delete'));
    }
  }, [isSuccessDeleteBenchmark]);

  const handleGoToCreateBenchmark = useCallback(() => {
    navigate('/create-benchmark');
  }, [navigate]);

  const handleGoToViewBenchmark = useCallback(
    (data: BenchmarkOutput) => {
      navigate(`/view-benchmark/${data.benchmarkInfo.id}`, {
        state: {
          benchmarkData: data,
        },
      });
    },
    [navigate],
  );

  return {
    page,
    benchmarks,
    totalPages,
    hasBenchmarks,
    toggleDropdown,
    searchBenchmark,
    handleChangePage,
    isLoadingBenchmarks,
    handleCloseDropdown,
    handleToggleDropdown,
    handleDropdownAction,
    hasInitialBenchmarks,
    handleDeleteBenchmark,
    showConfirmDeleteModal,
    handleGoToViewBenchmark,
    handleGoToCreateBenchmark,
    handleChangeSearchBenchmark,
    handleHideConfirmDeleteModal,
  };
};
