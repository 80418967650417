import styled from 'styled-components';

import { ICompanyDetailsFieldContainer } from './types';

export const OuterContainer = styled.div``;

export const Container = styled.div`
  width: 100%;
  min-height: calc(
    100vh - ${props => props.theme['back-navigation-button-navbar'].height}
  );
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  min-height: 63rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  padding-bottom: 2.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 1450px) {
    width: 95%;
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const CardInnerContainer = styled.div`
  margin-top: 5rem;
  padding: 0 5.5rem;

  @media (max-width: 525px) {
    padding: 0 4.5rem;
  }
`;

export const SectionTitle = styled.div`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

export const CompanyProfileCard = styled.div``;

export const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const BillingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const CompanyDetailsFieldContainer = styled.div<ICompanyDetailsFieldContainer>`
  width: 50%;
  padding-left: ${props => props.paddingLeft ?? '0'};
  padding-right: ${props => props.paddingRight ?? '0'};
  margin-bottom: 2.7rem;
`;

export const CompanyDetailsInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;

  @media (max-width: 900px) {
    ${CompanyDetailsFieldContainer} {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const CompanyDetailsRightColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const CompanyDetailsNameContainer = styled.div`
  width: 100%;
  margin-bottom: 2.7rem;
`;

export const CompanyDetailsNeedsDpoReviewContainer = styled.div`
  width: 50%;

  @media (max-width: 638px) {
    width: 100%;
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CompanyDetailsBillingToContactAddressContainer = styled.div`
  width: 50%;

  @media (max-width: 638px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const BillingDetailsVatExemptionTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: #676767;
`;

export const BillingDetailsVatExemptionToggleContainer = styled.div`
  width: 4rem;
  height: 4.8rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const DetailsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
`;

export const DetailsInnerFirstRowContainer = styled.div`
  display: flex;

  @media (max-width: 927px) {
    flex-direction: column;
  }
`;

export const DetailsImagePickerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 26rem;
  margin-right: 7rem;

  @media (max-width: 927px) {
    width: 100%;
    align-items: center;
    margin-right: 0;
    margin-bottom: 1.2rem;
  }
`;

export const DetailsImagePickerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DeleteAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  border-top: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const DeleteAccountButton = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 1rem 1.7rem;
  margin-top: 2.5rem;
  border: 0.1rem solid #bfbfbf80;
  border-radius: 1rem;
  color: #676767;
  cursor: pointer;

  & > div:first-child {
    margin-right: 1.5rem;
  }

  &:hover {
    background-color: #f7f8fa;
  }

  @media (max-width: 525px) {
    padding: 1rem 1.5rem;

    & > div:first-child {
      margin-right: 1.3rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 3rem;
  border-top: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const GeoLocationTitleContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
`;
