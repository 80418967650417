import { z } from 'zod';
import { t } from 'i18next';

export const adminPartnerFormSchema = z.object({
  name: z.string().nonempty({ message: t('generic.cantBeEmpty') }),
  email: z.string().email().optional().or(z.literal('')),
  picture: z.string().optional(),
  billingToContactAddress: z.boolean().optional(),
  type: z.string().nonempty({ message: t('generic.cantBeEmpty') }),
  contactManager1: z.string().optional(),
  contactManager2: z.string().optional(),
  contactDescription: z.string().optional(),
  contactBrand: z.string().optional(),
  companyName: z.string().optional(),
  contactAddress1: z.string().optional(),
  contactAddress2: z.string().optional(),
  contactPostalCode: z.string().optional(),
  contactCountry: z
    .object({
      value: z.string(),
      data: z.object({
        country: z.string(),
        countryId: z.string(),
      }),
    })
    .optional(),
  contactCity: z
    .object({
      value: z.string(),
      data: z.object({
        city: z.string(),
        cityId: z.string(),
      }),
    })
    .optional(),
  contactVat_id: z.string().optional(),
  contactWebSite: z.string().optional(),
  billingCompanyName: z.string().optional(),
  billingAddress1: z.string().optional(),
  billingAddress2: z.string().optional(),
  billingPostalCode: z.string().optional(),
  billingCountry: z
    .object({
      value: z.string(),
      data: z.object({
        country: z.string(),
        countryId: z.string(),
      }),
    })
    .optional(),
  billingCity: z
    .object({
      value: z.string(),
      data: z.object({
        city: z.string(),
        cityId: z.string(),
      }),
    })
    .optional(),
  billingVatExemption: z.boolean().optional(),
  billingVat_id: z.string().optional(),
});

export type AdminPartnerFormData = z.infer<typeof adminPartnerFormSchema>;
