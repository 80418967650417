/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo, useContext } from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import ToggleButton from 'components/shared/ToggleButton/ToggleButton';
import NoPermissionToAccessPageWarning from 'components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import InputSpinnerIcon from 'assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { DEFAULT_AVATAR_URL } from 'Services/Utils/constants';
import TrashCanIconV1 from 'assets/iconComponents/TrashCanIconV1';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  ICityComboBoxEntry,
  ICountryComboBoxEntry,
  PartnerTypes,
  TCitiesAvailableValues,
  TCountriesAvailableValues,
} from '../../Services/Utils/types';
import {
  useGetLocations,
  useGetPartnerById,
  useGetToken,
  useIsOperatorOrPartnerAdmin,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import {
  emailValid,
  urlValid,
  valueEmpty,
} from '../../validations/validationFunctions';
import { CompanyEditProps } from './types';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import { OuterContext } from '../../components/MenuNavbarV2/MenuNavbarV2';
import { useDeletePartner, useUpdatePartner } from './RQCustomHooks';

const CompanyEdit = (props: CompanyEditProps) => {
  const [
    isGetIsOperatorOrPartnerAdminEnabled,
    setIsGetIsOperatorOrPartnerAdminEnabled,
  ] = useState<boolean>(true);
  const [isGetPartnerEnabled, setIsGetPartnerEnabled] =
    useState<boolean>(false);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(false);
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState<boolean>(false);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [name, setName] = useState<string>();
  const [imageSrc, setImageSrc] = useState('');
  const [billingToContactAddress, setBillingToContactAddress] = useState(false);
  const [needsDpoReview, setNeedsDpoReview] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    name: '',
    vat_id: '',
    address1: '',
    postalCode: '',
    country: '',
    countryId: '',
    city: '',
    cityId: '',
    website: '',
    email: '',
  });
  const [contactDetailsCountrySelected, setContactDetailsCountrySelected] =
    useState<ICountryComboBoxEntry | undefined>();
  const [contactDetailsCitySelected, setContactDetailsCitySelected] = useState<
    ICityComboBoxEntry | undefined
  >();
  const [
    contactDetailsCitiesAvailableValues,
    setContactDetailsCitiesAvailableValues,
  ] = useState<TCitiesAvailableValues>([]);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    vat_id: '',
    vatExemption: false,
    address1: '',
    postalCode: '',
    city: '',
    cityId: '',
    country: '',
    countryId: '',
  });
  const [billingDetailsCountrySelected, setBillingDetailsCountrySelected] =
    useState<ICountryComboBoxEntry | undefined>();
  const [billingDetailsCitySelected, setBillingDetailsCitySelected] = useState<
    ICityComboBoxEntry | undefined
  >();
  const [
    billingDetailsCitiesAvailableValues,
    setBillingDetailsCitiesAvailableValues,
  ] = useState<TCitiesAvailableValues>([]);
  const [
    isContactDetailsWebsiteInvalidOrProcessing,
    setIsContactDetailsWebsiteInvalidOrProcessing,
  ] = useState(false);
  const [
    isContactDetailsEmailInvalidOrProcessing,
    setIsContactDetailsEmailInvalidOrProcessing,
  ] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [
    successfulActionNotificationText,
    setSuccessfulActionNotificationText,
  ] = useState('');
  const [loadingActionNotificationText, setLoadingActionNotificationText] =
    useState('');
  const {
    isLoading: isLoadingUpdatePartner,
    isSuccess: isSuccessUpdatePartner,
    isError: isErrorUpdatePartner,
    mutate: updatePartner,
  } = useUpdatePartner();
  const {
    isLoading: isLoadingDeletePartner,
    isSuccess: isSuccessDeletePartner,
    isError: isErrorDeletePartner,
    mutate: deletePartner,
  } = useDeletePartner();
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);
  const {
    data: partner,
    isFetching: isFetchingGetPartner,
    isSuccess: isSuccessGetPartner,
    isError: isErrorGetPartner,
  } = useGetPartnerById(
    isGetPartnerEnabled,
    isAuthenticated()?.partnerId ?? '',
  );
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    refetch: refetchGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const context = useContext(OuterContext);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const initialImageUrl = useMemo(() => [imageSrc], [imageSrc]);
  const [initialCompanyImageSrc, setInitialCompanyImageSrc] = useState('');
  const {
    data: isOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isGetIsOperatorOrPartnerAdminEnabled);
  const prohibitedFromViewingPage = useMemo(() => {
    const isBusinessAccount =
      isAuthenticated()?.partnerType === PartnerTypes.agency ||
      isAuthenticated()?.partnerType === PartnerTypes.brand;

    if (!isBusinessAccount) return true;

    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin
    ) {
      return !isOperatorOrPartnerAdmin;
    }
  }, [
    isOperatorOrPartnerAdmin,
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
  ]);
  const hasFinishedLoadingData = useMemo(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin &&
      isSuccessGetPartner &&
      !isFetchingGetPartner &&
      isSuccessGetLocations &&
      !isFetchingGetLocations
    ) {
      return true;
    }

    return false;
  }, [
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
    isSuccessGetPartner,
    isFetchingGetPartner,
    isSuccessGetLocations,
    isFetchingGetLocations,
  ]);

  useEffect(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin
    ) {
      setIsGetIsOperatorOrPartnerAdminEnabled(false);
    }
  }, [isSuccessIsOperatorOrPartnerAdmin, isFetchingIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (
      isSuccessIsOperatorOrPartnerAdmin &&
      !isFetchingIsOperatorOrPartnerAdmin &&
      !prohibitedFromViewingPage
    ) {
      setIsGetPartnerEnabled(true);
      setIsGetLocationsEnabled(true);
    }
  }, [
    isSuccessIsOperatorOrPartnerAdmin,
    isFetchingIsOperatorOrPartnerAdmin,
    prohibitedFromViewingPage,
  ]);

  useEffect(() => {
    if (isSuccessGetPartner && !isFetchingGetPartner && partner) {
      setName(partner.name ?? '');
      if (partner.displayPicture) {
        setImageSrc(partner.displayPicture);
        setInitialCompanyImageSrc(partner.displayPicture);
      }
      setNeedsDpoReview(partner.needsDpoReview);
      setBillingToContactAddress(partner.billingToContactAddress);
      setContactDetails({
        name: partner.contactDetail?.companyName ?? '',
        vat_id: partner.contactDetail?.vat_id ?? '',
        address1: partner.contactDetail?.address1 ?? '',
        postalCode: partner.contactDetail?.postalCode ?? '',
        country: partner.contactDetail?.country ?? '',
        countryId: partner.contactDetail?.countryId ?? '',
        city: partner.contactDetail?.city ?? '',
        cityId: partner.contactDetail?.cityId ?? '',
        website: partner.contactDetail?.website ?? '',
        email: partner.contactDetail?.email ?? '',
      });
      setBillingDetails({
        name: partner.billingDetail?.companyName ?? '',
        vat_id: partner.billingDetail?.vat_id ?? '',
        vatExemption: partner.billingDetail?.vatExemption ?? false,
        address1: partner.billingDetail?.address1 ?? '',
        postalCode: partner.billingDetail?.postalCode ?? '',
        city: partner.billingDetail?.city ?? '',
        cityId: partner.billingDetail?.cityId ?? '',
        country: partner.billingDetail?.country ?? '',
        countryId: partner.billingDetail?.countryId ?? '',
      });
      setIsGetPartnerEnabled(false);
    }
  }, [isSuccessGetPartner, isFetchingGetPartner, partner]);

  useEffect(() => {
    if (isSuccessGetLocations && locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [isSuccessGetLocations, locations]);

  useEffect(() => {
    if (
      isSuccessGetPartner &&
      partner &&
      countriesAvailableValues.length &&
      locations?.size &&
      partner?.contactDetail?.country &&
      partner?.contactDetail?.countryId
    ) {
      if (partner.contactDetail?.country) {
        const { country, countryId } = locations!.get(
          partner.contactDetail.country,
        )!;

        setContactDetailsCountrySelected({
          value: partner.contactDetail?.country,
          data: { country, countryId },
        });
      }

      if (partner.billingDetail?.country) {
        const { country, countryId } = locations!.get(
          partner.billingDetail.country,
        )!;

        setBillingDetailsCountrySelected({
          value: partner.billingDetail?.country,
          data: { country, countryId },
        });
      }
    }
  }, [
    isSuccessGetPartner,
    partner,
    countriesAvailableValues,
    locations?.size,
    partner?.contactDetail?.country,
    partner?.billingDetail?.country,
  ]);

  useEffect(() => {
    if (partner?.contactDetail?.cityId) {
      setContactDetailsCitySelected({
        value: partner.contactDetail.cityId,
        data: { city: partner.contactDetail.city! },
      });
    }

    if (partner?.billingDetail?.cityId) {
      setBillingDetailsCitySelected({
        value: partner.billingDetail.cityId,
        data: { city: partner.billingDetail.city! },
      });
    }
  }, [partner?.contactDetail?.cityId, partner?.billingDetail?.cityId]);

  useEffect(() => {
    if (contactDetailsCountrySelected?.value) {
      const contactDetailsCitiesAvailableValuesLocal: TCitiesAvailableValues =
        [];

      locations!
        .get(contactDetailsCountrySelected.value)
        ?.cities.forEach(({ city, cityId }) => {
          contactDetailsCitiesAvailableValuesLocal.push({
            value: cityId,
            label: city,
            data: {
              city,
            },
          });
        });

      contactDetailsCitiesAvailableValuesLocal.sort((city1, city2) =>
        city1.data!.city < city2.data!.city ? -1 : 1,
      );

      setContactDetailsCitiesAvailableValues(
        contactDetailsCitiesAvailableValuesLocal,
      );

      if (contactDetailsCitySelected?.value) {
        if (
          !locations
            ?.get(contactDetailsCountrySelected.value)
            ?.cities.find(
              city => city.cityId === contactDetailsCitySelected.value,
            )
        ) {
          setContactDetailsCitySelected(undefined);
          setContactDetails(prevState => ({
            ...prevState,
            city: '',
            cityId: '',
          }));
        }
      }
    } else {
      setContactDetailsCitiesAvailableValues([]);
    }
  }, [contactDetailsCountrySelected?.value, locations, partner]);

  useEffect(() => {
    if (billingDetailsCountrySelected?.value) {
      const billingDetailsCitiesAvailableValuesLocal: TCitiesAvailableValues =
        [];

      locations!
        .get(billingDetailsCountrySelected.value)
        ?.cities.forEach(({ city, cityId }) => {
          billingDetailsCitiesAvailableValuesLocal.push({
            value: cityId,
            label: city,
            data: {
              city,
            },
          });
        });

      billingDetailsCitiesAvailableValuesLocal.sort((city1, city2) =>
        city1.data!.city < city2.data!.city ? -1 : 1,
      );

      setBillingDetailsCitiesAvailableValues(
        billingDetailsCitiesAvailableValuesLocal,
      );

      if (billingDetailsCitySelected?.value) {
        if (
          !locations
            ?.get(billingDetailsCountrySelected.value)
            ?.cities.find(
              city => city.cityId === billingDetailsCitySelected.value,
            )
        ) {
          setBillingDetailsCitySelected(undefined);
          setBillingDetails(prevState => ({
            ...prevState,
            city: '',
            cityId: '',
          }));
        }
      }
    } else {
      setBillingDetailsCitiesAvailableValues([]);
    }
  }, [billingDetailsCountrySelected?.value, locations]);

  useEffect(() => {
    if (isSuccessUpdatePartner) {
      const invalidateTokenData = async () => {
        await queryClient.invalidateQueries({ queryKey: ['tokenData'] });
      };

      invalidateTokenData();

      setTimeout(() => {
        refetchGetToken();
      }, 2000);

      setShowSuccessNotification(true);
      setSuccessfulActionNotificationText(
        props.t('companyEdit.companyUpdated'),
      );
      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 2000);
    }
  }, [isSuccessUpdatePartner]);

  useEffect(() => {
    if (isSuccessDeletePartner) {
      setShowSuccessNotification(true);
      setSuccessfulActionNotificationText(
        props.t('companyEdit.accountDeleted'),
      );

      setTimeout(() => {
        localStorage.removeItem('jwt');
        navigate('/');
      }, 1300);

      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 2000);
    }
  }, [isSuccessDeletePartner]);

  useEffect(() => {
    if (isSuccessGetToken && !isFetchingGetToken) {
      setIsGetTokenEnabled(false);
      setTimeout(() => context.refetchBusinessProfilesByPartnerId(), 1000);
    }
  }, [isSuccessGetToken, isFetchingGetToken]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setHasError(true);
      setMsgError(props.t('companyEdit.couldNotLoadData'));
      setIsGetIsOperatorOrPartnerAdminEnabled(false);
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isErrorGetPartner) {
      setHasError(true);
      setMsgError(props.t('companyEdit.couldNotLoadData'));
      setIsGetPartnerEnabled(false);
    }
  }, [isErrorGetPartner]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setIsGetLocationsEnabled(false);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorUpdatePartner) {
      setHasError(true);
      setMsgError(props.t('companyEdit.couldNotUpdateCompany'));
    }
  }, [isErrorUpdatePartner]);

  useEffect(() => {
    if (isErrorDeletePartner) {
      setHasError(true);
      setMsgError(props.t('companyEdit.couldNotDeleteAccount'));
    }
  }, [isErrorDeletePartner]);

  const saveButtonClicked = () => {
    setLoadingActionNotificationText(props.t('companyEdit.updatingCompany'));

    const args: any = {};
    args.name = name;
    args.displayPicture = imageSrc;
    args.img = imageSrc;
    args.needsDpoReview = needsDpoReview;
    args.billingToContactAddress = billingToContactAddress;
    args.contactDetail = {
      ...(contactDetails.name && { companyName: contactDetails.name }),
      ...(contactDetails.vat_id && { vat_id: contactDetails.vat_id }),
      ...(contactDetails.address1 && { address1: contactDetails.address1 }),
      ...(contactDetails.postalCode && {
        postalCode: contactDetails.postalCode,
      }),
      ...(contactDetails.country && { country: contactDetails.country }),
      ...(contactDetails.countryId && { countryId: contactDetails.countryId }),
      ...(contactDetails.city && { city: contactDetails.city }),
      ...(contactDetails.cityId && { cityId: contactDetails.cityId }),
      ...(contactDetails.website && { website: contactDetails.website }),
      ...(contactDetails.email && { email: contactDetails.email }),
    };
    args.billingDetail = {
      ...(billingDetails.name && { companyName: billingDetails.name }),
      ...(billingDetails.vat_id && { vat_id: billingDetails.vat_id }),
      vatExemption: billingDetails.vatExemption,
      ...(billingDetails.address1 && { address1: billingDetails.address1 }),
      ...(billingDetails.postalCode && {
        postalCode: billingDetails.postalCode,
      }),
      ...(billingDetails.city && { city: billingDetails.city }),
      ...(billingDetails.cityId && { cityId: billingDetails.cityId }),
      ...(billingDetails.country && { country: billingDetails.country }),
      ...(billingDetails.countryId && { countryId: billingDetails.countryId }),
    };
    let partnerPictureFormData;
    if (files[0]?.data) {
      partnerPictureFormData = new FormData();
      partnerPictureFormData.append('file', files[0]!.data);
      partnerPictureFormData.append(
        'source',
        FileSourceEnum.USER_BRAND_PICTURE,
      );
      partnerPictureFormData.append('id', isAuthenticated()?.partnerId!);
    }
    updatePartner({
      updateData: args,
      pictureData: partnerPictureFormData,
      initialPicture: imageSrc || initialCompanyImageSrc,
    });
  };

  const deletePartnerButtonClicked = () => {
    setLoadingActionNotificationText(props.t('companyEdit.deletingAccount'));

    deletePartner();
  };

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (
      isFetchingIsOperatorOrPartnerAdmin ||
      isFetchingGetPartner ||
      isFetchingGetLocations
    ) {
      return props.t('generic.loadingData');
    }
    if (isLoadingUpdatePartner) {
      return props.t('companyEdit.updatingCompany');
    }
    return '';
  };

  const isCancelButtonEnabled = () => {
    if (!isLoadingUpdatePartner && !isLoadingDeletePartner) return true;

    return false;
  };

  const isSaveButtonEnabled = () => {
    if (
      !valueEmpty(name) &&
      !(contactDetails.email && isContactDetailsEmailInvalidOrProcessing) &&
      !(contactDetails.website && isContactDetailsWebsiteInvalidOrProcessing) &&
      !isLoadingUpdatePartner &&
      !isLoadingDeletePartner
    )
      return true;
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsGetIsOperatorOrPartnerAdminEnabled(true);
    }

    if (isErrorGetPartner) {
      setIsGetPartnerEnabled(true);
    }

    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }

    setHasError(false);
  };

  if (!isAuthenticated()) {
    return null;
  }

  if (prohibitedFromViewingPage) {
    return (
      <S.OuterContainer>
        <S.Container>
          <S.InnerContainer>
            <NoPermissionToAccessPageWarning containerHeight="auto" />
          </S.InnerContainer>
        </S.Container>
      </S.OuterContainer>
    );
  }

  return (
    <S.OuterContainer>
      <Loader
        show={
          isFetchingIsOperatorOrPartnerAdmin ||
          isFetchingGetPartner ||
          isFetchingGetLocations
        }
        message={getLoaderMessage()}
      />

      {hasFinishedLoadingData && (
        <S.Container>
          <S.InnerContainer>
            <S.CompanyProfileCard>
              <S.HeaderOuterContainer>
                <S.HeaderContainer>
                  <S.HeaderBackArrowContainer
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <BackArrowIcon width={'4rem'} height={'4rem'} />
                  </S.HeaderBackArrowContainer>
                  <S.HeaderTitleContainer>
                    {props.t('companyEdit.editCompany')}
                  </S.HeaderTitleContainer>
                  <S.HiddenHeaderElement />
                </S.HeaderContainer>
                <S.HeaderDivider />
              </S.HeaderOuterContainer>

              <S.CardInnerContainer>
                <S.DetailsContainer>
                  <S.SectionTitle>
                    {props.t('companyEdit.companyDetails')}
                  </S.SectionTitle>

                  <S.DetailsInnerContainer>
                    <S.DetailsInnerFirstRowContainer>
                      <S.DetailsImagePickerContainer>
                        <S.DetailsImagePickerButtonContainer>
                          <FilePickerV2
                            handleChange={(files: IFile[]) => {
                              setFiles(files);
                            }}
                            crossButtonClickedHandler={() =>
                              setImageSrc(DEFAULT_AVATAR_URL)
                            }
                            text={props.t('generic.selectPicture')}
                            showSelectedFiles={false}
                            single={true}
                            type={'images'}
                            marginTop={'1rem'}
                            initialImages={initialImageUrl}
                            imageContainerWidth={'16.8rem'}
                            imageContainerHeight={'13.8rem'}
                            buttonBorderRadius={'0.6rem'}
                          />
                        </S.DetailsImagePickerButtonContainer>
                      </S.DetailsImagePickerContainer>

                      <S.CompanyDetailsRightColumnContainer>
                        <S.CompanyDetailsNameContainer>
                          <InputControlV2
                            value={name}
                            onChange={(inputValue: string) =>
                              setName(inputValue)
                            }
                            title={props.t('generic.name')}
                            inputFieldContainerWithTitleWidth={'100%'}
                            mandatoryField={true}
                            placeholder={props.t('profileEdit.insertName')}
                            inputTitleMarginBottom={'1.2rem'}
                            warningMessageContainerPositionAbsolute={true}
                            warningMessageContainerTopOffset={'0.1rem'}
                            warningMessageContainerLeftOffset={'0.2rem'}
                            requiredFieldWarningMessage={props.t(
                              'generic.nameIsRequired',
                            )}
                          />
                        </S.CompanyDetailsNameContainer>

                        <S.CompanyDetailsNeedsDpoReviewContainer>
                          <S.BillingDetailsVatExemptionTitle>
                            {props.t('companyEdit.needsDpoReview')}
                          </S.BillingDetailsVatExemptionTitle>

                          <S.BillingDetailsVatExemptionToggleContainer>
                            <ToggleButton
                              checked={needsDpoReview}
                              setChecked={(checked: boolean) => {
                                setNeedsDpoReview(checked);
                              }}
                              width={'auto'}
                            />
                          </S.BillingDetailsVatExemptionToggleContainer>
                        </S.CompanyDetailsNeedsDpoReviewContainer>

                        <S.CompanyDetailsBillingToContactAddressContainer>
                          <S.BillingDetailsVatExemptionTitle>
                            {props.t(
                              'companyEdit.billingToContactAddressToggleLabel',
                            )}
                          </S.BillingDetailsVatExemptionTitle>

                          <S.BillingDetailsVatExemptionToggleContainer>
                            <ToggleButton
                              checked={
                                !contactDetails.address1
                                  ? false
                                  : billingToContactAddress
                              }
                              setChecked={(checked: boolean) => {
                                setBillingToContactAddress(checked);
                              }}
                              width={'auto'}
                              toggleContainerCursor={
                                !contactDetails.address1
                                  ? 'not-allowed'
                                  : 'pointer'
                              }
                            />
                          </S.BillingDetailsVatExemptionToggleContainer>
                        </S.CompanyDetailsBillingToContactAddressContainer>
                      </S.CompanyDetailsRightColumnContainer>
                    </S.DetailsInnerFirstRowContainer>
                  </S.DetailsInnerContainer>
                </S.DetailsContainer>
                <S.ContactDetailsContainer>
                  <S.SectionTitle>
                    {props.t('companyEdit.contactDetails')}
                  </S.SectionTitle>

                  <S.CompanyDetailsInnerContainer>
                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={contactDetails.name}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            name: inputValue,
                          })
                        }
                        title={props.t('generic.name')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('generic.insertName')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <InputControlV2
                        value={contactDetails.vat_id}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            vat_id: inputValue,
                          })
                        }
                        title={props.t('companyEdit.vatId')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.insertVatId')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={contactDetails.address1}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            address1: inputValue,
                          })
                        }
                        title={props.t('companyEdit.address')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.address')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <InputControlV2
                        value={contactDetails.postalCode}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            postalCode: inputValue,
                          })
                        }
                        title={props.t('companyEdit.postalCode')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.postalCode')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={contactDetails.website}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            website: inputValue,
                          })
                        }
                        validationFunction={urlValid}
                        setIsInvalidOrProcessing={(
                          isInvalidOrProcessing: boolean,
                        ) =>
                          setIsContactDetailsWebsiteInvalidOrProcessing(
                            isInvalidOrProcessing,
                          )
                        }
                        title={props.t('companyEdit.website')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.website')}
                        inputTitleMarginBottom={'1.2rem'}
                        allowGreenBorder={true}
                        allowCheckMarkIcon={true}
                        warningMessageContainerPositionAbsolute={true}
                        warningMessageContainerTopOffset={'0.1rem'}
                        warningMessageContainerLeftOffset={'0.2rem'}
                        invalidFieldWarningMessage={props.t(
                          'generic.websiteFormatIsNotValid',
                        )}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <InputControlV2
                        value={contactDetails.email}
                        onChange={(inputValue: string) =>
                          setContactDetails({
                            ...contactDetails,
                            email: inputValue,
                          })
                        }
                        validationFunction={emailValid}
                        setIsInvalidOrProcessing={(
                          isInvalidOrProcessing: boolean,
                        ) =>
                          setIsContactDetailsEmailInvalidOrProcessing(
                            isInvalidOrProcessing,
                          )
                        }
                        title={props.t('generic.email')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('generic.email')}
                        inputTitleMarginBottom={'1.2rem'}
                        allowGreenBorder={true}
                        allowCheckMarkIcon={true}
                        warningMessageContainerPositionAbsolute={true}
                        warningMessageContainerTopOffset={'0.1rem'}
                        warningMessageContainerLeftOffset={'0.2rem'}
                        invalidFieldWarningMessage={props.t(
                          'generic.emailFormatIsNotValid',
                        )}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <S.GeoLocationTitleContainer>
                        {props.t('generic.country')}
                      </S.GeoLocationTitleContainer>

                      <ComboBox
                        valueKey={'countrySelected'}
                        value={contactDetailsCountrySelected}
                        type={'single'}
                        width={'100%'}
                        borderRadius={'0.6rem'}
                        valuesAvailable={countriesAvailableValues}
                        isFilterable={true}
                        isClearable={true}
                        onChange={(obj: {
                          countrySelected: ICountryComboBoxEntry;
                        }) => {
                          setContactDetailsCountrySelected(obj.countrySelected);
                          setContactDetails(prevState => ({
                            ...prevState,
                            country: obj.countrySelected?.value,
                            countryId: obj.countrySelected?.data?.countryId,
                          }));
                        }}
                        placeholder={props.t('placeholder.selectCountry')}
                        height={'4.4rem'}
                        availableOptionsContainerBorderRadius={'0.6rem'}
                        optionContainerBorderRadius={'0.6rem'}
                        innerOptionsContainerMarginTop={'1.2rem'}
                        innerOptionsContainerMarginBottom={'1.2rem'}
                        innerOptionsContainerPaddingLeft={'1.2rem'}
                        innerOptionsContainerPaddingRight={'1.2rem'}
                        optionContainerPaddingTop={'2rem'}
                        optionContainerPaddingBottom={'2rem'}
                        optionContainerPaddingLeft={'2rem'}
                        optionContainerPaddingRight={'2rem'}
                        themeStyles={(themeGlobal as any).influencerProfileEdit}
                        changeBackgroundColorOnHover={false}
                        changeContainerBorderColorOnHover={true}
                        innerOptionsContainerMaxHeight={'25rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <S.GeoLocationTitleContainer>
                        {props.t('generic.city')}
                      </S.GeoLocationTitleContainer>

                      <ComboBox
                        valueKey={'citySelected'}
                        value={contactDetailsCitySelected}
                        type={'single'}
                        width={'100%'}
                        borderRadius={'0.6rem'}
                        valuesAvailable={contactDetailsCitiesAvailableValues}
                        isFilterable={true}
                        isClearable={true}
                        onChange={(obj: {
                          citySelected: ICityComboBoxEntry;
                        }) => {
                          if (contactDetailsCitiesAvailableValues.length) {
                            setContactDetailsCitySelected(obj.citySelected);
                            setContactDetails(prevState => ({
                              ...prevState,
                              city: obj.citySelected?.data?.city,
                              cityId: obj.citySelected?.value,
                            }));
                          }
                        }}
                        placeholder={props.t('placeholder.selectCity')}
                        height={'4.4rem'}
                        availableOptionsContainerBorderRadius={'0.6rem'}
                        optionContainerBorderRadius={'0.6rem'}
                        innerOptionsContainerMarginTop={'1.2rem'}
                        innerOptionsContainerMarginBottom={'1.2rem'}
                        innerOptionsContainerPaddingLeft={'1.2rem'}
                        innerOptionsContainerPaddingRight={'1.2rem'}
                        optionContainerPaddingTop={'2rem'}
                        optionContainerPaddingBottom={'2rem'}
                        optionContainerPaddingLeft={'2rem'}
                        optionContainerPaddingRight={'2rem'}
                        themeStyles={(themeGlobal as any).companyEdit}
                        changeBackgroundColorOnHover={false}
                        changeContainerBorderColorOnHover={true}
                        innerOptionsContainerMaxHeight={'25rem'}
                        disabled={!contactDetailsCountrySelected?.value}
                      />
                    </S.CompanyDetailsFieldContainer>
                  </S.CompanyDetailsInnerContainer>
                </S.ContactDetailsContainer>
                <S.BillingDetailsContainer>
                  <S.SectionTitle>
                    {props.t('companyEdit.billingDetails')}
                  </S.SectionTitle>

                  <S.CompanyDetailsInnerContainer>
                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={billingDetails.name}
                        onChange={(inputValue: string) =>
                          setBillingDetails({
                            ...billingDetails,
                            name: inputValue,
                          })
                        }
                        title={props.t('generic.name')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('generic.insertName')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <InputControlV2
                        value={billingDetails.address1}
                        onChange={(inputValue: string) =>
                          setBillingDetails({
                            ...billingDetails,
                            address1: inputValue,
                          })
                        }
                        title={props.t('companyEdit.address')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.address')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={billingDetails.vat_id}
                        onChange={(inputValue: string) =>
                          setBillingDetails({
                            ...billingDetails,
                            vat_id: inputValue,
                          })
                        }
                        title={props.t('companyEdit.vatId')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.insertVatId')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <S.BillingDetailsVatExemptionTitle>
                        {props.t('companyEdit.vatExemption')}
                      </S.BillingDetailsVatExemptionTitle>

                      <S.BillingDetailsVatExemptionToggleContainer>
                        <ToggleButton
                          checked={
                            !billingDetails.vat_id
                              ? false
                              : billingDetails.vatExemption
                          }
                          setChecked={(checked: boolean) => {
                            setBillingDetails(prevState => ({
                              ...prevState,
                              vatExemption: checked,
                            }));
                          }}
                          width={'auto'}
                          toggleContainerCursor={
                            !billingDetails.vat_id ? 'not-allowed' : 'pointer'
                          }
                        />
                      </S.BillingDetailsVatExemptionToggleContainer>
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <InputControlV2
                        value={billingDetails.postalCode}
                        onChange={(inputValue: string) =>
                          setBillingDetails({
                            ...billingDetails,
                            postalCode: inputValue,
                          })
                        }
                        title={props.t('companyEdit.postalCode')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        placeholder={props.t('companyEdit.postalCode')}
                        inputTitleMarginBottom={'1.2rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingLeft={'2rem'}>
                      <S.GeoLocationTitleContainer>
                        {props.t('generic.country')}
                      </S.GeoLocationTitleContainer>

                      <ComboBox
                        valueKey={'countrySelected'}
                        value={billingDetailsCountrySelected}
                        type={'single'}
                        width={'100%'}
                        borderRadius={'0.6rem'}
                        valuesAvailable={countriesAvailableValues}
                        isFilterable={true}
                        isClearable={true}
                        onChange={(obj: {
                          countrySelected: ICountryComboBoxEntry;
                        }) => {
                          setBillingDetailsCountrySelected(obj.countrySelected);
                          setBillingDetails(prevState => ({
                            ...prevState,
                            country: obj.countrySelected?.value,
                            countryId: obj.countrySelected?.data?.countryId,
                          }));
                        }}
                        placeholder={props.t('placeholder.selectCountry')}
                        height={'4.4rem'}
                        availableOptionsContainerBorderRadius={'0.6rem'}
                        optionContainerBorderRadius={'0.6rem'}
                        innerOptionsContainerMarginTop={'1.2rem'}
                        innerOptionsContainerMarginBottom={'1.2rem'}
                        innerOptionsContainerPaddingLeft={'1.2rem'}
                        innerOptionsContainerPaddingRight={'1.2rem'}
                        optionContainerPaddingTop={'2rem'}
                        optionContainerPaddingBottom={'2rem'}
                        optionContainerPaddingLeft={'2rem'}
                        optionContainerPaddingRight={'2rem'}
                        themeStyles={(themeGlobal as any).companyEdit}
                        changeBackgroundColorOnHover={false}
                        changeContainerBorderColorOnHover={true}
                        innerOptionsContainerMaxHeight={'25rem'}
                      />
                    </S.CompanyDetailsFieldContainer>

                    <S.CompanyDetailsFieldContainer paddingRight={'2rem'}>
                      <S.GeoLocationTitleContainer>
                        {props.t('generic.city')}
                      </S.GeoLocationTitleContainer>

                      <ComboBox
                        valueKey={'citySelected'}
                        value={billingDetailsCitySelected}
                        type={'single'}
                        width={'100%'}
                        borderRadius={'0.6rem'}
                        valuesAvailable={billingDetailsCitiesAvailableValues}
                        isFilterable={true}
                        isClearable={true}
                        onChange={(obj: {
                          citySelected: ICityComboBoxEntry;
                        }) => {
                          if (billingDetailsCitiesAvailableValues.length) {
                            setBillingDetailsCitySelected(obj.citySelected);
                            setBillingDetails(prevState => ({
                              ...prevState,
                              city: obj.citySelected?.data?.city,
                              cityId: obj.citySelected?.value,
                            }));
                          }
                        }}
                        placeholder={props.t('placeholder.selectCity')}
                        height={'4.4rem'}
                        availableOptionsContainerBorderRadius={'0.6rem'}
                        optionContainerBorderRadius={'0.6rem'}
                        innerOptionsContainerMarginTop={'1.2rem'}
                        innerOptionsContainerMarginBottom={'1.2rem'}
                        innerOptionsContainerPaddingLeft={'1.2rem'}
                        innerOptionsContainerPaddingRight={'1.2rem'}
                        optionContainerPaddingTop={'2rem'}
                        optionContainerPaddingBottom={'2rem'}
                        optionContainerPaddingLeft={'2rem'}
                        optionContainerPaddingRight={'2rem'}
                        themeStyles={(themeGlobal as any).companyEdit}
                        changeBackgroundColorOnHover={false}
                        changeContainerBorderColorOnHover={true}
                        innerOptionsContainerMaxHeight={'25rem'}
                        disabled={!billingDetailsCountrySelected?.value}
                      />
                    </S.CompanyDetailsFieldContainer>
                  </S.CompanyDetailsInnerContainer>
                </S.BillingDetailsContainer>

                <S.DeleteAccountContainer>
                  <S.SectionTitle>
                    {props.t('companyEdit.deleteAccount')}
                  </S.SectionTitle>

                  <S.DeleteAccountButton
                    onClick={() => deletePartnerButtonClicked()}
                  >
                    <TrashCanIconV1
                      containerWidth="2rem"
                      containerHeight="2rem"
                    />

                    {props.t('companyEdit.deleteAccountButtonLabel')}
                  </S.DeleteAccountButton>
                </S.DeleteAccountContainer>

                <S.Footer>
                  <StyledButton
                    borderRadius={'0.6rem'}
                    paddingLeft={'2rem'}
                    paddingTop={'1rem'}
                    paddingBottom={'1rem'}
                    paddingRight={'2rem'}
                    marginRight={'1rem'}
                    themeStyles={getThemeStyles(
                      ['button', 'secondary-1'],
                      themeGlobal,
                    )}
                    onClick={() => cancelButtonClicked()}
                    disabled={!isCancelButtonEnabled()}
                  >
                    {props.t('generic.cancel')}
                  </StyledButton>

                  <StyledButton
                    borderRadius={'0.6rem'}
                    paddingLeft={'2rem'}
                    paddingRight={'2rem'}
                    paddingTop={'1rem'}
                    paddingBottom={'1rem'}
                    onClick={() => saveButtonClicked()}
                    disabled={!isSaveButtonEnabled()}
                  >
                    {props.t('generic.save')}
                  </StyledButton>
                </S.Footer>
              </S.CardInnerContainer>
            </S.CompanyProfileCard>
          </S.InnerContainer>
        </S.Container>
      )}

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={isLoadingUpdatePartner || isLoadingDeletePartner}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationText}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationText}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(CompanyEdit);
