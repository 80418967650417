/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';
import { RevokeSocialDataAccessInput } from 'Services/SkorrApi/new/Shares/types';

import SkorrApi from '../../Services/SkorrApi/new';
import { IErrorObject } from '../../Services/Utils/types';
import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';

const { shares } = SkorrApi;

export const useGetSharedSocialDataAccess = (enabled: boolean = false) => {
  return useQuery(
    [
      'get-shared-social-data-access',
      isAuthenticated()?.userId,
      isAuthenticated()?.partnerId,
      isAuthenticated()?.partnerType,
    ],
    async () => {
      try {
        const data = await shares.getSharedSocialDataAccess();

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useRevokeSocialDataAccess = () => {
  return useMutation(
    async (data: RevokeSocialDataAccessInput) => {
      try {
        await shares.revokeSocialDataAccess(data);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
