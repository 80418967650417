import { t } from 'i18next';
import { socialNetworkAvailableValues } from 'data/constants';

import * as S from './Styles';
import { ListeningFilterCalendar } from '../ListeningFilterCalendar';
import { ListeningFilterDropdown } from '../ListeningFilterDropdown';
import { ListeningFilterTextInput } from '../ListeningFilterTextInput';

const ClickOutHandler = require('react-onclickout');

type Props = {
  customHook: any;
};

export function ListeningFilter({ customHook }: Props) {
  const {
    network,
    setNetwork,
    searchWord,
    countryCode,
    setCountryCode,
    validSearchWord,
    toggleFilterButton,
    handleClearFilters,
    handleChangeSearchWord,
    handleGoToTermAnalytics,
    countriesAvailableValues,
    handleToggleFilterButton,
    handleClickOutToggleFilterButton,
  } = customHook;

  return (
    <>
      <S.FiltersButton onClick={handleToggleFilterButton}>
        <S.NotificationDot />
        <svg viewBox="0 0 20 20">
          <path d="M3 5C7.7 5 12.3 5 17 5H3ZM18 5C18 4.4 17.6 4 17 4H3C2.4 4 2 4.4 2 5C2 5.6 2.4 6 3 6H17C17.6 6 18 5.6 18 5ZM7 15C9 15 11 15 13 15H7ZM14 15C14 14.4 13.6 14 13 14H7C6.4 14 6 14.4 6 15C6 15.6 6.4 16 7 16H13C13.6 16 14 15.6 14 15ZM5 10C8.3 10 11.7 10 15 10H5ZM16 10C16 9.4 15.6 9 15 9H5C4.4 9 4 9.4 4 10C4 10.6 4.4 11 5 11H15C15.6 11 16 10.6 16 10Z" />
        </svg>
        {t('listening.filter')}
      </S.FiltersButton>

      <S.FilterSideBarBackground active={toggleFilterButton}>
        <ClickOutHandler onClickOut={handleClickOutToggleFilterButton}>
          <S.FilterSideBarContainer>
            <ListeningFilterTextInput
              title="keywords"
              value={searchWord}
              setValue={handleChangeSearchWord}
              validSearchWord={validSearchWord}
              placeholder={t('listening.searchPlaceholder')}
            />

            <ListeningFilterCalendar customHook={customHook} />

            <ListeningFilterDropdown
              isFilterable
              title="country"
              value={countryCode}
              valueKey="countryCode"
              setValue={setCountryCode}
              optionValues={countriesAvailableValues}
              placeholder={t('discover.countryPlaceholder')}
            />

            <ListeningFilterDropdown
              title="socialNetwork"
              value={network}
              valueKey="network"
              setValue={setNetwork}
              optionValues={socialNetworkAvailableValues}
              placeholder={t('discover.socialNetworkPlaceholder')}
            />

            <div style={{ marginBottom: '8rem' }} />

            <S.FilterSideBarFooterContainer>
              <S.FilterSideBarFooterButton
                secondary
                onClick={handleClearFilters}
              >
                <S.FilterSideBarFooterButtonText secondary>
                  {t('discover.clear')}
                </S.FilterSideBarFooterButtonText>
              </S.FilterSideBarFooterButton>

              <S.FilterSideBarFooterButton
                onClick={() => handleGoToTermAnalytics()}
              >
                <S.FilterSideBarFooterButtonText>
                  {t('discover.apply')}
                </S.FilterSideBarFooterButtonText>
              </S.FilterSideBarFooterButton>
            </S.FilterSideBarFooterContainer>
          </S.FilterSideBarContainer>
        </ClickOutHandler>
      </S.FilterSideBarBackground>
    </>
  );
}
