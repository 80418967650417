/* eslint-disable no-param-reassign */
import SkorrApi from 'Services/SkorrApi/new';
import { IErrorObject, User } from 'Services/Utils/types';
import { logApiRequestError } from 'Services/Utils/Utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  GetAdminPartnersRequest,
  CreateAdminPartnerRequest,
  UpdateAdminPartnerRequest,
  ValidateAdminPartnerRequest,
  CreateAdminPartnerAgentRequest,
  UpdateAdminPartnerAgentRequest,
  ValidateAdminPartnerAgentRequest,
} from './types';

const { user, files } = SkorrApi;

export const useGetAdminPartner = (id: string) => {
  return useQuery(
    ['get-admin-partner', id],
    async () => {
      try {
        const data = await user.getAdminPartner(id);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: id !== undefined,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useGetAdminPartners = (input: GetAdminPartnersRequest) => {
  return useQuery(
    ['get-admin-partners', { page: input.page }],
    async () => {
      try {
        const data = await user.getAdminPartners(input);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useCreateAdminPartner = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: CreateAdminPartnerRequest) => {
      try {
        if (params.fileFormData) {
          const { data: imageUrl } = await files.uploadImage(
            params.fileFormData,
          );
          params.picture = imageUrl;
        }

        const response = await user.createAdminPartner(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partners', { page: 0 }],
        });
      },
    },
  );
};

export const useUpdateAdminPartner = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateAdminPartnerRequest) => {
      try {
        if (params.fileFormData) {
          const { data: imageUrl } = await files.uploadImage(
            params.fileFormData,
          );
          params.picture = imageUrl;
        }

        const response = await user.updateAdminPartner(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partners', { page: 0 }],
        });
      },
    },
  );
};

export const useValidateAdminPartner = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ValidateAdminPartnerRequest) => {
      try {
        const response = await user.validateAdminPartner(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partners'],
        });
      },
    },
  );
};

export const useGetAdminPartnerAgent = (agentId: string, partnerId: string) => {
  return useQuery(
    ['get-admin-partner-agent', agentId, partnerId],
    async () => {
      try {
        const data = await user.getAdminPartnerAgent(agentId, partnerId);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: agentId !== undefined,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useGetAdminPartnerAgents = (partnerId: string) => {
  return useQuery(
    ['get-admin-partner-agents', partnerId],
    async () => {
      try {
        const data = await user.getAdminPartnerAgents(partnerId);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: partnerId !== undefined,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useCreateAdminPartnerAgent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: CreateAdminPartnerAgentRequest) => {
      try {
        if (params.fileFormData) {
          const { data: imageUrl } = await files.uploadImage(
            params.fileFormData,
          );
          params.displayPicture = imageUrl;
        }

        const response = await user.createAdminPartnerAgent(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partner-agents'],
        });
      },
    },
  );
};

export const useUpdateAdminPartnerAgent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateAdminPartnerAgentRequest) => {
      try {
        if (params.fileFormData) {
          const { data: imageUrl } = await files.uploadImage(
            params.fileFormData,
          );
          params.displayPicture = imageUrl;
        }

        const response = await user.updateAdminPartnerAgent(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partner-agents'],
        });
        queryClient.resetQueries({
          queryKey: ['get-agent-user'],
        });
        queryClient.resetQueries({
          queryKey: ['get-admin-partner-agent'],
        });
      },
    },
  );
};

export const useValidateAdminPartnerAgent = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ValidateAdminPartnerAgentRequest) => {
      try {
        const response = await user.validateAdminPartnerAgent(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: ['get-admin-partner-agents'],
        });
      },
    },
  );
};

export const useGetAdminPartnerAgentUser = (
  agentId: string,
  partnerId: string,
) => {
  return useQuery(
    ['get-admin-partner-agent-user', agentId, partnerId],
    async () => {
      try {
        const data: User = await user.getAdminPartnerAgentUser(
          agentId,
          partnerId,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: agentId !== undefined,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: 3,
    },
  );
};
