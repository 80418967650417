import { SocialNetworks } from 'Services/Utils/types';

export enum PostType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  REEL = 'REEL',
  CAROUSEL = 'CAROUSEL',
  STORY = 'STORY',
}

export type PostSource = 'Post' | 'DiscoverPost';

export type Post = {
  source: PostSource;
  id: string;
  date: string;
  type: PostType;
  content: string;
  url: string;
  image: string;
  keywordsMatched?: boolean;
  aspectRatio: number;
  kpis: {
    comments?: number;
    likes?: number;
    saves?: number;
    shares?: number;
    reactions?: number;
    engagementRate?: number;
    audience?: number;
    views?: number;
    reach?: number;
    follows?: number;
    navigation?: number;
    profile_activity?: number;
    total_interactions?: number;
    impressions?: number;
    skorr?: number;
    pulsedSkorr?: number;
  };
  user: {
    publicUserId?: string;
    socialUserId?: string;
    displayPicture?: string;
    socialNetwork: SocialNetworks;
    username: string;
    countryCode?: string;
    biography?: string;
  };
  relatedHashtags: string[];
  avgLikes?: number;
  avgComments?: number;
  avgViews?: number;
};

export type DiscoverPost = {
  id: string;
  date: string;
  kpi: {
    likes?: number;
    comments?: number;
    shares?: number;
    reactions?: number;
    audience?: number;
    engRate?: number;
    views?: number;
    skorr?: number;
    pulsedSkorr?: number;
  };
  link: string;
  content: string;
  image: string;
  user: {
    id: string;
    displayPicture: string;
    socialNetwork: SocialNetworks;
    username: string;
    audience: number;
    engRate: number;
    posts: number;
    biography: string;
    countryCode: string;
  };
  avgLikes?: number;
  avgComments?: number;
  avgViews?: number;
  aspectRatio: number;
  type: PostType;
  relatedHashtags: string[];
};

export interface ContentPost {
  id: string;
  publicUserId: string;
  socialUserId?: string;
  username: string;
  socialNetwork: SocialNetworks;
  date: Date;
  type: PostType;
  content: string;
  url: string;
  image: string;
  keywordsMatched?: boolean;
  insights: {
    comments: number;
    likes: number;
    saves: number;
    shares: number;
    engagementRate: number;
    reactions: number;
    views: number;
    reach: number;
    follows: number;
    navigation: number;
    profile_activity: number;
    total_interactions: number;
    impressions: number;
  };
  aspectRatio: number;
  user?: {
    displayPicture?: string;
    countryCode?: string;
    biography?: string;
  };
}

export type Benchmark = {
  id: string;
  businessProfileId: string;
  list: string[];
  name: string;
  partnerId: string;
  type: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  startAt?: string;
  endAt?: string | null;
  usersBasicInfo: {
    id: string;
    image?: string;
    network?: SocialNetworks;
    username?: string;
  }[];
};

export type FilteredKpisOverview = {
  publicUserId: string;
  name: string;
  displayPicture: string;
  socialNetwork: SocialNetworks;
  kpis: {
    total: {
      posts: number;
      followers: number;
      engRate: number;
    };
    growth_perc: {
      followers: number;
    };
  };
  color?: string;
};

export type BenchmarkOutput = {
  benchmarkInfo: Benchmark;
  kpisOverview: FilteredKpisOverview[];
  postsFeed: Post[];
};

export type BenchmarkOutputRaw = {
  benchmarkInfo: Benchmark;
  kpisOverview: FilteredKpisOverview[];
  postsFeed: ContentPost[];
};

export type GetBenchmarksRequest = {
  name?: string;
  page: number;
  limit: number;
};

export type CreateBenchmarkRequest = {
  name: string;
  list: string[];
  startAt: Date;
  endAt: Date | null;
  usersInfo?: any;
};

export type UpdateBenchmarkRequest = {
  id: string;
  name?: string;
  list?: string[];
  startAt?: Date;
  endAt?: Date | null;
};

export type SearchUserBenchmarkRequest = {
  username: string;
  socialNetwork: SocialNetworks;
};

export type SearchUserBenchmarkResponse = {
  username: string;
  socialNetwork: SocialNetworks;
  displayPicture: string;
  publicUserId: string;
};
