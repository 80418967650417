import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const SortButton = styled.div<{ active: boolean; disable: boolean }>`
  height: 4rem;
  display: flex;
  min-width: 15rem;
  color: #676767;
  font-weight: 400;
  line-height: 1.8;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  transition: all 0.3s ease;
  pointer-events: ${({ disable }) => (disable ? 'none' : 'all')};
  background-color: ${({ disable }) => (disable ? '#eff1f6' : 'transparent')};
  cursor: pointer;

  &:hover {
    background: #eff1f6;
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    fill: #676767;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
    transition: transform 1s;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : undefined)};
  }
`;

export const ClearContainer = styled.div`
  height: 2rem;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  padding-right: 1rem;
  border-right: 0.1rem solid #676767;

  &:hover {
    svg {
      fill: #292929;
    }
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding: 0 1.2rem;
  align-items: center;
  border-radius: 0.6rem;
  transition: all 0.3s ease;
  box-shadow: 0 0 0 0.2rem #f4f4f4;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 0.2rem #292929;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: #292929;
    margin-right: 1rem;
  }

  input {
    width: 100%;
    height: 4rem;
    border: none;
    outline: none;
    color: #292929;
    font-weight: 400;
    font-size: 1.4rem;
    transition: all 0.3s ease;

    &::placeholder {
      color: #292929;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #292929;
      -webkit-box-shadow: 0 0 0 40rem #fff inset;
    }
  }
`;

export const DropdownContainer = styled.div<{ active: boolean }>`
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 1rem;
  margin-top: 1rem;
  background: #fff;
  position: absolute;
  transition: all 0.08s;
  border-radius: 0.6rem;
  flex-direction: column;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.14),
    0 0.1rem 1.8rem rgba(0, 0, 0, 0.12), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
`;

export const DropdownItemContainer = styled.div<{
  active: boolean;
}>`
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  margin-top: 0.4rem;
  border-radius: 0.6rem;
  justify-content: center;
  background: ${({ active }) => (active ? '#eff1f6' : undefined)};
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const DropdownItemText = styled.div`
  color: #292929;
  font-weight: 400;
  overflow: hidden;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
