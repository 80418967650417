import {
  getSocialNetworkIcon,
  convertNumberToHumanStringfiedFormat,
} from 'Services/Utils/Utils';
import { t } from 'i18next';
import { GetPublicUsersByFilterResponse } from 'Services/SkorrApi/new/User/types';

import * as S from './Styles';

type Props = {
  users: GetPublicUsersByFilterResponse | undefined;
};

export function ListeningTermTop5Creators({ users }: Props) {
  return (
    <S.ChartContentContainer customWidth={55}>
      <S.ChartMetricLabel>{t('listeningTerm.topCreators')}</S.ChartMetricLabel>

      <S.CreatorsList>
        <S.CreatorsListHead>
          <S.RankNameContainer>
            <S.Rank>#</S.Rank>
            <S.Name>{t('listeningTerm.creator')}</S.Name>
          </S.RankNameContainer>

          <S.CreatorsMetric>{t('listeningTerm.engRate')}</S.CreatorsMetric>
        </S.CreatorsListHead>

        {users?.publicUsers?.map((user, index) => (
          <div key={user._id}>
            <S.CreatorContainer>
              <S.RankNameContainer>
                <S.Rank>{index + 1}</S.Rank>

                <S.CreatorAvatarContainer>
                  <S.CreatorAvatar>
                    <img src={user.image} />
                  </S.CreatorAvatar>

                  <S.NetworkIcon>
                    {getSocialNetworkIcon({
                      socialNetwork: user.socialNetwork?.network!,
                      width: '2rem',
                      height: '2rem',
                      gradientColored: true,
                    })}
                  </S.NetworkIcon>

                  <S.CreatorDetails>
                    <span>{user.socialNetwork?.username}</span>

                    <S.CreatorDetailMetric>
                      {convertNumberToHumanStringfiedFormat(
                        user.kpi?.audience ?? 0,
                      ) + t('listeningTerm.followers')}
                    </S.CreatorDetailMetric>
                  </S.CreatorDetails>
                </S.CreatorAvatarContainer>
              </S.RankNameContainer>

              <S.CreatorKpi>
                {((user.kpi?.engagement_rate ?? 0) * 100).toFixed(1)}%
              </S.CreatorKpi>
            </S.CreatorContainer>

            {users.publicUsers.length - 1 !== index && <S.Divider />}
          </div>
        ))}
      </S.CreatorsList>
    </S.ChartContentContainer>
  );
}
