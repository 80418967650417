import moment from 'moment';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { logApiRequestError } from 'Services/Utils/Utils';
import { TopicsOfInterest } from 'features/Discover/types';
import { IFile } from 'components/shared/FilePickerV2/types';
import { FileSourceEnum } from 'Services/SkorrApi/new/Files/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useGetLocations,
  useGetTopicsInterest,
} from 'Services/Utils/CustomHooks';
import {
  GENDERS,
  IErrorObject,
  TCountriesAvailableValues,
} from 'Services/Utils/types';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  useGetAdminPartnerAgent,
  useCreateAdminPartnerAgent,
  useUpdateAdminPartnerAgent,
  useGetAdminPartnerAgentUser,
} from '../api';
import {
  AdminAgentFormData,
  adminAgentFormSchema,
} from '../schemas/admin-agent-form';

export const useAdminAgent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: agentId } = useParams();
  const { state } = location || {};
  const { partnerId }: { partnerId: string } = state || {};

  const pageTypeLabel = agentId
    ? t('skorrAdmin.agent.updateAgent')
    : t('skorrAdmin.agent.createAgent');

  const toastLabel = agentId
    ? t('skorrAdmin.agent.updateAgentToast')
    : t('skorrAdmin.agent.createAgentToast');

  const [date, setDate] = useState();
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);

  const [files, setFiles] = useState<IFile[]>([]);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [topicsOfInterestAvailableValues, setTopicsOfInterestAvailableValues] =
    useState<TopicsOfInterest[]>([]);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const { data: topicsOfInterest, isFetching: isFetchingGetTopicsInterest } =
    useGetTopicsInterest(true);

  useEffect(() => {
    if (topicsOfInterest && !isFetchingGetTopicsInterest) {
      const topicsOfInterestAvailableValues: TopicsOfInterest[] = [];

      topicsOfInterest.forEach((entry, key) => {
        topicsOfInterestAvailableValues.push({
          value: key,
          label: t(entry),
        });
      });

      setTopicsOfInterestAvailableValues(topicsOfInterestAvailableValues);
    }
  }, [topicsOfInterest, isFetchingGetTopicsInterest]);

  const { data: adminAgent, isLoading: isLoadingGetAdminAgent } =
    useGetAdminPartnerAgent(agentId!, partnerId);

  const { data: adminAgentUser, isLoading: isLoadingGetAdminAgentUser } =
    useGetAdminPartnerAgentUser(agentId!, partnerId);

  const isLoadingAgentInfo =
    isLoadingGetAdminAgent || isLoadingGetAdminAgentUser;

  const adminAgentForm = useForm<AdminAgentFormData>({
    resolver: zodResolver(adminAgentFormSchema),
    defaultValues: {
      displayPicture: null,
      topicsOfInterest: [],
      gender: null,
      dateOfBirth: null,
      roleIds: [],
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = adminAgentForm;

  const {
    mutateAsync: createAdminAgent,
    isSuccess: isSuccessCreateAdminAgent,
  } = useCreateAdminPartnerAgent();

  const {
    mutateAsync: updateAdminAgent,
    isSuccess: isSuccessUpdateAdminAgent,
  } = useUpdateAdminPartnerAgent();

  const isSuccess = isSuccessCreateAdminAgent || isSuccessUpdateAdminAgent;

  const handleSubmitForm = useCallback(async () => {
    handleSubmit(async (data: AdminAgentFormData) => {
      try {
        let pictureFormData;
        if (files[0]?.data) {
          pictureFormData = new FormData();
          pictureFormData.append('file', files[0]!.data);
          pictureFormData.append('source', FileSourceEnum.USER_BRAND_PICTURE);
          pictureFormData.append('id', agentId ?? partnerId);
        }

        if (agentId === undefined) {
          await createAdminAgent({
            ...data,
            partnerId,
            fileFormData: pictureFormData,
            countryCode: data.country?.value || null,
            countryId: data.country?.data.countryId || null,
            roleIds: data.roleIds?.length === 0 ? undefined : data.roleIds,
          });
        } else {
          await updateAdminAgent({
            ...data,
            agentId,
            partnerId,
            fileFormData: pictureFormData,
            countryCode: data.country?.value || null,
            countryId: data.country?.data.countryId || null,
            roleIds: data.roleIds?.length === 0 ? undefined : data.roleIds,
          });
        }
      } catch (err) {
        toast.error(logApiRequestError(err as IErrorObject));
      }
    })();
  }, [
    handleSubmit,
    files,
    agentId,
    partnerId,
    createAdminAgent,
    updateAdminAgent,
  ]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(toastLabel);

      setTimeout(() => {
        handleGoBack();
      }, 1000);
    }
  }, [isSuccess, toastLabel, handleGoBack]);

  useEffect(() => {
    if (adminAgent && adminAgentUser) {
      resetForm({
        name: adminAgent.name,
        email: adminAgent.email,
        password: 'Fakepassword1!',
        displayPicture: adminAgent.img!,
        topicsOfInterest: adminAgentUser.topicsInterest ?? [],
        gender: (adminAgentUser.gender as GENDERS) ?? null,
        dateOfBirth: adminAgentUser.dateOfBirth
          ? moment(adminAgentUser.dateOfBirth).utc().valueOf()
          : null,
        roleIds: adminAgent.roleIds,
        country:
          adminAgent.countryId && adminAgentUser.countryCode
            ? {
                value: adminAgentUser.countryCode,
                data: {
                  country: adminAgentUser.countryCode,
                  countryId: adminAgent.countryId,
                },
              }
            : undefined,
      });
    }
  }, [adminAgent, adminAgentUser]);

  return {
    date,
    watch,
    errors,
    setDate,
    agentId,
    register,
    setValue,
    setFiles,
    handleGoBack,
    pageTypeLabel,
    adminAgentForm,
    handleSubmitForm,
    isLoadingAgentInfo,
    showCalendarPicker,
    setShowCalendarPicker,
    countriesAvailableValues,
    topicsOfInterestAvailableValues,
  };
};
