/* eslint-disable no-array-constructor */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createContext } from 'react';

import { ILooseObject } from '../Services/Utils/types';
/* React Date Range */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
/* React Toastify */
import 'react-toastify/dist/ReactToastify.css';
/* React Grid Layout */
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
/* Tippy */
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

export const globalThemeLight: ILooseObject = {
  button: {
    shared: {
      'margin-top': '0px',
      'margin-bottom': '0px',
      'margin-left': '0px',
      'margin-right': '0px',
      'padding-top': '1.1rem',
      'padding-bottom': '1.1rem',
      'padding-left': '1.2rem',
      'padding-right': '1.2rem',
      'border-radius': '1.7rem',
      'border-style': 'solid',
      'border-width': '0rem',
      'font-size': '1.6rem',
      'font-weight': '500',
      'font-family': '"Roboto", Arial, sans-serif',
      cursor: 'pointer',
      width: 'auto',
      height: 'auto',
      'disabled-background-color': '#F4F4F4',
      'hover-disabled-background-color': 'rgb(180, 180, 180)',
      'disabled-border-color': 'rgb(180, 180, 180)',
      'hover-disabled-border-color': 'rgb(180, 180, 180)',
      'disabled-color': '#BFBFBF',
      outline: '0',
    },
    primary: {
      'background-color': '#5870F6',
      'hover-background-color': 'rgba(88, 112, 246, 0.9)',
      'border-color': '#5870F6',
      'hover-border-color': 'rgba(88, 112, 246, 0.9)',
      color: 'rgb(255, 255, 255)',
      'hover-color': 'rgb(255, 255, 255)',
    },
    danger: {
      'background-color': '#CF223F',
      'hover-background-color': 'rgba(207, 34, 63, 0.9)',
      'border-color': '#CF223F',
      'hover-border-color': 'AD001D',
      color: 'rgb(255, 255, 255)',
      'hover-color': 'rgb(255, 255, 255)',
    },
    'secondary-1': {
      'background-color': 'rgb(241, 245, 249)',
      'hover-background-color': 'rgb(230, 230, 230)',
      'border-color': 'rgb(241, 245, 249)',
      'hover-border-color': 'rgb(241, 245, 249)',
      color: '#79828C',
      'hover-color': '#79828C',
    },
    'cancel-1': {
      'background-color': '#FFFFFF',
      'hover-background-color': 'rgba(255, 255, 255, 0.9)',
      'border-color': '#3C4858',
      'hover-border-color': '#3C4858',
      color: '#3C4858',
      'hover-color': '#3C4858',
    },
    'signin-facebook': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#676767',
      'border-style': 'solid',
      'border-width': '0.1rem',
      'border-color': '#6767674D',
      'background-color': 'transparent',
      'flex-grow': '1',
      display: 'flex',
      'max-width': '26.5rem',
      'margin-right': '2.4rem',
      'align-items': 'center',
      transition: 'all 0.08s',
      'hover-background-color': '#1877F2',
      'hover-border-color': '#1877F2',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'signin-facebook-icon-color': '#1877F2',
      'signin-facebook-icon-color-hover': '#FFFFFF',
      'font-weight': '400',
    },
    'signin-twitter': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#676767',
      'border-style': 'solid',
      'border-width': '0.1rem',
      'border-color': '#6767674D',
      'background-color': 'transparent',
      'flex-grow': '0',
      'flex-basis': '4rem',
      display: 'flex',
      'max-width': 'unset',
      'margin-right': '0',
      'align-items': 'center',
      transition: 'all 0.08s',
      'hover-background-color': '#1DA1F2',
      'hover-border-color': '#1DA1F2',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'signin-twitter-icon-color': '#1DA1F2',
      'signin-twitter-icon-color-hover': '#FFFFFF',
    },
    'validate-captcha': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#FFFFFF',
      'border-style': 'solid',
      'border-width': '0rem',
      display: 'flex',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'align-items': 'center',
      transition: 'all 0.08s',
      'background-color': '#5870F6',
      'hover-background-color': 'rgba(88, 112, 246, 0.9)',
      'border-color': '#5870F6',
      'hover-border-color': 'rgba(88, 112, 246, 0.9)',
    },
  },
  container: {
    'background-color-1': '#FFFFFF',
    'background-color-5': '#F4F7FD',
    'background-color-7': '#E0E5EA',
    'background-color-10': 'rgb(255, 255, 255)',
    'background-color-11': '#F7F8FA',
    'scrollbar-width': '0.8rem',
    'scrollbar-track-border-radius': '0.4rem',
    'scrollbar-track-background-color': '#F4F7FD',
    'scrollbar-thumb-border-radius': '0.4rem',
    'scrollbar-thumb-background-color': '#BFBFBF',
    'scrollbar-thumb-hover-background-color': '#676767',
  },
  text: {
    'color-1': '#000000',
    'color-4': '#004085',
    'color-8': '#212529',
    'color-10': '#162B39',
    'color-12': '#3C4858',
    'color-13': '#747070',
    'color-14': '#4A5057',
    'color-16': '#8492A6',
    'color-18': '#EBEEF1',
    'color-20': '#ffffff',
    'color-22': '#E0E5EA',
    'color-25': '#EA560D',
    'color-26': '#292929',
    'color-27': '#676767',
    'color-28': '#5870F6',
    'color-29': '#BFBFBF',
    'color-30': '#FFFFFF',
    'color-31': '#B5B5B5',
    'color-32': '#121212',
    'color-33': '#B00020',
    'font-family-1': '"Fira Sans", sans-serif',
    inputTitle: {
      'font-size': '15.2px',
      'font-weight': '500',
      'font-family': '"Fira Sans", sans-serif',
      color: 'rgb(74, 80, 87)',
    },
  },
  inputField: {
    'font-size': '14.4px',
    'font-weight': '400',
    'font-family': '"Fira Sans", sans-serif',
    display: 'inline-block',
    'margin-top': '0px',
    'margin-bottom': '0px',
    'margin-left': '0px',
    'margin-right': '0px',
    'padding-top': '0px',
    'padding-bottom': '0px',
    'padding-left': '20px',
    'padding-right': '20px',
    color: 'rgb(74, 80, 87)',
    'background-color': 'rgb(232, 240, 254)',
    'focus-background-color': '#e1e4e8',
    'border-color': 'rgba(199, 202, 216, 0)',
    'focus-border-color': '#dfe6ed',
    'warning-border-color': 'rgb(204, 0, 0)',
  },
  inputFieldV2: {
    'border-color-default': '#f4f4f4',
    'border-color-default-hover': '#292929',
    'border-color-default-focus': '#5870F6',
    'border-color-valid': '#14B56F',
    'border-color-warning': '#B00020',
    'border-style': 'solid',
    'border-width': '0.2rem',
    'border-radius': '0.6rem',
    cursor: 'pointer',
    'input-field-padding': '1.6rem',
    'input-field-font-size': '1.6rem',
    'input-field-outline': '0',
    'input-field-caret-color': '#292929',
    'input-field-color': '#292929',
    'input-field-line-height': '4.4rem',
    'input-field-height': '4.4rem',
    'input-field-container-margin-top': '0px',
    'input-field-container-margin-bottom': '0px',
    'input-field-container-margin-left': '0px',
    'input-field-container-margin-right': '0px',
    'input-field-container-with-title-margin-top': '0px',
    'input-field-container-with-title-margin-bottom': '0px',
    'input-field-container-with-title-margin-left': '0px',
    'input-field-container-with-title-margin-right': '0px',
    'error-icon-color': '#B00020',
    'check-mark-icon-color': '#14B56F',
    'try-again-button-color': '#0000ff',
    'password-eye-icon-color': '#292929',
    'loader-icon-color': '#ffffff',
    'mandatory-asterisk-color': '#B00020',
  },
  inputVerificationCodeSingle: {
    height: '4.4rem',
    'border-radius': '0.6rem',
    'border-style': 'solid',
    'border-width': '0.2rem',
    'padding-left': '1.6rem',
    'padding-right': '1.6rem',
    'padding-top': '1.6rem',
    'padding-bottom': '1.6rem',
    'font-size': '1.6rem',
    'border-color': '#F4F4F4',
    width: '100%',
    outline: '0',
    'caret-color': '#292929',
    color: '#292929',
    'line-height': '4.4rem',
  },
  inputTitle1: {
    'font-size': '1.4rem',
    'font-weight': '400',
    'line-height': '1rem',
    'margin-bottom': '1.2rem',
    color: '#676767',
    'color-hover': '#292929',
  },
  warningContainer: {
    shared: {
      'font-size': '12px',
      'font-weight': '300',
      'font-family': '"Fira Sans", sans-serif',
    },
    mandatoryField: { color: 'rgb(204, 0, 0)' },
    optionalField: { color: 'rgb(0, 0, 0)' },
  },
  comboBox: {
    'font-size': '1.6rem',
    'font-weight': '300',
    'font-family': '"Fira Sans", sans-serif',
    'border-width': '0.1rem',
    'border-style': 'solid',
    'header-container-border-radius': '10rem',
    'border-color': 'rgb(204, 204, 204)',
    'border-color-hover': '#8492A6',
    'border-color-warning': '#B00020',
    'options-container-border-radius': '1.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'creatable-mode-input-border-radius': '1.6rem',
    'option-selected-font-size': '1.52rem',
    'option-selected-font-weight': '400',
    'option-selected-font-family': '"Fira Sans", sans-serif',
    'option-selected-border-width': '0.1rem',
    'option-selected-border-style': 'solid',
    'option-selected-border-radius': '0rem',
    'cross-font-size': '1.6rem',
    'arrow-font-size': '2.1rem',
    'warning-font-size': '1.6rem',
    'warning-weight': '300',
    'warning-font-family': '"Fira Sans", sans-serif',
    'scrollbar-width': '0.5rem',
    'scrollbar-height': '0.5rem',
    'scrollbar-tract-border-radius': '0.6rem',
    'scrollbar-thumb-border-radius': '0.6rem',
    'options-selected-placeholder-font-size': '1.6rem',
    'options-selected-placeholder-font-weight': '300',
    'options-selected-placeholder-font-family': '"Fira Sans", sans-serif',
    'option-selected-cross-font-size': '1.2rem',
    color: 'rgb(0, 0, 0)',
    'background-color': 'rgb(255, 255, 255)',
    'background-color-hover': '#EFF1F6',
    'hover-color': 'rgb(0, 0, 0)',
    'disabled-background-color': 'rgb(241, 245, 249)',
    'background-color-menu-open': 'unset',
    'background-color-hover-menu-open': 'unset',
    'option-selected-background-color': 'rgb(200, 200, 200)',
    'option-selected-color': 'rgb(0, 0, 0)',
    'option-border-color': 'rgb(190, 190, 190)',
    'option-selected-cross-container-background-color': 'rgb(255, 130, 130)',
    'option-selected-cross-container-background-color-hover':
      'rgb(255, 130, 130)',
    'option-selected-cross-color': 'rgb(190, 0, 0)',
    'option-selected-cross-color-hover': 'rgb(190, 0, 0)',
    'warning-color': 'rgb(0, 0, 0)',
    'scrollbar-track-background-color': '#f1f5f9',
    'scrollbar-corner': '#f1f5f9',
    'scrollbar-thumb-background-color': '#9b9b9b',
    'scrollbar-thumb-hover': '#555',
    'options-selected-placeholder-color': '#8492A6',
    'disabled-options-selected-placeholder-color': 'rgb(0, 0, 0)',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-border-radius': '1.6rem',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#000000',
    'option-container-color-hover': '#000000',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '300',
    'input-filter-color': '#292929',
    'input-filter-font-size': '1.4rem',
    'input-filter-font-weight': '400',
    'inner-options-container-max-height': '15rem',
  },
  comboBoxSocialNetworkSelectionFilter: {
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'header-container-border-radius': '0.6rem',
    'disabled-background-color': '#F1F5F9',
    'background-color': '#F7F8FA',
    'background-color-hover': '#F3F4F8',
    'background-color-menu-open': '#FFFFFF',
    'background-color-hover-menu-open': '#FFFFFF',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'option-container-border-radius': '0.6rem',
    'options-container-border-radius': '0.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
  },
  agentRolesSelectionComponent: {
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'header-container-border-radius': '0.6rem',
    'disabled-background-color': '#F1F5F9',
    'background-color': '#F7F8FA',
    'background-color-hover': '#F3F4F8',
    'background-color-menu-open': '#FFFFFF',
    'background-color-hover-menu-open': '#FFFFFF',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'option-container-border-radius': '0.6rem',
    'options-container-border-radius': '0.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#000000',
    'option-selected-font-weight': '400',
    'option-selected-container-padding-left': '1rem',
    'option-selected-container-padding-right': '0.6rem',
    'option-selected-container-padding-top': '0.4rem',
    'option-selected-container-padding-bottom': '0.4rem',
    'option-selected-cross-margin-left': '0.7rem',
    'inner-options-container-margin-top': '1.2rem',
    'inner-options-container-margin-bottom': '1.2rem',
    'inner-options-container-padding-left': '1.2rem',
    'inner-options-container-padding-right': '1.2rem',
    'option-container-padding-top': '1rem',
    'option-container-padding-bottom': '1rem',
    'option-container-padding-left': '1rem',
    'option-container-padding-right': '1rem',
  },
  comboBoxTopicsOfInterest: {
    'border-color': '#F4F4F4',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  influencerProfileEdit: {
    'border-color': '#F4F4F4',
    'border-color-hover': '#292929',
    'arrow-color-hover': '#292929',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#292929',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  companyEdit: {
    'border-color': '#F4F4F4',
    'border-color-hover': '#292929',
    'arrow-color-hover': '#292929',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#8492A6',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#292929',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  comboBoxTags: {
    'border-color': '#F4F4F4',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '1.5rem',
    'option-selected-cross-margin-right': '0rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'inner-options-container-max-height': '25rem',
  },
  checkBox: {
    'border-color': '#BFBFBF',
    'border-color-checked': '#5870F6',
    'background-color-checked': '#5870F6',
    'check-mark-icon-color': '#FFFFFF',
  },
  filePicker: {
    'border-radius': '20px',
    height: '40px',
    'font-size': '14.4px',
    'font-weight': '500',
    'font-family': '"Fira Sans", sans-serif',
    'padding-left': '20px',
    'padding-right': '20px',
    'cross-font-size': '11.2px',
    'cross-font-weight': '500',
    'cross-font-family': '"Fira Sans", sans-serif',
    'file-selected-font-size': '11.2px',
    'file-selected-font-weight': '500',
    'file-selected-font-family': '"Fira Sans", sans-serif',
    'scrollbar-width': '5px',
    'scrollbar-height': '5px',
    'scrollbar-tract-border-radius': '6px',
    'scrollbar-thumb-border-radius': '6px',
    'background-color': 'rgb(88, 112, 246)',
    'border-color': 'rgb(88, 112, 246)',
    'background-color-hover': 'rgba(88, 112, 246, 0.9)',
    'border-color-hover': 'rgba(88, 112, 246, 0.9)',
    color: 'rgb(255, 255, 255)',
    'cross-color': 'rgb(0, 0, 0)',
    'file-selected-color': 'rgb(0, 0, 0)',
    'scrollbar-track-background-color': '#f1f5f9',
    'scrollbar-corner': '#f1f5f9',
    'scrollbar-thumb-background-color': '#9b9b9b',
    'scrollbar-thumb-hover': '#555',
  },
  brandSelectionAfterLogin: {
    topHelperMessageContainer: {
      'background-color': '#CCE5FF',
      'border-color': '#B8DAFF',
      'icon-color': '#FFFFFF',
    },
    'selected-brand-profile-card-border-color': '#008000',
  },
  modal: {
    'border-width': '1px',
    'border-style': 'solid',
    'border-color': 'rgb(0, 0, 0, 0.2)',
    'cross-hover-color': '#516084',
  },
  'box-shadow': {
    'color-1': 'rgba(0, 0, 0, 0.1)',
    'color-2': 'rgba(216, 221, 230, 0.35)',
    'color-3': '#b3c9df',
  },
  'back-navigation-button': {
    color: '#162b39',
    'background-color': '#ffffff',
    'border-color': '#8492a6',
    'hover-color': '#ffffff',
    'hover-background-color': '#162b39',
    'hover-border-color': '#162b39',
  },
  'back-navigation-button-navbar': {
    height: '104px',
  },
  'menu-navbar': {
    text: {
      'color-1': '#8492A6',
      'color-2': '#162B39',
    },
    'background-color-1': '#FFFFFF',
    'icon-notification-circle': {
      'background-color-1': '#F5A623',
    },
    'dropdown-menu': {
      'border-color': '#8391B2',
      'entry-background-color-1': '#FFFFFF',
      'hover-entry-background-color-1': '#EDF0F5',
      'bottom-divider-color': '#E0E5EA',
      'section-title': '#8492A6',
      'info-tooltip-circle-color': '#8494A9',
      'info-tooltip-circle-color-hover': '#16344B',
    },
    height: '60px',
    tooltip: {
      color: '#333333',
      'border-color': '#CCCCCC',
      'background-color': '#FFFFFF',
    },
  },
  'social-networks': {
    facebook: {
      'background-color': '#e1eeff',
      color: '#1877F2',
    },
    'facebook-page': {
      'background-color': '#ffe6cb',
      color: '#F48226',
    },
    'facebook-page-instagram': {
      'background-color': '#f1e6ff',
      color: '#C32AA3',
    },
    twitter: {
      'background-color': '#e0f3ff',
      color: '#1DA1F2',
    },
    youtube: {
      'background-color': '#e0f3ff',
      color: '#FF0000',
      disabled: {
        color: 'rgb(191, 191, 191)',
      },
    },
    instagram: {
      'background-color': '#e0f3ff',
      color: '#FFFFFF',
      gradient: {
        color: 'url(#paint0_linear_110_310)',
      },
      disabled: {
        color: 'rgb(191, 191, 191)',
      },
    },
  },
  icons: {
    'exclamation-mark': {
      color: '#EA560D',
    },
    eyeV2Icon: '#8494A9',
    eyeV3Icon: '#8494A9',
    copyIconV1: '#8494A9',
    editIconV2: '#8494A9',
    calendarIconV1: '#8494A9',
    gearIconV1: '#8494A9',
    rightArrowIconV1: '#8494A9',
    plusIconV1: '#8494A9',
    emptyContentIconV1: '#8494A9',
    trashCanIconV1: '#8494A9',
    uploadIconV1: '#8494A9',
    bulletListIconV1: '#8494A9',
    color1: '#8494A9',
    color2: '#676767',
  },
  tooltip: {
    'background-color': '#000000',
    color: '#ffffff',
    'border-color': 'rgb(204, 204, 204)',
    'box-shadow-color': 'rgba(0, 0, 0, 0.1)',
  },
  signin: {
    'signin-method-divider-background-color': '#EEEEEE',
    'signin-method-divider-pseudo-element-background-color': '#FFFFFF',
    'signin-method-divider-pseudo-element-color': '#B5B5B5',
    'signin-content-divider-color': 'rgba(219, 219, 219, 0.5)',
    'signin-form-footer-color': '#676767',
    'signin-forgot-password-header-divider-color': 'rgba(219, 219, 219, 0.5)',
    'signin-forgot-password-back-arrow-color': '#000000',
    'signin-forgot-password-back-arrow-container-background-color-hover':
      '#F4F4F480',
    'signin-container-background-color-responsive': '#FFFFFF',
    'regular-notify': {
      'background-color': '#292929',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
    },
    'success-notify': {
      'background-color': '#14B56F',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
      'check-mark-icon-color': '#FFFFFF',
    },
    'error-notify': {
      'background-color': '#E01627',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
      'cross-icon-color': '#FFFFFF',
    },
  },
  'toggle-button': {
    'circle-background-color': '#FFFFFF',
    'circle-background-color-checked': '#5870F6',
    'circle-box-shadow-color': 'rgba(0, 0, 0, 0.6)',
    'track-background-color': '#BFBFBF',
    'track-background-color-checked': '#BFC9FB',
  },
  'exclamation-point-icon': {
    'error-notify-color': '#FFFFFF',
    'info-tooltip-color': '#8492A6',
  },
  'question-mark-icon': {
    color: '#DBDBDB',
  },
  signup: {
    'manager-account-tooltip-container-box-shadow-color-1':
      'rgba(0, 0, 0, 0.14)',
    'manager-account-tooltip-container-box-shadow-color-2':
      'rgba(0, 0, 0, 0.12)',
    'manager-account-tooltip-container-box-shadow-color-3':
      'rgba(0, 0, 0, 0.2)',
    'manager-account-tooltip-container-background-color': '#F4F4F4',
    'manager-account-tooltip-divider': '#DBDBDB',
    'manager-account-tooltip-color': '#292929',
    'manager-account-tooltip-link-color': 'rgb(88, 112, 246)',
    'manager-account-tooltip-container-border-color': '#E4E4E4',
    'apple-button': {
      'background-color': '#000000',
      color: '#FFFFFF',
      'border-color': '#A6A6A6',
    },
    'android-button': {
      'background-color': '#000000',
      color: '#FFFFFF',
      'border-color': 'A6A6A6',
      'stop-color-1': '#00A0FF',
      'stop-color-2': '#00A1FF',
      'stop-color-3': '#00BEFF',
      'stop-color-4': '#00D2FF',
      'stop-color-5': '#00DFFF',
      'stop-color-6': '#00E3FF',
      'stop-color-7': '#FFE000',
      'stop-color-8': '#FFBD00',
      'stop-color-9': '#FFA500',
      'stop-color-10': '#FF9C00',
      'stop-color-11': '#FF3A44',
      'stop-color-12': '#C31162',
      'stop-color-13': '#32A071',
      'stop-color-14': '#2DA771',
      'stop-color-15': '#15CF74',
      'stop-color-16': '#06E775',
      'stop-color-17': '#00F076',
    },
    'envelope-color': '#FFFFFF',
  },
  'additional-data-step': {
    'outer-container-background-color': '#F4F7FD',
    'background-color': '#FFFFFF',
    'box-shadow-color-1': 'rgba(0, 0, 0, 0.14)',
    'box-shadow-color-2': 'rgba(0, 0, 0, 0.12)',
    'box-shadow-color-3': 'rgba(0, 0, 0, 0.2)',
    'facebook-icon-color': '#FFFFFF',
    'facebook-page-instagram-icon-color': '#FFFFFF',
    'youtube-icon-color': '#FF0000',
    'instagram-icon-color': 'url(#paint0_linear_110_310)',
    'facebook-button': {
      'background-color': '#1877F2',
      'border-radius': '0.6rem',
    },
    'twitter-icon-color': '#FFFFFF',
    'twitter-button': {
      'background-color': '#1DA1F2',
      'border-radius': '0.6rem',
    },
    'empty-picture-container-background-color': '#F4F4F4',
    'empty-picture-icon-color': '#BFBFBF',
  },
  loaderLocal: {
    'container-background-color': 'rgba(0, 0, 0, 0.5)',
    'message-color': '#ffffff',
  },
  dropdownMenu: {
    'border-color': '#000000',
    'container-background-color': '#F9F9FB',
    'header-container-background-color': '#F9F9FB',
    'dropdown-menu-arrow-color': '#BFBFBF',
    'dropdown-menu-cross-color': '#BFBFBF',
  },
  socialNetworkAccountSelectionComponent: {
    'input-filter': {
      'background-color': '#F7F8FA',
      'background-color-hover': '#EFF1F6',
    },
    'magnifying-glass': {
      color: '#8492A6',
    },
    'not-active-social-network-accounts-divider-color': '#EEEEEE',
    'add-social-network-account-color': '#5870F6',
    'not-active-social-network-account-row-background-color-hover': '#F7F8FA',
    'active-social-network-account-container-background-color-hover': '#F7F8FA',
    'active-social-network-account-close-button-background-color': '#BFBFBF',
    'active-social-network-account-close-button-background-color-hover':
      '#676767',
    'expired-token-icon-background-color': '#FEB038',
    'expired-token-tooltip-container-background-color': '#F4F4F4',
    'expired-token-tooltip-container-border-color': '#E4E4E4',
    'expired-token-tooltip-color': '#292929',
    'expired-token-tooltip-link-color': '#5870F6',
  },
  listExternalMediaKitConfigurations: {
    configurationTypeBeingDisplayed: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.3)',
      cursor: 'default',
      'hover-background-color': '#5870F6',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    },
  },
  createExternalMediaKitConfiguration: {
    createButton: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.3)',
    },
  },
  createPortfolioConfiguration: {
    customPartnerBeingCreatedSaveButton: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.3)',
      'pointer-events-on-disable': 'all',
      'cursor-on-disable': 'pointer',
    },
  },
  'error-boundary': {
    'cross-icon-color': '#FFFFFF',
  },
  'earth-icon-default-color': '#BFBFBF',
  'dark-mode': false,
};

export const globalThemeDark: ILooseObject = {
  button: {
    shared: {
      'margin-top': '0px',
      'margin-bottom': '0px',
      'margin-left': '0px',
      'margin-right': '0px',
      'padding-top': '11px',
      'padding-bottom': '11px',
      'padding-left': '12px',
      'padding-right': '12px',
      'border-radius': '17px',
      'border-style': 'solid',
      'border-width': '0px',
      'font-size': '16px',
      'font-weight': '500',
      'font-family': '"Roboto", Arial, sans-serif',
      cursor: 'pointer',
      width: 'auto',
      height: 'auto',
      'disabled-background-color': '#F4F4F4',
      'hover-disabled-background-color': 'rgb(180, 180, 180)',
      'disabled-border-color': 'rgb(180, 180, 180)',
      'hover-disabled-border-color': 'rgb(180, 180, 180)',
      'disabled-color': '#BFBFBF',
    },
    primary: {
      'background-color': 'rgb(0, 0, 153)',
      'hover-background-color': 'rgb(0, 0, 204)',
      'border-color': 'rgb(0, 0, 153)',
      'hover-border-color': 'rgb(0, 0, 204)',
      color: 'rgb(255, 255, 255)',
      'hover-color': 'rgb(255, 255, 255)',
    },
    danger: {
      'background-color': 'rgb(153,0,0)',
      'hover-background-color': 'rgb(204, 0, 0)',
      'border-color': 'rgb(153,0,0)',
      'hover-border-color': 'rgb(204, 0, 0)',
      color: 'rgb(255, 255, 255)',
      'hover-color': 'rgb(255, 255, 255)',
    },
    'secondary-1': {
      'background-color': 'rgb(141, 145, 149)',
      'hover-background-color': 'rgba(141, 145, 149, 0.9)',
      'border-color': 'rgb(141, 145, 149)',
      'hover-border-color': 'rgb(141, 145, 149)',
      color: '#BDC6CF',
      'hover-color': '#BDC6CF',
    },
    'cancel-1': {
      'background-color': '#000000',
      'hover-background-color': 'rgba(0, 0, 0, 0.9)',
      'border-color': '#7F8C9C',
      'hover-border-color': '#7F8C9C',
      color: '#7F8C9C',
      'hover-color': '#7F8C9C',
    },
    'signin-facebook': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#676767',
      'border-style': 'solid',
      'border-width': '0.1rem',
      'border-color': '#6767674D',
      'background-color': 'transparent',
      'flex-grow': '1',
      display: 'flex',
      'max-width': '26.5rem',
      'margin-right': '2.4rem',
      'align-items': 'center',
      transition: 'all 0.08s',
      'hover-background-color': '#1877F2',
      'hover-border-color': '#1877F2',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'signin-facebook-icon-color': '#1877F2',
      'signin-facebook-icon-color-hover': '#FFFFFF',
      'font-weight': '400',
    },
    'signin-twitter': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#676767',
      'border-style': 'solid',
      'border-width': '0.1rem',
      'border-color': '#6767674D',
      'background-color': 'transparent',
      'flex-grow': '0',
      'flex-basis': '4rem',
      display: 'flex',
      'max-width': 'unset',
      'margin-right': '0',
      'align-items': 'center',
      transition: 'all 0.08s',
      'hover-background-color': '#1DA1F2',
      'hover-border-color': '#1DA1F2',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'signin-twitter-icon-color': '#1DA1F2',
      'signin-twitter-icon-color-hover': '#FFFFFF',
    },
    'validate-captcha': {
      'border-radius': '0.6rem',
      outline: '0',
      height: '4rem',
      'font-size': '1.6rem',
      color: '#FFFFFF',
      'border-style': 'solid',
      'border-width': '0rem',
      display: 'flex',
      'hover-color': '#FFFFFF',
      'justify-content': 'unset',
      cursor: 'pointer',
      'align-items': 'center',
      transition: 'all 0.08s',
      'background-color': '#5870F6',
      'hover-background-color': 'rgba(88, 112, 246, 0.9)',
      'border-color': '#5870F6',
      'hover-border-color': 'rgba(88, 112, 246, 0.9)',
    },
  },
  container: {
    'background-color-1': '#444444',
    'background-color-5': '#999999',
    'background-color-7': '#A6A1A6',
    'background-color-10': 'rgb(0, 0, 0)',
    'background-color-11': '#A1A2A5',
    'scrollbar-width': '0.8rem',
    'scrollbar-track-border-radius': '0.4rem',
    'scrollbar-track-background-color': '#AAAAAA',
    'scrollbar-thumb-border-radius': '0.4rem',
    'scrollbar-thumb-background-color': '#6A6A6A',
    'scrollbar-thumb-hover-background-color': '#121212',
  },
  text: {
    'color-1': 'rgb(255, 255, 255)',
    'color-4': '#2262A7',
    'color-8': '#ffffff',
    'color-10': '#ffffff',
    'color-12': '#ffffff',
    'color-13': '#A8A4A4',
    'color-14': '#ffffff',
    'color-16': '#ffffff',
    'color-18': '#EBEEF1',
    'color-20': '#ffffff',
    'color-22': '#E0E5EA',
    'color-25': '#EA560D',
    'color-26': '#7E7E7E',
    'color-27': '#121212',
    'color-28': '#5870F6',
    'color-29': '#BFBFBF',
    'color-30': '#000000',
    'color-31': '#B5B5B5',
    'color-32': '#121212',
    'color-33': '#B00020',
    'font-family-1': '"Fira Sans", sans-serif',
    inputTitle: {
      'font-size': '15.2px',
      'font-weight': '500',
      'font-family': '"Fira Sans", sans-serif',
      color: 'rgb(255, 255, 255)',
    },
  },
  inputField: {
    'font-size': '14.4px',
    'font-weight': '400',
    'font-family': '"Fira Sans", sans-serif',
    display: 'inline-block',
    'margin-top': '0px',
    'margin-bottom': '0px',
    'margin-left': '0px',
    'margin-right': '0px',
    'padding-top': '0px',
    'padding-bottom': '0px',
    'padding-left': '20px',
    'padding-right': '20px',
    color: 'rgb(74, 80, 87)',
    'background-color': 'rgb(232, 240, 254)',
    'focus-background-color': 'rgb(211, 215, 219)',
    'border-color': 'rgba(230, 222, 226, 0)',
    'focus-border-color': 'rgb(200, 200, 200)',
    'warning-border-color': 'rgb(255, 0, 0)',
  },
  inputFieldV2: {
    'border-color-default': '#f4f4f4',
    'border-color-default-hover': '#292929',
    'border-color-default-focus': '#5870F6',
    'border-color-valid': '#14B56F',
    'border-color-warning': '#B00020',
    'border-style': 'solid',
    'border-width': '0.2rem',
    'border-radius': '0.6rem',
    cursor: 'pointer',
    'input-field-padding': '1.6rem',
    'input-field-font-size': '1.6rem',
    'input-field-outline': '0',
    'input-field-caret-color': '#292929',
    'input-field-color': '#292929',
    'input-field-line-height': '4.4rem',
    'input-field-height': '4.4rem',
    'input-field-container-margin-top': '0px',
    'input-field-container-margin-bottom': '0px',
    'input-field-container-margin-left': '0px',
    'input-field-container-margin-right': '0px',
    'input-field-container-with-title-margin-top': '0px',
    'input-field-container-with-title-margin-bottom': '0px',
    'input-field-container-with-title-margin-left': '0px',
    'input-field-container-with-title-margin-right': '0px',
    'error-icon-color': '#B00020',
    'check-mark-icon-color': '#14B56F',
    'try-again-button-color': '#0000ff',
    'password-eye-icon-color': '#ffffff',
    'loader-icon-color': '#ffffff',
    'mandatory-asterisk-color': '#B00020',
  },
  inputVerificationCodeSingle: {
    height: '4.4rem',
    'border-radius': '0.6rem',
    'border-style': 'solid',
    'border-width': '0.2rem',
    'padding-left': '1.6rem',
    'padding-right': '1.6rem',
    'padding-top': '1.6rem',
    'padding-bottom': '1.6rem',
    'font-size': '1.6rem',
    'border-color': '#F4F4F4',
    width: '100%',
    outline: '0',
    'caret-color': '#292929',
    color: '#292929',
    'line-height': '4.4rem',
  },
  inputTitle1: {
    'font-size': '1.4rem',
    'font-weight': '400',
    'line-height': '1rem',
    'margin-bottom': '1.2rem',
    color: '#BCBCBC',
    'color-hover': '#7E7E7E',
  },
  warningContainer: {
    shared: {
      'font-size': '12px',
      'font-weight': '400',
      'font-family': '"Fira Sans", sans-serif',
    },
    mandatoryField: { color: 'rgb(255, 0, 0)' },
    optionalField: { color: 'rgb(255, 255, 255)' },
  },
  comboBox: {
    'font-size': '1.6rem',
    'font-weight': '300',
    'font-family': '"Fira Sans", sans-serif',
    'border-width': '0.1rem',
    'border-style': 'solid',
    'header-container-border-radius': '10rem',
    'border-color': 'rgb(204, 204, 204)',
    'border-color-hover': '#8492A6',
    'border-color-warning': '#B00020',
    'options-container-border-radius': '1.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'creatable-mode-input-border-radius': '1.6rem',
    'option-selected-font-size': '1.52rem',
    'option-selected-font-weight': '400',
    'option-selected-font-family': '"Fira Sans", sans-serif',
    'option-selected-border-width': '0.1rem',
    'option-selected-border-style': 'solid',
    'option-selected-border-radius': '0rem',
    'cross-font-size': '1.6rem',
    'arrow-font-size': '2.1rem',
    'warning-font-size': '1.6rem',
    'warning-weight': '300',
    'warning-font-family': '"Fira Sans", sans-serif',
    'scrollbar-width': '0.5rem',
    'scrollbar-height': '0.5rem',
    'scrollbar-tract-border-radius': '0.6rem',
    'scrollbar-thumb-border-radius': '0.6rem',
    'options-selected-placeholder-font-size': '1.6rem',
    'options-selected-placeholder-font-weight': '300',
    'options-selected-placeholder-font-family': '"Fira Sans", sans-serif',
    'option-selected-cross-font-size': '1.2rem',
    color: 'rgb(0, 0, 0)',
    'background-color': 'rgb(255, 255, 255)',
    'background-color-hover': '#EFF1F6',
    'hover-color': 'rgb(0, 0, 0)',
    'disabled-background-color': 'rgb(241, 245, 249)',
    'background-color-menu-open': 'unset',
    'background-color-hover-menu-open': 'unset',
    'option-selected-background-color': 'rgb(200, 200, 200)',
    'option-selected-color': 'rgb(0, 0, 0)',
    'option-border-color': 'rgb(190, 190, 190)',
    'option-selected-cross-container-background-color': 'rgb(255, 130, 130)',
    'option-selected-cross-container-background-color-hover':
      'rgb(255, 130, 130)',
    'option-selected-cross-color': 'rgb(190, 0, 0)',
    'option-selected-cross-color-hover': 'rgb(190, 0, 0)',
    'warning-color': 'rgb(0, 0, 0)',
    'scrollbar-track-background-color': '#f1f5f9',
    'scrollbar-corner': '#f1f5f9',
    'scrollbar-thumb-background-color': '#9b9b9b',
    'scrollbar-thumb-hover': '#555',
    'options-selected-placeholder-color': '#8492A6',
    'disabled-options-selected-placeholder-color': 'rgb(0, 0, 0)',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-border-radius': '1.6rem',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#000000',
    'option-container-color-hover': '#000000',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '300',
    'input-filter-color': '#292929',
    'input-filter-font-size': '1.4rem',
    'input-filter-font-weight': '400',
    'inner-options-container-max-height': '15rem',
  },
  comboBoxSocialNetworkSelectionFilter: {
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'header-container-border-radius': '0.6rem',
    'disabled-background-color': '#F1F5F9',
    'background-color': '#F7F8FA',
    'background-color-hover': '#F3F4F8',
    'background-color-menu-open': '#FFFFFF',
    'background-color-hover-menu-open': '#FFFFFF',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'option-container-border-radius': '0.6rem',
    'options-container-border-radius': '0.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
  },
  agentRolesSelectionComponent: {
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'header-container-border-radius': '0.6rem',
    'disabled-background-color': '#F1F5F9',
    'background-color': '#F7F8FA',
    'background-color-hover': '#F3F4F8',
    'background-color-menu-open': '#FFFFFF',
    'background-color-hover-menu-open': '#FFFFFF',
    'cross-color': '#BFBFBF',
    'cross-color-hover': '#8492A6',
    'arrow-color': '#BFBFBF',
    'arrow-color-hover': '#8492A6',
    'option-container-background-color': '#FFFFFF',
    'option-container-background-color-hover': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'option-container-border-radius': '0.6rem',
    'options-container-border-radius': '0.6rem',
    'options-container-box-shadow':
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#000000',
    'option-selected-font-weight': '400',
    'option-selected-container-padding-left': '1rem',
    'option-selected-container-padding-right': '0.6rem',
    'option-selected-container-padding-top': '0.4rem',
    'option-selected-container-padding-bottom': '0.4rem',
    'option-selected-cross-margin-left': '0.7rem',
    'inner-options-container-margin-top': '1.2rem',
    'inner-options-container-margin-bottom': '1.2rem',
    'inner-options-container-padding-left': '1.2rem',
    'inner-options-container-padding-right': '1.2rem',
    'option-container-padding-top': '1rem',
    'option-container-padding-bottom': '1rem',
    'option-container-padding-left': '1rem',
    'option-container-padding-right': '1rem',
  },
  comboBoxTopicsOfInterest: {
    'border-color': '#F4F4F4',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  influencerProfileEdit: {
    'border-color': '#F4F4F4',
    'border-color-hover': '#292929',
    'arrow-color-hover': '#292929',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  companyEdit: {
    'border-color': '#F4F4F4',
    'border-color-hover': '#292929',
    'arrow-color-hover': '#292929',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#8492A6',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#292929',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '4rem',
    'option-selected-cross-margin-right': '1.1rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#BDC5D0',
    'option-selected-cross-hover-color': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
  },
  comboBoxTags: {
    'border-color': '#F4F4F4',
    'border-width': '0.2rem',
    'options-selected-placeholder-font-size': '1.4rem',
    'options-selected-placeholder-font-weight': '400',
    'disabled-options-selected-placeholder-color': '#000000',
    'options-selected-placeholder-color': '#8492A6',
    'option-selected-font-size': '1.4rem',
    'option-selected-color': '#8492A6',
    'option-selected-font-weight': '400',
    'option-selected-background-color': '#F7F8FA',
    'option-selected-container-border-radius': '2rem',
    'option-selected-container-padding-top': '1rem',
    'option-selected-container-padding-bottom': '1rem',
    'option-selected-container-padding-left': '1.5rem',
    'option-selected-container-padding-right': '1.5rem',
    'option-selected-cross-margin-right': '0rem',
    'option-selected-cross-color': '#BDC5D0',
    'option-selected-cross-color-hover': '#8492A6',
    'arrow-cross-icon-vertical-divider-color': '#F4F4F4',
    'option-container-color': '#292929',
    'option-container-color-hover': '#292929',
    'option-container-font-size': '1.4rem',
    'option-container-font-weight': '400',
    'inner-options-container-max-height': '25rem',
  },
  checkBox: {
    'border-color': '#BFBFBF',
    'border-color-checked': '#5870F6',
    'background-color-checked': '#5870F6',
    'check-mark-icon-color': '#FFFFFF',
  },
  filePicker: {
    'border-radius': '20px',
    height: '40px',
    'font-size': '14.4px',
    'font-weight': '500',
    'font-family': '"Fira Sans", sans-serif',
    'padding-left': '20px',
    'padding-right': '20px',
    'cross-font-size': '11.2px',
    'cross-font-weight': '500',
    'cross-font-family': '"Fira Sans", sans-serif',
    'file-selected-font-size': '11.2px',
    'file-selected-font-weight': '500',
    'file-selected-font-family': '"Fira Sans", sans-serif',
    'scrollbar-width': '5px',
    'scrollbar-height': '5px',
    'scrollbar-tract-border-radius': '6px',
    'scrollbar-thumb-border-radius': '6px',
    'background-color': 'rgb(38, 62, 196)',
    'border-color': 'rgb(88, 112, 246)',
    'background-color-hover': 'rgba(38, 62, 196, 0.9)',
    'border-color-hover': 'rgba(88, 112, 246, 0.9)',
    color: 'rgb(255, 255, 255)',
    'cross-color': 'rgb(255, 255, 255)',
    'file-selected-color': 'rgb(255, 255, 255)',
    'scrollbar-track-background-color': 'rgb(100, 100, 100)',
    'scrollbar-corner': '#f1f5f9',
    'scrollbar-thumb-background-color': 'rgb(60, 60, 60)',
    'scrollbar-thumb-hover': 'rgb(50, 50, 50)',
  },
  brandSelectionAfterLogin: {
    topHelperMessageContainer: {
      'background-color': '#88A0BB',
      'border-color': '#99B1CC',
      'icon-color': '#000000',
    },
    'selected-brand-profile-card-border-color': '#00FF00',
  },
  modal: {
    'border-width': '1px',
    'border-style': 'solid',
    'border-color': 'rgb(0, 0, 0, 0.2)',
    'cross-hover-color': '#B7C5D9',
  },
  'box-shadow': {
    'color-1': 'rgba(255, 255, 255, 1)',
    'color-2': 'rgba(255, 255, 255, 1)',
    'color-3': '#b3c9df',
  },
  'back-navigation-button': {
    color: '#666666',
    'background-color': '#000000',
    'border-color': '#999999',
    'hover-color': '#000000',
    'hover-background-color': '#999999',
    'hover-border-color': '#999999',
  },
  'back-navigation-button-navbar': {
    height: '10.4rem',
  },
  'menu-navbar': {
    text: {
      'color-1': '#FFFFFF',
      'color-2': '#FFFFFF',
    },
    'background-color-1': '#333333',
    'icon-notification-circle': {
      'background-color-1': '#A05100',
    },
    'dropdown-menu': {
      'border-color': '#405070',
      'entry-background-color-1': '#999999',
      'hover-entry-background-color-1': '#A9B0B1',
      'bottom-divider-color': '#E0E5EA',
      'section-title': '#8492A6',
      'info-tooltip-circle-color': '#8494A9',
      'info-tooltip-circle-color-hover': '#16344B',
    },
    height: '60px',
    tooltip: {
      color: '#333333',
      'border-color': '#CCCCCC',
      'background-color': '#FFFFFF',
    },
  },
  'social-networks': {
    facebook: {
      'background-color': '#e1eeff',
      color: '#1877F2',
    },
    'facebook-page': {
      'background-color': '#ffe6cb',
      color: '#F48226',
    },
    'facebook-page-instagram': {
      'background-color': '#f1e6ff',
      color: '#C32AA3',
    },
    twitter: {
      'background-color': '#e0f3ff',
      color: '#1DA1F2',
    },
    youtube: {
      'background-color': '#e0f3ff',
      color: '#FF0000',
      disabled: {
        color: 'rgb(191, 191, 191)',
      },
    },
    instagram: {
      'background-color': '#e0f3ff',
      color: '#FFFFFF',
      gradient: {
        color: 'url(#paint0_linear_110_310)',
      },
      disabled: {
        color: 'rgb(191, 191, 191)',
      },
    },
  },
  icons: {
    'exclamation-mark': {
      color: '#EA560D',
    },
    eyeV2Icon: '#8494A9',
    eyeV3Icon: '#8494A9',
    copyIconV1: '#8494A9',
    editIconV2: '#8494A9',
    calendarIconV1: '#8494A9',
    gearIconV1: '#8494A9',
    rightArrowIconV1: '#8494A9',
    plusIconV1: '#8494A9',
    emptyContentIconV1: '#8494A9',
    trashCanIconV1: '#8494A9',
    uploadIconV1: '#8494A9',
    bulletListIconV1: '#8494A9',
    color1: '#8494A9',
    color2: '#676767',
  },
  tooltip: {
    'background-color': '#ffffff',
    color: '#000000',
    'border-color': 'rgb(204, 204, 204)',
    'box-shadow-color': 'rgba(0, 0, 0, 0.1)',
  },
  signin: {
    'signin-method-divider-background-color': '#EEEEEE',
    'signin-method-divider-pseudo-element-background-color': '#FFFFFF',
    'signin-method-divider-pseudo-element-color': '#B5B5B5',
    'signin-content-divider-color': 'rgba(219, 219, 219, 0.5)',
    'signin-form-footer-color': '#676767',
    'signin-forgot-password-header-divider-color': 'rgba(219, 219, 219, 0.5)',
    'signin-forgot-password-back-arrow-color': '#292929',
    'signin-forgot-password-back-arrow-container-background-color-hover':
      '#F4F4F480',
    'signin-container-background-color-responsive': '#FFFFFF',
    'regular-notify': {
      'background-color': '#292929',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
    },
    'success-notify': {
      'background-color': '#14B56F',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
      'check-mark-icon-color': '#FFFFFF',
    },
    'error-notify': {
      'background-color': '#E01627',
      color: '#FFFFFF',
      'padding-top': '1.6rem',
      'padding-bottom': '1.6rem',
      'padding-left': '1.6rem',
      'padding-right': '1.6rem',
      'border-radius': '0.6rem',
      'font-size': '1.4rem',
      display: 'flex',
      'align-items': 'center',
      transition: 'all 0.5s ease-in-out',
      'cross-icon-color': '#FFFFFF',
    },
  },
  'toggle-button': {
    'circle-background-color': '#FFFFFF',
    'circle-background-color-checked': '#5870F6',
    'circle-box-shadow-color': 'rgba(0, 0, 0, 0.6)',
    'track-background-color': '#BFBFBF',
    'track-background-color-checked': '#BFC9FB',
  },
  'exclamation-point-icon': {
    'error-notify-color': '#FFFFFF',
    'info-tooltip-color': '#8492A6',
  },
  'question-mark-icon': {
    color: '#DBDBDB',
  },
  signup: {
    'manager-account-tooltip-container-box-shadow-color-1':
      'rgba(0, 0, 0, 0.14)',
    'manager-account-tooltip-container-box-shadow-color-2':
      'rgba(0, 0, 0, 0.12)',
    'manager-account-tooltip-container-box-shadow-color-3':
      'rgba(0, 0, 0, 0.2)',
    'manager-account-tooltip-container-background-color': '#F4F4F4',
    'manager-account-tooltip-divider': '#DBDBDB',
    'manager-account-tooltip-color': '#292929',
    'manager-account-tooltip-link-color': 'rgb(88, 112, 246)',
    'manager-account-tooltip-container-border-color': '#E4E4E4',
    'apple-button': {
      'background-color': '#000000',
      color: '#FFFFFF',
      'border-color': '#A6A6A6',
    },
    'android-button': {
      'background-color': '#000000',
      color: '#FFFFFF',
      'border-color': 'A6A6A6',
      'stop-color-1': '#00A0FF',
      'stop-color-2': '#00A1FF',
      'stop-color-3': '#00BEFF',
      'stop-color-4': '#00D2FF',
      'stop-color-5': '#00DFFF',
      'stop-color-6': '#00E3FF',
      'stop-color-7': '#FFE000',
      'stop-color-8': '#FFBD00',
      'stop-color-9': '#FFA500',
      'stop-color-10': '#FF9C00',
      'stop-color-11': '#FF3A44',
      'stop-color-12': '#C31162',
      'stop-color-13': '#32A071',
      'stop-color-14': '#2DA771',
      'stop-color-15': '#15CF74',
      'stop-color-16': '#06E775',
      'stop-color-17': '#00F076',
    },
    'envelope-color': '#000000',
  },
  'additional-data-step': {
    'outer-container-background-color': '#F4F7FD',
    'background-color': '#FFFFFF',
    'box-shadow-color-1': 'rgba(0, 0, 0, 0.14)',
    'box-shadow-color-2': 'rgba(0, 0, 0, 0.12)',
    'box-shadow-color-3': 'rgba(0, 0, 0, 0.2)',
    'facebook-icon-color': '#FFFFFF',
    'facebook-page-instagram-icon-color': '#FFFFFF',
    'youtube-icon-color': '#FF0000',
    'instagram-icon-color': 'url(#paint0_linear_110_310)',
    'facebook-button': {
      'background-color': '#1877F2',
      'border-radius': '0.6rem',
    },
    'twitter-icon-color': '#FFFFFF',
    'twitter-button': {
      'background-color': '#1DA1F2',
      'border-radius': '0.6rem',
    },
    'empty-picture-container-background-color': '#F4F4F4',
    'empty-picture-icon-color': '#BFBFBF',
  },
  loaderLocal: {
    'container-background-color': 'rgba(0, 0, 0, 0.5)',
    'message-color': '#ffffff',
  },
  dropdownMenu: {
    'border-color': '#000000',
    'container-background-color': '#F9F9FB',
    'header-container-background-color': '#F9F9FB',
    'dropdown-menu-arrow-color': '#BFBFBF',
    'dropdown-menu-cross-color': '#BFBFBF',
  },
  socialNetworkAccountSelectionComponent: {
    'input-filter': {
      'background-color': '#F7F8FA',
      'background-color-hover': '#EFF1F6',
    },
    'magnifying-glass': {
      color: '#8492A6',
    },
    'not-active-social-network-accounts-divider-color': '#EEEEEE',
    'add-social-network-account-color': '#5870F6',
    'not-active-social-network-account-row-background-color-hover': '#F7F8FA',
    'active-social-network-account-container-background-color-hover': '#F7F8FA',
    'active-social-network-account-close-button-background-color': '#BFBFBF',
    'active-social-network-account-close-button-background-color-hover':
      '#676767',
    'expired-token-icon-background-color': '#FEB038',
    'expired-token-tooltip-container-background-color': '#F4F4F4',
    'expired-token-tooltip-container-border-color': '#E4E4E4',
    'expired-token-tooltip-color': '#292929',
    'expired-token-tooltip-link-color': '#5870F6',
  },
  listExternalMediaKitConfigurations: {
    configurationTypeBeingDisplayed: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.5)',
      cursor: 'default',
      'hover-background-color': '#5870F6',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    },
  },
  createExternalMediaKitConfiguration: {
    createButton: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.3)',
    },
  },
  createPortfolioConfiguration: {
    customPartnerBeingCreatedSaveButton: {
      'hover-disabled-background-color': 'rgba(180, 180, 180, 0.3)',
      'pointer-events-on-disable': 'all',
      'cursor-on-disable': 'pointer',
    },
  },
  'error-boundary': {
    'cross-icon-color': '#FFFFFF',
  },
  'earth-icon-default-color': '#BFBFBF',
  'dark-mode': true,
};

export const ThemeTogglerContext = createContext(globalThemeLight);

export const getThemeStyles = (themePath: string[], globalTheme: any): any => {
  const themePathLocal = themePath;
  const resultArray = Array<any>();
  const resultArrayShared = Array<any>();
  resultArray.push(globalTheme);
  for (let i = 0; i < themePathLocal.length; i++) {
    resultArray.push(resultArray[i][themePathLocal[i]]);
    if (resultArray[resultArray.length - 1].shared) {
      resultArrayShared.push(resultArray[resultArray.length - 1].shared);
    }
  }

  const stylesObj: ILooseObject = {};

  for (let i = 0; i < resultArrayShared.length; i++) {
    for (const k in resultArrayShared[i]) {
      stylesObj[k] = (resultArrayShared[i] as ILooseObject)[k];
    }
  }

  for (const k in resultArray[resultArray.length - 1]) {
    stylesObj[k] = resultArray[resultArray.length - 1][k];
  }

  return stylesObj;
};
