/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import {
  GetPublicPostsEvolutionGroupedByKpiInput,
  GetPublicUserHashtagsSortedByMetricsParams,
} from '../../Services/SkorrApi/new/Post/types';
import {
  GetKpisOverviewDailyInput,
  GetKpisOverviewInput,
  GetPublicUsersInfoInput,
} from '../../Services/SkorrApi/new/User/types';
import { IErrorObject, SocialNetworks } from '../../Services/Utils/types';
import { logApiRequestError } from '../../Services/Utils/Utils';

const { user } = SkorrApi;
const { post } = SkorrApi;

export const useGetPublicUserHashtagsLatest = (
  enabled: boolean = false,
  params: GetPublicUserHashtagsSortedByMetricsParams,
) => {
  return useQuery(
    [],
    async () => {
      try {
        const data = await post.getPublicUserHashtagsSortedByMetrics(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicUserKpisOverview = (
  enabled: boolean = false,
  params: GetKpisOverviewInput,
) => {
  return useQuery(
    ['get-kpis-overview', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getKpisOverview(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetKpisOverviewDaily = (
  enabled: boolean = false,
  params: Omit<GetKpisOverviewDailyInput, 'socialNetwork'> & {
    socialNetwork?: SocialNetworks;
  },
) => {
  return useQuery(
    ['get-kpis-overview-daily', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getKpisOverviewDaily(
          params as GetKpisOverviewDailyInput,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicUserAudienceDistributionData = (
  enabled: boolean,
  params: GetPublicUsersInfoInput,
) => {
  const {
    data: publicUserAudienceAgeGroups,
    isFetching: isFetchingGetPublicUserAudienceAgeGroups,
    isSuccess: isSuccessGetPublicUserAudienceAgeGroups,
    isError: isErrorGetPublicUserAudienceAgeGroups,
  } = useQuery(
    ['get-public-user-audience-age-groups', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getPublicUserAudienceAgeGroups(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const {
    data: publicUserAudienceGenders,
    isFetching: isFetchingGetPublicUserAudienceGenders,
    isSuccess: isSuccessGetPublicUserAudienceGenders,
    isError: isErrorGetPublicUserAudienceGenders,
  } = useQuery(
    ['get-public-user-audience-genders', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getPublicUserAudienceGenders(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const {
    data: publicUserAudienceGeo,
    isFetching: isFetchingGetPublicUserAudienceGeo,
    isSuccess: isSuccessGetPublicUserAudienceGeo,
    isError: isErrorGetPublicUserAudienceGeo,
  } = useQuery(
    ['get-public-user-audience-geo', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getPublicUserAudienceGeo(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const {
    data: publicUserAudienceTypes,
    isFetching: isFetchingGetPublicUserAudienceTypes,
    isSuccess: isSuccessGetPublicUserAudienceTypes,
    isError: isErrorGetPublicUserAudienceTypes,
  } = useQuery(
    ['get-public-user-audience-types', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getPublicUserAudienceTypes(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );
  const {
    data: publicUserAudienceReachability,
    isFetching: isFetchingGetPublicUserAudienceReachability,
    isSuccess: isSuccessGetPublicUserAudienceReachability,
    isError: isErrorGetPublicUserAudienceReachability,
  } = useQuery(
    ['get-public-user-audience-reachability', JSON.stringify(params)],
    async () => {
      try {
        const data = await user.getPublicUserAudienceReachability(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  );

  return {
    data: {
      publicUserAudienceAgeGroups,
      publicUserAudienceGenders,
      publicUserAudienceGeo,
      publicUserAudienceTypes,
      publicUserAudienceReachability,
    },
    isFetching:
      isFetchingGetPublicUserAudienceAgeGroups ||
      isFetchingGetPublicUserAudienceGenders ||
      isFetchingGetPublicUserAudienceGeo ||
      isFetchingGetPublicUserAudienceTypes ||
      isFetchingGetPublicUserAudienceReachability,
    isSuccess:
      isSuccessGetPublicUserAudienceAgeGroups ||
      isSuccessGetPublicUserAudienceGenders ||
      isSuccessGetPublicUserAudienceGeo ||
      isSuccessGetPublicUserAudienceTypes ||
      isSuccessGetPublicUserAudienceReachability,
    isError:
      isErrorGetPublicUserAudienceAgeGroups ||
      isErrorGetPublicUserAudienceGenders ||
      isErrorGetPublicUserAudienceGeo ||
      isErrorGetPublicUserAudienceTypes ||
      isErrorGetPublicUserAudienceReachability,
  };
};

export const useGetPublicPostsEvolutionGroupedByKpi = (
  enabled: boolean = false,
  params?: GetPublicPostsEvolutionGroupedByKpiInput,
) => {
  return useQuery(
    ['get-public-posts-evolution-grouped-by-kpi', JSON.stringify(params)],
    async () => {
      try {
        const data = await post.getPublicPostsEvolutionGroupedByKpi(
          params as GetPublicPostsEvolutionGroupedByKpiInput,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};
