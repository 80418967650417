import styled from 'styled-components';

export const Container = styled.div`
  gap: 2rem;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
`;

export const CompetitorList = styled.div<{ disable: boolean }>`
  display: flex;
  padding: 3rem;
  align-items: center;
  border-radius: 2rem;
  background-color: white;
  border: 0.1rem solid #eee;
  justify-content: space-between;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: #f9fbff;
  }
`;

export const CompetitorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CompetitorAvatarContainer = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    padding: 0.2rem;
    margin-left: -1.5rem;
    border-radius: 0.6rem;
    margin-bottom: -0.2rem;
    background-color: white;
  }
`;

export const CompetitorAvatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
  border: 0.3rem solid white;
`;

export const CompetitorNameNetworkContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
`;

export const CompetitorUsername = styled.div`
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
`;

export const CompetitorNetwork = styled.div`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const AddContainer = styled.div`
  gap: 1rem;
  display: flex;
  color: #676767;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  align-items: center;

  svg {
    fill: #676767;
    width: 2rem;
    height: 2rem;
  }
`;
