import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0;
  min-height: calc(100vh - 6.2rem);
  border: 1px solid rgb(244, 247, 253);
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ClearFiltersButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 1rem;
  margin-bottom: 2rem;
`;

export const PoweredByOuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
`;

export const PoweredByContainer = styled.div`
  width: 126.8rem;
  padding: 0 3.2rem;
`;

export const PoweredByInnerContainer = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
`;

export const PoweredByLabel = styled.div`
  border: solid 1px #e0e5ea;
  height: 4.4rem;
  font-weight: 700;
  color: #516c94;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 2.2rem;
  padding: 0 1rem 0 2rem;
  font-size: 0.9rem;
  line-height: 1rem;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  margin: 1rem 0;
`;

export const PoweredByImage = styled.img`
  height: 100%;
  width: auto;
  margin-left: 0.5rem;
`;
