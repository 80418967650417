import { useCallback, useRef, useState } from 'react';
import { DEFAULT_ITEMS_PER_PAGE } from 'data/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { SortOrder } from 'Services/SkorrApi/new/User/types';

import { useGetAdminPartners, useValidateAdminPartner } from '../api';
import { AdminPartnersListFilters, AdminPartnersSortBy } from '../types';

export const useAdminPartnersList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};
  const { filters }: { filters: AdminPartnersListFilters } = state || {};

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [page, setPage] = useState(filters?.page ?? 0);
  const [name, setName] = useState(filters?.name ?? '');

  const [type, setType] = useState<string | undefined>(
    filters?.type ?? undefined,
  );

  const [sortBy, setSortBy] = useState(
    filters?.sortBy ?? AdminPartnersSortBy.createdAt,
  );

  const [sortOrder, setSortOrder] = useState(
    filters?.sortOrder ?? SortOrder.DESC,
  );

  const {
    data: adminPartners,
    refetch: refetchAdminPartners,
    isLoading: isLoadingAdminPartners,
  } = useGetAdminPartners({
    name: name.trim() !== '' ? name.trim() : undefined,
    type,
    page,
    limit: DEFAULT_ITEMS_PER_PAGE,
    sortBy,
    sortOrder,
  });

  const totalPages = Math.ceil(
    (adminPartners?.total ?? DEFAULT_ITEMS_PER_PAGE) / DEFAULT_ITEMS_PER_PAGE,
  );

  const handleRefetchAdminPartners = useCallback(() => {
    refetchAdminPartners();
  }, [refetchAdminPartners]);

  const handleChangePage = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 0 && pageNumber <= totalPages) {
        setPage(pageNumber);

        setTimeout(() => {
          handleRefetchAdminPartners();
        }, 100);
      }
    },
    [totalPages, handleRefetchAdminPartners],
  );

  const { mutateAsync: validateAdminPartner } = useValidateAdminPartner();

  const handleValidateAdminPartner = useCallback(
    async (id: string, active: boolean) => {
      await validateAdminPartner({
        id,
        active,
      });
    },
    [validateAdminPartner],
  );

  const handleGoToCreatePartner = useCallback(() => {
    navigate('/admin/create-partner');
  }, [navigate]);

  const handleGoToEditPartner = useCallback(
    (id: string) => {
      navigate(`/admin/edit-partner/${id}`, {
        state: {
          filters: {
            name,
            type,
            page,
            sortBy,
            sortOrder,
          },
        },
      });
    },
    [navigate, name, type, page, sortBy, sortOrder],
  );

  const handleGoToAgentsList = useCallback(
    (id: string) => {
      navigate(`/admin/agents-list/${id}`, {
        state: {
          filters: {
            name,
            type,
            page,
            sortBy,
            sortOrder,
          },
        },
      });
    },
    [navigate, name, type, page, sortBy, sortOrder],
  );

  return {
    type,
    name,
    page,
    sortBy,
    setType,
    setName,
    inputRef,
    setSortBy,
    totalPages,
    adminPartners,
    handleChangePage,
    handleGoToAgentsList,
    handleGoToEditPartner,
    isLoadingAdminPartners,
    handleGoToCreatePartner,
    handleValidateAdminPartner,
  };
};
