import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { SubmitBenchmarkHookProps } from 'features/Benchmark/hooks/use-submit-benchmark';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type Props = {
  customHook: SubmitBenchmarkHookProps;
};

const SubmitBenchmarkFormCalendar = ({ customHook }: Props) => {
  const {
    timeFrame,
    setTimeFrame,
    toggleAutoUpdate,
    showTimeFramePicker,
    setShowTimeFramePicker,
  } = customHook;

  return (
    <S.Container>
      <ClickOutHandler onClickOut={() => setShowTimeFramePicker(false)}>
        <S.CalendarButton
          active={showTimeFramePicker}
          onClick={() => setShowTimeFramePicker(!showTimeFramePicker)}
        >
          {toggleAutoUpdate
            ? moment(timeFrame[0].startDate).format('ll')
            : `${moment(timeFrame[0].startDate).format('MMM D')} - ${moment(
                timeFrame[0].endDate,
              ).format('ll')}`}

          <svg version="1.1" viewBox="0 0 32 32">
            <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
          </svg>
        </S.CalendarButton>

        {showTimeFramePicker && (
          <S.CalendarPickerContainer>
            <DateRangePicker
              ranges={timeFrame}
              showDateDisplay={false}
              maxDate={moment().toDate()}
              onChange={(item: any) => {
                setTimeFrame([item.selection]);
              }}
            />
          </S.CalendarPickerContainer>
        )}
      </ClickOutHandler>
    </S.Container>
  );
};

export default SubmitBenchmarkFormCalendar;
