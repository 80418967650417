/* eslint-disable no-plusplus */
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';

import * as S from './Styles';

type Props = {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

const Pagination = ({ totalPages, currentPage, onPageChange }: Props) => {
  if (totalPages <= 1) return null;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    const currentPageIndex = currentPage + 1;

    let startPage = Math.max(
      1,
      currentPageIndex - Math.floor(maxVisiblePages / 2),
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <S.PageButton
          key={i}
          aria-label={`Page ${i}`}
          active={currentPageIndex === i}
          onClick={() => onPageChange(i - 1)}
          aria-current={currentPageIndex === i ? 'page' : undefined}
        >
          {i}
        </S.PageButton>,
      );
    }

    return pageNumbers;
  };

  return (
    <S.Nav aria-label="Pagination">
      <S.IconButton
        aria-label="First page"
        disabled={currentPage === 0}
        onClick={() => onPageChange(0)}
      >
        <FaAngleDoubleLeft />
      </S.IconButton>

      <S.IconButton
        aria-label="Previous page"
        disabled={currentPage === 0}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <FaAngleLeft />
      </S.IconButton>

      {renderPageNumbers()}

      <S.IconButton
        aria-label="Next page"
        disabled={currentPage === totalPages - 1}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <FaAngleRight />
      </S.IconButton>

      <S.IconButton
        aria-label="Last page"
        disabled={currentPage === totalPages - 1}
        onClick={() => onPageChange(totalPages - 1)}
      >
        <FaAngleDoubleRight />
      </S.IconButton>
    </S.Nav>
  );
};

export default Pagination;
