/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { css } from 'styled-components';

import {
  IInputFieldContainerProps,
  IInputFieldProps,
  IInputIconContainerProps,
  IInputFieldContainerWithTitleProps,
  IInputTitleProps,
  IWarningMessageContainerProps,
  IPasswordInfoContainerProps,
  ICrossContainerProps,
  IInputIconsContainerProps,
} from './types';

export const InputFieldContainerWithTitle = styled.div<IInputFieldContainerWithTitleProps>`
  flex-grow: ${props => {
    if (props.flexGrow) return props.flexGrow;
    return 'unset';
  }};
  width: ${props => {
    if (props.width) return props.width;
    return 'auto';
  }};
  margin-top: ${props => {
    if (props.marginTop) return props.marginTop;
    return props.theme.inputFieldV2[
      'input-field-container-with-title-margin-top'
    ];
  }};
  margin-bottom: ${props => {
    if (props.marginBottom) return props.marginBottom;
    return props.theme.inputFieldV2[
      'input-field-container-with-title-margin-bottom'
    ];
  }};
  margin-left: ${props => {
    if (props.marginLeft) return props.marginLeft;
    return props.theme.inputFieldV2[
      'input-field-container-with-title-margin-left'
    ];
  }};
  margin-right: ${props => {
    if (props.marginRight) return props.marginRight;
    return props.theme.inputFieldV2[
      'input-field-container-with-title-margin-right'
    ];
  }};

  cursor: ${props => {
    if (
      (props.disabled || props.disabledNotNative) &&
      props.notAllowedCursorOnDisabled
    )
      return 'not-allowed';
    return 'unset';
  }};

  ${props => {
    if (!(props.disabled || props.disabledNotNative)) {
      return css`
        &:hover input {
          border-color: ${() => {
            if (
              props.forceBorderColorGreen ||
              props.forceBorderColorGreenOnHoverOrFocus
            )
              return props.theme.inputFieldV2['border-color-valid'];
            if (
              props.forceBorderColorRed ||
              props.forceBorderColorRedOnHoverOrFocus
            )
              return props.theme.inputFieldV2['border-color-warning'];
            if (props.borderColorHover) return props.borderColorHover;
            if (props.inputState.valid && props.allowGreenBorder)
              return props.theme.inputFieldV2['border-color-valid'];
            if (!props.inputState.warning)
              return props.theme.inputFieldV2['border-color-default-hover'];
          }};
        }

        &:hover label {
          color: ${() => {
            if (props.titleColorHover) return props.titleColorHover;
            if (props.themeStyles) return props.theme['color-hover'];
            return props.theme.inputTitle1['color-hover'];
          }};
        }
      `;
    }
  }};
`;

export const InputTitle = styled.label<IInputTitleProps>`
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (props.themeStyles) return props.themeStyles['font-size'];
    return props.theme.inputTitle1['font-size'];
  }};
  font-weight: ${props => {
    if (props.fontWeight) return props.fontWeight;
    if (props.themeStyles) return props.themeStyles['font-weight'];
    return props.theme.inputTitle1['font-weight'];
  }};
  line-height: ${props => {
    if (props.lineHeight) return props.lineHeight;
    if (props.themeStyles) return props.themeStyles['line-height'];
    return props.theme.inputTitle1['line-height'];
  }};
  margin-bottom: ${props => {
    if (props.marginBottom) return props.marginBottom;
    if (props.themeStyles) return props.themeStyles['margin-bottom'];
    return props.theme.inputTitle1['margin-bottom'];
  }};
  color: ${props => {
    if (props.color) return props.color;
    if (props.themeStyles) return props.themeStyles.color;
    return props.theme.inputTitle1.color;
  }};
  display: inline-block;
  position: relative;

  ${props => {
    if (props.showMandatoryAsterisk) {
      return css`
        &::before {
          display: block;
          content: '*';
          position: absolute;
          left: 103%;
          font-weight: 800;
          color: ${props.theme.inputFieldV2['mandatory-asterisk-color']};
        }
      `;
    }
  }};

  ${props => {
    if (!(props.disabled || props.disabledNotNative)) {
      return css`
        &:hover {
          color: ${() => {
            if (props.colorHover) return props.colorHover;
            if (props.themeStyles) return props.theme['color-hover'];
            return props.theme.inputTitle1['color-hover'];
          }};
        }
      `;
    }
  }}
`;

export const InputFieldContainer = styled.div<IInputFieldContainerProps>`
  position: relative;
  width: ${props => {
    if (props.width) return props.width;
    return 'auto';
  }};
  height: ${props => {
    if (props.height) return props.height;
    return 'auto';
  }};
  margin-top: ${props => {
    if (props.marginTop) return props.marginTop;
    return props.theme.inputFieldV2['input-field-container-margin-top'];
  }};
  margin-bottom: ${props => {
    if (props.marginBottom) return props.marginBottom;
    return props.theme.inputFieldV2['input-field-container-margin-bottom'];
  }};
  margin-left: ${props => {
    if (props.marginLeft) return props.marginLeft;
    return props.theme.inputFieldV2['input-field-container-margin-left'];
  }};
  margin-right: ${props => {
    if (props.marginRight) return props.marginRight;
    return props.theme.inputFieldV2['input-field-container-margin-right'];
  }};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  &:hover input {
    border-color: ${props => {
      if (!props.disabled) {
        if (
          props.forceBorderColorGreen ||
          props.forceBorderColorGreenOnHoverOrFocus
        )
          return props.theme.inputFieldV2['border-color-valid'];
        if (
          props.forceBorderColorRed ||
          props.forceBorderColorRedOnHoverOrFocus
        )
          return props.theme.inputFieldV2['border-color-warning'];
        if (props.borderColorHover) return props.borderColorHover;
        if (props.inputState.valid && props.allowGreenBorder)
          return props.theme.inputFieldV2['border-color-valid'];
        if (!props.inputState.warning)
          return props.theme.inputFieldV2['border-color-default-hover'];
      }
    }};
  }
`;

export const InputField = styled.input.attrs({
  autocomplete: 'false',
})<IInputFieldProps>`
  width: ${props => {
    if (props.width) return props.width;
    if (props.themeStyles && props.themeStyles.width)
      return props.themeStyles.width;
    return '100%';
  }};
  height: ${props => {
    if (props.height) return props.height;
    if (props.themeStyles && props.themeStyles.height)
      return props.themeStyles.height;
    return props.theme.inputFieldV2['input-field-height'];
  }};
  line-height: ${props => {
    if (props.lineHeight) return props.lineHeight;
    if (props.themeStyles && props.themeStyles['line-height'])
      return props.themeStyles['line-height'];
    return props.theme.inputFieldV2['input-field-line-height'];
  }};
  border-color: ${props => {
    if (props.forceBorderColorDefault)
      return props.theme.inputFieldV2['border-color-default'];
    if (props.forceBorderColorGreen)
      return props.theme.inputFieldV2['border-color-valid'];
    if (props.forceBorderColorRed)
      return props.theme.inputFieldV2['border-color-warning'];
    if (props.borderColor) return props.borderColor;
    if (props.themeStyles && props.themeStyles['border-color'])
      return props.themeStyles['border-color'];
    if (props.inputState.warning)
      return props.theme.inputFieldV2['border-color-warning'];
    return props.theme.inputFieldV2['border-color-default'];
  }};
  border-width: ${props => {
    if (props.borderWidth) return props.borderWidth;
    if (props.themeStyles && props.themeStyles['border-width'])
      return props.themeStyles['border-width'];
    return props.theme.inputFieldV2['border-width'];
  }};
  border-style: ${props => {
    if (props.borderStyle) return props.borderStyle;
    if (props.themeStyles && props.themeStyles['border-style'])
      return props.themeStyles['border-style'];
    return props.theme.inputFieldV2['border-style'];
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (props.themeStyles && props.themeStyles['border-radius'])
      return props.themeStyles['border-radius'];
    return props.theme.inputFieldV2['border-radius'];
  }};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (props.themeStyles && props.themeStyles['padding-top'])
      return props.themeStyles['padding-top'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (props.themeStyles && props.themeStyles['padding-bottom'])
      return props.themeStyles['padding-bottom'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (props.themeStyles && props.themeStyles['padding-left'])
      return props.themeStyles['padding-left'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (props.themeStyles && props.themeStyles['padding-right'])
      return props.themeStyles['padding-right'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (props.themeStyles && props.themeStyles['font-size'])
      return props.themeStyles['font-size'];
    return props.theme.inputFieldV2['input-field-font-size'];
  }};
  color: ${props => {
    if (props.color) return props.color;
    if (props.themeStyles && props.themeStyles.color)
      return props.themeStyles.color;
    return props.theme.inputFieldV2['input-field-color'];
  }};
  caret-color: ${props => {
    if (props.caretColor) return props.caretColor;
    if (props.themeStyles && props.themeStyles['caret-color'])
      return props.themeStyles['caret-color'];
    return props.theme.inputFieldV2['input-field-caret-color'];
  }};
  outline: ${props => {
    if (props.outline) return props.outline;
    if (props.themeStyles && props.themeStyles.outline)
      return props.themeStyles.outline;
    return props.theme.inputFieldV2['input-field-outline'];
  }};
  cursor: ${props => {
    if (
      (props.disabled || props.disabledNotNative) &&
      props.notAllowedCursorOnDisabled
    ) {
      return 'not-allowed';
    }
    return 'unset';
  }};

  &:hover {
    border-color: ${props => {
      if (!props.disabled && !props.disabledNotNative) {
        if (props.borderColorHover) return props.borderColorHover;
        if (props.inputState.valid && props.allowGreenBorder)
          return props.theme.inputFieldV2['border-color-valid'];
        if (!props.inputState.warning)
          return props.theme.inputFieldV2['border-color-default-hover'];
      }
    }};
  }

  &&:focus {
    border-color: ${props => {
      if (
        props.forceBorderColorGreen ||
        props.forceBorderColorGreenOnHoverOrFocus
      )
        return props.theme.inputFieldV2['border-color-valid'];
      if (props.forceBorderColorRed || props.forceBorderColorRedOnHoverOrFocus)
        return props.theme.inputFieldV2['border-color-warning'];
      if (props.borderColorFocus) return props.borderColorFocus;
      if (props.inputState.valid && props.allowGreenBorder)
        return props.theme.inputFieldV2['border-color-valid'];
      if (props.inputState.empty || props.inputState.default)
        return props.theme.inputFieldV2['border-color-default-focus'];
      if (!props.inputState.warning)
        return props.theme.inputFieldV2['border-color-default-focus'];
    }};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
  }

  &[type='password'] {
    font-family: Verdana;
    letter-spacing: 0.125rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: #292929;
    padding-right: 5rem;
  }
`;

export const TextAreaField = styled.textarea<IInputFieldProps>`
  width: ${props => {
    if (props.width) return props.width;
    if (props.themeStyles && props.themeStyles.width)
      return props.themeStyles.width;
    return '100%';
  }};
  height: ${props => {
    if (props.height) return props.height;
    if (props.themeStyles && props.themeStyles.height)
      return props.themeStyles.height;
    return props.theme.inputFieldV2['input-field-height'];
  }};
  line-height: ${props => {
    if (props.lineHeight) return props.lineHeight;
    if (props.themeStyles && props.themeStyles['line-height'])
      return props.themeStyles['line-height'];
    return props.theme.inputFieldV2['input-field-line-height'];
  }};
  border-color: ${props => {
    if (props.forceBorderColorGreen)
      return props.theme.inputFieldV2['border-color-valid'];
    if (props.forceBorderColorRed)
      return props.theme.inputFieldV2['border-color-warning'];
    if (props.borderColor) return props.borderColor;
    if (props.themeStyles && props.themeStyles['border-color'])
      return props.themeStyles['border-color'];
    if (props.inputState.warning)
      return props.theme.inputFieldV2['border-color-warning'];
    return props.theme.inputFieldV2['border-color-default'];
  }};
  border-width: ${props => {
    if (props.borderWidth) return props.borderWidth;
    if (props.themeStyles && props.themeStyles['border-width'])
      return props.themeStyles['border-width'];
    return props.theme.inputFieldV2['border-width'];
  }};
  border-style: ${props => {
    if (props.borderStyle) return props.borderStyle;
    if (props.themeStyles && props.themeStyles['border-style'])
      return props.themeStyles['border-style'];
    return props.theme.inputFieldV2['border-style'];
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (props.themeStyles && props.themeStyles['border-radius'])
      return props.themeStyles['border-radius'];
    return props.theme.inputFieldV2['border-radius'];
  }};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (props.themeStyles && props.themeStyles['padding-top'])
      return props.themeStyles['padding-top'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (props.themeStyles && props.themeStyles['padding-bottom'])
      return props.themeStyles['padding-bottom'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (props.themeStyles && props.themeStyles['padding-left'])
      return props.themeStyles['padding-left'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (props.themeStyles && props.themeStyles['padding-right'])
      return props.themeStyles['padding-right'];
    return props.theme.inputFieldV2['input-field-padding'];
  }};
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (props.themeStyles && props.themeStyles['font-size'])
      return props.themeStyles['font-size'];
    return props.theme.inputFieldV2['input-field-font-size'];
  }};
  color: ${props => {
    if (props.color) return props.color;
    if (props.themeStyles && props.themeStyles.color)
      return props.themeStyles.color;
    return props.theme.inputFieldV2['input-field-color'];
  }};
  caret-color: ${props => {
    if (props.caretColor) return props.caretColor;
    if (props.themeStyles && props.themeStyles['caret-color'])
      return props.themeStyles['caret-color'];
    return props.theme.inputFieldV2['input-field-caret-color'];
  }};
  outline: ${props => {
    if (props.outline) return props.outline;
    if (props.themeStyles && props.themeStyles.outline)
      return props.themeStyles.outline;
    return props.theme.inputFieldV2['input-field-outline'];
  }};
  cursor: ${props => {
    if (
      (props.disabled || props.disabledNotNative) &&
      props.notAllowedCursorOnDisabled
    ) {
      return 'not-allowed';
    }
    return 'unset';
  }};

  &:hover {
    border-color: ${props => {
      if (!props.disabled && !props.disabledNotNative) {
        if (props.borderColorHover) return props.borderColorHover;
        if (props.inputState.valid && props.allowGreenBorder)
          return props.theme.inputFieldV2['border-color-valid'];
        if (!props.inputState.warning)
          return props.theme.inputFieldV2['border-color-default-hover'];
      }
    }};
  }

  &&:focus {
    border-color: ${props => {
      if (
        props.forceBorderColorGreen ||
        props.forceBorderColorGreenOnHoverOrFocus
      )
        return props.theme.inputFieldV2['border-color-valid'];
      if (props.forceBorderColorRed || props.forceBorderColorRedOnHoverOrFocus)
        return props.theme.inputFieldV2['border-color-warning'];
      if (props.borderColorFocus) return props.borderColorFocus;
      if (props.inputState.valid && props.allowGreenBorder)
        return props.theme.inputFieldV2['border-color-valid'];
      if (props.inputState.empty || props.inputState.default)
        return props.theme.inputFieldV2['border-color-default-focus'];
      if (!props.inputState.warning)
        return props.theme.inputFieldV2['border-color-default-focus'];
    }};
  }
`;

export const InputIconsContainer = styled.div<IInputIconsContainerProps>`
  position: absolute;
  top: 1.2rem;
  right: 1.1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 2rem;
  width: ${props => props.iconCount * 2.2}rem;
  opacity: ${props => props.opacity};
  transition: opacity;
  transition-duration: 0.08s;
`;

export const InputIconContainer = styled.div<IInputIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: ${props => (props.opacity == '0' ? '0' : '2rem')};
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  opacity: ${props => props.opacity};
  transition: opacity;
  transition-duration: 0.08s;
  pointer-events: ${props => props.pointerEvents};
`;

export const PasswordInfoContainer = styled.div<IPasswordInfoContainerProps>`
  opacity: ${props => (props.visible ? '1' : '0')};
  height: ${props => (props.visible ? '4rem' : '0')};
  margin-top: ${props => {
    if (props.visible) {
      if (props.marginTop) return props.marginTop;
      return '2.4rem';
    }
    return '0';
  }};
  transition: all 0.08s;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.text['color-27']};
  ${props => {
    if (props.visible != true) {
      return css`
        pointer-events: none;
      `;
    }
  }};
`;

export const PasswordInfoIconContainer = styled.div`
  flex-basis: 8rem;
  height: 100%;
  width: 2.4rem;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #bfbfbf;
    height: 2.4rem;
    width: 2.4rem;
  }
`;

export const WarningMessageContainer = styled.div<IWarningMessageContainerProps>`
  ${props => {
    if (props.positionAbsolute)
      return css`
        position: absolute;
        top: calc(100% + ${props.topOffset ? props.topOffset : '1.8rem'});
        left: ${props.leftOffset ? props.leftOffset : '1.9rem'};
        font-weight: 400;
        font-size: ${props.fontSize ? props.fontSize : '1.2rem'};
        line-height: 1.8rem;
        color: ${props.theme.inputFieldV2['error-icon-color']};
      `;
    return css`
      opacity: ${props.visible ? '1' : '0'};
      height: ${props.visible ? '2.4rem' : '0'};
      transition: all 0.08s;
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      color: ${props.theme.inputFieldV2['error-icon-color']};
      margin-top: ${props.visible ? '1.6rem' : '0'};
      padding-left: 1.2rem;
      transition: all 0.08s;
    `;
  }}
`;

export const TryAgainContainer = styled.span`
  cursor: pointer;
  color: ${props => props.theme.inputFieldV2['try-again-button-color']};
  padding-left: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const CrossContainer = styled.div<ICrossContainerProps>`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 2rem;
  color: #bfbfbf;
  font-size: 1.6rem;
  & > * {
    cursor: pointer;
  }
  &:hover {
    color: ${props => {
      if (!props.disabled) {
        return '#8492A6';
      }
    }};
  }
`;
