import styled from 'styled-components';
import { TiTimes } from 'react-icons/ti';

import { IContinueButtonContainerProps } from './types';

export const OuterContainer = styled.div`
  background-color: ${props =>
    props.theme['additional-data-step']['outer-container-background-color']};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-gutter: stable;
  z-index: 99999999999;
  overflow-y: auto;
`;

export const Container = styled.div`
  border-radius: 2.4rem;
  width: 84.1rem;
  height: 69.6rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const InnerContainer = styled.div`
  height: '69.6rem';
  display: 'flex';
  flexdirection: 'column';
`;

export const InfluencerContainer = styled.div`
  border-radius: 2.4rem;
  width: 84.1rem;
  height: 68.5rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};

  @media (max-width: 875px) {
    width: 95%;
  }

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const InfluencerContainerInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  min-height: 7.12rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 4.3rem;
  font-size: 2.6rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 570px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const HeaderTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
`;

export const HeaderSubtitleContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #676767;
  text-align: center;
  word-break: break-word;
  padding: 0 4rem;

  @media (max-width: 875px) {
    padding: 0 1rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const FormContainer = styled.div`
  margin-top: 2.7rem;
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  margin-bottom: 3.6rem;
  height: calc(100% - 18.2rem);
  overflow-y: visible;

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }

  @media (max-width: 515px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

export const CompanyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyDetailsTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
  margin-bottom: 3rem;
`;

export const CompanyDetailsInputsContainer = styled.div`
  display: flex;
  padding-top: 2.5rem;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const CompanyNameInputContainer = styled.div`
  width: calc(50% - 3rem);
  margin-right: 6rem;

  @media (max-width: 850px) {
    width: 100%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
`;

export const CompanyWebsiteInputContainer = styled.div`
  width: calc(50% - 3rem);

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const BusinessProfileCreationContainer = styled.div`
  margin-top: 3.8rem;
`;

export const BusinessProfileCreationTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
  margin-bottom: 1rem;
`;

export const BusinessProfileCreationSubtitle = styled.div`
  font-size: 1.4rem;
  color: ${props => props.theme.text['color-27']};
`;

export const BusinessProfileCreationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BPImagePickerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 26rem;

  @media (max-width: 700px) {
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

export const BPImageContainer = styled.div`
  width: 260px;
  height: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const BPImage = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
`;

export const BPImageCrossContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BPImageCross = styled(TiTimes)``;

export const BPImagePickerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BusinessProfileCreationInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
  width: calc(100% - 26rem);
  justify-content: center;

  @media (max-width: 753px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    padding-left: 0;
  }
`;

export const BusinessProfileNameInputContainer = styled.div`
  width: 90%;
  margin-bottom: 2rem;

  @media (max-width: 753px) {
    width: 100%;
  }
`;

export const BusinessProfileWebsiteInputContainer = styled.div`
  width: 90%;

  @media (max-width: 753px) {
    width: 100%;
  }
`;

export const ContinueButtonContainer = styled.div<IContinueButtonContainerProps>`
  display: flex;
  justify-content: end;
  margin-top: ${props => (props.influencer ? 'auto' : '0')};
  padding-left: ${props => (props.influencer ? '5.6rem' : '0rem')};
  padding-right: ${props => (props.influencer ? '5.6rem' : '0rem')};
  margin-bottom: 3rem;

  & > button {
    border-radius: 0.6rem;
    border-width: 0rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 4rem;
    font-size: 1.6rem;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const InfluencerNameAndCountriesContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const InfluencerNameInputContainer = styled.div`
  width: 58%;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const InfluencerCountryContainer = styled.div`
  width: 40%;

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 3rem;
  }
`;

export const InfluencerSocialNetworkAccountsContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
`;

export const TopicsInterestContainer = styled.div`
  margin-top: 3rem;
`;

export const TopicsInterestTitleContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
`;

export const FooterContainer = styled.div`
  height: 6.12rem;
  padding: 0 5.6rem;
  margin-top: auto;
`;

export const CompanyFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyDetailsForm = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }

    & > div:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const CompanyBrandProfile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const CompanyBrandProfileForm = styled.div`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const CompanyBrandProfileTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
  margin-bottom: 2rem;
`;
