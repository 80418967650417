import * as S from './Styles';

export function CardSkeleton() {
  return (
    <S.ShimmerWrapper>
      {Array.from({ length: 12 }).map((_, index) => (
        <S.Card key={index}>
          <S.ShimmerBlock height="6rem" />
          <S.ShimmerBlock height="30rem" />
          <S.ShimmerBlock height="6rem" />
        </S.Card>
      ))}
    </S.ShimmerWrapper>
  );
}
