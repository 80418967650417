import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  align-self: flex-start;
`;

export const SortButton = styled.div<{ active: boolean }>`
  height: 4rem;
  display: flex;
  fill: #606976;
  color: #606976;
  font-weight: 400;
  line-height: 1.8;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  position: relative;
  white-space: nowrap;
  line-height: 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #eff1f6;
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    min-width: 1.6rem;
    margin-left: 0.8rem;
    transition: transform 1s;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : undefined)};
  }
`;

export const DropdownContainer = styled.div<{ active: boolean }>`
  z-index: 10;
  padding: 1rem;
  width: 16.5rem;
  margin-top: 1rem;
  background: #fff;
  position: relative;
  transition: all 0.08s;
  border-radius: 0.6rem;
  flex-direction: column;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.14),
    0 0.1rem 1.8rem rgba(0, 0, 0, 0.12), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
`;

export const DropdownItemContainer = styled.div<{
  active: boolean;
}>`
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  position: relative;
  margin-top: 0.4rem;
  align-items: center;
  border-radius: 0.6rem;
  background: ${({ active }) => (active ? '#eff1f6' : undefined)};
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }
`;

export const DropdownItemText = styled.div`
  color: #292929;
  font-weight: 400;
  overflow: hidden;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
