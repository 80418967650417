/* eslint-disable eqeqeq */

const jwtParser = data => {
  const jwt = JSON.parse(localStorage.getItem('jwt')) || {};
  jwt.user = data.user;
  jwt.partnerId = data.partnerId;
  jwt.partnerType = data.partnerType;
  jwt.img =
    data.img && data.img != ''
      ? data.img
      : 'https://b-skorr-pp.s3.eu-west-3.amazonaws.com/images/default/default_avatar-2.png';
  jwt.userId = data.agentId;
  jwt.pages = data.pages;
  jwt.partnerName = data.partnerName;
  jwt.partnerImg = data.partnerImg;
  jwt.dpo = data.dpo == 'true';
  jwt.needsDpoReview = data.needsDpoReview == 'true';
  jwt.attributes = data.attributes;
  jwt.businessProfileId =
    data.businessProfileId == '' ? null : data.businessProfileId;
  jwt.businessProfileUserImage =
    data.businessProfileUserImage == '' ? null : data.businessProfileUserImage;
  jwt.businessProfile = data.businessProfile;
  jwt.businessProfileUserId =
    data.businessProfileUserId == '' ? null : data.businessProfileUserId;
  jwt.businessProfileUserIdAnalyticsReady =
    data.businessProfileUserIdAnalyticsReady == 'true';
  jwt.language = data.language;
  jwt.timezone = data.timezone;
  jwt.viewer = data.viewer;
  localStorage.setItem('jwt', JSON.stringify(jwt));
};

const auth = {
  isAuthenticated() {
    if (typeof window === 'undefined') return false;
    if (localStorage.getItem('jwt')) {
      try {
        return JSON.parse(localStorage.getItem('jwt'));
      } catch (err) {
        localStorage.removeItem('jwt');
        return false;
      }
    } else {
      return false;
    }
  },

  authenticate(data, cb) {
    if (typeof window !== 'undefined') {
      jwtParser(data);
    }
    cb();
  },

  setJwt(jwt) {
    localStorage.setItem('jwt', JSON.stringify(jwt));
  },

  resolveName() {
    if (auth.isAuthenticated().businessProfileId != null) {
      return auth.isAuthenticated().businessProfile;
    }
    return auth.isAuthenticated().partnerName;
  },

  isBusinessProfile() {
    if (
      auth.isAuthenticated().businessProfileId != null &&
      auth.isAuthenticated().businessProfileId != undefined &&
      auth.isAuthenticated().businessProfileId != ''
    ) {
      return true;
    }
    return false;
  },
};

export default auth;
