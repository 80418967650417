/* eslint-disable no-empty-function */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import { useNavigate, useParams } from 'react-router-dom';
import {
  useState,
  useEffect,
  useMemo,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { useTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import NoPermissionToAccessPageWarning from 'components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import TrashCanIconV1 from 'assets/iconComponents/TrashCanIconV1';
import InputSpinnerIcon from 'assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { useDeletePartner } from 'pages/CompanyEdit/RQCustomHooks';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  GENDERS,
  ICountryComboBoxEntry,
  PartnerTypes,
  TCountriesAvailableValues,
  TopicsInterestAvailableValues,
} from '../../Services/Utils/types';
import {
  useCreateAgent,
  useGetAgentUser,
  useUpdateAgentAndUser,
  useUpdateAgentUser,
  useUpdateInfluencerProfile,
} from './RQCustomHooks';
import {
  useGetLocations,
  useGetSelfUser,
  useGetToken,
  useGetTopicsInterest,
  useIsOperatorOrPartnerAdmin,
} from '../../Services/Utils/CustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import {
  emailValid,
  passwordValid,
  valueEmpty,
} from '../../validations/validationFunctions';
import { ProfileProps } from './types';
import { IFile } from '../../components/shared/FilePickerV2/types';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import { isAuthenticated } from '../../Services/Utils/Utils';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import { OuterContext } from '../../components/MenuNavbarV2/MenuNavbarV2';

const Profile = (props: ProfileProps) => {
  const [profileImageSrc, setProfileImageSrc] = useState('');
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] =
    useState<boolean>(false);
  const [isGetAgentUserEnabled, setIsGetAgentUserEnabled] =
    useState<boolean>(false);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(true);
  const [isGetTopicsInterestEnabled, setIsGetTopicsInterestEnabled] =
    useState(true);
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState<boolean>(false);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [countrySelected, setCountrySelected] = useState<
    ICountryComboBoxEntry | undefined
  >();
  const [genderSelected, setGenderSelected] = useState<string>();
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
  const [hasEmailBeenChanged, setHasEmailBeenChanged] = useState(false);
  const [forceEmailErrorDisplay, setForceEmailErrorDisplay] = useState<
    string | undefined
  >('');
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [hasPasswordBeenChanged, setHasPasswordBeenChanged] = useState(false);
  const [forcePasswordErrorDisplay, setForcePasswordErrorDisplay] = useState<
    string | undefined
  >();
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [isConfirmPasswordInputFocused, setIsConfirmPasswordInputFocused] =
    useState(false);
  const [hasConfirmPasswordBeenChanged, setHasConfirmPasswordBeenChanged] =
    useState(false);
  const [
    forceConfirmPasswordErrorDisplay,
    setForceConfirmPasswordErrorDisplay,
  ] = useState<string | undefined>();
  const [isConfirmPasswordProcessing, setIsConfirmPasswordProcessing] =
    useState(false);
  const [
    triggerConfirmPasswordValidation,
    setTriggerConfirmPasswordValidation,
  ] = useState(0);
  const [name, setName] = useState<string>('');
  const [isNameInputFocused, setIsNameInputFocused] = useState(false);
  const [hasNameBeenChanged, setHasNameBeenChanged] = useState(false);
  const [forceNameErrorDisplay, setForceNameErrorDisplay] = useState<
    string | undefined
  >('');
  const [topicsInterestAvailableValues, setTopicsInterestAvailableValues] =
    useState<TopicsInterestAvailableValues>([]);
  const [topicsInterestSelected, setTopicsInterestSelected] = useState<
    Array<string>
  >([]);
  const [hasCreateButtonBeenClicked, setHasCreateButtonBeenClicked] =
    useState(false);
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const {
    isLoading: isLoadingUpdateInfluencerProfile,
    isSuccess: isSuccessUpdateInfluencerProfile,
    isError: isErrorUpdateInfluencerProfile,
    mutate: updateInfluencerProfile,
  } = useUpdateInfluencerProfile();
  const {
    isLoading: isLoadingUpdateAgentAndUser,
    isSuccess: isSuccessUpdateAgentAndUser,
    isError: isErrorUpdateAgentAndUser,
    mutate: updateAgentAndUser,
  } = useUpdateAgentAndUser();
  const {
    isLoading: isLoadingUpdateAgentUser,
    isSuccess: isSuccessUpdateAgentUser,
    isError: isErrorUpdateAgentUser,
    mutate: updateAgentUser,
  } = useUpdateAgentUser();
  const {
    isLoading: isLoadingCreateAgent,
    isSuccess: isSuccessCreateAgent,
    isError: isErrorCreateAgent,
    mutate: createAgent,
  } = useCreateAgent();
  const {
    isLoading: isLoadingDeletePartner,
    isSuccess: isSuccessDeletePartner,
    isError: isErrorDeletePartner,
    mutate: deletePartner,
  } = useDeletePartner();
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
    refetch: refetchGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: topicsInterest,
    isFetching: isFetchingGetTopicsInterest,
    isSuccess: isSuccessTopicsInterest,
    isError: isErrorGetTopicsInterest,
  } = useGetTopicsInterest(isGetTopicsInterestEnabled);
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    refetch: refetchGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const [showSuccessNotification, setShowSuccessNotification] =
    useState<boolean>(false);
  const [
    successfulActionNotificationText,
    setSuccessfulActionNotificationText,
  ] = useState('');
  const [loadingActionNotificationText, setLoadingActionNotificationText] =
    useState('');
  const context = useContext(OuterContext);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const params = useParams();
  const creationMode = useMemo(
    () => window.location.pathname.includes('profile/create'),
    [],
  );
  const adminUpdateMode = useMemo(() => Boolean(params.id), [params.id]);
  const {
    data: agentUser,
    isFetching: isFetchingGetAgentUser,
    isSuccess: isSuccessGetAgentUser,
    isError: isErrorGetAgentUser,
    refetch: refetchGetAgentUser,
  } = useGetAgentUser(isGetAgentUserEnabled, params.id ?? '');
  const initialProfileImageUrl = useMemo(
    () => [profileImageSrc],
    [profileImageSrc],
  );
  const [gendersAvailableValues, _] = useState([
    {
      value: GENDERS.MALE,
      label: props.t('userProfile.male'),
    },
    {
      value: GENDERS.FEMALE,
      label: props.t('userProfile.female'),
    },
    {
      value: GENDERS.OTHER,
      label: props.t('userProfile.other'),
    },
  ]);
  const [yearOfBirth, setYearOfBirth] = useState();
  const [monthOfBirth, setMonthOfBirth] = useState();
  const [dayOfBirth, setDayOfBirth] = useState();
  const monthOfBirthRef = useRef<HTMLInputElement>();
  const dayOfBirthRef = useRef<HTMLInputElement>();
  const [yearOfBirthInputFocused, setYearOfBirthInputFocused] = useState(false);
  const [monthOfBirthInputFocused, setMonthOfBirthInputFocused] =
    useState(false);
  const [dayOfBirthInputFocused, setDayOfBirthInputFocused] = useState(false);
  const [showInvalidDateOfBirthWarning, setShowInvalidDateOfBirthWarning] =
    useState(false);
  const getPasswordInvalidMessage = useCallback((password: string): string => {
    if (!(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
      return props.t('signup.passwordLetterCaseWarning');
    }
    if (!/[0-9]/.test(password)) {
      return props.t('signup.passwordNumbersWarning');
    }
    if (!/[!@_#$%^&*]/.test(password)) {
      return props.t('signup.passwordSpecialCharacterWarning');
    }
    if (password.length < 8) {
      return props.t('signup.passwordLengthWarning');
    }
    return '';
  }, []);

  useEffect(() => {
    if (!creationMode) {
      if (!adminUpdateMode) {
        const invalidateQueryData = async () => {
          await queryClient.invalidateQueries({
            queryKey: ['get-user', isAuthenticated()?.userId],
          });
        };

        invalidateQueryData();
        setIsGetSelfUserEnabled(true);
        refetchGetSelfUser();
      } else {
        const invalidateQueryData = async () => {
          await queryClient.invalidateQueries({
            queryKey: [
              'get-agent-user',
              isAuthenticated()?.partnerId,
              params.id,
            ],
          });
        };

        invalidateQueryData();
        setIsGetAgentUserEnabled(true);
        refetchGetAgentUser();
      }
    }
  }, []);

  useEffect(() => {
    if (
      ((isSuccessGetSelfUser &&
        !isFetchingGetSelfUser &&
        selfUser &&
        !adminUpdateMode) ||
        (isSuccessGetAgentUser &&
          !isFetchingGetAgentUser &&
          agentUser &&
          adminUpdateMode)) &&
      !creationMode
    ) {
      if (!adminUpdateMode) {
        setIsGetSelfUserEnabled(false);
      } else {
        setIsGetAgentUserEnabled(false);
      }
      const user = (!adminUpdateMode ? selfUser : agentUser)!;

      setEmail(user.email ?? '');
      setName(user.name ?? '');
      if (user.displayPicture) {
        setProfileImageSrc(user.displayPicture);
      }
      if (user.dateOfBirth) {
        const dateOfBirthObj = new Date(user.dateOfBirth);
        setYearOfBirth(String(dateOfBirthObj.getFullYear()) as any);
        const stringifiedMonth = String(dateOfBirthObj.getMonth() + 1) as any;
        setMonthOfBirth(
          stringifiedMonth.length == 1
            ? `0${stringifiedMonth}`
            : stringifiedMonth,
        );
        const stringifiedDay = String(dateOfBirthObj.getDate()) as any;
        setDayOfBirth(
          stringifiedDay.length == 1 ? `0${stringifiedDay}` : stringifiedDay,
        );
      }

      if (user.topicsInterest?.length) {
        setTopicsInterestSelected(user.topicsInterest);
      }
    }
  }, [
    selfUser,
    isSuccessGetSelfUser,
    isFetchingGetSelfUser,
    agentUser,
    isSuccessGetAgentUser,
    isFetchingGetAgentUser,
    creationMode,
  ]);

  useEffect(() => {
    if (locations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [isSuccessGetLocations]);

  useEffect(() => {
    if (isSuccessTopicsInterest && topicsInterest) {
      setIsGetTopicsInterestEnabled(false);
      const topicsInterestAvailableValuesLocal: TopicsInterestAvailableValues =
        [];

      topicsInterest.forEach((label, key) => {
        topicsInterestAvailableValuesLocal.push({
          value: key,
          label: props.t(label),
        });
      });

      setTopicsInterestAvailableValues(topicsInterestAvailableValuesLocal);
    }
  }, [isSuccessTopicsInterest, topicsInterest]);

  useEffect(() => {
    if (
      ((isSuccessGetSelfUser &&
        !isFetchingGetSelfUser &&
        selfUser &&
        !adminUpdateMode) ||
        (isSuccessGetAgentUser &&
          !isFetchingGetAgentUser &&
          agentUser &&
          adminUpdateMode)) &&
      !creationMode
    ) {
      const user = (!adminUpdateMode ? selfUser : agentUser)!;

      if (countriesAvailableValues.length) {
        if (user.countryCode) {
          for (let i = 0; i < countriesAvailableValues.length; i++) {
            if (countriesAvailableValues[i].value === user.countryCode) {
              setCountrySelected({
                value: user!.countryCode!,
                data: countriesAvailableValues[i].data!,
              });
              break;
            }
          }
        }
      }

      if (gendersAvailableValues.length) {
        if (user.gender) {
          for (let i = 0; i < gendersAvailableValues.length; i++) {
            if (gendersAvailableValues[i].value === user.gender.toUpperCase()) {
              setGenderSelected(user.gender.toUpperCase() as GENDERS);
              break;
            }
          }
        }
      }
    }
  }, [
    selfUser,
    isSuccessGetSelfUser,
    isFetchingGetSelfUser,
    agentUser,
    isSuccessGetAgentUser,
    isFetchingGetAgentUser,
    countriesAvailableValues,
    gendersAvailableValues,
    creationMode,
  ]);

  useEffect(() => {
    if (
      yearOfBirthInputFocused &&
      !Number.isNaN(yearOfBirth) &&
      String(yearOfBirth).length === 4
    ) {
      monthOfBirthRef.current?.focus();
    }
  }, [yearOfBirth]);

  useEffect(() => {
    if (
      monthOfBirthInputFocused &&
      !Number.isNaN(monthOfBirth) &&
      String(monthOfBirth).length === 2
    ) {
      dayOfBirthRef.current?.focus();
    }
  }, [monthOfBirth]);

  useEffect(() => {
    if (
      yearOfBirthInputFocused ||
      monthOfBirthInputFocused ||
      dayOfBirthInputFocused
    ) {
      setShowInvalidDateOfBirthWarning(false);
    }

    if (
      !(
        yearOfBirthInputFocused ||
        monthOfBirthInputFocused ||
        dayOfBirthInputFocused
      ) &&
      !isDateOfBirthValid()
    ) {
      setShowInvalidDateOfBirthWarning(true);
    }
  }, [
    yearOfBirthInputFocused,
    monthOfBirthInputFocused,
    dayOfBirthInputFocused,
  ]);

  useEffect(() => {
    if (isEmailInputFocused && forceEmailErrorDisplay) {
      setForceEmailErrorDisplay(undefined);
    }
    if (isPasswordInputFocused && forcePasswordErrorDisplay) {
      setForcePasswordErrorDisplay(undefined);
    }
    if (isConfirmPasswordInputFocused && forceConfirmPasswordErrorDisplay) {
      setForceConfirmPasswordErrorDisplay(undefined);
    }
    if (isNameInputFocused && forceNameErrorDisplay) {
      setForceNameErrorDisplay(undefined);
    }
  }, [
    isEmailInputFocused,
    forceEmailErrorDisplay,
    isPasswordInputFocused,
    forcePasswordErrorDisplay,
    isConfirmPasswordInputFocused,
    forceConfirmPasswordErrorDisplay,
    isNameInputFocused,
    forceNameErrorDisplay,
    forceNameErrorDisplay,
  ]);

  useEffect(() => {
    if (
      isSuccessUpdateInfluencerProfile ||
      isSuccessUpdateAgentAndUser ||
      isSuccessUpdateAgentUser
    ) {
      if (!adminUpdateMode) {
        const invalidateTokenData = async () => {
          await queryClient.invalidateQueries({ queryKey: ['tokenData'] });
        };

        invalidateTokenData();
      }

      setTimeout(() => {
        refetchGetToken();
      }, 2000);

      setShowSuccessNotification(true);
      setSuccessfulActionNotificationText(
        props.t('profileEdit.profileUpdated'),
      );
      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 2000);

      if (
        isOperatorOrPartnerAdmin &&
        isAuthenticated()?.partnerType !== PartnerTypes.influencer
      ) {
        setTimeout(() => navigate('/agents'), 2000);
      }
    }
  }, [
    isSuccessUpdateInfluencerProfile,
    isSuccessUpdateAgentAndUser,
    isSuccessUpdateAgentUser,
  ]);

  useEffect(() => {
    if (isSuccessDeletePartner) {
      setShowSuccessNotification(true);
      setSuccessfulActionNotificationText(
        props.t('profileEdit.accountDeleted'),
      );

      setTimeout(() => {
        localStorage.removeItem('jwt');
        navigate('/');
      }, 1300);

      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 2000);
    }
  }, [isSuccessDeletePartner]);

  useEffect(() => {
    if (isSuccessCreateAgent) {
      setShowSuccessNotification(true);
      setSuccessfulActionNotificationText(
        props.t('profileEdit.profileCreated'),
      );

      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 2000);
      setTimeout(() => navigate('/agents'), 2000);
    }
  }, [isSuccessCreateAgent]);

  useEffect(() => {
    if (isSuccessGetToken && !isFetchingGetToken) {
      setIsGetTokenEnabled(false);
      setTimeout(() => context.refreshNavbar(), 1000);
    }
  }, [isSuccessGetToken, isFetchingGetToken]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
      setIsGetSelfUserEnabled(false);
    }
  }, [isErrorGetSelfUser]);

  useEffect(() => {
    if (isErrorGetAgentUser) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
      setIsGetAgentUserEnabled(false);
    }
  }, [isErrorGetAgentUser]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setIsGetLocationsEnabled(false);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorGetTopicsInterest) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
      setIsGetTopicsInterestEnabled(false);
    }
  }, [isErrorGetTopicsInterest]);

  useEffect(() => {
    if (isErrorUpdateInfluencerProfile) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotUpdateProfile'));
    }
  }, [isErrorUpdateInfluencerProfile]);

  useEffect(() => {
    if (isErrorUpdateAgentAndUser) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotUpdateProfile'));
    }
  }, [isErrorUpdateAgentAndUser]);

  useEffect(() => {
    if (isErrorUpdateAgentUser) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotUpdateProfile'));
    }
  }, [isErrorUpdateAgentUser]);

  useEffect(() => {
    if (isErrorCreateAgent) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotCreateProfile'));
    }
  }, [isErrorCreateAgent]);

  useEffect(() => {
    if (isErrorDeletePartner) {
      setHasError(true);
      setMsgError(props.t('profileEdit.couldNotDeleteAccount'));
    }
  }, [isErrorDeletePartner]);

  const isDateOfBirthValid = () => {
    return (
      (String(yearOfBirth ?? '').length === 0 &&
        String(monthOfBirth ?? '').length === 0 &&
        String(dayOfBirth ?? '').length === 0) ||
      (/^(?:[1-9]\d*|)$/.test(String(yearOfBirth ?? '')) &&
        String(yearOfBirth ?? '').length === 4 &&
        /^(?:[0-9]\d*|)$/.test(String(monthOfBirth ?? '')) &&
        String(monthOfBirth ?? '').length >= 1 &&
        !Array.from(monthOfBirth ?? []).every(digit => digit == 0) &&
        /^(?:[0-9]\d*|)$/.test(String(dayOfBirth ?? '')) &&
        String(dayOfBirth ?? '').length >= 1 &&
        !Array.from(dayOfBirth ?? []).every(digit => digit == 0))
    );
  };

  const saveButtonClicked = () => {
    if (!isSaveButtonEnabled()) {
      if (creationMode) {
        if (valueEmpty(email)) {
          setForceEmailErrorDisplay(props.t('generic.emailIsRequired'));
        }

        if (valueEmpty(password)) {
          setForcePasswordErrorDisplay(props.t('generic.passwordIsRequired'));
        }

        if (valueEmpty(confirmPassword)) {
          setForceConfirmPasswordErrorDisplay(
            props.t('signup.confirmPasswordIsRequired'),
          );
        } else if (!isConfirmPasswordValid) {
          setForceConfirmPasswordErrorDisplay(
            props.t('signup.passwordsNeedToMatch'),
          );
        }

        if (valueEmpty(name)) {
          setForceNameErrorDisplay(props.t('generic.nameIsRequired'));
        }
      } else {
        if (valueEmpty(name)) {
          setForceNameErrorDisplay(props.t('generic.nameIsRequired'));
        }

        if (valueEmpty(password) && !valueEmpty(confirmPassword)) {
          setForcePasswordErrorDisplay(
            props.t('profileEdit.newPasswordIsRequired'),
          );
        }

        if (valueEmpty(confirmPassword) && !valueEmpty(password)) {
          setForceConfirmPasswordErrorDisplay(
            props.t('profileEdit.confirmNewPasswordIsRequired'),
          );
        }
      }
    } else {
      setLoadingActionNotificationText(
        creationMode
          ? props.t('profileEdit.creatingProfile')
          : props.t('profileEdit.savingChanges'),
      );

      const args: any = {};
      args.name = name;
      args.displayPicture = profileImageSrc;
      if (
        String(yearOfBirth ?? '').length === 4 &&
        String(monthOfBirth ?? '').length >= 1 &&
        String(dayOfBirth ?? '').length >= 1
      ) {
        args.dateOfBirth = new Date(
          `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
        );
      } else {
        args.dateOfBirth = null;
      }
      args.countryId = countrySelected?.data?.countryId ?? null;
      args.countryCode = countrySelected?.value ?? null;
      args.gender = genderSelected ?? null;
      args.topicsOfInterest = topicsInterestSelected;

      let influencerProfilePictureFormData;
      if (files[0]?.data) {
        influencerProfilePictureFormData = new FormData();
        influencerProfilePictureFormData.append('file', files[0]!.data);
        influencerProfilePictureFormData.append(
          'source',
          FileSourceEnum.BRAND_PICTURE,
        );
        influencerProfilePictureFormData.append(
          'id',
          isAuthenticated()?.userId!,
        );
      }
      if (creationMode) {
        args.email = email;
        args.password = password;

        createAgent({
          creationData: args,
          pictureData: influencerProfilePictureFormData,
          initialPicture: profileImageSrc,
        });
      } else if (adminUpdateMode) {
        updateAgentUser({
          updateData: {
            ...args,
            agentId: params.id,
            ...(password && { newPassword: password }),
          },
          pictureData: influencerProfilePictureFormData,
          initialPicture: profileImageSrc,
        });
      } else if (isAuthenticated()!.partnerType === PartnerTypes.influencer) {
        updateInfluencerProfile({
          influencerProfileUpdateData: {
            ...args,
            ...(password && { newPassword: password }),
          },
          influencerPictureData: influencerProfilePictureFormData,
          influencerInitialPicture: profileImageSrc,
        });
      } else
        updateAgentAndUser({
          updateData: { ...args, ...(password && { newPassword: password }) },
          pictureData: influencerProfilePictureFormData,
          initialPicture: profileImageSrc,
        });
    }

    setHasCreateButtonBeenClicked(true);
  };

  const deletePartnerButtonClicked = () => {
    setLoadingActionNotificationText(props.t('profileEdit.deletingAccount'));

    deletePartner();
  };

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (
      isFetchingIsOperatorOrPartnerAdmin ||
      isFetchingGetSelfUser ||
      isFetchingGetAgentUser ||
      isFetchingGetLocations ||
      isFetchingGetTopicsInterest
    ) {
      return props.t('generic.loadingData');
    }
    if (
      isLoadingUpdateInfluencerProfile ||
      isLoadingUpdateAgentAndUser ||
      isLoadingUpdateAgentUser ||
      isLoadingCreateAgent
    ) {
      return props.t('generic.savingChanges');
    }
    return '';
  };

  const isCancelButtonEnabled = () => {
    if (
      !isLoadingUpdateInfluencerProfile &&
      !isLoadingUpdateAgentUser &&
      !isLoadingUpdateAgentAndUser &&
      !isLoadingCreateAgent &&
      !isLoadingDeletePartner
    )
      return true;

    return false;
  };

  const isSaveButtonEnabled = () => {
    if (creationMode) {
      if (
        !valueEmpty(email) &&
        isEmailValid &&
        isPasswordValid &&
        isConfirmPasswordValid &&
        !valueEmpty(name) &&
        isDateOfBirthValid() &&
        !isLoadingCreateAgent &&
        !isSuccessCreateAgent &&
        !isLoadingDeletePartner
      ) {
        return true;
      }
      return false;
    }

    if (
      !valueEmpty(name) &&
      isDateOfBirthValid() &&
      ((isPasswordValid && isConfirmPasswordValid) ||
        (!password.length && !confirmPassword.length)) &&
      !isLoadingUpdateInfluencerProfile &&
      !isLoadingUpdateAgentUser &&
      !isLoadingUpdateAgentAndUser &&
      !isLoadingDeletePartner
    )
      return true;
    return false;
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }

    if (isErrorGetAgentUser) {
      setIsGetAgentUserEnabled(true);
    }

    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }

    if (isErrorGetTopicsInterest) {
      setIsGetTopicsInterestEnabled(true);
    }

    setHasError(false);
  };

  const getPageTitle = () => {
    if (creationMode) {
      return props.t('profileEdit.createProfile');
    }
    return props.t('profileEdit.editProfile');
  };

  const getEmailInput = () => {
    if (creationMode) {
      return (
        <InputControlV2
          value={email}
          onChange={(inputValue: string) => {
            setEmail(inputValue);
            setHasEmailBeenChanged(true);
          }}
          setIsInvalidOrProcessing={(isInvalidOrProcessing: boolean) =>
            setIsEmailValid(!isInvalidOrProcessing)
          }
          validationFunction={emailValid}
          verifyEmailAvailability={true}
          title={props.t('generic.email')}
          setFocused={focused => setIsEmailInputFocused(focused)}
          setBlurred={blurred => {
            setIsEmailInputFocused(!blurred);
            if (
              !email.length &&
              (hasEmailBeenChanged || hasCreateButtonBeenClicked)
            ) {
              setForceEmailErrorDisplay(props.t('generic.emailIsRequired'));
            }
          }}
          forceErrorDisplay={forceEmailErrorDisplay}
          forceBorderColorRed={Boolean(forceEmailErrorDisplay)}
          validateOnFirstRender={false}
          focusOnMount={true}
          allowGreenBorder={true}
          allowCheckMarkIcon={true}
          placeholder={props.t('placeholder.insertEmail')}
          inputTitleMarginBottom={'1.2rem'}
          inputFieldContainerMarginBottom={'2.7rem'}
          warningMessageContainerPositionAbsolute={true}
          warningMessageContainerTopOffset={'0.1rem'}
          warningMessageContainerLeftOffset={'0.2rem'}
          requiredFieldWarningMessage={props.t('generic.emailIsRequired')}
          invalidFieldWarningMessage={props.t('generic.emailFormatIsNotValid')}
        />
      );
    }

    return (
      <InputControlV2
        value={email}
        onChange={(inputValue: string) => {
          setEmail(inputValue);
        }}
        title={props.t('generic.email')}
        inputTitleMarginBottom={'1.2rem'}
        inputFieldContainerMarginBottom={'2.7rem'}
        warningMessageContainerPositionAbsolute={true}
        warningMessageContainerTopOffset={'0.1rem'}
        warningMessageContainerLeftOffset={'0.2rem'}
        requiredFieldWarningMessage={props.t('generic.emailIsRequired')}
        invalidFieldWarningMessage={props.t('generic.emailFormatIsNotValid')}
        disabled={true}
        notAllowedCursorOnDisabled={true}
      />
    );
  };

  return (
    <S.OuterContainer>
      <Loader
        show={
          isFetchingIsOperatorOrPartnerAdmin ||
          isFetchingGetSelfUser ||
          isFetchingGetAgentUser ||
          isFetchingGetLocations ||
          isFetchingGetTopicsInterest
        }
        message={getLoaderMessage()}
      />

      <S.Container>
        <S.InnerContainer>
          <S.BusinessProfileCard>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderTitleContainer>
                  {getPageTitle()}
                </S.HeaderTitleContainer>
                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            {(adminUpdateMode &&
              isAuthenticated()?.partnerType == PartnerTypes.influencer) ||
            (adminUpdateMode &&
              isSuccessIsOperatorOrPartnerAdmin &&
              !isOperatorOrPartnerAdmin) ? (
              <NoPermissionToAccessPageWarning containerHeight="auto" />
            ) : (
              <S.CardInnerContainer>
                <S.BPDetailsContainer>
                  <S.Title>{props.t('profileEdit.profileDetails')}</S.Title>

                  <S.BPDetailsInnerContainer>
                    <S.BPDetailsInnerFirstRowContainer>
                      <S.BPImagePickerContainer>
                        <S.BPImagePickerButtonContainer>
                          <FilePickerV2
                            handleChange={(files: IFile[]) => {
                              setFiles(files);
                              if (!files.length) {
                                setProfileImageSrc('');
                              }
                            }}
                            text={props.t('generic.selectPicture')}
                            showSelectedFiles={false}
                            single={true}
                            type={'images'}
                            marginTop={'1rem'}
                            initialImages={initialProfileImageUrl}
                            imageContainerWidth={'16.8rem'}
                            imageContainerHeight={'13.8rem'}
                            buttonBorderRadius={'0.6rem'}
                          />
                        </S.BPImagePickerButtonContainer>
                      </S.BPImagePickerContainer>

                      <S.BPNameAndDescriptionContainer>
                        <S.BPNameAndDescriptionInnerContainer>
                          {getEmailInput()}

                          <InputControlV2
                            value={password}
                            onChange={(inputValue: string) => {
                              setPassword(inputValue);
                              setHasPasswordBeenChanged(true);
                            }}
                            setIsInvalidOrProcessing={(
                              isInvalidOrProcessing: boolean,
                              isProcessing: boolean,
                            ) => {
                              setIsPasswordValid(!isInvalidOrProcessing);
                              if (
                                !isProcessing &&
                                hasPasswordBeenChanged &&
                                confirmPassword &&
                                !isConfirmPasswordProcessing
                              ) {
                                setTriggerConfirmPasswordValidation(
                                  prevState => prevState + 1,
                                );
                              }
                            }}
                            validationFunction={passwordValid}
                            type={'password'}
                            title={
                              creationMode
                                ? props.t('generic.password')
                                : props.t('profileEdit.newPassword')
                            }
                            setFocused={focused =>
                              setIsPasswordInputFocused(focused)
                            }
                            setBlurred={blurred => {
                              setIsPasswordInputFocused(!blurred);
                              if (creationMode) {
                                if (
                                  !password.length &&
                                  (hasPasswordBeenChanged ||
                                    hasCreateButtonBeenClicked)
                                ) {
                                  setForcePasswordErrorDisplay(
                                    props.t('generic.passwordIsRequired'),
                                  );
                                }
                              } else if (
                                !password.length &&
                                confirmPassword.length
                              ) {
                                setForcePasswordErrorDisplay(
                                  props.t('profileEdit.newPasswordIsRequired'),
                                );
                              } else if (
                                !password.length &&
                                !confirmPassword.length
                              ) {
                                setForceConfirmPasswordErrorDisplay('');
                              }
                            }}
                            isFocused={isPasswordInputFocused}
                            forceErrorDisplay={forcePasswordErrorDisplay}
                            forceBorderColorRed={Boolean(
                              forcePasswordErrorDisplay,
                            )}
                            requiredFieldWarningMessage={
                              creationMode
                                ? props.t('generic.passwordIsRequired')
                                : props.t('profileEdit.newPasswordIsRequired')
                            }
                            invalidFieldWarningMessage={getPasswordInvalidMessage(
                              password,
                            )}
                            validateOnFirstRender={true}
                            allowGreenBorder={true}
                            allowCheckMarkIcon={true}
                            showPasswordTooltip={!!isPasswordInputFocused}
                            placeholder={
                              creationMode
                                ? props.t('placeholder.insertPassword')
                                : props.t('placeholder.insertNewPassword')
                            }
                            inputTitleMarginBottom={'1.2rem'}
                            inputFieldContainerMarginBottom={'2.7rem'}
                            warningMessageContainerPositionAbsolute={true}
                            warningMessageContainerTopOffset={'0.1rem'}
                            warningMessageContainerLeftOffset={'0.2rem'}
                            passwordTooltipMarginTop={'1.7rem'}
                          />

                          <InputControlV2
                            value={confirmPassword}
                            onChange={(inputValue: string) => {
                              setConfirmPassword(inputValue);
                              setHasConfirmPasswordBeenChanged(true);
                            }}
                            setIsInvalidOrProcessing={(
                              isInvalidOrProcessing: boolean,
                              isProcessing: boolean,
                            ) => {
                              setIsConfirmPasswordValid(!isInvalidOrProcessing);
                              setIsConfirmPasswordProcessing(isProcessing);
                            }}
                            validationFunction={() =>
                              !confirmPassword.localeCompare(password)
                            }
                            type={'password'}
                            title={
                              creationMode
                                ? props.t('signup.confirmPassword')
                                : props.t('profileEdit.confirmNewPassword')
                            }
                            setFocused={focused =>
                              setIsConfirmPasswordInputFocused(focused)
                            }
                            setBlurred={blurred => {
                              setIsConfirmPasswordInputFocused(!blurred);
                              if (creationMode) {
                                if (
                                  !confirmPassword.length &&
                                  (hasConfirmPasswordBeenChanged ||
                                    hasCreateButtonBeenClicked)
                                ) {
                                  setForceConfirmPasswordErrorDisplay(
                                    props.t('signup.confirmPasswordIsRequired'),
                                  );
                                }
                              } else if (
                                !confirmPassword.length &&
                                password.length
                              ) {
                                setForceConfirmPasswordErrorDisplay(
                                  props.t(
                                    'profileEdit.confirmNewPasswordIsRequired',
                                  ),
                                );
                              } else if (
                                !confirmPassword.length &&
                                !password.length
                              ) {
                                setForcePasswordErrorDisplay('');
                              }
                            }}
                            isFocused={isConfirmPasswordInputFocused}
                            forceErrorDisplay={forceConfirmPasswordErrorDisplay}
                            forceBorderColorRed={Boolean(
                              forceConfirmPasswordErrorDisplay,
                            )}
                            invalidFieldWarningMessage={props.t(
                              'signup.passwordsNeedToMatch',
                            )}
                            validateOnFirstRender={true}
                            allowGreenBorder={true}
                            allowCheckMarkIcon={true}
                            placeholder={
                              creationMode
                                ? props.t('placeholder.insertPassword')
                                : props.t('placeholder.insertNewPassword')
                            }
                            inputTitleMarginBottom={'1.2rem'}
                            inputFieldContainerMarginBottom={'2.7rem'}
                            warningMessageContainerPositionAbsolute={true}
                            warningMessageContainerTopOffset={'0.1rem'}
                            warningMessageContainerLeftOffset={'0.2rem'}
                            triggerValidationFunction={
                              triggerConfirmPasswordValidation
                            }
                            triggeredValidationFunctionTimer={0}
                          />

                          <InputControlV2
                            value={name}
                            onChange={(inputValue: string) => {
                              setName(inputValue);
                              setHasNameBeenChanged(true);
                            }}
                            title={props.t('generic.name')}
                            inputFieldContainerWithTitleWidth={'100%'}
                            placeholder={props.t('placeholder.insertName')}
                            setFocused={focused =>
                              setIsNameInputFocused(focused)
                            }
                            setBlurred={blurred => {
                              setIsNameInputFocused(!blurred);
                              if (
                                !name.length &&
                                (hasNameBeenChanged ||
                                  hasCreateButtonBeenClicked)
                              ) {
                                setForceNameErrorDisplay(
                                  props.t('generic.nameIsRequired'),
                                );
                              }
                            }}
                            inputTitleMarginBottom={'1.2rem'}
                            inputFieldContainerMarginBottom={'2.7rem'}
                            warningMessageContainerPositionAbsolute={true}
                            warningMessageContainerTopOffset={'0.1rem'}
                            warningMessageContainerLeftOffset={'0.2rem'}
                            forceErrorDisplay={forceNameErrorDisplay}
                            forceBorderColorRed={Boolean(forceNameErrorDisplay)}
                            requiredFieldWarningMessage={props.t(
                              'generic.nameIsRequired',
                            )}
                          />

                          <S.TopicsInterestContainer>
                            <S.DateOfBirthPickerContainer>
                              <S.TopicsInterestTitleContainer>
                                {props.t('generic.topicsOfInterest')}
                              </S.TopicsInterestTitleContainer>

                              <ComboBox
                                valueKey={'topicsInterestSelected'}
                                value={topicsInterestSelected}
                                type={'multi'}
                                width={'100%'}
                                marginTop={'0.5rem'}
                                borderRadius={'0.6rem'}
                                valuesAvailable={topicsInterestAvailableValues}
                                isFilterable={true}
                                isClearable={true}
                                placeholder={props.t(
                                  'profileEdit.selectNoMoreThanFiveTopics',
                                )}
                                onChange={(obj: {
                                  topicsInterestSelected: Array<string>;
                                }) => {
                                  setTopicsInterestSelected(
                                    obj.topicsInterestSelected,
                                  );
                                }}
                                preventOptionSelection={
                                  topicsInterestSelected.length === 5
                                }
                                height={'7rem'}
                                availableOptionsContainerBorderRadius={'0.6rem'}
                                optionContainerBorderRadius={'0.6rem'}
                                innerOptionsContainerMarginTop={'1.2rem'}
                                innerOptionsContainerMarginBottom={'1.2rem'}
                                innerOptionsContainerPaddingLeft={'1.2rem'}
                                innerOptionsContainerPaddingRight={'1.2rem'}
                                optionContainerPaddingTop={'2rem'}
                                optionContainerPaddingBottom={'2rem'}
                                optionContainerPaddingLeft={'2rem'}
                                optionContainerPaddingRight={'2rem'}
                                optionSelectedContainerBorderRadius={'2rem'}
                                themeStyles={(themeGlobal as any).comboBoxTags}
                                changeBackgroundColorOnHover={false}
                                crossIconV2={true}
                                growWithoutScroll={true}
                              />
                            </S.DateOfBirthPickerContainer>
                          </S.TopicsInterestContainer>

                          <S.CountryGenderContainer>
                            <S.CountryContainer>
                              <S.TopicsInterestTitleContainer>
                                {props.t('generic.country')}
                              </S.TopicsInterestTitleContainer>

                              <ComboBox
                                valueKey={'countrySelected'}
                                value={countrySelected}
                                type={'single'}
                                width={'100%'}
                                borderRadius={'0.6rem'}
                                valuesAvailable={countriesAvailableValues}
                                isFilterable={true}
                                isClearable={true}
                                onChange={(obj: {
                                  countrySelected: ICountryComboBoxEntry;
                                }) => {
                                  setCountrySelected(obj.countrySelected);
                                }}
                                placeholder={props.t(
                                  'placeholder.selectCountry',
                                )}
                                height={'4.4rem'}
                                availableOptionsContainerBorderRadius={'0.6rem'}
                                optionContainerBorderRadius={'0.6rem'}
                                innerOptionsContainerMarginTop={'1.2rem'}
                                innerOptionsContainerMarginBottom={'1.2rem'}
                                innerOptionsContainerPaddingLeft={'1.2rem'}
                                innerOptionsContainerPaddingRight={'1.2rem'}
                                optionContainerPaddingTop={'2rem'}
                                optionContainerPaddingBottom={'2rem'}
                                optionContainerPaddingLeft={'2rem'}
                                optionContainerPaddingRight={'2rem'}
                                themeStyles={
                                  (themeGlobal as any).influencerProfileEdit
                                }
                                changeBackgroundColorOnHover={false}
                                changeContainerBorderColorOnHover={true}
                                innerOptionsContainerMaxHeight={'25rem'}
                              />
                            </S.CountryContainer>

                            <S.GenderContainer>
                              <S.TopicsInterestTitleContainer>
                                {props.t('generic.gender')}
                              </S.TopicsInterestTitleContainer>

                              <ComboBox
                                valueKey={'genderSelected'}
                                value={genderSelected}
                                type={'single'}
                                width={'100%'}
                                borderRadius={'0.6rem'}
                                valuesAvailable={gendersAvailableValues}
                                isFilterable={false}
                                isClearable={true}
                                onChange={(obj: { genderSelected: string }) => {
                                  setGenderSelected(obj.genderSelected);
                                }}
                                placeholder={props.t(
                                  'placeholder.selectGender',
                                )}
                                height={'4.4rem'}
                                availableOptionsContainerBorderRadius={'0.6rem'}
                                optionContainerBorderRadius={'0.6rem'}
                                innerOptionsContainerMarginTop={'1.2rem'}
                                innerOptionsContainerMarginBottom={'1.2rem'}
                                innerOptionsContainerPaddingLeft={'1.2rem'}
                                innerOptionsContainerPaddingRight={'1.2rem'}
                                optionContainerPaddingTop={'2rem'}
                                optionContainerPaddingBottom={'2rem'}
                                optionContainerPaddingLeft={'2rem'}
                                optionContainerPaddingRight={'2rem'}
                                themeStyles={
                                  (themeGlobal as any).influencerProfileEdit
                                }
                                changeBackgroundColorOnHover={false}
                                changeContainerBorderColorOnHover={true}
                                innerOptionsContainerMaxHeight={'25rem'}
                              />
                            </S.GenderContainer>
                          </S.CountryGenderContainer>

                          <S.GenderAgeContainer>
                            <S.DateOfBirthPickerContainer>
                              <S.DateOfBirthPickerTitle>
                                {props.t('generic.dateOfBirth')}
                              </S.DateOfBirthPickerTitle>

                              <S.DateOfBirthPickerInputContainer>
                                <InputControlV2
                                  value={yearOfBirth}
                                  type={'number'}
                                  onChange={(inputValue: string) => {
                                    if (
                                      /^(?:[1-9]\d*|)$/.test(inputValue) &&
                                      Number(inputValue) >= 0 &&
                                      inputValue.length <= 4
                                    ) {
                                      setYearOfBirth(inputValue as any);
                                    }
                                  }}
                                  placeholder={props.t(
                                    'profileEdit.insertYearPlaceholder',
                                  )}
                                  inputFieldContainerWidth={'12rem'}
                                  onFocus={() =>
                                    setYearOfBirthInputFocused(true)
                                  }
                                  onBlur={() =>
                                    setYearOfBirthInputFocused(false)
                                  }
                                  forceBorderColorRed={
                                    showInvalidDateOfBirthWarning
                                  }
                                />
                                <S.DateOfBirthInputSeparator>
                                  <S.DateOfBirthInputSeparatorContent />
                                </S.DateOfBirthInputSeparator>
                                <InputControlV2
                                  value={monthOfBirth}
                                  type={'number'}
                                  onChange={(inputValue: string) => {
                                    if (
                                      /^(?:[0-9]\d*|)$/.test(inputValue) &&
                                      Number(inputValue) >= 0 &&
                                      Number(inputValue) <= 12
                                    ) {
                                      setMonthOfBirth(inputValue as any);
                                    }
                                  }}
                                  placeholder={props.t(
                                    'profileEdit.insertMonthPlaceholder',
                                  )}
                                  inputFieldContainerWidth={'10.5rem'}
                                  refNotNative={monthOfBirthRef}
                                  onFocus={() =>
                                    setMonthOfBirthInputFocused(true)
                                  }
                                  onBlur={() =>
                                    setMonthOfBirthInputFocused(false)
                                  }
                                  forceBorderColorRed={
                                    showInvalidDateOfBirthWarning
                                  }
                                />
                                <S.DateOfBirthInputSeparator>
                                  <S.DateOfBirthInputSeparatorContent />
                                </S.DateOfBirthInputSeparator>
                                <InputControlV2
                                  value={dayOfBirth}
                                  type={'number'}
                                  onChange={(inputValue: string) => {
                                    if (
                                      /^(?:[0-9]\d*|)$/.test(inputValue) &&
                                      Number(inputValue) >= 0 &&
                                      Number(inputValue) <= 31
                                    ) {
                                      setDayOfBirth(inputValue as any);
                                    }
                                  }}
                                  placeholder={props.t(
                                    'profileEdit.insertDayPlaceholder',
                                  )}
                                  inputFieldContainerWidth={'10.5rem'}
                                  refNotNative={dayOfBirthRef}
                                  onFocus={() =>
                                    setDayOfBirthInputFocused(true)
                                  }
                                  onBlur={() =>
                                    setDayOfBirthInputFocused(false)
                                  }
                                  forceBorderColorRed={
                                    showInvalidDateOfBirthWarning
                                  }
                                />
                              </S.DateOfBirthPickerInputContainer>
                              {showInvalidDateOfBirthWarning && (
                                <S.DateOfBirthInvalidValueWarning>
                                  {props.t('warning.invalidDateOfBirth')}
                                </S.DateOfBirthInvalidValueWarning>
                              )}
                            </S.DateOfBirthPickerContainer>
                          </S.GenderAgeContainer>
                        </S.BPNameAndDescriptionInnerContainer>
                      </S.BPNameAndDescriptionContainer>
                    </S.BPDetailsInnerFirstRowContainer>
                  </S.BPDetailsInnerContainer>
                </S.BPDetailsContainer>

                {isAuthenticated()?.partnerType == PartnerTypes.influencer && (
                  <S.DeleteAccountContainer>
                    <S.Title>{props.t('companyEdit.deleteAccount')}</S.Title>

                    <S.DeleteAccountButton
                      onClick={() => deletePartnerButtonClicked()}
                    >
                      <TrashCanIconV1
                        containerWidth="2rem"
                        containerHeight="2rem"
                      />

                      {props.t('companyEdit.deleteAccountButtonLabel')}
                    </S.DeleteAccountButton>
                  </S.DeleteAccountContainer>
                )}

                <S.BusinessProfileFooter>
                  <StyledButton
                    borderRadius={'0.6rem'}
                    paddingLeft={'2rem'}
                    paddingTop={'1rem'}
                    paddingBottom={'1rem'}
                    paddingRight={'2rem'}
                    marginRight={'1rem'}
                    themeStyles={getThemeStyles(
                      ['button', 'secondary-1'],
                      themeGlobal,
                    )}
                    onClick={() => cancelButtonClicked()}
                    disabledNotNative={!isCancelButtonEnabled()}
                  >
                    {props.t('generic.cancel')}
                  </StyledButton>

                  <StyledButton
                    borderRadius={'0.6rem'}
                    paddingLeft={'2rem'}
                    paddingRight={'2rem'}
                    paddingTop={'1rem'}
                    paddingBottom={'1rem'}
                    cursorOnDisable={'default'}
                    blockHoverStylesOnDisable={true}
                    pointerEventsOnDisable={'all'}
                    onClick={() => saveButtonClicked()}
                    disabledNotNative={!isSaveButtonEnabled()}
                  >
                    {!creationMode
                      ? props.t('generic.save')
                      : props.t('generic.create')}
                  </StyledButton>
                </S.BusinessProfileFooter>
              </S.CardInnerContainer>
            )}
          </S.BusinessProfileCard>
        </S.InnerContainer>
      </S.Container>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={
          isLoadingUpdateInfluencerProfile ||
          isLoadingUpdateAgentAndUser ||
          isLoadingUpdateAgentUser ||
          isLoadingCreateAgent ||
          isLoadingDeletePartner
        }
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationText}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationText}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.OuterContainer>
  );
};

export default withTranslation()(Profile);
