import { t } from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import { withTranslation } from 'react-i18next';
import { useAdminAgentsList } from 'features/Admin';
import Button from 'components/shared/Button/Button';

import * as S from './Styles';

const AdminAgentsList = () => {
  const {
    adminAgents,
    handleGoBack,
    handleGoToEditAgent,
    isLoadingAdminAgents,
    handleGoToCreateAgent,
    handleValidateAdminAgent,
  } = useAdminAgentsList();

  return (
    <S.Container>
      <S.TopBarContainer>
        <S.HeaderSection>
          <S.BackButton onClick={handleGoBack}>
            <svg viewBox="0 0 20 20">
              <path
                d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
                fill="#292929"
              />
            </svg>
          </S.BackButton>

          <S.MainTitle>{t('skorrAdmin.agent.title')}</S.MainTitle>
        </S.HeaderSection>

        <Button
          marginLeft="auto"
          borderRadius="3rem"
          onClick={handleGoToCreateAgent}
        >
          {t('skorrAdmin.agent.createAgent')}
        </Button>
      </S.TopBarContainer>

      <S.TableContainer>
        <Loader message={t('generic.loading')} show={isLoadingAdminAgents}>
          <table>
            <thead>
              <tr>
                <th>{t('skorrAdmin.agent.logo')}</th>
                <th>{t('skorrAdmin.agent.name')}</th>
                <th>{t('skorrAdmin.agent.email')}</th>
                <th>{t('skorrAdmin.agent.active')}</th>
                <th>{t('skorrAdmin.agent.dpo')}</th>
                <th>{t('skorrAdmin.agent.createdAt')}</th>
                <th>{t('skorrAdmin.agent.actions')}</th>
              </tr>
            </thead>

            <tbody>
              {adminAgents?.map(item => (
                <tr key={item.id}>
                  <td>
                    <S.LogoContainer src={item.img!} />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    {item.active ? t('generic.true') : t('generic.false')}
                  </td>
                  <td>{item.dpo ? t('generic.yes') : t('generic.no')}</td>
                  <td>{moment(item.createdAt).format('MM-DD-YYYY')}</td>
                  <td>
                    <S.ActionsContainer>
                      <a
                        id={`edit-agent-${item.id}`}
                        onClick={() =>
                          handleGoToEditAgent(item.id!, item.partnerId)
                        }
                      >
                        <svg viewBox="0 0 32 32">
                          <path d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z" />
                        </svg>
                      </a>

                      <S.ActionTooltip
                        placement="top"
                        target={`edit-agent-${item.id}`}
                      >
                        {t('skorrAdmin.agent.editAgent')}
                      </S.ActionTooltip>

                      <a
                        id={`validate-agent-${item.id}`}
                        onClick={() =>
                          handleValidateAdminAgent(!item.active, item.id!)
                        }
                      >
                        <svg viewBox="0 0 32 32">
                          <path d="M10.827 26.778c-0.331 0-0.662-0.126-0.914-0.379l-8.622-8.622c-0.505-0.505-0.505-1.324 0-1.829s1.324-0.505 1.829 0l7.708 7.708 18.054-18.054c0.505-0.505 1.324-0.505 1.829 0s0.505 1.324 0 1.829l-18.969 18.969c-0.253 0.253-0.584 0.379-0.914 0.379z" />
                        </svg>
                      </a>

                      <S.ActionTooltip
                        placement="top"
                        target={`validate-agent-${item.id}`}
                      >
                        {t('skorrAdmin.agent.validateAgent')}
                      </S.ActionTooltip>
                    </S.ActionsContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Loader>
      </S.TableContainer>
    </S.Container>
  );
};

export default withTranslation()(AdminAgentsList);
