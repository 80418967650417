import {
  IErrorObject,
  TCitiesAvailableValues,
  TCountriesAvailableValues,
} from 'Services/Utils/types';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { IFile } from 'components/shared/FilePickerV2/types';
import { useGetLocations } from 'Services/Utils/CustomHooks';
import countryRegionData from 'data/country_region_data.json';
import { FileSourceEnum } from 'Services/SkorrApi/new/Files/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isAuthenticated, logApiRequestError } from 'Services/Utils/Utils';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  useGetAdminPartner,
  useCreateAdminPartner,
  useUpdateAdminPartner,
} from '../api';
import {
  AdminPartnerFormData,
  adminPartnerFormSchema,
} from '../schemas/admin-partner-form';
import { AdminPartnersListFilters } from '../types';

export const useAdminPartner = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: partnerId } = useParams();
  const { state } = location || {};
  const { filters }: { filters: AdminPartnersListFilters } = state || {};

  const pageTypeLabel = partnerId
    ? t('skorrAdmin.partner.updatePartner')
    : t('skorrAdmin.partner.createPartner');

  const toastLabel = partnerId
    ? t('skorrAdmin.partner.updatePartnerToast')
    : t('skorrAdmin.partner.createPartnerToast');

  const [files, setFiles] = useState<IFile[]>([]);
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const citiesAvailableValues = useCallback(
    (countryName: string | undefined) => {
      if (countryName === undefined) return [];

      const cities: TCitiesAvailableValues = [];

      const data = countryRegionData;

      data.forEach(country => {
        if (country.country === countryName) {
          country.cities.forEach(({ city, cityId }) => {
            cities.push({
              value: city,
              label: city,
              data: {
                city,
                cityId: cityId.$oid,
              },
            });
          });
        }
      });

      return cities;
    },
    [countryRegionData],
  );

  const { data: adminPartner, isLoading: isLoadingGetAdminPartner } =
    useGetAdminPartner(partnerId!);

  const adminPartnerForm = useForm<AdminPartnerFormData>({
    resolver: zodResolver(adminPartnerFormSchema),
    defaultValues: {
      billingVatExemption: false,
      billingToContactAddress: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    watch,
    trigger,
    register,
    setValue,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = adminPartnerForm;

  const {
    mutateAsync: createAdminPartner,
    isSuccess: isSuccessCreateAdminPartner,
  } = useCreateAdminPartner();

  const {
    mutateAsync: updateAdminPartner,
    isSuccess: isSuccessUpdateAdminPartner,
  } = useUpdateAdminPartner();

  const isSuccess = isSuccessCreateAdminPartner || isSuccessUpdateAdminPartner;

  const handleSubmitForm = useCallback(async () => {
    handleSubmit(async (data: AdminPartnerFormData) => {
      try {
        const cleanedData = Object.fromEntries(
          Object.entries(data).filter(([_, value]) => value !== ''),
        );

        let pictureFormData;
        if (files[0]?.data) {
          pictureFormData = new FormData();
          pictureFormData.append('file', files[0]!.data);
          pictureFormData.append('source', FileSourceEnum.USER_BRAND_PICTURE);
          pictureFormData.append('id', isAuthenticated()?.partnerId!);
        }

        if (partnerId === undefined) {
          await createAdminPartner({
            ...cleanedData,
            name: data.name,
            type: data.type,
            fileFormData: pictureFormData,
            contactCity: data.contactCity?.data.city,
            contactCityId: data.contactCity?.data.cityId,
            contactCountry: data.contactCountry?.data.country,
            contactCountryId: data.contactCountry?.data.countryId,
            billingCity: data.billingCity?.data.city,
            billingCityId: data.billingCity?.data.cityId,
            billingCountry: data.billingCountry?.data.country,
            billingCountryId: data.billingCountry?.data.countryId,
          });
        } else {
          await updateAdminPartner({
            ...cleanedData,
            id: partnerId,
            name: data.name,
            type: data.type,
            fileFormData: pictureFormData,
            contactCity: data.contactCity?.data.city,
            contactCityId: data.contactCity?.data.cityId,
            contactCountry: data.contactCountry?.data.country,
            contactCountryId: data.contactCountry?.data.countryId,
            billingCity: data.billingCity?.data.city,
            billingCityId: data.billingCity?.data.cityId,
            billingCountry: data.billingCountry?.data.country,
            billingCountryId: data.billingCountry?.data.countryId,
          });
        }
      } catch (err) {
        toast.error(logApiRequestError(err as IErrorObject));
      }
    })();
  }, [handleSubmit, files, partnerId, createAdminPartner, updateAdminPartner]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(toastLabel);

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [isSuccess, toastLabel, navigate]);

  const handleGoBack = useCallback(() => {
    navigate('/admin/partners-list', {
      state: {
        filters,
      },
    });
  }, [navigate, filters]);

  useEffect(() => {
    if (adminPartner) {
      resetForm({
        name: adminPartner?.name ?? undefined,
        email: adminPartner?.contactDetail?.email ?? undefined,
        picture: adminPartner?.displayPicture ?? undefined,
        billingToContactAddress: adminPartner?.billingToContactAddress ?? false,
        type: adminPartner?.type ?? undefined,
        contactManager1: adminPartner?.contactDetail?.manager1 ?? undefined,
        contactManager2: adminPartner?.contactDetail?.manager2 ?? undefined,
        contactDescription:
          adminPartner?.contactDetail?.description ?? undefined,
        contactBrand: adminPartner?.contactDetail?.brand ?? undefined,
        companyName: adminPartner?.contactDetail?.companyName ?? undefined,
        contactAddress1: adminPartner?.contactDetail?.address1 ?? undefined,
        contactAddress2: adminPartner?.contactDetail?.address2 ?? undefined,
        contactPostalCode: adminPartner?.contactDetail?.postalCode ?? undefined,
        contactCountry:
          adminPartner?.contactDetail?.country &&
          adminPartner?.contactDetail?.countryId
            ? {
                value: adminPartner.contactDetail?.country,
                data: {
                  country: adminPartner.contactDetail?.country,
                  countryId: adminPartner.contactDetail?.countryId,
                },
              }
            : undefined,
        contactCity:
          adminPartner?.contactDetail?.city &&
          adminPartner?.contactDetail?.cityId
            ? {
                value: adminPartner.contactDetail?.city,
                data: {
                  city: adminPartner.contactDetail?.city,
                  cityId: adminPartner.contactDetail?.cityId,
                },
              }
            : undefined,
        contactVat_id: adminPartner?.contactDetail?.vat_id ?? undefined,
        contactWebSite: adminPartner?.contactDetail?.website ?? undefined,
        billingCompanyName:
          adminPartner?.billingDetail?.companyName ?? undefined,
        billingAddress1: adminPartner?.billingDetail?.address1 ?? undefined,
        billingAddress2: adminPartner?.billingDetail?.address2 ?? undefined,
        billingPostalCode: adminPartner?.billingDetail?.postalCode ?? undefined,
        billingCountry:
          adminPartner?.billingDetail?.country &&
          adminPartner?.billingDetail?.countryId
            ? {
                value: adminPartner.billingDetail?.country,
                data: {
                  country: adminPartner.billingDetail?.country,
                  countryId: adminPartner.billingDetail?.countryId,
                },
              }
            : undefined,
        billingCity:
          adminPartner?.billingDetail?.city &&
          adminPartner?.billingDetail?.cityId
            ? {
                value: adminPartner.billingDetail?.city,
                data: {
                  city: adminPartner.billingDetail?.city,
                  cityId: adminPartner.billingDetail?.cityId,
                },
              }
            : undefined,
        billingVatExemption: adminPartner?.billingDetail?.vatExemption ?? false,
        billingVat_id: adminPartner?.billingDetail?.vat_id ?? undefined,
      });
    }
  }, [adminPartner]);

  return {
    watch,
    errors,
    trigger,
    register,
    setValue,
    setFiles,
    handleGoBack,
    pageTypeLabel,
    adminPartnerForm,
    handleSubmitForm,
    citiesAvailableValues,
    countriesAvailableValues,
    isLoadingGetAdminPartner,
  };
};
