import { SocialNetworks } from 'Services/Utils/types';

export type ParamsFilters = {
  endDate: number;
  startDate: number;
  keywords: string[];
  countryCode: string[];
  network: SocialNetworks[] | undefined;
};

export type SearchesLocalStorage = {
  term: string;
  avgEng: number;
  numUsers: number;
  numPosts: number;
  filters: ParamsFilters;
};

export enum ListeningSortByField {
  'creators' = 'creators',
  'posts' = 'posts',
  'avgEng' = 'avgEng',
}

export enum ListeningPostsSortByField {
  'date' = 'date',
  'likes' = 'likes',
  'comments' = 'comments',
  'shares' = 'shares',
}

export enum ListeningUsersSortByField {
  'audience' = 'audience',
  'likes' = 'likes',
  'comments' = 'comments',
  'posts' = 'posts',
  'engagement_rate' = 'engagement_rate',
  'reactions' = 'reactions',
}
