import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 5rem;
`;

export const SelectedUsersText = styled.h1`
  display: flex;
  color: #292929;
  white-space: pre;
  font-weight: 600;
  font-size: 1.4rem;
  margin-left: 0.5rem;
  align-items: center;
  flex-direction: row;

  p {
    color: #676767;
    font-weight: 400;
  }
`;

export const CustomLabel = styled.p<{ hasUsers: boolean }>`
  color: #676767;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: 0.5rem;
  margin-top: ${({ hasUsers }) => (hasUsers ? '5rem' : undefined)};
`;
