import { t } from 'i18next';
import { useCallback, useState } from 'react';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type ValuesAvailable = { value: string; label: string };

type Props = {
  resource?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isFilterable?: boolean;
  toggleButton: boolean;
  valueSelected: string | undefined;
  valuesAvailable: ValuesAvailable[];
  handleToggleButton: () => void;
  handleClickOutToggleButton: () => void;
  handleChangeValueSelected: (value: any) => void;
};

const SubmitBenchmarkFormDropdown = ({
  resource,
  disabled = false,
  isClearable = false,
  isFilterable = false,
  toggleButton,
  valueSelected,
  valuesAvailable,
  handleToggleButton,
  handleClickOutToggleButton,
  handleChangeValueSelected,
}: Props) => {
  const [search, setSearch] = useState('');

  const convertValueToLabel = useCallback(
    (valueSelected: string): string => {
      return valuesAvailable.find(item => item.value === valueSelected)
        ?.label as string;
    },
    [valuesAvailable],
  );

  const getLabel = useCallback(() => {
    if (!valueSelected) return t(`${resource}`);

    return convertValueToLabel(valueSelected);
  }, [valueSelected, resource, convertValueToLabel]);

  const filteredValues = useCallback(() => {
    return valuesAvailable.filter(item =>
      search.trim()
        ? item.label.toLowerCase().includes(search.trim().toLowerCase())
        : true,
    );
  }, [valuesAvailable, search]);

  return (
    <S.Container>
      <S.SortButton
        active={toggleButton}
        disable={disabled}
        onClick={handleToggleButton}
      >
        {getLabel()}

        {isClearable && valueSelected && (
          <S.ClearContainer
            onClick={e => {
              e.stopPropagation();
              handleChangeValueSelected(undefined);
            }}
          >
            <svg viewBox="0 0 32 32">
              <path d="M24.707 23.293c0.391 0.391 0.391 1.024 0 1.415s-1.024 0.391-1.415 0l-7.292-7.293-7.293 7.293c-0.391 0.391-1.024 0.391-1.415 0s-0.391-1.024 0-1.415l7.293-7.293-7.292-7.293c-0.391-0.391-0.391-1.024 0-1.415s1.024-0.391 1.415 0l7.293 7.293 7.293-7.293c0.391-0.391 1.024-0.391 1.415 0s0.391 1.024 0 1.415l-7.295 7.293 7.292 7.293z" />
            </svg>
          </S.ClearContainer>
        )}

        <svg version="1.1" viewBox="0 0 32 32">
          <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
        </svg>
      </S.SortButton>

      <ClickOutHandler onClickOut={handleClickOutToggleButton}>
        <S.DropdownContainer active={toggleButton}>
          {isFilterable && (
            <S.InputContainer
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <svg viewBox="0 0 20 20">
                <path d="M18.2369 17.2128L14.8131 13.789C15.878 12.4962 16.5178 10.84 16.5178 9.03428C16.5178 4.90128 13.1674 1.55078 9.03434 1.55078C4.90128 1.55078 1.5509 4.90128 1.5509 9.03434C1.5509 13.1674 4.90134 16.5178 9.0344 16.5178C10.8401 16.5178 12.4963 15.878 13.7891 14.8131L17.2129 18.2369C17.4957 18.5197 17.9542 18.5197 18.237 18.2369C18.5198 17.9541 18.5198 17.4955 18.2369 17.2128ZM2.99928 9.03434C2.99928 5.70128 5.70128 2.99928 9.03434 2.99928C12.3674 2.99928 15.0694 5.70128 15.0694 9.03434C15.0694 12.3674 12.3674 15.0694 9.03434 15.0694C5.70128 15.0694 2.99928 12.3674 2.99928 9.03434Z" />
              </svg>

              <input
                type="text"
                name="search"
                value={search}
                placeholder={t('comboBox.search')}
                onChange={e => setSearch(e.target.value)}
              />
            </S.InputContainer>
          )}

          {filteredValues().length === 0 ? (
            <S.DropdownItemContainer active={false}>
              <S.DropdownItemText>
                {t('comboBox.noOptionsAvailable')}
              </S.DropdownItemText>
            </S.DropdownItemContainer>
          ) : (
            filteredValues().map(item => (
              <S.DropdownItemContainer
                key={item.value}
                active={valueSelected === item.value}
                onClick={() => handleChangeValueSelected(item.value)}
              >
                <S.DropdownItemText>{item.label}</S.DropdownItemText>
              </S.DropdownItemContainer>
            ))
          )}
        </S.DropdownContainer>
      </ClickOutHandler>
    </S.Container>
  );
};

export default SubmitBenchmarkFormDropdown;
