import {
  useDiscoverContext,
  DiscoverToolBar,
  DiscoverEmptyState,
  DiscoverProfilesCardGrid,
} from 'features/Discover';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import Pagination from 'components/shared/Pagination/Pagination';
import LoaderFixed from 'components/shared/LoaderFixed/LoaderFixed';
import SaveUsersListModal from 'components/shared/SaveUsersListModal';

import * as S from './Styles';

const Discover = () => {
  const {
    page,
    totalPages,
    showSaveList,
    selectedUserIds,
    handleChangePage,
    publicUsersByFilter,
    handleCloseSaveList,
    isLoadingPublicUsersByFilter,
  } = useDiscoverContext();

  return (
    <>
      <LoaderFixed
        show={isLoadingPublicUsersByFilter}
        message={t('generic.loading')}
      />

      <DiscoverToolBar />

      {publicUsersByFilter?.total === 0 ? (
        <DiscoverEmptyState />
      ) : (
        <S.MainContainer>
          <DiscoverProfilesCardGrid />

          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={handleChangePage}
          />
        </S.MainContainer>
      )}

      <SaveUsersListModal
        showSaveList={showSaveList}
        selectedUserIds={selectedUserIds}
        handleCloseSaveList={handleCloseSaveList}
      />
    </>
  );
};

export default withTranslation()(Discover);
