import { t } from 'i18next';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';
import { SearchUserBenchmarkResponse } from 'features/Benchmark/types';

import * as S from './Styles';

type Props = {
  data: SearchUserBenchmarkResponse[];
  selectedUsers: SearchUserBenchmarkResponse[];
  handleUsers: (user: SearchUserBenchmarkResponse) => void;
};

const SubmitBenchmarkUsersFound = ({
  data,
  handleUsers,
  selectedUsers,
}: Props) => {
  return (
    <S.Container>
      {data
        .filter(
          item =>
            !selectedUsers.some(
              selected => selected.publicUserId === item.publicUserId,
            ),
        )
        .map(user => {
          let diffNetwork;

          if (selectedUsers.length > 0) {
            diffNetwork = user.socialNetwork !== selectedUsers[0].socialNetwork;
          } else {
            diffNetwork = false;
          }

          return (
            <S.CompetitorList
              key={user.publicUserId}
              onClick={() => handleUsers(user)}
              disable={selectedUsers.length >= 5 || diffNetwork}
            >
              <S.CompetitorInfo>
                <S.CompetitorAvatarContainer>
                  <S.CompetitorAvatar src={user.displayPicture} />
                  {getSocialNetworkIcon({
                    width: '1.5rem',
                    height: '1.5rem',
                    gradientColored: true,
                    socialNetwork: user.socialNetwork,
                  })}
                </S.CompetitorAvatarContainer>

                <S.CompetitorNameNetworkContainer>
                  <S.CompetitorUsername>{user.username}</S.CompetitorUsername>
                  <S.CompetitorNetwork>
                    {t(`socialNetworks.${user.socialNetwork}`)}
                  </S.CompetitorNetwork>
                </S.CompetitorNameNetworkContainer>
              </S.CompetitorInfo>

              <S.AddContainer>
                {t('benchmark.form.addToBenchmark')}

                <svg viewBox="0 0 32 32">
                  <path d="M16 2.667c-7.364 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.969 13.333-13.333-5.969-13.333-13.333-13.333zM16 27.333c-6.259 0-11.333-5.075-11.333-11.333s5.075-11.333 11.333-11.333 11.333 5.075 11.333 11.333-5.075 11.333-11.333 11.333zM24.543 16c0 0.552-0.448 1-1 1h-6.543v6.543c0 0.552-0.448 1-1 1s-1-0.448-1-1v-6.543h-6.543c-0.552 0-1-0.448-1-1s0.448-1 1-1h6.543v-6.543c0-0.552 0.448-1 1-1s1 0.448 1 1v6.543h6.543c0.552 0 1 0.448 1 1z" />
                </svg>
              </S.AddContainer>
            </S.CompetitorList>
          );
        })}
    </S.Container>
  );
};

export default SubmitBenchmarkUsersFound;
