/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Agent } from 'Services/SkorrApi/new/User/types';
import { DEFAULT_AVATAR_URL } from 'Services/Utils/constants';
import CrossIconV3 from 'assets/iconComponents/CrossIconV3';
import { PartnerTypes } from 'Services/Utils/types';

import * as S from './Styles';
import {
  useActivateAgentById,
  useDeactivateAgentById,
  useDeleteAgentById,
  useGetAgentsByPartnerId,
} from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { isAuthenticated } from '../../Services/Utils/Utils';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { AgentsProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import {
  getFormattedTimeFromDate,
  getShortenedMonthResourceKey,
} from '../../Services/Utils/dateUtils';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import Button from '../../components/shared/Button/Button';
import GearIconV1 from '../../assets/iconComponents/GearIconV1';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import TrashCanIconV1 from '../../assets/iconComponents/TrashCanIconV1';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import NoPermissionToAccessPageWarning from '../../components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';

const Agents = (props: AgentsProps) => {
  const navigate = useNavigate();
  const [isGetAgentsByPartnerIdEnabled, setIsGetAgentsByPartnerIdEnabled] =
    useState(true);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const {
    data: agents,
    isFetching: isFetchingGetAgentsByPartnerId,
    isSuccess: isSuccessGetAgentsByPartnerId,
    isError: isErrorGetAgentsByPartnerId,
    refetch: refetchGetAgentsByPartnerId,
  } = useGetAgentsByPartnerId(
    isGetAgentsByPartnerIdEnabled &&
      isSuccessIsOperatorOrPartnerAdmin &&
      isOperatorOrPartnerAdmin,
  );
  const {
    isLoading: isLoadingDeleteAgentById,
    isSuccess: isSuccessDeleteAgentById,
    isError: isErrorDeleteAgentById,
    mutate: deleteAgentById,
  } = useDeleteAgentById();
  const {
    isLoading: isLoadingActivateAgentById,
    isSuccess: isSuccessActivateAgentById,
    isError: isErrorActivateAgentById,
    mutate: activateAgentById,
  } = useActivateAgentById();
  const {
    isLoading: isLoadingDeactivateAgentById,
    isSuccess: isSuccessDeactivateAgentById,
    isError: isErrorDeactivateAgentById,
    mutate: deactivateAgentById,
  } = useDeactivateAgentById();
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const themeGlobal = useTheme();

  useEffect(() => {
    if (isSuccessGetAgentsByPartnerId) {
      setIsGetAgentsByPartnerIdEnabled(false);
    }
  }, [isSuccessGetAgentsByPartnerId]);

  useEffect(() => {
    if (isSuccessDeleteAgentById) {
      setSuccessfulActionNotificationMessage(props.t('agents.agentDeleted'));
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetAgentsByPartnerId();
    }
  }, [isSuccessDeleteAgentById]);

  useEffect(() => {
    if (isSuccessActivateAgentById) {
      setSuccessfulActionNotificationMessage(props.t('agents.agentActivated'));
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetAgentsByPartnerId();
    }
  }, [isSuccessActivateAgentById]);

  useEffect(() => {
    if (isSuccessDeactivateAgentById) {
      setSuccessfulActionNotificationMessage(
        props.t('agents.agentDeactivated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetAgentsByPartnerId();
    }
  }, [isSuccessDeactivateAgentById]);

  useEffect(() => {
    if (isErrorGetAgentsByPartnerId) {
      setIsGetAgentsByPartnerIdEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorGetAgentsByPartnerId]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotLoadData'));
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isErrorDeleteAgentById) {
      setHasError(true);
      setMsgError(props.t('agents.couldNotDeleteAgent'));
    }
  }, [isErrorDeleteAgentById]);

  useEffect(() => {
    if (isErrorActivateAgentById) {
      setHasError(true);
      setMsgError(props.t('agents.couldNotActivateAgent'));
    }
  }, [isErrorActivateAgentById]);

  useEffect(() => {
    if (isErrorDeactivateAgentById) {
      setHasError(true);
      setMsgError(props.t('agents.couldNotDeactivateAgent'));
    }
  }, [isErrorDeactivateAgentById]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetAgentsByPartnerId) {
      setIsGetAgentsByPartnerIdEnabled(true);
    }
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
  };

  const getCreatedAtLabel = (date: Date) => {
    const createdAtDate = new Date(Date.parse(date as unknown as string));

    const getCreatedAtDateLabel = `${props.t(
      getShortenedMonthResourceKey(createdAtDate),
    )} ${createdAtDate.getDate()}${`, ${createdAtDate.getFullYear()}`}`;

    return `${getCreatedAtDateLabel}`;
  };

  const getUpdatedAtLabel = (date: Date) => {
    const updatedAtDate = new Date(Date.parse(date as unknown as string));

    const getUpdatedAtDateLabel = `${props.t(
      getShortenedMonthResourceKey(updatedAtDate),
    )} ${updatedAtDate.getDate()}${`, ${updatedAtDate.getFullYear()} - ${getFormattedTimeFromDate(
      updatedAtDate,
      { seconds: false },
    )}`}`;

    return `${getUpdatedAtDateLabel}`;
  };

  const canDeactivate = (agentId: string) => {
    if (isAuthenticated()?.agentId == agentId) {
      return false;
    }

    if (agents?.length && agents.length == 1) {
      return false;
    }

    const activeAgents = agents?.filter(agent => agent.active);
    if (activeAgents?.length == 1 && activeAgents[0].id == agentId) {
      return false;
    }

    return true;
  };

  const canDelete = (agentId: string) => {
    if (isAuthenticated()?.agentId == agentId) {
      return false;
    }

    if (agents?.length && agents.length == 1) {
      return false;
    }

    const activeAgents = agents?.filter(agent => agent.active);
    if (activeAgents?.length == 1 && activeAgents[0].id == agentId) {
      return false;
    }

    return true;
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetAgentsByPartnerId || isFetchingIsOperatorOrPartnerAdmin
        }
        message={props.t('generic.loadingData')}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('agents.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          {isSuccessIsOperatorOrPartnerAdmin &&
          isOperatorOrPartnerAdmin &&
          isAuthenticated()?.partnerType !== PartnerTypes.influencer ? (
            <S.CardInnerContainer>
              {isSuccessGetAgentsByPartnerId && agents?.length && (
                <S.ConfigurationListContainer>
                  {(agents ?? []).map((agent: Agent) => (
                    <S.BenchmarkListItemCard>
                      <S.SocialAccountCardContainer>
                        <S.SocialAccountImage
                          src={agent.img || DEFAULT_AVATAR_URL}
                        />
                      </S.SocialAccountCardContainer>

                      <S.NameContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemNameContainer>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.email')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {agent.email}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemNameContainer>
                        </S.BenchmarkListItemCol>
                      </S.NameContainer>

                      <S.CreatedAtContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.createdAt')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {getCreatedAtLabel(agent.createdAt!)}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.CreatedAtContainer>

                      <S.UpdatedAtContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.lastUpdatedAt')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {getUpdatedAtLabel(agent.updatedAt!)}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.UpdatedAtContainer>

                      <S.ActionsContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemTitle>
                            {props.t('generic.actions')}
                          </S.BenchmarkListItemTitle>
                          <S.ActionsInnerContainer>
                            <S.EditConfigurationContainer>
                              <S.ViewsColumnInnerContainer>
                                <S.EyeIconContainer
                                  id={`edit-agent-${agent.id}`}
                                >
                                  <S.GearIconContainer
                                    onClick={() => {
                                      navigate(`/profile/edit/${agent.id}`);
                                    }}
                                  >
                                    <GearIconV1 />
                                  </S.GearIconContainer>
                                </S.EyeIconContainer>

                                <S.EditConfigurationTooltip
                                  placement="top"
                                  target={`edit-agent-${agent.id}`}
                                  autohide={false}
                                >
                                  {props.t('generic.settings')}
                                </S.EditConfigurationTooltip>
                              </S.ViewsColumnInnerContainer>
                            </S.EditConfigurationContainer>

                            {canDelete(agent.id!) && (
                              <S.EditConfigurationContainer>
                                <S.ViewsColumnInnerContainer>
                                  <S.EyeIconContainer
                                    id={`delete-agent-${agent.id}`}
                                  >
                                    <S.GearIconContainer
                                      onClick={() => {
                                        setLoadingActionNotificationMessage(
                                          props.t('agents.deletingAgent'),
                                        );
                                        deleteAgentById(agent.id!);
                                      }}
                                    >
                                      <TrashCanIconV1
                                        containerWidth="2.4rem"
                                        containerHeight="2.4rem"
                                      />
                                    </S.GearIconContainer>
                                  </S.EyeIconContainer>

                                  <S.EditConfigurationTooltip
                                    placement="top"
                                    target={`delete-agent-${agent.id}`}
                                    autohide={false}
                                  >
                                    {props.t('generic.delete')}
                                  </S.EditConfigurationTooltip>
                                </S.ViewsColumnInnerContainer>
                              </S.EditConfigurationContainer>
                            )}

                            {canDeactivate(agent.id!) && (
                              <S.EditConfigurationContainer>
                                <S.ViewsColumnInnerContainer>
                                  <S.EyeIconContainer
                                    id={`activate-or-deactivate-agent-${agent.id}`}
                                  >
                                    <S.GearIconContainer
                                      onClick={() => {
                                        setLoadingActionNotificationMessage(
                                          props.t(
                                            `agents.${
                                              agent.active
                                                ? 'deactivating'
                                                : 'activating'
                                            }Agent`,
                                          ),
                                        );
                                        if (agent.active) {
                                          deactivateAgentById(agent.id!);
                                        } else {
                                          activateAgentById(agent.id!);
                                        }
                                      }}
                                    >
                                      {agent.active ? (
                                        <CrossIconV3
                                          containerWidth="2.4rem"
                                          containerHeight="2.4rem"
                                        />
                                      ) : (
                                        <CheckMarkIcon
                                          containerWidth="2.4rem"
                                          containerHeight="2.4rem"
                                        />
                                      )}
                                    </S.GearIconContainer>
                                  </S.EyeIconContainer>

                                  <S.EditConfigurationTooltip
                                    placement="top"
                                    target={`activate-or-deactivate-agent-${agent.id}`}
                                    autohide={false}
                                  >
                                    {props.t(
                                      `agents.${
                                        agent.active ? 'deactivate' : 'activate'
                                      }Agent`,
                                    )}
                                  </S.EditConfigurationTooltip>
                                </S.ViewsColumnInnerContainer>
                              </S.EditConfigurationContainer>
                            )}
                          </S.ActionsInnerContainer>
                        </S.BenchmarkListItemCol>
                      </S.ActionsContainer>

                      <S.ActiveContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.active')}
                            </S.BenchmarkListItemTitle>
                            <S.ActiveCheckMarkIconContainer>
                              {agent.active ? (
                                <CheckMarkIcon
                                  containerWidth="2.4rem"
                                  containerHeight="2.4rem"
                                />
                              ) : (
                                <div style={{ height: '2.4rem' }}></div>
                              )}
                            </S.ActiveCheckMarkIconContainer>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.ActiveContainer>
                    </S.BenchmarkListItemCard>
                  ))}
                </S.ConfigurationListContainer>
              )}

              {isSuccessGetAgentsByPartnerId && !agents.length && (
                <S.EmptyInfoContainer>
                  <EmptyInfo
                    width="auto"
                    icon={
                      <ExclamationPointIcon
                        containerWidth="4rem"
                        containerHeight="5rem"
                      />
                    }
                    header={props.t('agents.emptyInfoTitle')}
                    legend={props.t('agents.emptyInfoSubtitle')}
                  />
                </S.EmptyInfoContainer>
              )}
            </S.CardInnerContainer>
          ) : (isSuccessIsOperatorOrPartnerAdmin &&
              !isOperatorOrPartnerAdmin) ||
            isAuthenticated()?.partnerType == PartnerTypes.influencer ? (
            <NoPermissionToAccessPageWarning containerHeight="auto" />
          ) : null}

          {isSuccessIsOperatorOrPartnerAdmin &&
            isOperatorOrPartnerAdmin &&
            isAuthenticated()?.partnerType !== PartnerTypes.influencer && (
              <S.FooterContainer>
                <Button
                  borderRadius={'0.6rem'}
                  borderWidth={'0rem'}
                  height={'4rem'}
                  fontSize={'1.6rem'}
                  textAlign={'center'}
                  fontWeight={'500'}
                  paddingRight={'1rem'}
                  onClick={() => {
                    navigate('/profile/create');
                  }}
                >
                  {props.t('generic.create')}
                </Button>
              </S.FooterContainer>
            )}
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={
          isLoadingDeleteAgentById ||
          isLoadingActivateAgentById ||
          isLoadingDeactivateAgentById
        }
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.Container>
  );
};

export default withTranslation()(Agents);
