import styled from 'styled-components';

export const MainContainer = styled.div`
  gap: 2.4rem;
  display: flex;
  margin-top: 5rem;
`;

export const UserCardContainer = styled.div`
  width: 30rem;
  padding: 2.5rem;
  padding-bottom: 0;
  border-radius: 2rem;
  background-color: white;
  border: 0.1rem solid #eee;
`;

export const TopSectionContainer = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const UserAvatarInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;

  div {
    padding: 0.2rem;
    margin-left: -1.5rem;
    border-radius: 0.6rem;
    margin-bottom: -0.2rem;
    background-color: white;
  }
`;

export const UserAvatarInfoImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  vertical-align: middle;
  border: 0.3rem solid white;
`;

export const UserInfoContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
`;

export const UserInfoName = styled.h1`
  color: #292929;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserInfoNetwork = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const UserMetricsContainer = styled.div`
  display: flex;
  height: 7.5rem;
  margin-top: 3rem;
  margin-left: 0.4rem;
`;

export const UserMetricContainer = styled.div`
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
`;

export const UserMetricValue = styled.span`
  color: #292929;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.2rem;
`;

export const UserMetricLabel = styled.span`
  color: #676767;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export const UserMetricColor = styled.div<{ color: string }>`
  width: 4.2rem;
  height: 0.4rem;
  margin-top: auto;
  border-radius: 2.2rem;
  background-color: ${({ color }) => color};
`;

export const UserEvolutionContainer = styled.div<{ positive: boolean }>`
  width: 100%;
  display: flex;
  white-space: pre;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  align-items: center;
  justify-content: flex-end;
  color: ${({ positive }) => (positive ? '#14b56f' : '#B00020')};

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${({ positive }) => (positive ? '#14b56f' : '#B00020')};
  }
`;

export const AddMoreCard = styled.div`
  gap: 1rem;
  width: 30rem;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  justify-content: center;
  border: 0.2rem solid #eee;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #8492a6;
  }
`;

export const AddMoreLabel = styled.span`
  color: #8492a6;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;
