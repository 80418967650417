import { t } from 'i18next';
import { useState } from 'react';
import { getSocialNetworkIcon } from 'Services/Utils/Utils';
import { SearchUserBenchmarkResponse } from 'features/Benchmark/types';

import * as S from './Styles';

type Props = {
  data: SearchUserBenchmarkResponse[];
  handleUsers: (user: SearchUserBenchmarkResponse) => void;
};

const SubmitBenchmarkSelectedUsersCard = ({ data, handleUsers }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <S.Card>
      <S.MainRow onClick={() => setExpanded(!expanded)}>
        <S.AvatarsContainer>
          {data.map((item, index) => (
            <S.AvatarImg key={index} src={item.displayPicture} />
          ))}
        </S.AvatarsContainer>

        <S.LabelIconContainer active={expanded}>
          {expanded
            ? t('benchmark.form.hide') +
              data.length +
              t('benchmark.form.competitors')
            : t('benchmark.form.show') +
              data.length +
              t('benchmark.form.competitors')}

          <svg version="1.1" viewBox="0 0 32 32">
            <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
          </svg>
        </S.LabelIconContainer>
      </S.MainRow>

      <S.CompetitorsContainer expanded={expanded} numOfUsers={data.length}>
        {data.map(item => (
          <S.CompetitorList
            key={item.publicUserId}
            onClick={() => handleUsers(item)}
          >
            <S.CompetitorInfo>
              <S.CompetitorAvatarContainer>
                <S.CompetitorAvatar src={item.displayPicture} />
                {getSocialNetworkIcon({
                  width: '1.5rem',
                  height: '1.5rem',
                  gradientColored: true,
                  socialNetwork: item.socialNetwork,
                })}
              </S.CompetitorAvatarContainer>

              <S.CompetitorNameNetworkContainer>
                <S.CompetitorUsername>{item.username}</S.CompetitorUsername>
                <S.CompetitorNetwork>
                  {t(`socialNetworks.${item.socialNetwork}`)}
                </S.CompetitorNetwork>
              </S.CompetitorNameNetworkContainer>
            </S.CompetitorInfo>

            <S.RemoveContainer>
              {t('benchmark.form.remove')}

              <svg viewBox="0 0 32 32">
                <path d="M16 2.667c-7.364 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333 13.333-5.969 13.333-13.333-5.969-13.333-13.333-13.333zM16 27.333c-6.259 0-11.333-5.075-11.333-11.333s5.075-11.333 11.333-11.333 11.333 5.075 11.333 11.333-5.075 11.333-11.333 11.333zM8.457 17c-0.552 0-1-0.448-1-1s0.448-1 1-1h15.085c0.552 0 1 0.448 1 1s-0.448 1-1 1h-15.085z" />
              </svg>
            </S.RemoveContainer>
          </S.CompetitorList>
        ))}
      </S.CompetitorsContainer>
    </S.Card>
  );
};

export default SubmitBenchmarkSelectedUsersCard;
