import moment from 'moment';
import auth from 'api/auth-helper';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetLocations } from 'Services/Utils/CustomHooks';
import {
  MAX_DAYS_FILTER,
  LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY,
} from 'data/constants';
import {
  SocialNetworks,
  TCountriesAvailableValues,
} from 'Services/Utils/types';

import {
  ParamsFilters,
  SearchesLocalStorage,
  ListeningSortByField,
} from '../types';

export const useListening = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location || {};
  const { filters }: { filters: ParamsFilters } = state || {};

  const startDate = moment().subtract(90, 'days').utc().startOf('day');
  const endDate = moment().utc().endOf('day');

  const [timeFrame, setTimeFrame] = useState(() => {
    return [
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ];
  });

  const [validTimeframe, setValidTimeframe] = useState(true);
  const [validSearchWord, setValidSearchWord] = useState(true);
  const [toggleSortByButton, setToggleSortByButton] = useState(false);
  const [toggleFilterButton, setToggleFilterButton] = useState(false);
  const [showTimeFramePicker, setShowTimeFramePicker] = useState(false);

  const [visibleHashtagsCount, setVisibleHashtagsCount] = useState(5);
  const [previousSearchesCount, setPreviousSearchesCount] = useState(5);

  const [searchWord, setSearchWord] = useState<string | undefined>();
  const [network, setNetwork] = useState<SocialNetworks | undefined>();
  const [sortByField, setSortByField] = useState(ListeningSortByField.creators);
  const [countryCode, setCountryCode] = useState<string>(
    filters?.countryCode[0] ?? 'PT',
  );

  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);

  const storedPreviousSearches = useCallback(() => {
    const rawData = localStorage.getItem(
      `${LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY}_${
        auth.isAuthenticated().agentId
      }`,
    );

    let data: SearchesLocalStorage[];

    try {
      const parsedData = JSON.parse(rawData || '[]');

      if (
        !Array.isArray(parsedData) ||
        !parsedData.every(
          item =>
            typeof item === 'object' &&
            'term' in item &&
            'avgEng' in item &&
            'numUsers' in item &&
            'numPosts' in item &&
            'filters' in item,
        )
      ) {
        localStorage.removeItem(
          `${LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY}_${
            auth.isAuthenticated().agentId
          }`,
        );
        return [];
      }

      data = parsedData;
    } catch {
      localStorage.removeItem(
        `${LISTENING_PREVIOUS_SEARCHES_LOCALSTORAGE_KEY}_${
          auth.isAuthenticated().agentId
        }`,
      );
      return [];
    }

    const key = () => {
      switch (sortByField) {
        case ListeningSortByField.creators:
          return 'numUsers';
        case ListeningSortByField.posts:
          return 'numPosts';
        case ListeningSortByField.avgEng:
          return 'avgEng';
        default:
          return 'numUsers';
      }
    };

    data.sort((a, b) => b[key()] - a[key()]);

    return data;
  }, [sortByField]);

  const handleGoToTermAnalytics = useCallback(
    (item?: SearchesLocalStorage) => {
      if (validTimeframe) {
        if (item && item.term.trim().length > 2) {
          const query = queryString.stringify(
            {
              ...item.filters,
            },
            { arrayFormat: 'bracket' },
          );

          navigate(`/listening/${item.term.trim()}?${query}`, {
            state: {
              filters: item.filters,
            },
          });

          setToggleFilterButton(false);

          return;
        }

        if (searchWord && searchWord.trim().length > 2) {
          const query = queryString.stringify(
            {
              endDate: timeFrame[0].endDate.valueOf(),
              startDate: timeFrame[0].startDate.valueOf(),
              keywords: [searchWord.trim()],
              countryCode: [countryCode],
              network: network ? [network] : undefined,
            },
            { arrayFormat: 'bracket' },
          );

          navigate(`/listening/${searchWord.trim()}?${query}`, {
            state: {
              filters: {
                endDate: timeFrame[0].endDate.valueOf(),
                startDate: timeFrame[0].startDate.valueOf(),
                keywords: [searchWord.trim()],
                countryCode: [countryCode],
                network: network ? [network] : undefined,
              },
            },
          });

          setToggleFilterButton(false);
        }
      }
    },
    [timeFrame, searchWord, validTimeframe, countryCode, network],
  );

  const handleChangeSearchWord = useCallback((word: string) => {
    setSearchWord(word);
  }, []);

  const handleChangeSortBy = useCallback(
    (sortKey: keyof typeof ListeningSortByField) => {
      setSortByField(ListeningSortByField[sortKey]);
      setToggleSortByButton(false);
    },
    [sortByField],
  );

  const handleLoadMoreHashtags = useCallback(() => {
    setVisibleHashtagsCount(prevState => prevState + 5);
  }, []);

  const handleLoadMorePreviousSearches = useCallback(() => {
    setPreviousSearchesCount(prevState => prevState + 5);
  }, []);

  const handleToggleSortByButton = useCallback(() => {
    setTimeout(() => setToggleSortByButton(!toggleSortByButton), 0);
  }, [toggleSortByButton]);

  const handleClickOutToggleSortByButton = useCallback(() => {
    if (toggleSortByButton) {
      setToggleSortByButton(false);
    }
  }, [toggleSortByButton]);

  const handleToggleFilterButton = useCallback(() => {
    setTimeout(() => setToggleFilterButton(!toggleFilterButton), 0);
  }, [toggleFilterButton]);

  const handleClickOutToggleFilterButton = useCallback(() => {
    if (toggleFilterButton) {
      setToggleFilterButton(false);
    }
  }, [toggleFilterButton]);

  const { data: locations, isFetching: isFetchingGetLocations } =
    useGetLocations(true);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];

      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });

      setCountriesAvailableValues(countriesAvailableValues);
    }
  }, [locations, isFetchingGetLocations]);

  const handleClearFilters = useCallback(() => {
    setSearchWord('');
    setCountryCode('PT');
    setNetwork(undefined);
    setTimeFrame([
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ]);
  }, []);

  useEffect(() => {
    if (
      searchWord &&
      searchWord.trim().length > 0 &&
      searchWord.trim().length < 3
    ) {
      setValidSearchWord(false);
    } else {
      setValidSearchWord(true);
    }
  }, [searchWord]);

  useEffect(() => {
    const diffDays = moment(timeFrame[0].endDate).diff(
      moment(timeFrame[0].startDate),
      'days',
    );

    if (diffDays > MAX_DAYS_FILTER) {
      setValidTimeframe(false);
    } else {
      setValidTimeframe(true);
    }
  }, [timeFrame]);

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setSearchWord(filters.keywords[0]);
      setNetwork(filters.network ? filters.network[0] : undefined);
      setTimeFrame([
        {
          startDate: moment(filters.startDate).toDate(),
          endDate: moment(filters.endDate).toDate(),
          key: 'selection',
        },
      ]);
    }
  }, [filters]);

  return {
    network,
    timeFrame,
    setNetwork,
    searchWord,
    countryCode,
    sortByField,
    setTimeFrame,
    setCountryCode,
    validTimeframe,
    validSearchWord,
    toggleFilterButton,
    toggleSortByButton,
    handleChangeSortBy,
    handleClearFilters,
    showTimeFramePicker,
    visibleHashtagsCount,
    previousSearchesCount,
    setShowTimeFramePicker,
    handleChangeSearchWord,
    handleLoadMoreHashtags,
    storedPreviousSearches,
    handleGoToTermAnalytics,
    countriesAvailableValues,
    handleToggleFilterButton,
    handleToggleSortByButton,
    handleLoadMorePreviousSearches,
    handleClickOutToggleFilterButton,
    handleClickOutToggleSortByButton,
  };
};
