import { useCallback } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { FilteredKpisOverview } from 'features/Benchmark/types';
import { benchmarkColors } from 'features/Benchmark/data/constants';

import * as S from './Styles';

type Kpis = {
  posts: number;
  followers: number;
  engRate: number;
};

type Props = {
  title: string;
  keyValue: string;
  data: FilteredKpisOverview[];
};

const BenchmarkCompetitorsChart = ({ title, data, keyValue }: Props) => {
  const noPercentage = keyValue === 'posts';

  const options = {
    colors: benchmarkColors,
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 50,
        right: 50,
      },
    },
    fill: {
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 15,
        borderRadiusApplication: 'end',
      },
    },
    xaxis: {
      categories: [''],
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: 7,
        offsetY: 3,
        formatter(value: any) {
          return noPercentage ? value : `${value.toFixed(2)} %`;
        },
        style: {
          colors: ['#606976'],
          fontWeight: 400,
          fontSize: '1.2rem',
        },
      },
    },
  };

  const stackedBarData = useCallback(() => {
    return (data || []).map(user => {
      const value =
        keyValue === 'growth'
          ? Number(user.kpis.growth_perc.followers)
          : Number(user.kpis.total[keyValue as keyof Kpis]);

      return {
        name: user.name,
        data: [value],
      };
    });
  }, [data]);

  return (
    <S.CardContainer>
      <S.CardInnerContainer>
        <S.CardTitle>{title}</S.CardTitle>
        <ReactApexChart
          type="bar"
          height="100%"
          series={stackedBarData()}
          options={options as ApexOptions}
        />
      </S.CardInnerContainer>
    </S.CardContainer>
  );
};

export default BenchmarkCompetitorsChart;
