/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useState, useEffect, useRef, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

import Button from '../../components/shared/Button/Button';
import * as S from './Styles';
import { ISignUpProps } from './types';
import SkorrOnboardingBackgroundImage from '../../assets/skorr-onboarding-bg.jpg';
import SkorrLogo from '../../assets/logo-skorr-vh.svg';
import SkorrLogoSmallWindow from '../../assets/logo-skorr-vh-noslogan.svg';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import {
  emailValid,
  valueEmpty,
  passwordValid,
  urlValid,
} from '../../validations/validationFunctions';
import FacebookIcon from '../../assets/iconComponents/FacebookIcon';
import TwitterIcon from '../../assets/iconComponents/TwitterIcon';
import InputCheckMarkIcon from '../../assets/iconComponents/InputCheckMarkIcon';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import QuestionMarkIcon from '../../assets/iconComponents/QuestionMarkIcon';
import EnvelopeIcon from '../../assets/iconComponents/EnvelopeIcon';
import AppleStoreButton from '../../assets/iconComponents/AppleStoreButton';
import GoogleStoreButton from '../../assets/iconComponents/GoogleStoreButton';
import ToggleButton from '../../components/shared/ToggleButton/ToggleButton';
import { ISignUpPayload, PartnerTypes } from '../../Services/Utils/types';
import { useSignup } from './RQCustomHooks';

const SignUp = (props: ISignUpProps) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailInputFocused, setIsEmailInputFocused] =
    useState<boolean>(false);
  const [hasEmailBeenChanged, setHasEmailBeenChanged] = useState(false);
  const [forceEmailErrorDisplay, setForceEmailErrorDisplay] = useState<
    string | undefined
  >();
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isPasswordInputFocused, setIsPasswordInputFocused] =
    useState<boolean>(false);
  const [hasPasswordBeenChanged, setHasPasswordBeenChanged] = useState(false);
  const [forcePasswordErrorDisplay, setForcePasswordErrorDisplay] = useState<
    string | undefined
  >();
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    useState<boolean>(false);
  const [isConfirmPasswordProcessing, setIsConfirmPasswordProcessing] =
    useState(false);
  const [isConfirmPasswordInputFocused, setIsConfirmPasswordInputFocused] =
    useState<boolean>(false);
  const [hasConfirmPasswordBeenChanged, setHasConfirmPasswordBeenChanged] =
    useState(false);
  const [
    forceConfirmPasswordErrorDisplay,
    setForceConfirmPasswordErrorDisplay,
  ] = useState<string | undefined>();
  const [
    triggerConfirmPasswordValidation,
    setTriggerConfirmPasswordValidation,
  ] = useState(0);
  const [businessAccount, setBusinessAccount] = useState<boolean>(false);
  const [website, setWebsite] = useState<string>('');
  const [hasWebsiteBeenChanged, setHasWebsiteBeenChanged] =
    useState<boolean>(false);
  const [isWebsiteValid, setIsWebsiteValid] = useState<boolean>(false);
  const [isWebsiteInputFocused, setIsWebsiteInputFocused] =
    useState<boolean>(false);
  const [forceWebsiteErrorDisplay, setForceWebsiteErrorDisplay] = useState<
    string | undefined
  >();
  const [hasSignupButtonBeenClicked, setHasSignupButtonBeenClicked] =
    useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [showActivationCodeEmailSent, setShowActivationCodeEmailSent] =
    useState<boolean>(false);
  const {
    isSuccess: isSuccessSignup,
    isError: isErrorSignup,
    isLoading: isLoadingSignup,
    mutate: signupMutation,
  } = useSignup();
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const getPasswordInvalidMessage = useCallback((password: string): string => {
    if (!(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
      return props.t('signup.passwordLetterCaseWarning');
    }
    if (!/[0-9]/.test(password)) {
      return props.t('signup.passwordNumbersWarning');
    }
    if (!/[!@_#$%^&*]/.test(password)) {
      return props.t('signup.passwordSpecialCharacterWarning');
    }
    if (password.length < 8) {
      return props.t('signup.passwordLengthWarning');
    }
    return '';
  }, []);

  useEffect(() => {
    if (isEmailInputFocused && forceEmailErrorDisplay) {
      setForceEmailErrorDisplay(undefined);
    }
    if (isPasswordInputFocused && forcePasswordErrorDisplay) {
      setForcePasswordErrorDisplay(undefined);
    }
    if (isConfirmPasswordInputFocused && forceConfirmPasswordErrorDisplay) {
      setForceConfirmPasswordErrorDisplay(undefined);
    }
    if (isWebsiteInputFocused && forceWebsiteErrorDisplay) {
      setForceWebsiteErrorDisplay(undefined);
    }
  }, [
    isEmailInputFocused,
    isPasswordInputFocused,
    isConfirmPasswordInputFocused,
    isWebsiteInputFocused,
  ]);

  useEffect(() => {
    if (isErrorSignup) {
      setHasError(true);
      setMsgError(props.t('signup.couldNotCreateAccount'));
    }
  }, [isErrorSignup]);

  useEffect(() => {
    if (isSuccessSignup) {
      setShowActivationCodeEmailSent(true);
      setTimeout(() => setShowActivationCodeEmailSent(false), 3000);
    }
  }, [isSuccessSignup]);

  const isSignUpWithEmailButtonEnabled = () => {
    return (
      !valueEmpty(email) &&
      isEmailValid &&
      isPasswordValid &&
      isConfirmPasswordValid &&
      (!businessAccount || isWebsiteValid) &&
      !isLoadingSignup
    );
  };

  const signUpActionInvoked = () => {
    if (!isSignUpWithEmailButtonEnabled()) {
      if (valueEmpty(email))
        setForceEmailErrorDisplay(props.t('generic.emailIsRequired'));
      if (valueEmpty(password))
        setForcePasswordErrorDisplay(props.t('generic.passwordIsRequired'));
      if (valueEmpty(confirmPassword)) {
        setForceConfirmPasswordErrorDisplay(
          props.t('signup.confirmPasswordIsRequired'),
        );
      } else if (!isConfirmPasswordValid) {
        setForceConfirmPasswordErrorDisplay(
          props.t('signup.passwordsNeedToMatch'),
        );
      }

      if (businessAccount && valueEmpty(website))
        setForceWebsiteErrorDisplay(
          props.t('signin.generic.websiteIsRequired'),
        );
    } else {
      const signupPayload: ISignUpPayload & { dateOfBirth: null } = {
        name: '',
        email,
        password,
        socialTokens: [],
        loginTokens: [],
        displayPicture: null,
        topicsOfInterest: null,
        age: null,
        gender: null,
        country: null,
        countryId: null,
        countryCode: null,
        city: null,
        cityId: null,
        type: businessAccount ? PartnerTypes.brand : PartnerTypes.influencer,
        over18: null,
        agencyTalent: null,
        agencyId: null,
        agencyName: null,
        lookingForwardAgency: null,
        brandName: null,
        websiteUrl: website,
        brandEmail: null,
        dateOfBirth: null,
      };
      signupMutation(signupPayload);
    }

    setHasSignupButtonBeenClicked(true);
  };

  return (
    <S.Container>
      <S.BackgroundImageContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageLeft src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
        <S.BackgroundImageInnerContainer>
          <S.BackgroundImageRight src={SkorrOnboardingBackgroundImage} />
        </S.BackgroundImageInnerContainer>
      </S.BackgroundImageContainer>

      <S.Navbar>
        <S.NavbarInnerContainer>
          <S.NavbarAnchorTag>
            <S.NavbarImage
              src={SkorrLogo}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
            <S.NavbarImageSmallWindow
              src={SkorrLogoSmallWindow}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
          </S.NavbarAnchorTag>
        </S.NavbarInnerContainer>
      </S.Navbar>

      <S.FormSectionContainer>
        {!isSuccessSignup ? (
          <S.FormOuterContainer>
            <S.FormContainer>
              <S.FormContainerFirstHalf>
                <S.FormContainerFirstHalfInnerContainer>
                  <S.Title>{props.t('signup.createAnAccount')}</S.Title>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={email}
                      onChange={(inputValue: string) => {
                        setEmail(inputValue);
                        setHasEmailBeenChanged(true);
                      }}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                      ) => setIsEmailValid(!isInvalidOrProcessing)}
                      validationFunction={emailValid}
                      verifyEmailAvailability={true}
                      title={props.t('generic.email')}
                      setFocused={focused => setIsEmailInputFocused(focused)}
                      setBlurred={blurred => {
                        setIsEmailInputFocused(!blurred);
                        if (
                          !email.length &&
                          (hasEmailBeenChanged || hasSignupButtonBeenClicked)
                        ) {
                          setForceEmailErrorDisplay(
                            props.t('generic.emailIsRequired'),
                          );
                        }
                      }}
                      forceErrorDisplay={forceEmailErrorDisplay}
                      forceBorderColorRed={Boolean(forceEmailErrorDisplay)}
                      validateOnFirstRender={true}
                      focusOnMount={true}
                      allowGreenBorder={true}
                      allowCheckMarkIcon={true}
                      invalidFieldWarningMessage={props.t(
                        'generic.emailFormatIsNotValid',
                      )}
                    />
                  </S.FormInputContainer>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={password}
                      onChange={(inputValue: string) => {
                        setPassword(inputValue);
                        setHasPasswordBeenChanged(true);
                      }}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                        isProcessing: boolean,
                      ) => {
                        setIsPasswordValid(!isInvalidOrProcessing);
                        if (
                          !isProcessing &&
                          hasPasswordBeenChanged &&
                          confirmPassword &&
                          !isConfirmPasswordProcessing
                        ) {
                          setTriggerConfirmPasswordValidation(
                            prevState => prevState + 1,
                          );
                        }
                      }}
                      validationFunction={passwordValid}
                      type={'password'}
                      title={props.t('generic.password')}
                      setFocused={focused => setIsPasswordInputFocused(focused)}
                      setBlurred={blurred => {
                        setIsPasswordInputFocused(!blurred);
                        if (
                          !password.length &&
                          (hasPasswordBeenChanged || hasSignupButtonBeenClicked)
                        ) {
                          setForcePasswordErrorDisplay(
                            props.t('generic.passwordIsRequired'),
                          );
                        }
                      }}
                      isFocused={isPasswordInputFocused}
                      forceErrorDisplay={forcePasswordErrorDisplay}
                      forceBorderColorRed={Boolean(forcePasswordErrorDisplay)}
                      invalidFieldWarningMessage={getPasswordInvalidMessage(
                        password,
                      )}
                      validateOnFirstRender={true}
                      allowGreenBorder={true}
                      allowCheckMarkIcon={true}
                      showPasswordTooltip={!!isPasswordInputFocused}
                      passwordTooltipMarginTop={'1.7rem'}
                    />
                  </S.FormInputContainer>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={confirmPassword}
                      onChange={(inputValue: string) => {
                        setConfirmPassword(inputValue);
                        setHasConfirmPasswordBeenChanged(true);
                      }}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                        isProcessing: boolean,
                      ) => {
                        setIsConfirmPasswordValid(!isInvalidOrProcessing);
                        setIsConfirmPasswordProcessing(isProcessing);
                      }}
                      validationFunction={() =>
                        !confirmPassword.localeCompare(password)
                      }
                      type={'password'}
                      title={props.t('signup.confirmPassword')}
                      setFocused={focused =>
                        setIsConfirmPasswordInputFocused(focused)
                      }
                      setBlurred={blurred => {
                        setIsConfirmPasswordInputFocused(!blurred);
                        if (
                          !confirmPassword.length &&
                          (hasConfirmPasswordBeenChanged ||
                            hasSignupButtonBeenClicked)
                        ) {
                          setForceConfirmPasswordErrorDisplay(
                            props.t('signup.confirmPasswordIsRequired'),
                          );
                        }
                      }}
                      isFocused={isConfirmPasswordInputFocused}
                      forceErrorDisplay={forceConfirmPasswordErrorDisplay}
                      forceBorderColorRed={Boolean(
                        forceConfirmPasswordErrorDisplay,
                      )}
                      invalidFieldWarningMessage={props.t(
                        'signup.passwordsNeedToMatch',
                      )}
                      validateOnFirstRender={true}
                      allowGreenBorder={true}
                      allowCheckMarkIcon={true}
                      triggerValidationFunction={
                        triggerConfirmPasswordValidation
                      }
                      triggeredValidationFunctionTimer={0}
                    />
                  </S.FormInputContainer>

                  <S.TermsAndConditions
                    onClick={() =>
                      window.open('https://skorr.social/terms/', '_blank')
                    }
                  >
                    <S.TermsAndConditionsPart1Word>
                      {props.t('signup.termsAndConditionsAgreementPart1')}
                    </S.TermsAndConditionsPart1Word>
                    <S.TermsAndConditionsPart1Word>
                      {props.t('signup.termsAndConditionsAgreementPart2')}
                    </S.TermsAndConditionsPart1Word>
                    <S.TermsAndConditionsPart1Word>
                      {props.t('signup.termsAndConditionsAgreementPart3')}
                    </S.TermsAndConditionsPart1Word>
                    <S.TermsAndConditionsPart1Word>
                      {props.t('signup.termsAndConditionsAgreementPart4')}
                    </S.TermsAndConditionsPart1Word>
                    <S.TermsAndConditionsPart1Word>
                      {props.t('signup.termsAndConditionsAgreementPart5')}
                    </S.TermsAndConditionsPart1Word>

                    <S.TermsAndConditionsPart2Word>
                      {props.t('signup.termsAndConditionsAgreementPart6')}
                    </S.TermsAndConditionsPart2Word>
                    <S.TermsAndConditionsPart2Word>
                      {props.t('signup.termsAndConditionsAgreementPart7')}
                    </S.TermsAndConditionsPart2Word>
                    <S.TermsAndConditionsPart2Word>
                      {props.t('signup.termsAndConditionsAgreementPart8')}
                    </S.TermsAndConditionsPart2Word>
                  </S.TermsAndConditions>

                  <ToggleButton
                    checked={businessAccount}
                    setChecked={(checked: boolean) =>
                      setBusinessAccount(checked)
                    }
                    label={
                      <S.ManagerAccountTogglerTitleContainer>
                        <S.LabelTextContainer>
                          {props.t('signup.managerAccount')}
                        </S.LabelTextContainer>
                        <S.QuestionMarkIconContainer>
                          <QuestionMarkIcon
                            width={'2.3rem'}
                            height={'2.3rem'}
                            cursor={'default'}
                            id={'manager-account-tooltip'}
                          />
                        </S.QuestionMarkIconContainer>
                        <S.ManagerAccountTooltipContainer>
                          <S.ManagerAccountTooltipEntry>
                            <S.ManagerAccountTooltipEntryBold>
                              {props.t(
                                'signup.managerAccountTooltip.brand.title',
                              )}
                            </S.ManagerAccountTooltipEntryBold>
                            {props.t('signup.managerAccountTooltip.brand.rest')}
                          </S.ManagerAccountTooltipEntry>
                          <S.ManagerAccountTooltipDivider />
                          <S.ManagerAccountTooltipEntry>
                            <S.ManagerAccountTooltipEntryBold>
                              {props.t(
                                'signup.managerAccountTooltip.influencer.title',
                              )}
                            </S.ManagerAccountTooltipEntryBold>
                            {props.t(
                              'signup.managerAccountTooltip.influencer.rest',
                            )}
                          </S.ManagerAccountTooltipEntry>
                          <S.ManagerAccountTooltipDivider />
                          <S.ManagerAccountTooltipEntry>
                            <S.ManagerAccountTooltipEntryBold>
                              {props.t(
                                'signup.managerAccountTooltip.agency.title',
                              )}
                            </S.ManagerAccountTooltipEntryBold>
                            {props.t(
                              'signup.managerAccountTooltip.agency.rest',
                            )}
                          </S.ManagerAccountTooltipEntry>
                        </S.ManagerAccountTooltipContainer>
                      </S.ManagerAccountTogglerTitleContainer>
                    }
                    highlightLabelOnChecked={true}
                    marginBlockStart={'3.2rem'}
                    toggleButtonContainerMarginTop={'0.4rem'}
                    toggleButtonContainerMarginBottom={'0.4rem'}
                    toggleButtonContainerMarginLeft={'0.1rem'}
                    toggleButtonContainerMarginRight={'0.1rem'}
                    toggleButtonOuterContainerPaddingTop={'0.1rem'}
                    toggleButtonOuterContainerPaddingBottom={'0.1rem'}
                    toggleButtonOuterContainerPaddingLeft={'0.6rem'}
                    toggleButtonOuterContainerPaddingRight={'0.6rem'}
                    alignItems={'center'}
                    position={'relative'}
                  />

                  <S.BusinessAccountWebsiteInputContainer
                    visible={!!businessAccount}
                  >
                    <InputControlV2
                      value={website}
                      onChange={(inputValue: string) => {
                        setWebsite(inputValue);
                        setHasWebsiteBeenChanged(true);
                      }}
                      validationFunction={
                        businessAccount ? urlValid : undefined
                      }
                      setIsInvalidOrProcessing={
                        businessAccount
                          ? (isInvalidOrProcessing: boolean) =>
                              setIsWebsiteValid(!isInvalidOrProcessing)
                          : undefined
                      }
                      title={props.t('generic.website')}
                      setFocused={focused => setIsWebsiteInputFocused(focused)}
                      setBlurred={blurred => {
                        setIsWebsiteInputFocused(!blurred);
                        if (
                          !website.length &&
                          (hasWebsiteBeenChanged || hasSignupButtonBeenClicked)
                        ) {
                          setForceWebsiteErrorDisplay(
                            props.t('signin.generic.websiteIsRequired'),
                          );
                        }
                      }}
                      forceErrorDisplay={forceWebsiteErrorDisplay}
                      validateOnFirstRender={!!businessAccount}
                      allowGreenBorder={true}
                      allowCheckMarkIcon={true}
                      disabled={!businessAccount}
                      invalidFieldWarningMessage={props.t(
                        'signin.generic.websiteFormatIsNotValid',
                      )}
                    />
                  </S.BusinessAccountWebsiteInputContainer>

                  <Button
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    display={'block'}
                    width={'100%'}
                    textAlign={'center'}
                    marginBlock={'2.4rem'}
                    marginTop={'3.2rem'}
                    fontWeight={'500'}
                    disabledNotNative={!isSignUpWithEmailButtonEnabled()}
                    cursorOnDisable={'default'}
                    blockHoverStylesOnDisable={true}
                    pointerEventsOnDisable={'all'}
                    onClick={() => signUpActionInvoked()}
                  >
                    {props.t('signup.signupWithEmail')}
                  </Button>

                  {/* <S.SignInMethodDivider />

                    <S.SignInSocialButtonsContainer>

                      <Button
                          themeStyles={(themeGlobal as any)['button']['signin-facebook']}
                          paddingLeft={'0.6rem'} 
                          paddingRight={'0.6rem'}
                          paddingTop={'0.1rem'} 
                          paddingBottom={'0.1rem'}
                      >
                          <FacebookIcon 
                              svgWidth={'3.4rem'} 
                              svgHeight={'2rem'} 
                              flexBasis={'3.4rem'}
                              svgMarginRight={'2rem'}
                              justifyContent={'start'}
                              containerHeight={'2rem'}
                          />
                          <S.SignInFacebookButtonLabelContainer>
                              {props.t('signin.generic.continueWithFacebook')}
                          </S.SignInFacebookButtonLabelContainer>
                      </Button>

                      <Button
                          themeStyles={(themeGlobal as any)['button']['signin-twitter']}
                          paddingLeft={'0.6rem'} 
                          paddingRight={'0.6rem'}
                          paddingTop={'0.1rem'} 
                          paddingBottom={'0.1rem'}
                          textAlign={'center'}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                      >
                          <TwitterIcon 
                              svgWidth={'2rem'} 
                              svgHeight={'2rem'}
                              containerHeight={'2rem'}
                          />
                      </Button>

                  </S.SignInSocialButtonsContainer> */}
                </S.FormContainerFirstHalfInnerContainer>
              </S.FormContainerFirstHalf>

              <S.SignInFormContentDivider />

              <S.SignInFormFooter>
                {props.t('signup.alreadyHaveAnAccount')}
                <S.SignInFormFooterLink
                  onClick={() => navigate('/')}
                  onMouseDown={(event: any) => event.preventDefault()}
                >
                  {props.t('signup.signin')}
                </S.SignInFormFooterLink>
              </S.SignInFormFooter>
            </S.FormContainer>

            <S.SignInPageFooter>
              <S.SignInPageFooterLinksContainer>
                <S.SignInPageFooterLink
                  onClick={() =>
                    window.open('https://www.skorr.social', '_blank')
                  }
                >
                  {props.t('signin.generic.about')}
                </S.SignInPageFooterLink>
                <S.SignInPageFooterLink
                  onClick={() =>
                    window.open('https://skorr.social/terms', '_blank')
                  }
                >
                  {props.t('signin.generic.terms')}
                </S.SignInPageFooterLink>
                <S.SignInPageFooterLink
                  onClick={() =>
                    window.open('https://skorr.social/knowledge-base', '_blank')
                  }
                >
                  {props.t('signin.generic.help')}
                </S.SignInPageFooterLink>
              </S.SignInPageFooterLinksContainer>
              <S.SignInPageFooterLangCopyRightsContainer>
                <S.CopyRightSpan>
                  {props.t('signin.generic.copyright')}
                </S.CopyRightSpan>
              </S.SignInPageFooterLangCopyRightsContainer>
            </S.SignInPageFooter>
          </S.FormOuterContainer>
        ) : (
          <S.FormOuterContainer>
            <S.FormContainer>
              <S.SignupSuccesfulHeaderContainer>
                <S.SignupSuccessfulTitleContainer>
                  <span>{props.t('signup.congratulations')}</span>
                  <span>{props.t('signup.yourAccountWasCreated')}</span>
                </S.SignupSuccessfulTitleContainer>
              </S.SignupSuccesfulHeaderContainer>

              <S.SignupSuccessfulContentOuterContainer>
                <S.SignupSuccessfullContentTextContainer>
                  {businessAccount ? (
                    <>
                      <span>
                        {props.t('signup.businessAccountFinalMessage1')}
                      </span>
                      <span>
                        {props.t('signup.businessAccountFinalMessage2')}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>{props.t('signup.influencerFinalMessage1')}</span>
                      <span>{props.t('signup.influencerFinalMessage2')}</span>
                    </>
                  )}
                </S.SignupSuccessfullContentTextContainer>

                {businessAccount == false && (
                  <>
                    <S.AppDownloadButtonsContainer>
                      <AppleStoreButton
                        width={'14.1rem'}
                        height={'4.7rem'}
                        onClick={() => {
                          window.open(
                            'https://apps.apple.com/pt/app/skorr-grow-socially/id1353070098',
                            '_blank',
                          );
                        }}
                      />
                      <GoogleStoreButton
                        width={'16rem'}
                        height={'4.7rem'}
                        onClick={() => {
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.skorr.android.growsocial.media',
                            '_blank',
                          );
                        }}
                      />
                    </S.AppDownloadButtonsContainer>

                    <S.DividerOr />
                  </>
                )}

                <Button
                  borderRadius={'0.6rem'}
                  borderWidth={'0rem'}
                  height={'4rem'}
                  fontSize={'1.6rem'}
                  display={'block'}
                  width={'100%'}
                  textAlign={'center'}
                  marginBlock={'2.4rem'}
                  marginTop={'2.2rem'}
                  fontWeight={'500'}
                  backgroundColor="#f4f4f4"
                  color="#676767"
                  onClick={() => navigate('/account-activation')}
                >
                  {businessAccount
                    ? props.t('signup.continue')
                    : props.t('signup.continueFromWeb')}
                </Button>
              </S.SignupSuccessfulContentOuterContainer>

              <S.SignInFormContentDivider />

              <S.SignInFormFooter>
                {props.t('generic.needHelp')}{' '}
                <S.SignInFormFooterLink
                  href={`mailto:app@skorr.social?subject=Skorr Help :: Question`}
                >
                  {props.t('signin.generic.contactUs')}
                </S.SignInFormFooterLink>
              </S.SignInFormFooter>
            </S.FormContainer>

            <S.SignInPageFooter>
              <S.SignInPageFooterLinksContainer>
                <S.SignInPageFooterLink
                  onClick={() => window.open('https://skorr.social', '_blank')}
                >
                  {props.t('signin.generic.about')}
                </S.SignInPageFooterLink>
                <S.SignInPageFooterLink
                  onClick={() =>
                    window.open('https://skorr.social/terms', '_blank')
                  }
                >
                  {props.t('signin.generic.terms')}
                </S.SignInPageFooterLink>
              </S.SignInPageFooterLinksContainer>
              <S.SignInPageFooterLangCopyRightsContainer>
                <S.CopyRightSpan>
                  {props.t('signin.generic.copyright')}
                </S.CopyRightSpan>
              </S.SignInPageFooterLangCopyRightsContainer>
            </S.SignInPageFooter>
          </S.FormOuterContainer>
        )}

        <S.SecondBackgroundImageContainer />
      </S.FormSectionContainer>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingSignup}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('signup.signingUp')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'bottom'}
        show={showActivationCodeEmailSent}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <EnvelopeIcon width={'2rem'} height={'2rem'} marginRight={'1rem'} />
          {props.t('signup.activationCodeEmailSent')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={'Close'}
        primaryButtonAction={() => setHasError(false)}
      />
    </S.Container>
  );
};

export default withTranslation()(SignUp);
