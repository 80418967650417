import {
  IKpiContainerProps,
  ISocialNetworkContainerProps,
} from 'pages/CreateMediaKitConfiguration/Post/types';
import { FaAngleRight } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-top: 3rem;
`;

export const CardTitle = styled.h2`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
  margin-left: 1rem;
`;

export const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;

  @media (max-width: 1150px) {
    margin: 0;
    max-width: 100%;
  }
`;

export const OuterContainer = styled.div`
  width: 100%;
  padding: 0px 1rem;
  position: relative;
  margin-bottom: 2rem;

  @media (min-width: 665px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  @media (max-width: 1150px) {
    padding: 0 0.4rem;
  }

  @media (min-width: 1285px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1665px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  border-radius: 2rem;
  transition: transform 0.2s ease 0s;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(216, 221, 230, 0.35) 0px 0.1rem 9px 0.5rem;

  &:hover {
    transform: scale(1.03);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  padding: 2rem 1.5rem 2rem 2rem;
`;

export const HeaderAvatar = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 2rem;
  border-radius: 50%;
  cursor: pointer;
`;

export const HeaderName = styled.div`
  font-weight: 500;
  overflow: hidden;
  font-size: 1.44rem;
  line-height: 1.6rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  color: rgb(22, 43, 57);
  -webkit-box-orient: vertical;
`;

export const HeaderDate = styled.div`
  font-size: 1.28rem;
  margin-top: 0.4rem;
  line-height: 1.44rem;
  color: rgb(132, 146, 166);
`;

export const PictureContainer = styled.div`
  height: 23vw;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  background: rgb(231, 234, 240);

  @media (min-width: 1665px) {
    height: 18.5vw;
  }

  @media (min-width: 1285px) and (max-width: 1664px) {
    height: 23vw;
  }

  @media (min-width: 992px) and (max-width: 1284px) {
    height: 31vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: 46vw;
  }

  @media (min-width: 665px) and (max-width: 767px) {
    height: 45vw;
  }

  @media (max-width: 665px) {
    height: 91vw;
  }
`;

export const PictureOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center;
  -webkit-box-align: center;
  transition: opacity 0.3s ease 0s;
  background-color: rgba(22, 43, 57, 0.3);

  &:hover {
    opacity: 1;
  }
`;

export const PictureOverlayActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const PictureOverlayIcon = styled.i`
  cursor: pointer;

  svg {
    color: #fff;
    font-size: 4.8rem;
  }
`;

export const TypeIconContainer = styled.div`
  top: 1.5rem;
  right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
`;

export const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const TextContainer = styled.div`
  z-index: 0;
  margin: 2rem;
  color: #8492a6;
  overflow: hidden;
  font-weight: 400;
  position: absolute;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 6.9rem;
  align-items: center;
  -webkit-box-align: center;
  padding: 1.4rem 2rem 1.4rem 1.4rem;

  @media (max-width: 272px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const SocialNetworkContainer = styled.div<ISocialNetworkContainerProps>`
  display: flex;
  padding: 0.3rem;
  min-width: 2.6rem;
  align-items: center;
  border-radius: 10rem;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  outline: white solid 0.2rem;

  @media (max-width: 272px) {
    width: 100%;
    margin-bottom: 0.2rem;
  }
`;

export const DescriptionTextContainer = styled.a<{
  typeCursor?: boolean;
}>`
  flex-grow: 1;
  width: 22.3rem;
  display: block;
  margin: 0px 2.5rem;
  font-size: 1.28rem;
  overflow-x: overlay;
  -webkit-box-flex: 1;
  text-decoration: none;
  color: rgb(132, 146, 166);
  cursor: ${({ typeCursor }) => (typeCursor ? 'pointer' : 'text')};

  @media (max-width: 272px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const DescriptionText = styled.p`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const ViewPostContainer = styled.span`
  align-items: center;
  display: inline-flex;
`;

export const ViewPostIcon = styled(FaAngleRight)``;

export const CopyContentContainer = styled.div.attrs(() => ({
  className: '--hide-from-pdf',
}))`
  min-width: 2rem;
  user-select: none;
  position: relative;
  transition: all 0.25s;

  @media (max-width: 272px) {
    width: 100%;
    text-align: center;
    margin-top: 0.4rem;
  }
`;

export const CopiedToClipboard = styled.span`
  left: 50%;
  opacity: 0;
  color: #333;
  width: 8rem;
  bottom: 320%;
  position: absolute;
  text-align: center;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 0.1rem solid #eeeeee;
  filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
`;

export const CopyIcon = styled(IoMdCopy)`
  color: #8492a6;
  cursor: pointer;
  font-size: 2rem;
`;

// filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
// pointer-events to fix the tooltip flickering when mouseover
export const CopyIconTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    max-width: 20rem;
    position: relative;
    text-align: center;
    color: #333;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    border: 0.1rem solid #eeeeee;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
  }

  .arrow {
    display: none !important;
  }

  .tooltip-inner::before,
  .tooltip-inner::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .tooltip-inner::after {
    right: calc(50% - 0.8rem);
    border-color: #fff transparent transparent transparent;
    border-width: 0.7rem;
  }

  .tooltip-inner::before {
    right: calc(50% - 1rem);
    border-color: #eeeeee transparent transparent transparent;
    border-width: 0.9rem;
  }
`;

export const ReactionsContainer = styled.div`
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
`;

export const KpiContainer = styled.div<IKpiContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;
  line-height: 1.5;
  flex: 0 0 25%;
  max-width: ${({ maxWidth }) => (maxWidth ? 'unset' : '25%')};
  min-width: 8.5rem;
  flex-grow: 1;
`;

export const KpiLabel = styled.span`
  font-size: 1.12rem;
  color: rgb(132, 146, 166);
`;

export const KpiValue = styled.span`
  font-size: 1.28rem;
  color: rgb(22, 43, 57);
  font-weight: 500;
  white-space: nowrap;
`;
