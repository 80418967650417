import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
`;

export const Card = styled.div`
  padding: 3rem;
  margin: 3rem 0;
  border-radius: 2rem;
  background-color: #fff;
  box-shadow: 0 1px 0 1px #d8dde659;
`;

export const FormSectionBlock = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebeef1;
`;

export const FormSectionTitle = styled.h3`
  color: #3c4858;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Row = styled.div`
  gap: 2rem;
  display: flex;
  flex-wrap: wrap;
`;

export const FormGroup = styled.div`
  gap: 1rem;
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;

  @media (min-width: 992px) {
    width: 30%;
  }
`;

export const FormGroupLabel = styled.label`
  color: #3c4858;
  font-size: 1.3rem;
`;

export const Input = styled.input`
  outline: 0;
  width: 25rem;
  height: 4.2rem;
  color: #292929;
  line-height: 18;
  font-weight: 400;
  padding: 0 1.2rem;
  font-size: 1.4rem;
  align-items: center;
  border-radius: 5rem;
  background-color: #ffffff;
  border: 0.1rem solid #cccccc;

  &::placeholder {
    color: #8492a6;
  }

  &:focus {
    border: 0;
    outline: #292929 solid 0.2rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: #292929;
    -webkit-box-shadow: 0 0 0px 40rem #ffffff inset;
  }
`;

export const FilePickerContainer = styled.span`
  display: flex;
  justify-content: flex-start;
`;

export const ErrorText = styled.span`
  color: #b00020;
  font-size: 1rem;
  text-align: start;
`;

export const FooterContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
`;
