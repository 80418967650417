import styled from 'styled-components';

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 0 7.1rem;
  padding-top: 6.9rem;
  flex-direction: column;
`;

export const TopBarContainer = styled.div`
  height: 9rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const MainTitle = styled.h1`
  color: #292929;
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 3.3rem;
`;

export const TopBarActions = styled.div`
  display: flex;
`;

export const InputContainer = styled.div`
  height: 4rem;
  display: flex;
  margin: 0 2rem;
  padding: 0 2rem;
  align-items: center;
  border-radius: 0.6rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 0 0.1rem #292929;
  }

  svg {
    width: 1.7rem;
    height: 1.7rem;
    fill: #676767;
    margin-right: 1rem;
  }

  input {
    width: 15rem;
    height: 4rem;
    border: none;
    outline: none;
    color: #676767;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    background: transparent;
    transition: all 0.3s ease;

    &::placeholder {
      color: #676767;
    }

    &:focus {
      width: 22rem;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #676767;
      -webkit-box-shadow: 0 0 0 40rem #f4f7fd inset;
    }
  }
`;
