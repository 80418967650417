/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import {
  GenerateExternalMediaKitUrlParams,
  UpdateExternalMediaKitByIdParams,
} from '../../Services/SkorrApi/new/ExternalMediaKit/types';
import {
  GetPublicUserHashtagsSortedByMetricsParams,
  IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
} from '../../Services/SkorrApi/new/Post/types';
import { IErrorObject } from '../../Services/Utils/types';
import { logApiRequestError } from '../../Services/Utils/Utils';

const { post, externalMediaKit } = SkorrApi;

export const useGetPublicUserHashtagsLatest = (
  enabled: boolean = false,
  params: GetPublicUserHashtagsSortedByMetricsParams,
) => {
  return useQuery(
    [],
    async () => {
      try {
        const data = await post.getPublicUserHashtagsSortedByMetrics(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicPostsByPublicUserIdFilteredByKeywords = (
  enabled: boolean = false,
  params: IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
) => {
  return useQuery(
    [
      'get-public-posts-by-public-user-id-filtered-by-keywords',
      JSON.stringify(params),
    ],
    async () => {
      try {
        const data = await post.getPublicPostsByPublicUserIdFilteredByKeywords(
          params,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetExternalMediaKitConfigurationById = (
  enabled: boolean = false,
  id: string,
) => {
  return useQuery(
    ['get-external-media-kit-configuration-by-id', id],
    async () => {
      try {
        const data = await externalMediaKit.getExternalMediaConfigurationById(
          id,
        );

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGenerateExternalMediaKitUrl = () => {
  return useMutation(
    async (params: GenerateExternalMediaKitUrlParams) => {
      try {
        const response = await externalMediaKit.generateExternalMediaKitUrl(
          params,
        );
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdateExternalMediaKitById = () => {
  return useMutation(
    async (params: UpdateExternalMediaKitByIdParams) => {
      try {
        const response = await externalMediaKit.updateExternalMediaKitById(
          params,
        );
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
