import { t } from 'i18next';
import { GetHashtagsByFilterResponse } from 'Services/SkorrApi/new/Post';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';

import * as S from './Styles';

type Props = {
  customHook: any;
};

export function ListeningTermOverview({ customHook }: Props) {
  const { term, timeFrame, hashtagsByFilter, publicPostsKpisByFilter } =
    customHook;

  return (
    <S.FullWidthRow>
      <S.ContentContainer>
        <S.ContentInnerContainer>
          <S.ContentHeadRow>
            <S.TermNameContainer>
              <S.TermName>{term}</S.TermName>
            </S.TermNameContainer>
          </S.ContentHeadRow>

          <S.ContentMiddleRow>
            <S.HashtagsRow>
              {hashtagsByFilter?.length > 0 && <S.HashtagsSymbol />}
              {hashtagsByFilter
                ?.slice(0, 5)
                .map((tag: GetHashtagsByFilterResponse) => (
                  <span key={tag.hashtag}>{tag.hashtag}</span>
                ))}
            </S.HashtagsRow>

            <S.DateTimeframe>
              <span>{timeFrame}</span>
            </S.DateTimeframe>
          </S.ContentMiddleRow>

          <S.TermKpisContainer>
            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(publicPostsKpisByFilter?.numUsers ?? 0),
                )}
              </S.MetricValue>

              <S.MetricLabel>{t('listeningTerm.creators')}</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(publicPostsKpisByFilter?.numPosts ?? 0),
                )}
              </S.MetricValue>

              <S.MetricLabel>{t('listeningTerm.posts')}</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(publicPostsKpisByFilter?.audience ?? 0),
                )}
              </S.MetricValue>

              <S.MetricLabel>{t('listeningTerm.avgAudience')}</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {convertNumberToHumanStringfiedFormat(
                  Math.round(publicPostsKpisByFilter?.reactions ?? 0),
                )}
              </S.MetricValue>

              <S.MetricLabel>{t('listeningTerm.avgEngagements')}</S.MetricLabel>
            </S.MetricContainer>

            <S.MetricContainer>
              <S.MetricValue>
                {`${((publicPostsKpisByFilter?.engRate ?? 0) * 100).toFixed(
                  3,
                )}%`}
              </S.MetricValue>

              <S.MetricLabel>{t('listeningTerm.engRate')}</S.MetricLabel>
            </S.MetricContainer>
          </S.TermKpisContainer>
        </S.ContentInnerContainer>
      </S.ContentContainer>
    </S.FullWidthRow>
  );
}
