/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  RevokeAccessModalContainerProps,
  SharedAccountOuterContainerProps,
  SharedAccountRightContainerProps,
  SharedAccountsContainerProps,
  SharedAccountUsernameContainerProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1245px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 5.7rem - 4.4rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 12rem);
`;

export const PartnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PartnerImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
`;

export const PartnerImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const PartnerNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  height: 100%;
  max-width: 20rem;
`;

export const PartnerNameLabel = styled.div`
  padding-bottom: 1.4rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: #8492a680;
`;

export const PartnerName = styled.div`
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #292929;
  max-width: 16rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const PartnerDisconnectContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;

  & button {
    background-color: #f1f5f9;
    color: #8492a6;

    &:hover {
      background-color: #e6e6e6;
      color: #8492a6;
    }
  }

  @media (max-width: 520px) {
    padding-left: 1rem;
  }
`;

export const ShareLinkOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  position: relative;
`;

export const ShareLinkInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80rem;
  height: 6rem;
  background-color: #f1f5f9;
  border: 0.1rem solid #e0e5ea;
  border-radius: 1.6rem;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const ShareLinkContainer = styled.div`
  max-width: calc(100% - 6.2rem);
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 1.6rem;
  color: #8492a6;
`;

export const CopyShareLinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  transition: all 0.25s;

  & button {
    color: #ffffff;
    display: flex;
    justify-content: center;
    width: 7rem;

    &:hover {
      opacity: 0.9;
      color: #ffffff;
    }
  }
`;

export const SharedAccountsContainer = styled.div<SharedAccountsContainerProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: ${props => (!props.influencerPage ? '4rem' : '6rem')};
  flex-wrap: wrap;
  padding: 0 6rem;
  max-height: 27rem;
  overflow-y: auto;

  @media (max-width: 1850px) {
    padding: 0 15rem;
  }

  @media (max-width: 1620px) {
    padding: 0 10rem;
  }

  @media (max-width: 1450px) {
    padding: 0 5rem;
  }

  @media (max-width: 1330px) {
    padding: 0;
  }
`;

export const SharedAccountOuterContainer = styled.div<SharedAccountOuterContainerProps>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  ${props => {
    if (props.count == 1) {
      return css`
        width: 100%;
      `;
    }
    if (props.count == 2) {
      return css`
        width: 50%;

        @media (max-width: 1330px) {
          width: 100%;
        }
      `;
    }
    if (props.count >= 3) {
      return css`
        width: 33.3333%;

        @media (max-width: 1850px) {
          width: 50%;
        }

        @media (max-width: 1330px) {
          width: 100%;
        }
      `;
    }
  }}
`;

export const SharedAccountContainer = styled.div`
  height: 7rem;
  width: 39rem;
  display: flex;
  align-items: center;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 1.5rem;
  padding: 0 0.5rem;
`;

export const SharedAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SharedAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const SharedAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SharedAccountCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 19.5rem;
  height: 100%;
  padding-left: 0.8rem;
`;

export const SharedAccountUsernameContainer = styled.div<SharedAccountUsernameContainerProps>`
  padding-bottom: 0.9rem;
  color: #292929;
  width: 15rem;
  font-size: 1.5rem;
  font-weight: 600;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
  display: flex;
  align-items: center;

  ${props => {
    if (props.verified) {
      return css`
        align-items: center;
      `;
    }
  }}
`;

export const SharedAccountLastActionDate = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: #676767;
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
  overflow-y: hidden;
`;

export const SharedAccountUsername = styled.div`
  color: #292929;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SharedAccountExpiredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #feb038;
  background-color: #fff7eb;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.4rem;
  padding-left: 0.3rem;
  border-radius: 2rem;
  svg path {
    fill: #feb038;
  }
`;

export const SharedAccountRightContainer = styled.div<SharedAccountRightContainerProps>`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'unset')};

  & button {
    background-color: #f1f5f9;
    color: #8492a6;

    &:hover {
      background-color: #e6e6e6;
      color: #8492a6;
    }
  }
`;

export const SharedAccountsNotFoundFirstMessage = styled.div`
  font-size: 1.36rem;
  color: #8492a6;
  font-weight: 400;
`;

export const SharedAccountsNotFoundSecondMessage = styled.div`
  color: #162b39;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 2rem;
  text-align: center;
`;

export const RevokeAccessModalContainer = styled.div<RevokeAccessModalContainerProps>`
  height: ${props => (props.fullAccessRevocationMode ? '22rem' : '33rem')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RevokeAccessModalTitleContainer = styled.div`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
  max-width: 37.5rem;
  text-align: center;
`;

export const RevokeAccessModalSubtitleContainer = styled.div`
  font-size: 1.5rem;
  color: #8492a6;
  font-weight: 400;
  max-width: 48rem;
  text-align: center;
  margin: 0rem auto 0 auto;
`;

export const SharedAccountContainerToDisconnect = styled.div`
  height: 7rem;
  width: auto;
  display: flex;
  align-items: center;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 1.5rem;
  padding: 0 0.5rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const RevokeAccessModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
  margin-top: auto;

  & button {
    padding: 1.5rem 4rem;
  }
`;

export const SocialAccountCardContainer = styled.div`
  width: 7rem;
  height: 7rem;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const NameContainer = styled.div`
  width: 24rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 40%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const ActiveContainer = styled.div`
  width: 6rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const EmptyInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const EmptyInfoInnerContainer = styled.div`
  background-color: #fff;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  width: 60rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
