import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% { background-position: -20rem 0; }
  100% { background-position: 20rem 0; }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  margin-bottom: auto;
  border-radius: 2rem;
  border: solid 1px #eee;
  flex-direction: column;
  background-color: white;
`;

export const SkeletonItem = styled.div`
  display: flex;
  height: 8.2rem;
  padding: 2rem 3rem;
  align-items: center;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    border-bottom: unset;
  }
`;

export const SkeletonBlockContainer = styled.div<{ custom?: boolean }>`
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
  margin: ${({ custom }) => (custom ? '0 auto' : 'unset')};
`;

export const SkeletonBlock = styled.div<{ width: number }>`
  height: 1.6rem;
  border-radius: 0.6rem;
  background-size: 200% 100%;
  animation: ${shimmer} 5s infinite;
  width: ${({ width }) => `${width}rem`};
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
`;

export const SkeletonAvatarContainer = styled.div`
  width: 30rem;
  display: flex;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: flex-end;
`;

export const SkeletonAvatar = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-left: -1.2rem;
  align-items: flex-end;
  border: 0.3rem solid white;
  background-size: 200% 100%;
  animation: ${shimmer} 5s infinite;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);

  &:first-child {
    margin-left: 0;
  }
`;

export const SkeletonTimeframe = styled.div`
  height: 3rem;
  width: 16rem;
  border-radius: 1.5rem;
  background-size: 200% 100%;
  animation: ${shimmer} 5s infinite;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
`;

export const SkeletonActionsContainer = styled.div`
  gap: 6rem;
  display: flex;
  align-items: center;
`;
