/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useQueryClient } from 'react-query';

import StyledButton from '../../components/shared/Button/Button';
import * as S from './Styles';
import { IAdditionalDataStepProps } from './types';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import {
  ICountryComboBoxEntry,
  PartnerTypes,
  TCountriesAvailableValues,
  TopicsInterestAvailableValues,
} from '../../Services/Utils/types';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import FilePickerV2 from '../../components/shared/FilePickerV2/FilePickerV2';
import { isAuthenticated } from '../../Services/Utils/Utils';
import {
  useGetAfterSignInData,
  useGetLocations,
  useGetToken,
  useGetTopicsInterest,
} from '../../Services/Utils/CustomHooks';
import LoaderLocal from '../../components/shared/LoaderLocal/LoaderLocal';
import { IFile } from '../../components/shared/FilePickerV2/types';
import { useCompleteAdditionalDataStep } from './RQCustomHooks';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import { urlValid, valueEmpty } from '../../validations/validationFunctions';

const AdditionalDataStep = (props: IAdditionalDataStepProps) => {
  const [companyName, setCompanyName] = useState<string>('');
  const [companyWebsite, setCompanyWebsite] = useState<string>('');
  const [isCompanyWebsiteValid, setIsCompanyWebsiteValid] = useState(false);
  const [businessProfileName, setBusinessProfileName] = useState<string>('');
  const [businessProfileDescription, setBusinessProfileDescription] =
    useState<string>('');
  const [files, setFiles] = useState<IFile[]>([]);
  const [getAfterSignInDataQueryKey, setGetAfterSignInDataQueryKey] = useState(
    String(new Date().getTime()),
  );
  const [isGetAfterSignInDataEnabled, setIsGetAfterSignInDataEnabled] =
    useState<boolean>(!!isAuthenticated());
  const [isGetTokenEnabled, setIsGetTokenEnabled] = useState<boolean>(false);
  const [isGetTopicsInterestEnabled, setIsGetTopicsInterestEnabled] =
    useState<boolean>(
      Boolean(isAuthenticated()?.partnerType === PartnerTypes.influencer),
    );
  const [countriesAvailableValues, setCountriesAvailableValues] =
    useState<TCountriesAvailableValues>([]);
  const [isGetLocationsEnabled, setIsGetLocationsEnabled] =
    useState<boolean>(false);
  const [influencerName, setInfluencerName] = useState<string>('');
  const [isInfluencerNameInputFocused, setIsInfluencerNameInputFocused] =
    useState<boolean>(false);
  const [hasInfluencerNameBeenChanged, setHasInfluencerNameBeenChanged] =
    useState(false);
  const [forceInfluencerNameErrorDisplay, setForceInfluencerNameErrorDisplay] =
    useState<string | undefined>();
  const [countrySelected, setCountrySelected] = useState<
    ICountryComboBoxEntry | undefined
  >();
  const [
    isCountrySelectedComboBoxFocused,
    setIsCountrySelectedComboBoxFocused,
  ] = useState<boolean>(false);
  const [hasCountrySelectedBeenChanged, setHasCountrySelectedBeenChanged] =
    useState(false);
  const [
    forceCountrySelectedErrorDisplay,
    setForceCountrySelectedErrorDisplay,
  ] = useState<string | undefined>();
  const [topicsInterestAvailableValues, setTopicsInterestAvailableValues] =
    useState<TopicsInterestAvailableValues>([]);
  const [topicsInterestSelected, setTopicsInterestSelected] = useState<
    Array<string>
  >([]);
  const [
    isTopicsInterestSelectedComboBoxFocused,
    setIsTopicsInterestSelectedComboBoxFocused,
  ] = useState<boolean>(false);
  const [
    hasTopicsInterestSelectedBeenChanged,
    setHasTopicsInterestSelectedBeenChanged,
  ] = useState(false);
  const [
    forceTopicsInterestSelectedErrorDisplay,
    setForceTopicsInterestSelectedErrorDisplay,
  ] = useState<string | undefined>();
  const [hasConfirmButtonBeenClicked, setHasConfirmButtonBeenClicked] =
    useState(false);
  const formContainerRef = useRef(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [showLoaderLocal, setShowLoaderLocal] = useState<boolean>(true);
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const queryClient = useQueryClient();
  const {
    data: afterSignInData,
    isFetching: isFetchingGetAfterSignInData,
    isSuccess: isSuccessGetAfterSignInData,
    isError: isErrorGetAfterSignInData,
  } = useGetAfterSignInData(
    isGetAfterSignInDataEnabled,
    isAuthenticated()?.agentEmail ?? '',
    getAfterSignInDataQueryKey,
  );
  const {
    isLoading: isLoadingCompleteAdditionalDataStep,
    isSuccess: isSuccessCompleteAdditionalDataStep,
    isError: isErrorCompleteAdditionalDataStep,
    mutate: mutateCompleteAdditionalDataStep,
  } = useCompleteAdditionalDataStep();
  const {
    data: topicsInterest,
    isFetching: isFetchingGetTopicsInterest,
    isSuccess: isSuccessTopicsInterest,
    isError: isErrorGetTopicsInterest,
  } = useGetTopicsInterest(isGetTopicsInterestEnabled);
  const {
    isFetching: isFetchingGetToken,
    isSuccess: isSuccessGetToken,
    isError: isErrorGetToken,
  } = useGetToken(isGetTokenEnabled, isAuthenticated()?.agentEmail ?? '');
  const [hasGetTokenBeenExecuted, setHasGetTokenBeenExecuted] = useState(false);
  const {
    data: locations,
    isFetching: isFetchingGetLocations,
    isSuccess: isSuccessGetLocations,
    isError: isErrorGetLocations,
  } = useGetLocations(isGetLocationsEnabled);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    } else {
      setShowLoaderLocal(true);
    }
  }, []);

  useEffect(() => {
    if (
      isFetchingGetAfterSignInData ||
      isFetchingGetToken ||
      isFetchingGetLocations
    ) {
      setShowLoaderLocal(true);
    }
  }, [
    isFetchingGetAfterSignInData,
    isFetchingGetToken,
    isFetchingGetLocations,
  ]);

  useEffect(() => {
    if (isInfluencerNameInputFocused && forceInfluencerNameErrorDisplay) {
      setForceInfluencerNameErrorDisplay(undefined);
    }
    if (isCountrySelectedComboBoxFocused && forceCountrySelectedErrorDisplay) {
      setForceCountrySelectedErrorDisplay(undefined);
    }
    if (
      isTopicsInterestSelectedComboBoxFocused &&
      forceTopicsInterestSelectedErrorDisplay
    ) {
      setForceTopicsInterestSelectedErrorDisplay(undefined);
    }
  }, [
    isInfluencerNameInputFocused,
    forceInfluencerNameErrorDisplay,
    isCountrySelectedComboBoxFocused,
    forceCountrySelectedErrorDisplay,
    isTopicsInterestSelectedComboBoxFocused,
    forceTopicsInterestSelectedErrorDisplay,
  ]);

  useEffect(() => {
    if (isSuccessGetAfterSignInData && !isFetchingGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(false);
      if (!afterSignInData.isEmailVerified) {
        navigate('/account-activation');
      } else if (afterSignInData.isAdditionalDataStepComplete) {
        navigate('/discover');
      } else if (isAuthenticated()!.partnerType != 'INFLUENCER') {
        setCompanyWebsite(afterSignInData.partnerWebsite!);
        setShowLoaderLocal(false);
      } else {
        setIsGetLocationsEnabled(true);
      }
    }
  }, [isSuccessGetAfterSignInData, isFetchingGetAfterSignInData]);

  useEffect(() => {
    if (locations && !isFetchingGetLocations) {
      const countriesAvailableValues: TCountriesAvailableValues = [];
      locations.forEach((entry, key) => {
        countriesAvailableValues.push({
          value: key,
          label: entry.country,
          data: {
            country: entry.country,
            countryId: entry.countryId,
          },
        });
      });
      setCountriesAvailableValues(countriesAvailableValues);
      setShowLoaderLocal(false);
    }
  }, [isSuccessGetLocations, isFetchingGetLocations]);

  useEffect(() => {
    if (isSuccessTopicsInterest && topicsInterest) {
      setIsGetTopicsInterestEnabled(false);
      const topicsInterestAvailableValuesLocal: TopicsInterestAvailableValues =
        [];

      topicsInterest.forEach((label, key) => {
        topicsInterestAvailableValuesLocal.push({
          value: key,
          label: props.t(label),
        });
      });

      setTopicsInterestAvailableValues(topicsInterestAvailableValuesLocal);
    }
  }, [isSuccessTopicsInterest, topicsInterest]);

  useEffect(() => {
    if (isErrorGetAfterSignInData) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotGetEssentialData'));
      setIsGetAfterSignInDataEnabled(false);
    }
  }, [isErrorGetAfterSignInData]);

  useEffect(() => {
    if (isErrorGetLocations) {
      setHasError(true);
      setMsgError(props.t('signin.generic.couldNotGetEssentialData'));
      setIsGetLocationsEnabled(false);
    }
  }, [isErrorGetLocations]);

  useEffect(() => {
    if (isErrorCompleteAdditionalDataStep) {
      setHasError(true);
      setMsgError(props.t('errorBoundary.errorFound'));
    }
  }, [isErrorCompleteAdditionalDataStep]);

  useEffect(() => {
    if (isErrorGetToken) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotUpdateToken'));
      setIsGetTokenEnabled(false);
    }
  }, [isErrorGetToken]);

  useEffect(() => {
    if (isErrorGetTopicsInterest) {
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetTopicsOfInterest'));
      setIsGetTopicsInterestEnabled(false);
    }
  }, [isErrorGetTopicsInterest]);

  useEffect(() => {
    if (isSuccessCompleteAdditionalDataStep) {
      setIsGetTokenEnabled(true);
      setHasGetTokenBeenExecuted(true);

      const invalidateGetPartnerBusinessProfilesData = async () => {
        await queryClient.invalidateQueries({
          queryKey: [
            'getPartnerBusinessProfiles',
            isAuthenticated()?.partnerId,
          ],
        });
      };

      invalidateGetPartnerBusinessProfilesData();
    }
  }, [isSuccessCompleteAdditionalDataStep]);

  useEffect(() => {
    if (isSuccessGetToken && hasGetTokenBeenExecuted && !isFetchingGetToken) {
      navigate('/discover');
    }
  }, [isSuccessGetToken, hasGetTokenBeenExecuted, isFetchingGetToken]);

  const isContinueButtonDisabled = () => {
    const sessionData = isAuthenticated();
    if (sessionData?.partnerType == 'INFLUENCER') {
      if (
        !influencerName ||
        !countrySelected ||
        !topicsInterestSelected.length ||
        showLoaderLocal ||
        isLoadingCompleteAdditionalDataStep ||
        isFetchingGetAfterSignInData
      )
        return true;
      return false;
    }
    if (
      sessionData?.partnerType == 'BRAND' ||
      sessionData?.partnerType == 'AGENCY'
    ) {
      if (
        (!companyName && !companyWebsite && !businessProfileName) ||
        (companyWebsite && !isCompanyWebsiteValid) ||
        isLoadingCompleteAdditionalDataStep ||
        isFetchingGetAfterSignInData ||
        showLoaderLocal
      )
        return true;
      return false;
    }
  };

  const continueActionInvoked = (skip?: boolean) => {
    if (skip) {
      mutateCompleteAdditionalDataStep({
        completeAdditionalDataStepParams: {
          companyName: '',
          companyWebsite: '',
          businessProfileName: '',
          businessProfileDescription: '',
          businessProfilePictureUrl: '',
          influencerName: '',
          topicsOfInterest: [],
        },
      });
    } else {
      if (isContinueButtonDisabled()) {
        if (valueEmpty(influencerName)) {
          setForceInfluencerNameErrorDisplay(props.t('generic.nameIsRequired'));
        }
        if (!countrySelected) {
          setForceCountrySelectedErrorDisplay(
            props.t('generic.countryIsRequired'),
          );
        }
        if (!topicsInterestSelected.length) {
          setForceTopicsInterestSelectedErrorDisplay(
            props.t('generic.topicsOfInterestIsRequired'),
          );
        }
      } else {
        let businessProfilePictureFormData;
        if (files[0]?.data) {
          businessProfilePictureFormData = new FormData();
          businessProfilePictureFormData.append('file', files[0]!.data);
          businessProfilePictureFormData.append(
            'source',
            FileSourceEnum.BRAND_PICTURE,
          );
          businessProfilePictureFormData.append(
            'id',
            isAuthenticated()?.businessProfileUserId!,
          );
        }
        mutateCompleteAdditionalDataStep({
          completeAdditionalDataStepParams: {
            companyName: companyName || '',
            companyWebsite: companyWebsite || '',
            businessProfileName: businessProfileName || '',
            businessProfileDescription: businessProfileDescription || '',
            businessProfilePictureUrl: '',
            influencerName,
            topicsOfInterest: topicsInterestSelected,
            countryCode: countrySelected?.value ?? '',
            countryId: countrySelected?.data.countryId ?? '',
            country: countrySelected?.data.country ?? '',
          },
          businessProfilePictureData: businessProfilePictureFormData,
        });
      }

      setHasConfirmButtonBeenClicked(true);
    }
  };

  const onErrorModalCloseButtonClick = () => {
    if (isErrorGetAfterSignInData) {
      setIsGetAfterSignInDataEnabled(true);
      setGetAfterSignInDataQueryKey(String(new Date().getTime()));
    }
    if (isErrorGetLocations) {
      setIsGetLocationsEnabled(true);
    }
    if (isErrorGetTopicsInterest) {
      setIsGetTopicsInterestEnabled(true);
    }
    if (isErrorGetToken) {
      setIsGetTokenEnabled(true);
      setHasGetTokenBeenExecuted(true);
    }
    setHasError(false);
  };

  const renderFormContent = () => {
    return (
      <S.InfluencerContainer>
        <LoaderLocal
          show={
            isFetchingGetAfterSignInData ||
            isFetchingGetLocations ||
            isFetchingGetTopicsInterest ||
            showLoaderLocal
          }
          message={props.t('generic.loading1')}
          borderRadius={'2.4rem'}
          spinner={true}
          spinnerFontSize={'1.7rem'}
        >
          <S.InfluencerContainerInner>
            <S.HeaderOuterContainer>
              <S.HeaderContainer>
                <S.HeaderBackArrowContainer
                  onClick={() => {
                    // localStorage.removeItem('jwt');
                    navigate('/');
                  }}
                >
                  <BackArrowIcon width={'2.8rem'} height={'2.8rem'} />
                </S.HeaderBackArrowContainer>
                <S.HeaderTitlesContainer>
                  <S.HeaderTitleContainer>
                    {props.t(
                      `additionalDataStep.${
                        isAuthenticated()?.partnerType ===
                        PartnerTypes.influencer
                          ? 'influencerTitle'
                          : 'businessAccountTitle'
                      }`,
                    )}
                  </S.HeaderTitleContainer>
                  {isAuthenticated()?.partnerType ===
                    PartnerTypes.influencer && (
                    <S.HeaderSubtitleContainer>
                      {props.t('additionalDataStep.subtitle')}
                    </S.HeaderSubtitleContainer>
                  )}
                </S.HeaderTitlesContainer>

                <S.HiddenHeaderElement />
              </S.HeaderContainer>
              <S.HeaderDivider />
            </S.HeaderOuterContainer>

            <S.FormContainer ref={formContainerRef}>
              {isAuthenticated()?.partnerType === PartnerTypes.influencer && (
                <>
                  <S.InfluencerNameAndCountriesContainer>
                    <S.InfluencerNameInputContainer>
                      <InputControlV2
                        value={influencerName}
                        onChange={(inputValue: string) => {
                          setInfluencerName(inputValue);
                          setHasInfluencerNameBeenChanged(true);
                        }}
                        title={props.t('generic.name')}
                        inputFieldContainerWithTitleWidth={'100%'}
                        setFocused={focused =>
                          setIsInfluencerNameInputFocused(focused)
                        }
                        setBlurred={blurred => {
                          setIsInfluencerNameInputFocused(!blurred);
                          if (
                            !influencerName.length &&
                            (hasInfluencerNameBeenChanged ||
                              hasConfirmButtonBeenClicked)
                          ) {
                            setForceInfluencerNameErrorDisplay(
                              props.t('generic.nameIsRequired'),
                            );
                          }
                        }}
                        forceErrorDisplay={forceInfluencerNameErrorDisplay}
                        forceBorderColorRed={Boolean(
                          forceInfluencerNameErrorDisplay,
                        )}
                      />
                    </S.InfluencerNameInputContainer>

                    <S.InfluencerCountryContainer>
                      <S.TopicsInterestTitleContainer>
                        {props.t('generic.country')}
                      </S.TopicsInterestTitleContainer>

                      <ComboBox
                        valueKey={'countrySelected'}
                        value={countrySelected}
                        type={'single'}
                        width={'100%'}
                        borderRadius={'0.6rem'}
                        valuesAvailable={countriesAvailableValues}
                        isFilterable={true}
                        isClearable={true}
                        onChange={(obj: {
                          countrySelected: ICountryComboBoxEntry;
                        }) => {
                          setCountrySelected(obj.countrySelected);
                          setHasCountrySelectedBeenChanged(true);
                        }}
                        height={'4.4rem'}
                        availableOptionsContainerBorderRadius={'0.6rem'}
                        optionContainerBorderRadius={'0.6rem'}
                        innerOptionsContainerMarginTop={'1.2rem'}
                        innerOptionsContainerMarginBottom={'1.2rem'}
                        innerOptionsContainerPaddingLeft={'1.2rem'}
                        innerOptionsContainerPaddingRight={'1.2rem'}
                        optionContainerPaddingTop={'2rem'}
                        optionContainerPaddingBottom={'2rem'}
                        optionContainerPaddingLeft={'2rem'}
                        optionContainerPaddingRight={'2rem'}
                        themeStyles={
                          (themeGlobal as any).comboBoxTopicsOfInterest
                        }
                        changeBackgroundColorOnHover={false}
                        innerOptionsContainerMaxHeight={'20rem'}
                        onChangeShowDropdown={(showDropdown: boolean) => {
                          if (showDropdown) {
                            (formContainerRef!.current as any).scrollTop = (
                              formContainerRef?.current as any
                            ).scrollHeight;
                            setIsCountrySelectedComboBoxFocused(true);
                          }
                        }}
                        setBlurred={blurred => {
                          setIsCountrySelectedComboBoxFocused(!blurred);
                          if (
                            !countrySelected &&
                            (hasCountrySelectedBeenChanged ||
                              hasConfirmButtonBeenClicked)
                          ) {
                            setForceCountrySelectedErrorDisplay(
                              props.t('generic.countryIsRequired'),
                            );
                          }
                        }}
                        forceErrorDisplay={forceCountrySelectedErrorDisplay}
                        forceBorderColorRed={Boolean(
                          forceCountrySelectedErrorDisplay,
                        )}
                      />
                    </S.InfluencerCountryContainer>
                  </S.InfluencerNameAndCountriesContainer>

                  <S.TopicsInterestContainer>
                    <S.TopicsInterestTitleContainer>
                      {props.t('generic.topicsOfInterest')}
                    </S.TopicsInterestTitleContainer>

                    <ComboBox
                      valueKey={'topicsInterestSelected'}
                      value={topicsInterestSelected}
                      type={'multi'}
                      width={'100%'}
                      marginTop={'0.5rem'}
                      borderRadius={'0.6rem'}
                      valuesAvailable={topicsInterestAvailableValues}
                      isFilterable={true}
                      isClearable={true}
                      placeholder={props.t(
                        'profileEdit.selectNoMoreThanFiveTopics',
                      )}
                      onChange={(obj: {
                        topicsInterestSelected: Array<string>;
                      }) => {
                        setTopicsInterestSelected(obj.topicsInterestSelected);
                        setHasTopicsInterestSelectedBeenChanged(true);
                      }}
                      preventOptionSelection={
                        topicsInterestSelected.length === 5
                      }
                      height={'7rem'}
                      availableOptionsContainerBorderRadius={'0.6rem'}
                      optionContainerBorderRadius={'0.6rem'}
                      innerOptionsContainerMarginTop={'1.2rem'}
                      innerOptionsContainerMarginBottom={'1.2rem'}
                      innerOptionsContainerPaddingLeft={'1.2rem'}
                      innerOptionsContainerPaddingRight={'1.2rem'}
                      optionContainerPaddingTop={'2rem'}
                      optionContainerPaddingBottom={'2rem'}
                      optionContainerPaddingLeft={'2rem'}
                      optionContainerPaddingRight={'2rem'}
                      optionSelectedContainerBorderRadius={'2rem'}
                      themeStyles={(themeGlobal as any).comboBoxTags}
                      changeBackgroundColorOnHover={false}
                      crossIconV2={true}
                      growWithoutScroll={true}
                      onChangeShowDropdown={(showDropdown: boolean) => {
                        if (showDropdown) {
                          (formContainerRef!.current as any).scrollTop = (
                            formContainerRef?.current as any
                          ).scrollHeight;
                          setIsTopicsInterestSelectedComboBoxFocused(true);
                        }
                      }}
                      setBlurred={blurred => {
                        setIsTopicsInterestSelectedComboBoxFocused(!blurred);
                        if (
                          !topicsInterestSelected.length &&
                          (hasTopicsInterestSelectedBeenChanged ||
                            hasConfirmButtonBeenClicked)
                        ) {
                          setForceTopicsInterestSelectedErrorDisplay(
                            props.t('generic.topicsOfInterestIsRequired'),
                          );
                        }
                      }}
                      forceErrorDisplay={
                        forceTopicsInterestSelectedErrorDisplay
                      }
                      forceBorderColorRed={Boolean(
                        forceTopicsInterestSelectedErrorDisplay,
                      )}
                    />
                  </S.TopicsInterestContainer>
                </>
              )}

              {isAuthenticated()?.partnerType !== PartnerTypes.influencer && (
                <S.CompanyFormContainer>
                  <S.CompanyDetailsContainer>
                    <S.CompanyDetailsTitle>
                      {props.t('additionalDataStep.companyDetails')}
                    </S.CompanyDetailsTitle>

                    <S.CompanyDetailsForm>
                      <div>
                        <InputControlV2
                          value={companyName}
                          onChange={(inputValue: string) =>
                            setCompanyName(inputValue)
                          }
                          title={props.t('generic.name')}
                          inputFieldContainerWithTitleWidth={'100%'}
                        />
                      </div>

                      <div>
                        <InputControlV2
                          value={companyWebsite}
                          onChange={(inputValue: string) =>
                            setCompanyWebsite(inputValue)
                          }
                          validationFunction={urlValid}
                          setIsInvalidOrProcessing={(
                            isInvalidOrProcessing: boolean,
                          ) => setIsCompanyWebsiteValid(!isInvalidOrProcessing)}
                          title={props.t('generic.website')}
                          inputFieldContainerWithTitleWidth={'100%'}
                          invalidFieldWarningMessage={props.t(
                            'signin.generic.websiteFormatIsNotValid',
                          )}
                          warningMessageContainerPositionAbsolute={true}
                          warningMessageContainerTopOffset={'0.1rem'}
                          warningMessageContainerLeftOffset={'0.2rem'}
                        />
                      </div>
                    </S.CompanyDetailsForm>
                  </S.CompanyDetailsContainer>

                  <S.CompanyBrandProfile>
                    <S.CompanyBrandProfileTitle>
                      {props.t('additionalDataStep.createBusinessProfileTitle')}
                    </S.CompanyBrandProfileTitle>

                    <S.CompanyBrandProfileForm>
                      <S.BPImagePickerContainer>
                        <S.BPImagePickerButtonContainer>
                          <FilePickerV2
                            handleChange={(files: IFile[]) => setFiles(files)}
                            text={props.t('additionalDataStep.selectPicture')}
                            showSelectedFiles={false}
                            single={true}
                            type={'images'}
                            marginTop={'1rem'}
                          />
                        </S.BPImagePickerButtonContainer>
                      </S.BPImagePickerContainer>

                      <S.BusinessProfileCreationInputsContainer>
                        <S.BusinessProfileNameInputContainer>
                          <InputControlV2
                            value={businessProfileName}
                            onChange={(inputValue: string) =>
                              setBusinessProfileName(inputValue)
                            }
                            title={props.t('generic.name')}
                            inputFieldContainerWithTitleWidth={'100%'}
                          />
                        </S.BusinessProfileNameInputContainer>

                        <S.BusinessProfileWebsiteInputContainer>
                          <InputControlV2
                            value={businessProfileDescription}
                            onChange={(inputValue: string) =>
                              setBusinessProfileDescription(inputValue)
                            }
                            title={props.t('generic.description')}
                            inputFieldContainerWithTitleWidth={'100%'}
                          />
                        </S.BusinessProfileWebsiteInputContainer>
                      </S.BusinessProfileCreationInputsContainer>
                    </S.CompanyBrandProfileForm>
                  </S.CompanyBrandProfile>
                </S.CompanyFormContainer>
              )}
            </S.FormContainer>

            <S.FooterContainer>
              <S.ContinueButtonContainer>
                {isAuthenticated()?.partnerType !== PartnerTypes.influencer && (
                  <StyledButton
                    onClick={() => continueActionInvoked(true)}
                    marginRight={'1rem'}
                  >
                    {props.t('generic.skip')}
                  </StyledButton>
                )}

                <StyledButton
                  cursorOnDisable={'default'}
                  blockHoverStylesOnDisable={true}
                  pointerEventsOnDisable={'all'}
                  disabledNotNative={isContinueButtonDisabled()}
                  onClick={() => continueActionInvoked()}
                >
                  {props.t('generic.confirm')}
                </StyledButton>
              </S.ContinueButtonContainer>
            </S.FooterContainer>
          </S.InfluencerContainerInner>
        </LoaderLocal>
      </S.InfluencerContainer>
    );
  };

  return (
    <S.OuterContainer>
      {renderFormContent()}

      <NotifyCustom
        location={'bottom'}
        show={isLoadingCompleteAdditionalDataStep}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t('additionalDataStep.updatingPartner')}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => onErrorModalCloseButtonClick()}
      />
    </S.OuterContainer>
  );
};

export default withTranslation()(AdditionalDataStep);
