import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 4rem 30rem;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  margin-left: 1rem;
  margin-right: 2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #292929;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
`;
