/* eslint-disable import/extensions */
/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { withTranslation } from 'react-i18next';

import CardChartEvolutionPerformance from '../CardChartEvolutionPerformance/CardChartEvolutionPerformance';
import InstagramIcon from '../../../../assets/iconComponents/InstagramIcon';
import YoutubeIcon from '../../../../assets/iconComponents/YoutubeIcon';
import { ICardChartEvolutionPerformanceProps } from '../CardChartEvolutionPerformance/types';
import { SocialNetworks } from '../../../../Services/Utils/types';
import * as S from './styles';

const GetOptionsChart = (
  key: string,
  t: (path: string) => string,
  location: any,
  date: any,
  networkSelectedId: any,
  socialNetworksConfig: any,
) => {
  let timeframeInDays;
  if (date.startDate === undefined && date.endDate === undefined) {
    timeframeInDays = 30;
  } else {
    timeframeInDays =
      (Date.parse(date.endDate) - Date.parse(date.startDate)) /
      1000 /
      60 /
      60 /
      24;
  }
  if (
    (networkSelectedId !== undefined ||
      location.pathname.includes('public-user-media-kit')) &&
    socialNetworksConfig &&
    socialNetworksConfig.length > 0
  ) {
    const options = {
      color: [] as any,
      series: [] as any,
      totals: [] as any,
    };
    const arr = socialNetworksConfig;
    for (const element of arr) {
      if (
        element.sn !== networkSelectedId &&
        !location.pathname.includes('public-user-media-kit')
      )
        continue;
      options.color.push(element.chartColor);
      options.series.push({
        key: location.pathname.includes('public-user-media-kit')
          ? /* key */ `${element.sn}.${key}`
          : location.pathname.includes('benchmark')
          ? key
          : `${element.sn}.${key}`,
        label: location.pathname.includes('benchmark')
          ? t(`generic.${key}`)
          : t(element.label),
      });
      const eleList: any = [
        {
          key: location.pathname.includes('public-user-media-kit')
            ? /* key */ `${element.sn}.${key}`
            : location.pathname.includes('benchmark')
            ? key
            : `${element.sn}.${key}`,
          label: `AVG${
            location.pathname.includes('public-user-media-kit')
              ? /* '' */ ` ${t(element.label)}`
              : ` ${t(element.label)}`
          }`,
          icon: element.icon,
          type:
            key === 'insight_post_engagement_rate' ||
            key === 'engagement_rate' ||
            key === 'insight_engagement_rate'
              ? 'perc'
              : 'number',
          fnType:
            key === 'insight_post_engagement_rate' ||
            key === 'engagement_rate' ||
            key === 'insight_engagement_rate'
              ? 'rate'
              : 'avg',
          color: element.color,
        },
      ];

      eleList.push({
        key:
          key === 'insight_engagement_rate' || key === 'engagement_rate'
            ? null
            : location.pathname.includes('public-user-media-kit')
            ? /* key */ `${element.sn}.${key}`
            : location.pathname.includes('benchmark')
            ? key
            : `${element.sn}.${key}`,
        label:
          key === 'insight_engagement_rate' || key === 'engagement_rate'
            ? null
            : `Total${
                location.pathname.includes('public-user-media-kit')
                  ? /* '' */ ` ${t(element.label)}`
                  : ` ${t(element.label)}`
              }`,
        type:
          key === 'insight_engagement_rate' || key === 'engagement_rate'
            ? null
            : 'number',
        fnType:
          key === 'insight_engagement_rate' || key === 'engagement_rate'
            ? null
            : 'total',
        icon: element.icon,
        color: element.color,
      });

      eleList.push({
        key:
          timeframeInDays >= 180
            ? null
            : location.pathname.includes('public-user-media-kit')
            ? /* key */ `${element.sn}.${key}`
            : location.pathname.includes('benchmark')
            ? key
            : `${element.sn}.${key}`,
        label: timeframeInDays >= 180 ? null : 'Evolution in this Period',
        type:
          timeframeInDays >= 180
            ? null
            : key === 'insight_engagement_rate' || key === 'engagement_rate'
            ? 'pp'
            : 'perc',
        fnType: timeframeInDays >= 180 ? null : 'total',
        keyType: timeframeInDays >= 180 ? null : 'VS',
        icon: element.icon,
        color: element.color,
        digits: 2,
      });

      {
        !location.pathname.includes('benchmark') &&
          eleList.push({
            key:
              timeframeInDays >= 180
                ? null
                : location.pathname.includes('public-user-media-kit')
                ? /* key */ `${element.sn}.${key}`
                : `${element.sn}.${key}`,
            label: timeframeInDays >= 180 ? null : 'VS prev. Period',
            type:
              timeframeInDays >= 180
                ? null
                : key === 'insight_engagement_rate' || key === 'engagement_rate'
                ? 'pp'
                : 'perc',
            fnType: timeframeInDays >= 180 ? null : 'total',
            keyType: timeframeInDays >= 180 ? null : 'VS2',
            icon: element.icon,
            color: element.color,
            digits: 2,
          });
      }
      options.totals.push(...eleList);
    }
    return options;
  }
  return [];
};

const EvolutionPerformance = (props: any) => {
  const {
    filteredKPIs,
    olderKpis,
    filteredKPIsFromPosts,
    networkSelectedId,
    date,
    isDataFilteredFromPosts,
  } = props.data;

  const getCardChartEvolutionPerformanceProps = ({
    getOptionsChartLabel,
  }: {
    getOptionsChartLabel: string;
  }): Partial<ICardChartEvolutionPerformanceProps> => {
    let data;
    let olderData;

    if (getOptionsChartLabel === 'audience') {
      data = filteredKPIs || [];

      olderData = olderKpis || [];
    } else {
      data =
        (isDataFilteredFromPosts ? filteredKPIsFromPosts : filteredKPIs) || [];

      olderData = (isDataFilteredFromPosts ? [] : olderKpis) || [];
    }

    return {
      height: 350,
      data,
      olderData,
      loading: false,
      sortedBy: 'date',
      valueType: 'number',
      disableCombo: true,
      showAvg: true,
      showEvolution: true,
      convertNumberToReadableString: true,
      showTotal: true,
      kpisOverview:
        props.data.isDataFilteredFromPosts &&
        getOptionsChartLabel !== 'audience'
          ? props.data.kpisFilteredFromPostsOverview
          : props.data.kpisOverview,
      ...GetOptionsChart(
        getOptionsChartLabel,
        (path: string) => props.t(path),
        { pathname: 'public-user-media-kit' },
        { startDate: date[0].startDate, endDate: date[0].endDate },
        networkSelectedId,
        [getSocialNetworkConfig(props.data.networkSelectedId)],
      ),
      socialNetwork: networkSelectedId,
    };
  };

  return (
    <S.EvolutionPerformanceMainContainer>
      <S.EvolutionPerformanceContainer>
        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'audience',
            })}
            keyId={'audience'}
            title={props.t('generic.followers')}
            showTotal={false}
          />
        </S.ChartPerformanceContainer>

        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'likes',
            })}
            title={props.t('generic.likes')}
            showEvolution={!isDataFilteredFromPosts}
          />
        </S.ChartPerformanceContainer>

        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'engagement',
            })}
            title={props.t('generic.reactions')}
            showEvolution={!isDataFilteredFromPosts}
          />
        </S.ChartPerformanceContainer>

        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'engagement_rate',
            })}
            title={props.t(
              'mediaKit.evolutionPerformance.engagementRate.title',
            )}
            showTotal={false}
            keyId={'engRate'}
            valueType={'perc'}
            convertNumberToReadableString={false}
            showEvolution={!isDataFilteredFromPosts}
          />
        </S.ChartPerformanceContainer>

        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'posts',
            })}
            title={props.t(
              `generic.${
                networkSelectedId === SocialNetworks.YOUTUBE ? 'video' : 'post'
              }Count`,
            )}
            keyId={'postCount1'}
            showEvolution={!isDataFilteredFromPosts}
          />
        </S.ChartPerformanceContainer>

        <S.ChartPerformanceContainer>
          <CardChartEvolutionPerformance
            {...getCardChartEvolutionPerformanceProps({
              getOptionsChartLabel: 'comments',
            })}
            title={props.t('generic.comments')}
            showEvolution={!isDataFilteredFromPosts}
          />
        </S.ChartPerformanceContainer>

        {!isDataFilteredFromPosts && (
          <>
            <S.ChartPerformanceContainer>
              <CardChartEvolutionPerformance
                {...getCardChartEvolutionPerformanceProps({
                  getOptionsChartLabel: 'insight_post_impressions',
                })}
                title={props.t(
                  `generic.impressionsPer${
                    networkSelectedId === SocialNetworks.YOUTUBE
                      ? 'Video'
                      : 'Post'
                  }`,
                )}
              />
            </S.ChartPerformanceContainer>

            <S.ChartPerformanceContainer>
              <CardChartEvolutionPerformance
                {...getCardChartEvolutionPerformanceProps({
                  getOptionsChartLabel: 'insight_post_reach',
                })}
                title={props.t(
                  `generic.reachPer${
                    networkSelectedId === SocialNetworks.YOUTUBE
                      ? 'Video'
                      : 'Post'
                  }`,
                )}
              />
            </S.ChartPerformanceContainer>
          </>
        )}
      </S.EvolutionPerformanceContainer>
    </S.EvolutionPerformanceMainContainer>
  );
};

export default withTranslation()(EvolutionPerformance);

const getSocialNetworkConfig = (network: SocialNetworks) => {
  switch (network) {
    case SocialNetworks.INSTAGRAM:
    case SocialNetworks.FACEBOOKPAGEINSTAGRAM:
      return {
        sn: SocialNetworks.FACEBOOKPAGEINSTAGRAM,
        chartColor: '#C32AA3',
        label: 'socialNetworks.FACEBOOKPAGEINSTAGRAM',
        color: 'instagram-pro',
        icon: InstagramIcon,
      };
    case SocialNetworks.YOUTUBE:
      return {
        sn: SocialNetworks.YOUTUBE,
        chartColor: '#C32AA3',
        label: 'socialNetworks.YOUTUBE',
        color: 'youtube',
        icon: YoutubeIcon,
      };
  }
};
